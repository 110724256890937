import React from 'react';
import { useField } from 'formik';
import OutlinedTextInput, { OutlinedTextInputProps } from './OutlinedTextInput';

export interface StandardTextInputFieldProps extends OutlinedTextInputProps {
  readonly name: string; // override(name is required)
}

export default function OutlinedTextInputField({
  name,
  value,
  onChange,
  ...props
}: StandardTextInputFieldProps) {
  const [field, meta] = useField(name);

  const error = !!(meta.error && meta.touched);

  return (
    <OutlinedTextInput
      {...field}
      name={name}
      error={error}
      helperText={meta.error}
      onChange={(e: React.ChangeEvent<any>) => {
        (onChange as Function)?.call(null, e); // FIXME type
        field.onChange(e);
      }}
      {...props}
    />
  );
}
