import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import styled from "styled-components";
import AppBar from "@material-ui/core/AppBar";
import Select from "@material-ui/core/Select";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Box from "@material-ui/core/Box";
import Menu from "@material-ui/core/Menu";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuIcon from "@material-ui/icons/Menu";
import { useApolloClient } from "@apollo/react-hooks";
import Logo from "../../images/logos/header_logo.svg";
import { useLogisticsQuery } from "../../generated/graphql";

const GET_CLIENT_STATUS = gql`
  {
    isAuthenticated @client
    logisticId @client
  }
`;

const useStyles = makeStyles((theme) => ({
  appBar: {
    paddingRight: "2rem",
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: "none",
  },
  sellerSelect: {
    marginLeft: theme.spacing(2),
    width: 180,
    height: 30,
    backgroundColor: "#404553",
  },
  selectMenuPaper: {
    borderRadius: "3px",
  },
  selectMenuList: {
    padding: 0,
  },
  menuItemRoot: {
    backgroundColor: "#272d3d",
    color: "white",
    height: 30,
    fontSize: theme.typography.pxToRem(14),
  },
  white: {
    color: "white",
    fill: "white !important",
    fontSize: theme.typography.pxToRem(14),
  },
  actions: {
    display: "flex",
  },
}));

const StyledToolbar = styled(Toolbar)({
  justifyContent: "space-between",
  padding: "0.5rem 0",
  paddingLeft: "1.5rem",
});

const MenuButton = styled(IconButton)({
  marginLeft: -15,
  width: 72,
});

export default ({ onToggleMenu }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const client = useApolloClient();
  const open = Boolean(anchorEl);
  const { loading, error, data } = useQuery(GET_CLIENT_STATUS);

  const { data: logisticsData } = useLogisticsQuery({
    variables: {
      where: {
        company: {
          deletedAt: {
            equals: null,
          },
        },
      },
    },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.clear();
    client.clearStore();
  };

  const handleChange = (e) => {
    client.writeData({
      data: {
        logisticId: e.target.value,
      },
    });
  };

  return (
    <AppBar position="fixed" className={classes.appBar} color="primary">
      <StyledToolbar>
        <Box display="flex" alignItems="center">
          <MenuButton
            color="inherit"
            aria-label="open drawer"
            onClick={onToggleMenu}
          >
            <MenuIcon />
          </MenuButton>
          <img src={Logo} alt="logo" style={{ width: 120, height: 40 }} />
          {logisticsData?.logistics.length && (
            <Select
              className={classes.sellerSelect}
              classes={{
                iconOutlined: classes.white,
                outlined: classes.white,
              }}
              MenuProps={{
                classes: {
                  paper: classes.selectMenuPaper,
                  list: classes.selectMenuList,
                },
              }}
              variant="outlined"
              value={data?.logisticId}
              onChange={handleChange}
              fullWidth
            >
              <MenuItem
                classes={{
                  root: classes.menuItemRoot,
                }}
                value={""}
              >
                전체
              </MenuItem>
              {logisticsData?.logistics.map(({ id, company }) => (
                <MenuItem
                  classes={{
                    root: classes.menuItemRoot,
                  }}
                  key={id}
                  value={id}
                >
                  {company.name}
                </MenuItem>
              ))}
            </Select>
          )}
        </Box>
        <div className={classes.actions}>
          {data.isAuthenticated && (
            <div>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem
                  component={Link}
                  to={"/profile"}
                  onClick={handleClose}
                >
                  Profile
                </MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </div>
          )}
        </div>
      </StyledToolbar>
    </AppBar>
  );
};
