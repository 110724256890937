import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

export const GET_LOCAL_STATE = gql`
  query {
    isAuthenticated @client
    companyId @client
    logisticId @client
    user @client {
      id
      email
      name
      phone
      role
      type
      company {
        id
      }
      team {
        id
        name
      }
    }
  }
`;
const useLocalStore = () => {
  const { loading, error, data } = useQuery(GET_LOCAL_STATE, {
    onError: (e) => console.log(e),
  });

  return { loading, error, data };
};

export default useLocalStore;
