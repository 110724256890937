import React, { useCallback, useState } from "react";
import XLSX from "xlsx";
import styled from "styled-components";
import LinearProgress from "@material-ui/core/LinearProgress";
import {
  Box,
  Button,
  Container,
  FormHelperText,
  IconButton,
  Snackbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import NumberFormat from "react-number-format";
import { isValid } from "date-fns";
import * as Yup from "yup";
import { ReactComponent as Excel } from "../../images/excel.svg";
import { ReactComponent as ExcelDownload } from "../../images/download.svg";
import useExcelReader from "../../hooks/getExcelReader";
import {
  useUploadExcelStockMutation,
  useSellersQuery,
  useBrandsQuery,
  useCentersQuery,
  UploadExcelStockInputType,
  LoadUnit,
} from "../../generated/graphql";
import useLocalStore from "../../hooks/useLocalStore";
import { Alert } from "@material-ui/lab";

function getKeyByValue(object: any, value: string) {
  return Object.keys(object).find((key) => object[key] === value);
}

const LOAD_UNIT_LABELS = {
  [LoadUnit.Each]: "개별",
  [LoadUnit.Box]: "박스",
  [LoadUnit.Pallet]: "팔레트",
  [LoadUnit.Container]: "컨테이너",
};

const EXCEL_DATA_RECORD: Record<string, string> = {
  idx: "구분",
  sellerName: "화주사",
  brandName: "브랜드",
  productName: "상품명",
  levelOneName: "옵션1 이름",
  levelTwoName: "옵션2 이름",
  levelOne: "옵션1 값",
  levelTwo: "옵션2 값",
  amount: "개별상품 입고예정 수량",
  loadUnit: "입고 단위",
  loadUnitAmount: "입고 단위 수량",
  scheduledDate: "입고예정 일자(YYYY-MM-DD)",
  manufacturedDate: "제조일자(YYYY-MM-DD)",
  expiryDate: "유통기한(YYYY-MM-DD)",
  note: "비고",
  centerName: "센터명",
  zoneName: "ZONE",
  rackName: "1단계",
  levelName: "2단계",
};

const Dropzone = styled.div(({ theme }) => ({
  borderRadius: "3px",
  border: "dashed 1px #a8aab1",
  height: 200,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  "&:focus": {
    outline: "none",
  },
}));

const InstructionTypo = styled(Typography)(({ theme }) => ({
  whiteSpace: "pre",
  lineHeight: "29px",
  color: "#a8aab1",
  marginBottom: theme.spacing(1),
}));

export interface Props {
  onSubmit?: (values: any) => void;
}

const parseDateString = (dateString?: string): Date | undefined => {
  if (!dateString) {
    return undefined;
  }

  const dateObject = new Date(dateString);

  if (isValid(dateObject)) {
    const year = dateObject.getFullYear();
    const month = dateObject.getMonth();
    const date = dateObject.getDate();

    return new Date(year, month, date);
  }

  // YYYYMMDD
  if (dateString?.length === 8) {
    const year = Number(dateString?.substring(0, 4));
    const month = Number(dateString?.substring(4, 6));
    const date = Number(dateString?.substring(6, 8));

    return new Date(year, month - 1, date);
  }

  return dateObject;
};

export default function Upload({ onSubmit = () => {} }: Props) {
  const [validating, setValidating] = useState(false);
  const [error, setError] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const { data: store } = useLocalStore();
  const { data: centersData } = useCentersQuery({
    variables: {
      where: {
        logistic: {
          id: {
            equals: store?.logisticId,
          },
        },
      },
    },
    skip: !store,
  });

  const { data: sellersData, error: sellersError } = useSellersQuery({
    variables: {
      where: {
        logisticId: { equals: store?.logisticId },
      },
    },
    skip: !store,
  });

  const { data: brandsData, error: brandsError } = useBrandsQuery({
    variables: {
      where: {
        seller: {
          logisticId: { equals: store?.logisticId },
        },
      },
    },
    skip: !store,
  });

  const [
    uploadExcel,
    { loading: uploadLoading, error: uplaodError },
  ] = useUploadExcelStockMutation({
    onCompleted: () => {
      setAlertOpen(true);
    },
    onError: () => {
      setAlertOpen(true);
    },
  });
  const { data, read } = useExcelReader();
  const [file, setFile] = useState<any>();
  const onDrop = useCallback(
    (acceptedFiles) => {
      console.log("boom");
      acceptedFiles.forEach((file: any) => read(file));
      setFile(acceptedFiles[0]);
      setError("");
    },
    [read, setFile]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const disabled = false;

  if (brandsError || sellersError) return <span>Error :(</span>;
  if (!store?.logisticId) return <span>좌측 상단에서 3PL 을 선택해주세요</span>;

  const handleUpload = () => {
    const [header, ...rows] = data;
    let idx: string | null = null;
    setValidating(true);

    try {
      const excelStockPayload = rows.reduce((acc, curr, i) => {
        if (!curr?.length) return acc;

        const row: string[] = curr.map((col: any, i: number) =>
          i === 12 ? String(col) : String(col).trim()
        );

        idx = row[0] ?? String(i + 1);
        const sellerName = row[1];
        const brandName = row[2];
        const productName = row[3];
        const levelOne = row[6];
        const levelTwo = row[7];
        const amountRow = row[8]?.replace(/,/g, "");
        const amount = Number.isNaN(Number(amountRow))
          ? row[8] // 엑셀 입력 원본 데이터 반환
          : Number(amountRow);

        const loadUnit = row[9];
        const loadUnitAmountRow = row[10]?.replace(/,/g, "");
        const loadUnitAmount = Number.isNaN(Number(loadUnitAmountRow))
          ? row[10] // 엑셀 입력 원본 데이터 반환
          : Number(loadUnitAmountRow);

        const manufacturedDate = parseDateString(row[12]);
        const expiryDate = parseDateString(row[13]);

        const centerName = row[14];
        const zoneName = row[15];
        const rackName = row[16];
        const levelName = row[17];

        const note = row[18];

        Yup.object({
          sellerName: Yup.string().required(),
          brandName: Yup.string().required(),
          productName: Yup.string().required(),
          amount: Yup.number()
            .integer()
            .min(0)
            .nullable()
            .required(),
          loadUnit: Yup.string()
            .oneOf(Object.values(LOAD_UNIT_LABELS))
            .nullable(),
          loadUnitAmount: Yup.number()
            .integer()
            .min(0)
            .nullable(),
          manufacturedDate: Yup.string().nullable(),
          expiryDate: Yup.string().nullable(),
          note: Yup.string().nullable(),
          centerName: Yup.string()
            .nullable()
            .required(),
          zoneName: Yup.string().nullable(),
          rackName: Yup.string().nullable(),
          levelName: Yup.string().nullable(),
        }).validateSync({
          sellerName,
          brandName,
          productName,
          amount,
          loadUnit,
          loadUnitAmount,
          manufacturedDate,
          expiryDate,
          note,
          centerName,
          zoneName,
          rackName,
          levelName,
        });

        const seller = sellersData?.sellers.find(
          (seller) => seller.company.name === sellerName
        );

        if (!seller) {
          throw new Error(`화주사를 찾을 수 없습니다.(${sellerName})`);
        }

        const brand = brandsData?.brands.find(
          (brand) =>
            brand.seller.company.name === sellerName && brand.name === brandName
        );

        if (!brand) {
          throw new Error(`브랜드를 찾을 수 없습니다.(${brandName})`);
        }

        const center = centersData?.centers.find(
          (center) => center.name === centerName
        );

        if (!center) {
          throw new Error(`센터를 찾을 수 없습니다.(${centerName})`);
        }

        if (center.useLocation && !(rackName && zoneName && levelName)) {
          throw new Error(
            `로케이션 사용중인 센터는 로케이션이 필수 입력값입니다.`
          );
        }

        const prestock: UploadExcelStockInputType = {
          sellerName,
          brandName,
          productName,
          levelOneName: levelOne,
          levelTwoName: levelTwo,
          amount: amount as number,
          loadUnit: getKeyByValue(LOAD_UNIT_LABELS, loadUnit) as LoadUnit,
          loadUnitAmount: loadUnitAmount as number,
          manufacturedDate: manufacturedDate?.toISOString(),
          expiryDate: expiryDate?.toISOString(),
          note,
          centerName,
          zoneName,
          rackName,
          levelName,
        };

        return [...acc, prestock];
      }, []);

      if (store?.logisticId) {
        uploadExcel({
          variables: {
            logisticId: store?.logisticId,
            records: excelStockPayload,
          },
        });
      }
    } catch (e) {
      console.log(e);

      const message =
        e instanceof Yup.ValidationError
          ? `엑셀 입력에 잘못된 값이 있습니다. 확인 후 다시 업로드해주세요.(${
              EXCEL_DATA_RECORD[e.path]
            } : ${idx} => ${(e.params as any)?.originalValue})`
          : e.message ?? `엑셀 업로드 시 오류가 발생하였습니다.`;

      setError(message);
      return;
    } finally {
      setValidating(false);
    }
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
  };

  const handleExcelFormDownload = () => {
    const dataset = [
      [
        "구분",
        "화주사(필수)",
        "브랜드(필수)",
        "상품명(필수)",
        "옵션1 이름",
        "옵션2 이름",
        "옵션1 값",
        "옵션2 값",
        "개별상품 입고예정 수량(필수)",
        "입고 단위",
        "입고 단위 수량",
        "입고예정 일자(YYYY-MM-DD)",
        "제조일자(YYYY-MM-DD)",
        "유통기한(YYYY-MM-DD)",
        "센터명(필수)",
        "ZONE",
        "1단계",
        "2단계",
        "비고",
      ],
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(dataset);
    const new_workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(new_workbook, worksheet, "엑셀등록양식");
    XLSX.writeFile(new_workbook, "엑셀등록양식.xlsx");
  };

  return (
    <Container maxWidth="md">
      <Box display="flex" justifyContent="flex-end">
        <Tooltip title="엑셀 양식 다운로드">
          <IconButton onClick={handleExcelFormDownload}>
            <ExcelDownload width={24} height={24} />
          </IconButton>
        </Tooltip>
      </Box>
      {(uploadLoading || validating) && (
        <LinearProgress variant="indeterminate" color="secondary" />
      )}
      <Dropzone {...getRootProps()}>
        {file ? (
          <>
            <Excel height={84} style={{ marginLeft: 40 }} />
            <Typography>{file?.name}</Typography>
            <Typography>
              <NumberFormat
                value={file.size}
                displayType={"text"}
                thousandSeparator={true}
              />
              bytes
            </Typography>
            <input {...getInputProps()} />
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: 8 }}
              disabled={disabled}
              disableElevation
            >
              파일 바꾸기
            </Button>
          </>
        ) : isDragActive ? (
          <InstructionTypo color="textSecondary" variant="body1">
            여기에 파일을 드래그 하세요...
          </InstructionTypo>
        ) : (
          <>
            <InstructionTypo
              color="textSecondary"
              variant="body1"
              align="center"
            >
              {`파일을 여기로 드래그 합니다.\n또는`}
            </InstructionTypo>
            <input {...getInputProps()} />
            <Button variant="contained" color="primary" disableElevation>
              컴퓨터에서 파일 선택
            </Button>
          </>
        )}
        <FormHelperText error>{error}</FormHelperText>
      </Dropzone>
      <Box display="flex" justifyContent="flex-end" pt={3}>
        <Button
          onClick={handleUpload}
          variant="contained"
          color="primary"
          disabled={uploadLoading}
          disableElevation
        >
          서버에 업로드
        </Button>
      </Box>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={uplaodError ? "error" : "success"}
        >
          {uplaodError
            ? `오류가 발생했습니다: ${uplaodError.graphQLErrors?.[0]?.message ??
                "Unknown"}`
            : "성공적으로 업로드 되었습니다"}
        </Alert>
      </Snackbar>
    </Container>
  );
}
