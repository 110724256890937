import React, { ReactNode, useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { FieldInputProps } from "formik";
import { makeStyles, Theme } from "@material-ui/core";
import FormControl, { FormControlProps } from "@material-ui/core/FormControl";
import FormHelperText, {
  FormHelperTextProps,
} from "@material-ui/core/FormHelperText";
import OutlinedInput, {
  OutlinedInputProps,
} from "@material-ui/core/OutlinedInput";
import { InputBaseComponentProps } from "@material-ui/core/InputBase";
import InputLabel, { InputLabelProps } from "@material-ui/core/InputLabel";

// TODO helper text positioning

type InputSize = "small" | "medium" | "large";

const useStyles = makeStyles<Theme, { size: InputSize }>((theme) => ({
  formControl: {
    boxSizing: "border-box",
  },
  size: (props: { size: InputSize }) => ({
    minWidth: 70,
    height: props.size === "small" ? 41 : props.size === "medium" ? 47 : 51,
  }),
}));

export interface OutlinedTextInputProps
  extends Omit<
      FormControlProps,
      "size" | "children" | keyof FieldInputProps<any>
    >,
    Partial<FieldInputProps<any>> {
  readonly label?: string | ReactNode;
  readonly size?: InputSize;
  readonly helperText?: string;
  readonly InputProps?: OutlinedInputProps & {
    inputProps?: InputBaseComponentProps;
  };
  readonly InputLabelProps?: InputLabelProps;
  readonly FormHelperTextProps?: FormHelperTextProps;
}

export default function OutlinedTextInput({
  name,
  value,
  onChange,
  onBlur,
  label,
  size = "small",
  placeholder,
  error,
  helperText,
  className,
  style,
  InputProps,
  InputLabelProps,
  FormHelperTextProps,
  ...props
}: OutlinedTextInputProps) {
  const classes = useStyles({ size });
  const labelRef = useRef<HTMLLabelElement>(null);
  const [labelWidth, setLabelWidth] = useState(0);

  useEffect(() => {
    setLabelWidth(labelRef.current?.offsetWidth ?? 0);
  }, []);

  return (
    <FormControl
      variant="standard"
      error={error}
      className={clsx(classes.size, className)}
      style={style}
      {...props}
    >
      {label && (
        <InputLabel shrink ref={labelRef} {...InputLabelProps}>
          {label}
        </InputLabel>
      )}
      <OutlinedInput
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        labelWidth={labelWidth}
        fullWidth
        notched
        style={{
          width: style?.width,
          height: style?.height,
        }}
        inputProps={InputProps?.inputProps}
        className={clsx(classes.input, classes.size)}
        {...InputProps}
      />
      {error && (
        <FormHelperText {...FormHelperTextProps}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
}
