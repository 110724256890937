import React from "react";

function SvgComponent(props) {
  return (
    <svg
      id="prefix__\uB808\uC774\uC5B4_1"
      x={0}
      y={0}
      viewBox="0 0 760 390"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          ".prefix__st0{fill:#262d3e}.prefix__st2{fill:#e9e7e5}.prefix__st3{fill:#fff}.prefix__st5{fill:#ffbe2e}"
        }
      </style>
      <g id="prefix__\uADF8\uB8F9_402" transform="translate(-66.348 -16.772)">
        <path
          className="prefix__st0"
          d="M108.45 368.3l-.87-.5c.31-.55 31.69-54.72 76.67-68.88 4.69-1.48 7.12-3.24 7.22-5.25.33-6.57-22.37-16.3-46.41-26.61-32.47-13.92-69.27-29.69-67.61-44.67 1.62-14.54 27.99-28.8 39.26-34.26 20.74-10.05 45.46-17.63 61.5-18.85 33.5-2.56 93.68 22.29 101.8 56.57 7.28 30.71 78.83 50.42 83.12 51.57l76.44-43.68c1.03-.56 1.97-1.25 2.8-2.05 5.21-5.03 5.37-13.36.35-18.57-.86-.88-1.72-1.79-2.62-2.72-8.69-9.07-19.51-20.35-48.74-40.5-18.8-12.95-27.12-24.78-24.74-35.15 2.05-8.92 11.77-16.49 28.9-22.52 12.67-4.46 24.91-6.36 25.04-6.38l.17-.03.15.08c.86.47 86.33 47.54 115.11 74.83 28.5 27.02 132.78-15.46 133.83-15.89l.38.92c-.26.11-26.7 10.94-56.35 18.25-39.93 9.85-66.36 8.99-78.54-2.56-28.12-26.66-110.68-72.35-114.76-74.59-3.13.51-48.38 8.21-52.94 28.11-2.28 9.93 5.9 21.4 24.33 34.1 29.32 20.21 40.63 32.01 48.9 40.63.9.93 1.76 1.84 2.62 2.72 5.4 5.61 5.24 14.58-.37 19.99-.89.86-1.91 1.61-3.01 2.21l-76.79 43.88-.2-.05c-3.12-.81-76.44-20.27-84.04-52.36-8.02-33.83-67.6-58.35-100.76-55.81-15.93 1.22-40.5 8.76-61.14 18.76-23.49 11.38-37.6 23.58-38.7 33.47-1.58 14.25 34.86 29.86 67.01 43.64 25.42 10.9 47.38 20.31 47.01 27.57-.12 2.5-2.72 4.52-7.92 6.15-23.29 7.33-42.74 25.53-54.95 39.51-13.24 15.17-21.07 28.78-21.15 28.92z"
          id="prefix__\uD328\uC2A4_356"
        />
        <g id="prefix__\uADF8\uB8F9_277" transform="translate(90.584 97.122)">
          <g id="prefix__\uADF8\uB8F9_276">
            <path
              className="prefix__st2"
              d="M39.74 126.34l-19.97-11.53a2.03 2.03 0 01-.74-2.78c.18-.31.43-.57.74-.74L43.16 97.7l26.07 16.48-20.7 12.15a8.79 8.79 0 01-8.79.01z"
              id="prefix__\uD328\uC2A4_358"
            />
            <path
              className="prefix__st2"
              d="M80.72 108.8L2.18 63.14c-1.73-1-2.31-3.22-1.31-4.94.32-.55.77-1 1.32-1.32l21.77-12.53 75.97 59.18-8.65 5.18a10.354 10.354 0 01-10.56.09z"
              id="prefix__\uD328\uC2A4_359"
            />
            <g id="prefix__\uADF8\uB8F9_275" transform="translate(10.133)">
              <g id="prefix__\uADF8\uB8F9_272">
                <g
                  id="prefix__\uADF8\uB8F9_268"
                  transform="translate(0 16.281)"
                >
                  <g
                    id="prefix__\uADF8\uB8F9_257"
                    transform="translate(0 13.738)"
                  >
                    <g
                      id="prefix__\uADF8\uB8F9_250"
                      transform="translate(38.617)"
                    >
                      <path
                        className="prefix__st3"
                        d="M.95 7.05v4.37L79.78 56.8v-4.24L.95 7.05z"
                        id="prefix__\uD328\uC2A4_360"
                      />
                      <g id="prefix__\uADF8\uB8F9_249">
                        <path
                          className="prefix__st3"
                          d="M79.79 52.57L.95 7.05l9.43-5.44 78.83 45.51-9.42 5.45z"
                          id="prefix__\uD328\uC2A4_361"
                        />
                        <path
                          className="prefix__st3"
                          d="M79.79 52.57L2.53 7.96l7.86-4.54 77.47 44.73-8.07 4.42z"
                          id="prefix__\uD328\uC2A4_362"
                        />
                        <path
                          className="prefix__st0"
                          d="M89.8 47.46v-.66l-.58-.33L10.67 1.11l-.29-.17L.96 6.39l-.58.33v5.04l78.83 45.38.58.33.58-.33 9.43-5.43v-4.25zm-1.74-.33l-2.27 1.31-6.01 3.47L51.8 35.74 2.11 7.05l8.27-4.78 77.68 44.86zM1.53 11.09V8.05L79.21 52.9v2.91L1.53 11.09z"
                          id="prefix__\uD328\uC2A4_363"
                        />
                      </g>
                    </g>
                    <g
                      id="prefix__\uADF8\uB8F9_252"
                      transform="translate(25.744 6.904)"
                    >
                      <path
                        className="prefix__st3"
                        d="M.96 7.05v4.37L79.79 56.8v-4.24L.96 7.05z"
                        id="prefix__\uD328\uC2A4_364"
                      />
                      <g id="prefix__\uADF8\uB8F9_251">
                        <path
                          className="prefix__st3"
                          d="M79.79 52.57L.96 7.05l9.43-5.44 78.83 45.51-9.43 5.45z"
                          id="prefix__\uD328\uC2A4_365"
                        />
                        <path
                          className="prefix__st3"
                          d="M79.79 52.57L2.53 7.96l7.86-4.54 77.47 44.73-8.07 4.42z"
                          id="prefix__\uD328\uC2A4_366"
                        />
                        <path
                          className="prefix__st0"
                          d="M89.8 47.46v-.66l-.58-.33L10.67 1.11l-.29-.17L.96 6.39l-.58.33v5.04l78.83 45.38.58.33.58-.33 9.43-5.43v-4.25zm-1.74-.33l-2.27 1.31-6.01 3.47L51.8 35.74 2.11 7.05l8.27-4.78 77.68 44.86zM1.53 11.09V8.05L79.21 52.9v2.91L1.53 11.09z"
                          id="prefix__\uD328\uC2A4_367"
                        />
                      </g>
                    </g>
                    <g
                      id="prefix__\uADF8\uB8F9_254"
                      transform="translate(12.873 14.348)"
                    >
                      <path
                        className="prefix__st3"
                        d="M.96 7.05v4.37L79.79 56.8v-4.24L.96 7.05z"
                        id="prefix__\uD328\uC2A4_368"
                      />
                      <g id="prefix__\uADF8\uB8F9_253">
                        <path
                          className="prefix__st3"
                          d="M79.79 52.57L.96 7.05l9.43-5.44 78.83 45.51-9.43 5.45z"
                          id="prefix__\uD328\uC2A4_369"
                        />
                        <path
                          className="prefix__st3"
                          d="M79.79 52.57L2.53 7.96l7.86-4.54 77.47 44.73-8.07 4.42z"
                          id="prefix__\uD328\uC2A4_370"
                        />
                        <path
                          className="prefix__st0"
                          d="M89.79 47.46v-.66l-.58-.33L10.67 1.11l-.29-.17L.96 6.39l-.58.33v5.04l78.83 45.38.58.33.58-.33 9.43-5.43-.01-4.25zm-1.73-.33l-2.27 1.31-6.01 3.47L51.8 35.74 2.11 7.05l8.27-4.78 77.68 44.86zM1.53 11.09V8.05L79.21 52.9v2.91L1.53 11.09z"
                          id="prefix__\uD328\uC2A4_371"
                        />
                      </g>
                    </g>
                    <g
                      id="prefix__\uADF8\uB8F9_256"
                      transform="translate(0 21.813)"
                    >
                      <path
                        className="prefix__st3"
                        d="M.96 7.05v4.37L79.79 56.8v-4.24L.96 7.05z"
                        id="prefix__\uD328\uC2A4_372"
                      />
                      <g id="prefix__\uADF8\uB8F9_255">
                        <path
                          className="prefix__st3"
                          d="M79.78 52.57L.96 7.05l9.43-5.44 78.83 45.51-9.44 5.45z"
                          id="prefix__\uD328\uC2A4_373"
                        />
                        <path
                          className="prefix__st3"
                          d="M79.79 52.57L2.53 7.96l7.86-4.54 77.47 44.73-8.07 4.42z"
                          id="prefix__\uD328\uC2A4_374"
                        />
                        <path
                          className="prefix__st0"
                          d="M89.8 47.46v-.66l-.58-.33L10.67 1.11l-.29-.17L.96 6.39l-.58.33v5.04l78.83 45.38.58.33.58-.33 9.43-5.43v-4.25zm-1.74-.33l-2.27 1.31-6.01 3.47L51.8 35.74 2.11 7.05l8.27-4.78 77.68 44.86zM1.53 11.09V8.05L79.21 52.9v2.91L1.53 11.09z"
                          id="prefix__\uD328\uC2A4_375"
                        />
                      </g>
                    </g>
                  </g>
                  <g id="prefix__\uADF8\uB8F9_266">
                    <g
                      id="prefix__\uADF8\uB8F9_259"
                      transform="translate(38.617)"
                    >
                      <path
                        className="prefix__st3"
                        d="M.95 7.05v4.37L79.78 56.8v-4.24L.95 7.05z"
                        id="prefix__\uD328\uC2A4_376"
                      />
                      <g id="prefix__\uADF8\uB8F9_258">
                        <path
                          className="prefix__st3"
                          d="M79.79 52.57L.95 7.05l9.43-5.44 78.84 45.51-9.43 5.45z"
                          id="prefix__\uD328\uC2A4_377"
                        />
                        <path
                          className="prefix__st3"
                          d="M79.79 52.57L2.53 7.96l7.86-4.54 77.47 44.73-8.07 4.42z"
                          id="prefix__\uD328\uC2A4_378"
                        />
                        <path
                          className="prefix__st0"
                          d="M89.8 47.46v-.66l-.58-.33L10.67 1.11l-.29-.17L.96 6.39l-.58.33v5.04l78.83 45.38.58.33.58-.33 9.43-5.43v-4.25zm-1.74-.33l-2.27 1.31-6.01 3.46L51.8 35.74 2.11 7.05l8.27-4.78 77.68 44.86zM1.53 11.09V8.05L79.21 52.9v2.91L1.53 11.09z"
                          id="prefix__\uD328\uC2A4_379"
                        />
                      </g>
                    </g>
                    <g
                      id="prefix__\uADF8\uB8F9_261"
                      transform="translate(25.744 6.904)"
                    >
                      <path
                        className="prefix__st3"
                        d="M.96 7.05v4.37L79.79 56.8v-4.24L.96 7.05z"
                        id="prefix__\uD328\uC2A4_380"
                      />
                      <g id="prefix__\uADF8\uB8F9_260">
                        <path
                          className="prefix__st3"
                          d="M79.79 52.57L.96 7.05l9.43-5.44 78.83 45.51-9.43 5.45z"
                          id="prefix__\uD328\uC2A4_381"
                        />
                        <path
                          className="prefix__st3"
                          d="M79.79 52.57L2.53 7.96l7.86-4.54 77.47 44.73-8.07 4.42z"
                          id="prefix__\uD328\uC2A4_382"
                        />
                        <path
                          className="prefix__st0"
                          d="M89.79 47.46v-.66l-.58-.33L10.67 1.11l-.29-.17L.96 6.39l-.58.33v5.04l78.83 45.38.58.33.58-.33 9.43-5.43-.01-4.25zm-1.73-.33l-2.27 1.31-6.01 3.47L51.8 35.74 2.11 7.05l8.27-4.78 77.68 44.86zM1.53 11.09V8.05L79.21 52.9v2.91L1.53 11.09z"
                          id="prefix__\uD328\uC2A4_383"
                        />
                      </g>
                    </g>
                    <g
                      id="prefix__\uADF8\uB8F9_263"
                      transform="translate(12.871 14.348)"
                    >
                      <path
                        className="prefix__st3"
                        d="M.96 7.05v4.37l78.83 45.39v-4.24L.96 7.05z"
                        id="prefix__\uD328\uC2A4_384"
                      />
                      <g id="prefix__\uADF8\uB8F9_262">
                        <path
                          className="prefix__st3"
                          d="M79.79 52.57L.96 7.05l9.43-5.44 78.83 45.51-9.43 5.45z"
                          id="prefix__\uD328\uC2A4_385"
                        />
                        <path
                          className="prefix__st3"
                          d="M79.79 52.57L2.53 7.96l7.86-4.54 77.47 44.73-8.07 4.42z"
                          id="prefix__\uD328\uC2A4_386"
                        />
                        <path
                          className="prefix__st0"
                          d="M89.8 47.46v-.66l-.58-.33L10.67 1.11l-.29-.17L.96 6.39l-.58.33v5.04l78.83 45.38.58.33.58-.33 9.43-5.43v-4.25zm-1.74-.33l-2.27 1.31-6.01 3.47L51.8 35.74 2.11 7.05l8.27-4.78 77.68 44.86zM1.53 11.09V8.05L79.21 52.9v2.91L1.53 11.09z"
                          id="prefix__\uD328\uC2A4_387"
                        />
                      </g>
                    </g>
                    <g
                      id="prefix__\uADF8\uB8F9_265"
                      transform="translate(0 21.813)"
                    >
                      <path
                        className="prefix__st3"
                        d="M.96 7.05v4.37L79.79 56.8v-4.24L.96 7.05z"
                        id="prefix__\uD328\uC2A4_388"
                      />
                      <g id="prefix__\uADF8\uB8F9_264">
                        <path
                          className="prefix__st3"
                          d="M79.78 52.57L.96 7.05l9.43-5.44 78.83 45.52-9.44 5.44z"
                          id="prefix__\uD328\uC2A4_389"
                        />
                        <path
                          className="prefix__st3"
                          d="M79.79 52.57L2.53 7.96l7.86-4.54 77.47 44.73-8.07 4.42z"
                          id="prefix__\uD328\uC2A4_390"
                        />
                        <path
                          className="prefix__st0"
                          d="M89.79 47.46v-.66l-.58-.33L10.67 1.11l-.29-.17L.96 6.39l-.58.33v5.04l78.83 45.38.58.33.58-.33 9.43-5.43-.01-4.25zM10.38 2.28l77.68 44.85-2.27 1.31-6.01 3.47L51.8 35.74 2.11 7.05m-.58 4.04V8.05L79.21 52.9v2.91L1.53 11.09z"
                          id="prefix__\uD328\uC2A4_391"
                        />
                      </g>
                    </g>
                  </g>
                  <g
                    id="prefix__\uADF8\uB8F9_267"
                    transform="translate(8.366 36.102)"
                  >
                    <path
                      className="prefix__st3"
                      d="M50.09 39.77V30.2l11.94 6.9v9.48l-11.94-6.81z"
                      id="prefix__\uD328\uC2A4_392"
                    />
                    <path
                      className="prefix__st0"
                      d="M70.26 41.85l-7.66-4.41v-.67L49.5 29.2v10.9l13.09 7.48v-1.33l7.67-4.4zm-8.81 3.75l-10.78-6.16V31.2l10.78 6.24v8.16z"
                      id="prefix__\uD328\uC2A4_393"
                    />
                    <path
                      className="prefix__st3"
                      d="M.96 11.27V1.95L12.9 8.92v9.29L.96 11.27z"
                      id="prefix__\uD328\uC2A4_394"
                    />
                    <path
                      className="prefix__st0"
                      d="M21.13 13.48l-7.66-4.41v-.48L.38.94V11.6l13.09 7.62v-1.33l7.66-4.41zm-8.81 3.73L1.53 10.94V2.96l10.78 6.3.01 7.95z"
                      id="prefix__\uD328\uC2A4_395"
                    />
                    <path
                      className="prefix__st3"
                      d="M24.94 25.1v-9.31l11.94 6.97v9.29L24.94 25.1z"
                      id="prefix__\uD328\uC2A4_396"
                    />
                    <path
                      className="prefix__st0"
                      d="M45.12 27.3l-7.66-4.41v-.47l-13.09-7.65v10.66l13.09 7.62v-1.34l7.66-4.41zm-8.82 3.74l-10.78-6.28v-7.98l10.78 6.3v7.96z"
                      id="prefix__\uD328\uC2A4_397"
                    />
                  </g>
                </g>
                <g
                  id="prefix__\uADF8\uB8F9_270"
                  transform="translate(21.76 3.162)"
                >
                  <g id="prefix__\uADF8\uB8F9_269">
                    <path
                      className="prefix__st3"
                      d="M22.91 52.23L1.02 39.59V14.33l21.89 12.64v25.26z"
                      id="prefix__\uD328\uC2A4_398"
                    />
                    <path
                      className="prefix__st3"
                      d="M44.79 18.7L26.9 28.94 5.02 16.3 22.9 6.06 44.79 18.7z"
                      id="prefix__\uD328\uC2A4_399"
                    />
                    <path
                      d="M22.91.94L1.02 13.58l-.64.4v25.99L22.26 52.6l.64.37.64-.37 21.89-12.64V13.95L22.91.94zm0 1.49l20.6 11.89-10.34 5.97-10.26 5.92-3.23-1.87L2.31 14.32l20.6-11.89zM1.66 39.22V15.44l20.6 11.89v23.79l-20.6-11.9z"
                      fill="#0d1858"
                      id="prefix__\uD328\uC2A4_400"
                    />
                  </g>
                </g>
                <g id="prefix__\uADF8\uB8F9_271" transform="translate(41.711)">
                  <path
                    className="prefix__st3"
                    d="M72.19 22.29L36.64 1.77 1.1 22.29v41.05l35.54 20.52V42.91l35.55-20.62z"
                    id="prefix__\uD328\uC2A4_401"
                  />
                  <path
                    className="prefix__st3"
                    d="M1.1 63.2l35.54 20.52V42.78L1.1 22.16V63.2z"
                    id="prefix__\uD328\uC2A4_402"
                  />
                  <path
                    className="prefix__st0"
                    d="M72.9 21.88L36.64.94.5 21.81l-.11.06-.01.83h.01v41.04l35.54 20.52.71.41.71-.41 35.19-20.31.36-.21V21.88zM36.64 2.59l16.7 9.64-34.12 19.7-16.7-9.64 34.12-19.7zm-.71 80.03L1.81 62.92v-39.4l34.12 19.7v39.4zm15.18-48.99l-14.47 8.35-16.71-9.64 34.12-19.7 16.7 9.65-19.64 11.34z"
                    id="prefix__\uD328\uC2A4_403"
                  />
                  <path
                    className="prefix__st0"
                    d="M22.52 42.86l-6.59-3.81a2.02 2.02 0 00-2.08-.11c-.75.52-1.16 1.4-1.08 2.31.02 1.84.94 3.56 2.45 4.61l6.59 3.81c.36.22.77.34 1.19.35.31 0 .62-.08.89-.24.75-.52 1.16-1.4 1.08-2.31a5.663 5.663 0 00-2.45-4.61zm.66 5.68c-.2.11-.5-.02-.66-.11l-6.59-3.81a4.277 4.277 0 01-1.73-3.38c0-.64.2-.98.37-1.07.2-.12.5.02.66.11l.46.26a5.26 5.26 0 002.23 3.82l5.63 3.25c-.05.54-.21.84-.37.93z"
                    id="prefix__\uD328\uC2A4_404"
                  />
                </g>
              </g>
              <g
                id="prefix__\uADF8\uB8F9_274"
                transform="translate(13.653 74.213)"
              >
                <g id="prefix__\uADF8\uB8F9_273">
                  <path
                    className="prefix__st3"
                    d="M22.91 52.23L1.02 39.59V14.32L22.9 26.95v25.28h.01z"
                    id="prefix__\uD328\uC2A4_405"
                  />
                  <path
                    className="prefix__st3"
                    d="M44.79 14.32L22.91 26.96 1.02 14.32 22.91 1.69l21.88 12.63z"
                    id="prefix__\uD328\uC2A4_406"
                  />
                  <path
                    className="prefix__st3"
                    d="M44.79 18.7L26.9 28.94 5.02 16.3 22.91 6.06 44.79 18.7z"
                    id="prefix__\uD328\uC2A4_407"
                  />
                  <path
                    className="prefix__st0"
                    d="M22.91.94L1.02 13.58l-.64.4v25.99l21.89 12.64.64.37.64-.37 21.89-12.64V13.95L22.91.94zm0 1.49l20.6 11.89-10.34 5.97-10.26 5.92-3.23-1.87L2.31 14.32l20.6-11.89zM1.66 39.22V15.44l20.6 11.89v23.79l-20.6-11.9z"
                    id="prefix__\uD328\uC2A4_408"
                  />
                </g>
                <path
                  className="prefix__st3"
                  d="M17.63 44.66l-3.34-1.93c-.6-.43-.96-1.11-.97-1.85v-3.51c0-.71.43-1.04.97-.73l3.34 1.93c.6.43.96 1.11.97 1.85v3.51c0 .72-.43 1.04-.97.73z"
                  id="prefix__\uD328\uC2A4_409"
                />
              </g>
            </g>
          </g>
        </g>
        <g id="prefix__\uADF8\uB8F9_289" transform="translate(350.54 16.772)">
          <g id="prefix__\uADF8\uB8F9_288">
            <path
              className="prefix__st2"
              d="M58.61 118.64l-34.6-19.97a4.9 4.9 0 01-1.79-6.69c.43-.74 1.04-1.36 1.78-1.79l39.81-23.1 48.86 28.21-40.19 23.32c-4.29 2.49-9.58 2.5-13.87.02z"
              id="prefix__\uD328\uC2A4_410"
            />
            <g id="prefix__\uADF8\uB8F9_287">
              <g id="prefix__\uADF8\uB8F9_278" transform="translate(11.752)">
                <path
                  className="prefix__st3"
                  d="M17.73 50.39L1.25 25.27 42.71 1.32l16.5 25.12-41.48 23.95z"
                  id="prefix__\uD328\uC2A4_411"
                />
                <path
                  className="prefix__st3"
                  d="M16.87 55.17L.38 30.05 41.85 6.1l16.5 25.12-41.48 23.95z"
                  id="prefix__\uD328\uC2A4_412"
                />
                <path
                  className="prefix__st0"
                  d="M17.65 50.76L.85 25.17 42.8.94l16.81 25.58-41.96 24.24zm-16-25.4l16.18 24.65 41-23.67-16.2-24.65L1.65 25.36z"
                  id="prefix__\uD328\uC2A4_413"
                />
              </g>
              <g
                id="prefix__\uADF8\uB8F9_279"
                transform="translate(70.397 .141)"
              >
                <path
                  className="prefix__st3"
                  d="M42.26 50.38l16.49-25.12L17.28 1.32.78 26.43l41.48 23.95z"
                  id="prefix__\uD328\uC2A4_414"
                />
                <path
                  className="prefix__st3"
                  d="M43.08 55.03l16.49-25.12L18.1 5.96 1.6 31.08l41.48 23.95z"
                  id="prefix__\uD328\uC2A4_415"
                />
                <path
                  className="prefix__st0"
                  d="M42.35 50.76L.38 26.53 17.19.94l41.95 24.23-.16.25-16.63 25.34zM1.17 26.34l41 23.67 16.18-24.65L17.37 1.69 1.17 26.34z"
                  id="prefix__\uD328\uC2A4_416"
                />
              </g>
              <g
                id="prefix__\uADF8\uB8F9_280"
                transform="translate(29.035 49.099)"
              >
                <path
                  className="prefix__st3"
                  d="M.66 1.43v44.34l41.49 23.95V25.38L.66 1.43z"
                  id="prefix__\uD328\uC2A4_417"
                />
                <path
                  className="prefix__st0"
                  d="M42.15 25.06L.38.94v44.98l41.76 24.11L83.77 46l.14-.08V.94L42.15 25.06zM.94 45.6V1.91l40.93 23.63v43.69L.94 45.6z"
                  id="prefix__\uD328\uC2A4_418"
                />
              </g>
              <g
                id="prefix__\uADF8\uB8F9_281"
                transform="translate(28.745 25.3)"
              >
                <path
                  className="prefix__st3"
                  d="M83.92 25.23L42.43 49.18.95 25.23 42.44 1.27l41.48 23.96z"
                  id="prefix__\uD328\uC2A4_419"
                />
                <path
                  className="prefix__st0"
                  d="M42.44 49.51L.38 25.23 42.44.94l.14.08 41.91 24.2-42.05 24.29zM1.52 25.23l40.92 23.62 40.92-23.62L42.44 1.6 1.52 25.23z"
                  id="prefix__\uD328\uC2A4_420"
                />
              </g>
              <g
                id="prefix__\uADF8\uB8F9_282"
                transform="translate(75.747 85.971)"
              >
                <path
                  className="prefix__st3"
                  d="M4.29 21.74v-7.86L.37 16.14 7.31.94l6.94 7.19-3.7 2.13v7.86l-6.26 3.62zm.73-9.12v7.86l4.81-2.78V9.85l3.26-1.88-5.55-5.75-5.56 12.16 3.04-1.76z"
                  id="prefix__\uD328\uC2A4_421"
                />
              </g>
              <path
                className="prefix__st0"
                d="M71.17 26.57L29.13 50.53l41.91 24.19.44-.23-.31.28v-48.2z"
                id="prefix__\uD328\uC2A4_422"
              />
              <g id="prefix__\uADF8\uB8F9_283" transform="translate(0 49.3)">
                <path
                  className="prefix__st3"
                  d="M71.17 25.18l-27.49.92L1.38 2.11l28.3-.88 41.49 23.95z"
                  id="prefix__\uD328\uC2A4_423"
                />
                <path
                  className="prefix__st0"
                  d="M43.61 26.37l-.07-.04L.38 1.85 29.76.94l.07.04 42.33 24.44-28.55.95zM2.39 2.35l41.36 23.46 26.44-.88L29.62 1.51l-27.23.84z"
                  id="prefix__\uD328\uC2A4_424"
                />
              </g>
              <g
                id="prefix__\uADF8\uB8F9_284"
                transform="translate(104.962 73.864)"
              >
                <path
                  className="prefix__st3"
                  d="M1.11 25.13l-.73.42V1.35L1.1.94l.01 24.19z"
                  id="prefix__\uD328\uC2A4_425"
                />
              </g>
              <g
                id="prefix__\uADF8\uB8F9_285"
                transform="translate(69.814 49.3)"
              >
                <path
                  className="prefix__st3"
                  d="M1.37 25.18l27.49.92 42.3-23.99-28.31-.88L1.37 25.18z"
                  id="prefix__\uD328\uC2A4_426"
                />
                <path
                  className="prefix__st0"
                  d="M28.93 26.37h-.08L.38 25.43 42.78.95h.08l29.3.91-43.23 24.51zM2.35 24.93l26.44.88L70.15 2.35l-27.23-.84L2.35 24.93z"
                  id="prefix__\uD328\uC2A4_427"
                />
              </g>
              <g
                id="prefix__\uADF8\uB8F9_286"
                transform="translate(48.332 48.182)"
              >
                <path
                  className="prefix__st3"
                  d="M.7 13.69L22.61 1.31l21.27 12.5-21.27 12.53L.7 13.69z"
                  id="prefix__\uD328\uC2A4_428"
                />
                <path
                  className="prefix__st3"
                  d="M3.7 15.38L23.69 4.11l18.26 10.64-19.34 11.42L3.7 15.38z"
                  id="prefix__\uD328\uC2A4_429"
                />
                <path
                  className="prefix__st0"
                  d="M22.62 26.71l-.16-.09L.38 13.5 22.62.95l.16.09 21.73 12.77-21.89 12.9zM1.35 13.69l21.26 12.28 20.64-12.16L22.61 1.68 1.35 13.69z"
                  id="prefix__\uD328\uC2A4_430"
                />
              </g>
            </g>
          </g>
        </g>
        <g id="prefix__\uADF8\uB8F9_308" transform="translate(260.622 172.125)">
          <path
            className="prefix__st2"
            d="M78.74 170.73l-76.03-43.9a4.672 4.672 0 01-.02-8.08l101.06-58.79 95.2 54.97-95.83 55.75a24.284 24.284 0 01-24.38.05z"
            id="prefix__\uD328\uC2A4_431"
          />
          <g id="prefix__\uADF8\uB8F9_299" transform="translate(12.957 56.6)">
            <g id="prefix__\uADF8\uB8F9_290" transform="translate(.004 2.726)">
              <path
                className="prefix__st3"
                d="M187.02 48.61v-2.9h-1.79c-.12-.08-.24-.17-.38-.25L111.02 2.84c-3.23-1.87-8.88-1.63-12.62.52L4.11 58.18c-.61.35-1.17.77-1.68 1.25h-1.4v6.41c.12 1.35.95 2.52 2.18 3.09l73.82 42.62c3.23 1.87 8.88 1.63 12.62-.52l94.29-54.81c1.97-1.14 3.01-2.59 3.08-3.98l.01-1.73-.01-1.9z"
                id="prefix__\uD328\uC2A4_432"
              />
              <path
                className="prefix__st3"
                d="M187.01 52.22l.01-1.73v-1.9-2.9h-1.79c-.12-.08-.24-.17-.38-.25l-27.38-15.81c-1.42-.03-2.84.1-4.23.38-3.9 1.29-66.65 37.78-67.92 43.32S81.7 106.6 81.7 106.6v6.16c2.76.17 5.52-.43 7.96-1.75l94.29-54.81c1.96-1.15 3-2.6 3.06-3.98z"
                id="prefix__\uD328\uC2A4_433"
              />
              <path
                className="prefix__st0"
                d="M82.51 113.45c-2.02.06-4.01-.4-5.8-1.33L2.89 69.5a4.441 4.441 0 01-2.5-3.64v-7.08h1.8a9.19 9.19 0 011.61-1.17L98.09 2.8c3.91-2.26 9.86-2.49 13.27-.52l73.82 42.62c.09.05.17.1.26.16h2.24l-.01 7.18c-.08 1.72-1.29 3.32-3.4 4.54l-94.29 54.81a15.219 15.219 0 01-7.47 1.86zM1.68 60.08v5.76c.13 1.11.83 2.07 1.85 2.53l73.82 42.62c3.05 1.76 8.42 1.53 11.97-.52l94.29-54.81c1.69-.98 2.69-2.23 2.75-3.44l.01-3.66v-2.18h-1.34l-.16-.11c-.18-.12-.26-.17-.34-.22L110.7 3.41c-3.05-1.76-8.42-1.53-11.97.52L4.44 58.74c-.56.32-1.08.7-1.55 1.15l-.19.19H1.68z"
                id="prefix__\uD328\uC2A4_434"
              />
            </g>
            <g id="prefix__\uADF8\uB8F9_291">
              <path
                className="prefix__st3"
                d="M77.04 108.08L3.21 65.46c-3.24-1.87-2.83-5.13.9-7.29l94.3-54.81c3.73-2.16 9.39-2.39 12.62-.52l73.82 42.62c3.23 1.87 2.83 5.13-.9 7.29l-94.29 54.81c-3.74 2.16-9.39 2.39-12.62.52z"
                id="prefix__\uD328\uC2A4_435"
              />
              <path
                className="prefix__st0"
                d="M82.52 109.98c-2.02.06-4.02-.4-5.81-1.33L2.89 66.03A4.392 4.392 0 01.38 62.3c0-1.73 1.24-3.43 3.41-4.68L98.08 2.8c3.91-2.25 9.86-2.49 13.27-.52l73.82 42.62c1.46.69 2.42 2.12 2.51 3.73 0 1.73-1.24 3.43-3.42 4.69l-94.29 54.82a15.344 15.344 0 01-7.45 1.84zM105.55 2.22c-2.38-.02-4.72.56-6.81 1.7L4.44 58.74c-1.76 1.01-2.76 2.31-2.76 3.56.1 1.14.81 2.13 1.86 2.6l73.82 42.62c3.05 1.76 8.42 1.53 11.97-.52l94.29-54.82c1.76-1.01 2.77-2.31 2.77-3.56-.1-1.14-.81-2.13-1.86-2.6L110.7 3.4a10.48 10.48 0 00-5.15-1.18z"
                id="prefix__\uD328\uC2A4_436"
              />
            </g>
            <g
              id="prefix__\uADF8\uB8F9_292"
              transform="translate(12.197 9.554)"
            >
              <path
                className="prefix__st0"
                d="M71.88 94.39l-.32-.18L.38 53.11 90.08.95l.32.18 71.18 41.1-89.7 52.16z"
                id="prefix__\uD328\uC2A4_437"
              />
            </g>
            <g
              id="prefix__\uADF8\uB8F9_293"
              transform="translate(10.333 3.089)"
            >
              <path
                className="prefix__st3"
                d="M74.13 96.71L1.68 54.88 93.15 1.69l72.45 41.83-91.47 53.19z"
                id="prefix__\uD328\uC2A4_438"
              />
              <path
                className="prefix__st0"
                d="M74.13 97.46l-.33-.19L.38 54.88 93.15.94l.33.19 73.42 42.39-92.77 53.94zM2.98 54.88l71.15 41.08 90.17-52.44L93.15 2.44 2.98 54.88z"
                id="prefix__\uD328\uC2A4_439"
              />
            </g>
            <g
              id="prefix__\uADF8\uB8F9_296"
              transform="translate(131.374 3.228)"
            >
              <g
                id="prefix__\uADF8\uB8F9_294"
                transform="translate(.008 2.496)"
              >
                <path
                  className="prefix__st0"
                  d="M33.01 16.54l-.03.29-7.38-4.26c-.13.1-.26.19-.39.28-2.08 1.2-3.29 3.12-5.38 1.92s-4.24-1.6-2.16-2.8c.15-.09.32-.15.48-.23l.68-5.56-2.87-.99-.44 5.03-8.16-8.18c-1.43-.83-3.88-.43-4.34.71L1.98 5.27l-.95-.09V11.32c.07.43.35.79.75.98L24.2 25.24c.74.38 1.57.52 2.39.4l8.21-1.05c1.32-.17 2.08-.85 2.02-1.54v-5.96l-3.81-.55z"
                  id="prefix__\uD328\uC2A4_440"
                />
                <path
                  className="prefix__st0"
                  d="M26.04 26.33c-.75.01-1.5-.17-2.16-.53L1.46 12.86c-.29-.16-.55-.38-.75-.65l-.32.16V4.46l1.18.12.85-2.08c.31-.65.89-1.12 1.58-1.31 1.22-.41 2.55-.31 3.7.28l.14.11L15 8.76l.39-4.41 4.19 1.48v6.88l-1.14-.39c-.15.06-.29.13-.42.2-.16.08-.31.19-.44.3.38.24.77.46 1.18.64.45.21.94.46 1.42.74 1.11.64 1.8.18 3.15-.83.5-.39 1.03-.76 1.58-1.09.08-.05.16-.1.23-.16l.35-.26.44.14 6.6 3.81 4.55.66c.23.03.4.23.4.46v6.12c.09 1.05-.97 1.98-2.59 2.18l-8.21 1.05c-.21.03-.42.05-.64.05zM1.68 11.23v.02c.02.15.17.33.42.48l22.42 12.94c.61.31 1.31.42 1.98.32l8.21-1.05c.98-.12 1.48-.57 1.46-.84v-5.46l-2.59-.38-.07.61-7.87-4.55-.11.07c-.5.3-.99.64-1.45 1-1.32.99-2.68 2.02-4.58.92-.46-.27-.92-.49-1.34-.69-1.1-.53-1.96-.95-1.99-1.76-.02-.69.61-1.15 1.17-1.48.05-.03.87.51.92.48V6.83l-1.73-.82-.5 5.65-9.07-9.1c-.8-.36-1.71-.41-2.55-.14-.32.07-.61.28-.79.57L2.4 5.96l-.72-.07v5.34z"
                  id="prefix__\uD328\uC2A4_441"
                />
              </g>
              <g id="prefix__\uADF8\uB8F9_295">
                <path
                  className="prefix__st3"
                  d="M36.08 18.61l-10.47-6.04c-.13.1-.26.19-.39.28a8.346 8.346 0 01-7.54 0c-2.08-1.2-2.08-3.15 0-4.35.15-.09.32-.15.48-.23L7.37 2.04c-1.43-.83-3.88-.43-4.34.71L1.1 7.43c-.21.5.05 1.04.69 1.4l22.42 12.95c.74.38 1.57.52 2.39.4l8.21-1.05c2-.26 2.71-1.69 1.27-2.52z"
                  id="prefix__\uD328\uC2A4_442"
                />
                <path
                  className="prefix__st0"
                  d="M26.05 22.86c-.75.01-1.5-.17-2.16-.53L1.46 9.39A1.769 1.769 0 01.5 7.18L2.42 2.5c.31-.64.89-1.12 1.58-1.31 1.22-.41 2.56-.31 3.7.28l11.88 6.86-1.14.52c-.15.06-.29.13-.42.2-.89.24-1.42 1.16-1.18 2.05.15.58.6 1.03 1.18 1.18a7.73 7.73 0 006.89 0c.08-.05.16-.1.23-.16l.35-.26.45.14 10.47 6.05a1.8 1.8 0 011.05 1.91c-.19.94-1.17 1.63-2.57 1.81l-8.21 1.05c-.2.03-.42.04-.63.04zM5.55 2.25c-.38 0-.76.06-1.12.18-.33.07-.62.27-.8.56L1.7 7.67c-.11.27.29.52.41.6l22.42 12.94c.61.31 1.31.42 1.98.32l8.21-1.05c.97-.12 1.41-.54 1.46-.77s-.32-.47-.43-.54l-10.1-5.83c-.04.02-.07.04-.11.07a8.964 8.964 0 01-8.19 0c-1.08-.5-1.8-1.55-1.89-2.74.04-.98.56-1.88 1.38-2.41L7.05 2.6c-.46-.25-.98-.37-1.5-.35z"
                  id="prefix__\uD328\uC2A4_443"
                />
              </g>
            </g>
            <g
              id="prefix__\uADF8\uB8F9_297"
              transform="translate(108.684 1.562)"
            >
              <path
                className="prefix__st3"
                d="M60.02 41.09L1.97 7.58C.58 6.77.75 5.37 2.36 4.44l3.59-2.07a6.063 6.063 0 015.44-.23l58.05 33.51c1.4.8 1.22 2.21-.39 3.14l-3.59 2.07a6.032 6.032 0 01-5.44.23z"
                id="prefix__\uD328\uC2A4_444"
              />
              <path
                className="prefix__st0"
                d="M62.39 42.29c-.94.02-1.87-.19-2.7-.63L1.65 8.14A2.23 2.23 0 01.38 6.21c.09-1.02.72-1.91 1.66-2.34L5.62 1.8a6.68 6.68 0 016.09-.23l58.05 33.51c.75.36 1.23 1.1 1.27 1.93a2.865 2.865 0 01-1.66 2.34l-3.59 2.07c-1.04.59-2.2.88-3.39.87zm-2.04-1.76c1.55.67 3.32.59 4.79-.23l3.59-2.07c.63-.36 1.01-.82 1.01-1.21-.05-.36-.29-.66-.62-.8L11.07 2.7a5.417 5.417 0 00-4.79.23L2.69 5c-.63.36-1.01.82-1.01 1.21.05.36.29.66.62.8l58.05 33.52z"
                id="prefix__\uD328\uC2A4_445"
              />
            </g>
            <g
              id="prefix__\uADF8\uB8F9_298"
              transform="translate(119.996 13.584)"
            >
              <path
                className="prefix__st3"
                d="M30.52 21.35L1.5 4.59c-.7-.4-.61-1.1.2-1.57l1.8-1.04a3 3 0 012.72-.11l29.02 16.76c.7.4.61 1.11-.2 1.57l-1.8 1.04c-.83.46-1.84.5-2.72.11z"
                id="prefix__\uD328\uC2A4_446"
              />
              <path
                className="prefix__st0"
                d="M31.71 22.27c-.53.01-1.05-.11-1.51-.36L1.18 5.15C.7 4.92.39 4.44.38 3.91c.04-.63.42-1.19.99-1.45l1.8-1.04c1.04-.59 2.3-.63 3.37-.11l29.03 16.76c.47.24.78.72.8 1.25-.04.63-.42 1.18-.99 1.45l-1.8 1.04c-.58.3-1.22.47-1.87.46zM5.04 2.25c-.43 0-.85.1-1.22.3l-1.8 1.04c-.27.16-.34.3-.34.32.04.05.09.09.15.11l29.02 16.76c.67.28 1.43.24 2.07-.11l1.8-1.04c.27-.16.34-.3.34-.32a.526.526 0 00-.14-.12L5.89 2.43c-.26-.13-.56-.2-.85-.18z"
                id="prefix__\uD328\uC2A4_447"
              />
            </g>
          </g>
          <g id="prefix__\uADF8\uB8F9_307" transform="translate(76.918)">
            <g
              id="prefix__\uADF8\uB8F9_300"
              transform="translate(9.453 95.015)"
            >
              <path
                className="prefix__st0"
                d="M25.02 10.82a9.723 9.723 0 00-8.79 0c-2.43 1.4-2.43 3.67 0 5.08 2.76 1.4 6.03 1.4 8.79 0 2.42-1.4 2.42-3.68 0-5.08z"
                id="prefix__\uD328\uC2A4_448"
              />
              <path
                className="prefix__st0"
                d="M34.59 4.34c-7.83-4.52-20.1-4.53-27.94 0C2.61 6.67.38 9.88.38 13.36s2.23 6.69 6.27 9.02a30.48 30.48 0 0027.94 0c4.05-2.33 6.27-5.54 6.27-9.02s-2.23-6.69-6.27-9.02zm-1.67 15.17c-6.78 3.91-17.82 3.91-24.61 0-2.98-1.72-4.61-3.9-4.61-6.15s1.64-4.43 4.61-6.15a27.22 27.22 0 0124.61 0c2.98 1.72 4.61 3.9 4.61 6.15s-1.63 4.43-4.61 6.15z"
                id="prefix__\uD328\uC2A4_449"
              />
            </g>
            <g id="prefix__\uADF8\uB8F9_306">
              <g id="prefix__\uADF8\uB8F9_304">
                <g id="prefix__\uADF8\uB8F9_301">
                  <path
                    className="prefix__st3"
                    d="M27.46 3.7c-13.12 6.04-24.88 24.35-26 41.27-.45 6.76.89 12.24 3.54 15.97l18.9 35.83 9.06 4.43c.82-1.85 14.04-64.79 14.65-66.68 1.42-4.42 11.09-7.2 11.16-11.51C59.03 7.25 40.25-2.2 27.46 3.7zM25.8 44.08c-5.17 2.58-9.12-.59-8.8-7.01.32-6.35 4.7-13.4 9.74-15.8 4.99-2.38 8.84.65 8.65 6.82-.21 6.25-4.48 13.43-9.59 15.99z"
                    id="prefix__\uD328\uC2A4_450"
                  />
                  <path
                    className="prefix__st3"
                    d="M29.09 6.92C15.97 12.97 4.21 31.27 3.1 48.19c-.22 2.91-.03 5.84.55 8.7 14.97 9 26.18 8.48 37.87 16.45 3.64-16.91 7.41-34.6 7.72-35.59 1.42-4.42 8.67-7.74 8.74-12.05.26-15.76-16.1-24.67-28.89-18.78zM27.43 47.3c-5.17 2.58-9.12-.58-8.8-7.01.32-6.35 4.7-13.4 9.74-15.8 4.99-2.38 8.84.65 8.65 6.83-.2 6.24-4.47 13.43-9.59 15.98z"
                    id="prefix__\uD328\uC2A4_451"
                  />
                  <path
                    className="prefix__st0"
                    d="M33.46 102.55l-10.29-5.02-.15-.29L4.13 61.4C1.31 57.45.02 51.71.47 44.9 1.59 27.92 13.51 9.03 27.04 2.79c6.8-3.14 15.72-2.22 22.7 2.33 6.49 4.23 10.15 10.76 10.03 17.9-.05 2.78-3.03 4.82-5.92 6.8-2.32 1.59-4.72 3.23-5.29 5-.24.75-2.7 12.24-5.31 24.4-5.18 24.12-8.88 41.27-9.37 42.37l-.42.96zm-8.83-6.53l7.7 3.76c1.22-4.9 5.64-25.51 8.96-40.97 3.17-14.74 5.12-23.81 5.36-24.59.78-2.42 3.46-4.26 6.06-6.04 2.47-1.69 5.02-3.43 5.05-5.18.11-6.45-3.22-12.35-9.12-16.2-6.41-4.2-14.56-5.05-20.76-2.2C14.94 10.56 3.53 28.7 2.46 45.03c-.42 6.34.74 11.65 3.36 15.33l18.81 35.66zm-2.26-50.05c-1.14.01-2.26-.31-3.22-.93-2.19-1.43-3.33-4.35-3.15-8.03.34-6.74 4.87-14.06 10.3-16.66 2.56-1.22 5.01-1.21 6.9.02 2.13 1.39 3.28 4.21 3.17 7.73-.21 6.6-4.76 14.16-10.14 16.85-1.19.65-2.51.99-3.86 1.02zm7.61-24.52c-.98.03-1.94.27-2.81.71-4.76 2.27-8.87 8.98-9.17 14.96-.15 2.95.67 5.23 2.25 6.26 1.32.87 3.14.8 5.11-.19 4.79-2.39 8.84-9.18 9.03-15.13.09-2.81-.74-5-2.27-6-.64-.42-1.38-.63-2.14-.61z"
                    id="prefix__\uD328\uC2A4_452"
                  />
                </g>
                <g
                  id="prefix__\uADF8\uB8F9_303"
                  transform="translate(9.061 4.21)"
                >
                  <g id="prefix__\uADF8\uB8F9_302">
                    <path
                      className="prefix__st0"
                      d="M49.91 21.5c.27-15.76-9.66-23.48-22.45-17.59-13.12 6.04-24.88 24.35-26 41.27-.45 6.77.89 12.24 3.55 15.98l18.9 35.83 21.56-56.63a56.01 56.01 0 002.14-5.62c1.41-4.28 2.18-8.74 2.3-13.24z"
                      id="prefix__\uD328\uC2A4_453"
                    />
                    <path
                      className="prefix__st0"
                      d="M24.4 98.34l-1.21-.58L4.13 61.62C1.31 57.67.02 51.93.47 45.12 1.58 28.14 13.51 9.25 27.04 3.01 33.1.22 38.86.27 43.26 3.14c5.08 3.31 7.8 9.84 7.65 18.39-.12 4.6-.91 9.15-2.36 13.52-.62 1.94-1.35 3.85-2.18 5.72L24.4 98.34zm11.24-95.4c-2.7.06-5.34.69-7.76 1.87-12.94 5.96-24.35 24.1-25.42 40.43-.42 6.35.74 11.65 3.36 15.33l17.93 34L44.53 40c.8-1.82 1.51-3.68 2.12-5.57 1.38-4.18 2.14-8.54 2.26-12.94.13-7.72-2.33-13.8-6.75-16.68a11.68 11.68 0 00-6.52-1.87z"
                      id="prefix__\uD328\uC2A4_454"
                    />
                  </g>
                </g>
              </g>
              <g
                id="prefix__\uADF8\uB8F9_305"
                transform="translate(23.891 29.125)"
              >
                <path
                  className="prefix__st3"
                  d="M10.95 34.72l-9.61-6.54c-1.35-.92-1.25-3.61.22-6.02 1.47-2.4 3.75-3.61 5.1-2.69l4.85 3.3 8.95-18.18C21.72 2.08 24 .5 25.54 1.06c1.54.57 1.77 3.06.51 5.57L12.63 33.69c-.43.85-1.22 1.34-1.68 1.03z"
                  id="prefix__\uD328\uC2A4_455"
                />
              </g>
            </g>
          </g>
        </g>
        <g id="prefix__\uADF8\uB8F9_390" transform="translate(528.632 33.965)">
          <path
            className="prefix__st2"
            d="M66.52 47.55L2.04 90.32A3.709 3.709 0 001 95.47c.32.48.74.88 1.24 1.16l209.75 121.53c1.11.64 2.47.67 3.61.07l71.9-38.27c1.81-.96 2.5-3.21 1.54-5.02-.33-.61-.82-1.12-1.42-1.47L70.43 47.43a3.701 3.701 0 00-3.91.12z"
            id="prefix__\uD328\uC2A4_456"
          />
          <g id="prefix__\uADF8\uB8F9_310" transform="translate(47.115 14.495)">
            <g id="prefix__\uADF8\uB8F9_309">
              <path
                className="prefix__st0"
                d="M35.59 52.75L.91 72.66V21.39L35.59 1.47v51.28z"
                id="prefix__\uD328\uC2A4_457"
              />
              <path
                className="prefix__st0"
                d="M.64 73.12a.529.529 0 01-.26-.46V21.39c0-.19.1-.36.27-.46L35.32 1.01a.523.523 0 01.79.45v51.28c0 .19-.1.36-.27.46L1.17 73.12c-.08.05-.17.07-.26.07-.1 0-.19-.02-.27-.07zM35.06 2.38L1.44 21.69v50.06l33.62-19.31V2.38z"
                id="prefix__\uD328\uC2A4_458"
              />
            </g>
          </g>
          <g
            id="prefix__\uADF8\uB8F9_311"
            transform="translate(75.341 106.446)"
          >
            <path
              className="prefix__st3"
              d="M15.83 8.17L2.46 17.14c.32-.44.47-.98.41-1.53V6.9c-.06-.76-.28-1.5-.65-2.16A3.54 3.54 0 00.91 3.39l3.32-1.92 11.6 6.7z"
              id="prefix__\uD328\uC2A4_459"
            />
            <path
              d="M2.05 17.48c-.17-.2-.16-.51.02-.7.22-.34.32-.76.27-1.16v-8.7c-.05-.68-.25-1.33-.58-1.92-.26-.48-.65-.88-1.12-1.16a.523.523 0 010-.91l3.32-1.92c.16-.09.36-.09.53 0l11.6 6.7c.25.14.34.47.2.72-.04.07-.1.13-.17.18L2.75 17.59c-.09.06-.19.09-.29.09a.578.578 0 01-.41-.2zm2.17-15.4L1.87 3.45c.32.3.59.65.81 1.03.41.74.66 1.57.72 2.42v8.72c0 .09 0 .18-.01.26L14.83 8.2 4.22 2.08z"
              id="prefix__\uD328\uC2A4_460"
            />
          </g>
          <g id="prefix__\uADF8\uB8F9_338" transform="translate(27.833 81.49)">
            <g
              id="prefix__\uADF8\uB8F9_327"
              transform="translate(120.138 69.442)"
            >
              <g id="prefix__\uADF8\uB8F9_316">
                <g id="prefix__\uADF8\uB8F9_312" transform="translate(2.374)">
                  <path
                    className="prefix__st0"
                    d="M.91 5.74L14.3 28.15s4.24-2.48 5.9-3.41c1.71-.95 2.77-3.03 2.77-6.01 0-5.89-4.14-13.05-9.23-16C11 1.15 8.53 1.1 6.84 2.32L.91 5.74z"
                    id="prefix__\uD328\uC2A4_461"
                  />
                  <path
                    className="prefix__st0"
                    d="M13.85 28.42L.45 6.01c-.07-.12-.09-.26-.05-.4s.13-.25.25-.32L6.53 1.9C8.46.51 11.18.65 14 2.28c5.24 3.03 9.5 10.41 9.5 16.46 0 3.09-1.08 5.39-3.04 6.48-1.66.92-5.89 3.4-5.89 3.4-.25.13-.57.05-.72-.2zM7.1 2.78L1.64 5.93l12.85 21.5c1.14-.67 4.13-2.41 5.46-3.15 1.61-.89 2.5-2.87 2.5-5.55 0-5.71-4.03-12.68-8.97-15.54a7.861 7.861 0 00-3.85-1.18c-.9-.02-1.79.25-2.53.77z"
                    id="prefix__\uD328\uC2A4_462"
                  />
                </g>
                <g
                  id="prefix__\uADF8\uB8F9_313"
                  transform="translate(3.738 8.263)"
                >
                  <path
                    d="M.91 5.4a12.13 12.13 0 005.5 9.52c3.04 1.75 5.5.33 5.5-3.18a12.13 12.13 0 00-5.5-9.52C3.37.47.91 1.89.91 5.4z"
                    id="prefix__\uD328\uC2A4_463"
                  />
                  <path
                    d="M6.14 15.38C2.74 13.16.6 9.46.38 5.4A3.859 3.859 0 013.52.93c1.12-.2 2.28.11 3.15.84 3.4 2.22 5.54 5.93 5.76 9.98a4.37 4.37 0 01-1.87 4.02c-.52.29-1.1.45-1.7.44a5.59 5.59 0 01-2.72-.83zM2.78 2.3a3.37 3.37 0 00-1.34 3.1c.2 3.68 2.15 7.05 5.23 9.07a2.841 2.841 0 004.01-.13c.65-.69.91-1.66.69-2.58-.2-3.68-2.15-7.05-5.23-9.07-.66-.42-1.41-.66-2.19-.69-.41-.01-.81.1-1.17.3z"
                    id="prefix__\uD328\uC2A4_464"
                  />
                </g>
                <g
                  id="prefix__\uADF8\uB8F9_314"
                  transform="translate(5.654 7.631)"
                >
                  <path
                    className="prefix__st3"
                    d="M.38 4.87a12.13 12.13 0 005.5 9.52c3.04 1.75 5.5.33 5.5-3.18a12.13 12.13 0 00-5.5-9.52C2.84-.06.38 1.36.38 4.87z"
                    id="prefix__\uD328\uC2A4_465"
                  />
                </g>
                <g id="prefix__\uADF8\uB8F9_315" transform="translate(0 3.437)">
                  <path
                    className="prefix__st3"
                    d="M.91 8.07c0 5.89 4.14 13.05 9.23 16s9.24.56 9.24-5.33-4.14-13.05-9.24-16S.91 2.18.91 8.07zm3.74 2.16c0-3.51 2.46-4.93 5.5-3.18 3.25 2.12 5.29 5.65 5.5 9.52 0 3.51-2.46 4.93-5.5 3.18-3.25-2.11-5.3-5.65-5.5-9.52z"
                    id="prefix__\uD328\uC2A4_466"
                  />
                  <path
                    className="prefix__st0"
                    d="M9.88 24.52C4.64 21.5.38 14.11.38 8.06c0-3.04 1.05-5.33 2.97-6.43s4.42-.88 7.06.65c5.24 3.02 9.5 10.4 9.5 16.45 0 3.04-1.05 5.33-2.97 6.44-.81.46-1.73.7-2.67.69a8.858 8.858 0 01-4.39-1.34zm-6-21.97c-1.57.91-2.44 2.87-2.44 5.52 0 5.71 4.02 12.68 8.97 15.54 2.29 1.33 4.43 1.56 6 .65s2.44-2.87 2.44-5.52c0-5.71-4.03-12.68-8.97-15.54A7.889 7.889 0 006.02 2c-.75-.02-1.49.18-2.14.55zm6 17.66c-3.4-2.22-5.54-5.93-5.76-9.98a3.874 3.874 0 013.14-4.48c1.12-.2 2.28.11 3.15.84 3.4 2.22 5.54 5.93 5.76 9.98a4.37 4.37 0 01-1.87 4.02c-.52.29-1.1.45-1.7.44-.96-.03-1.9-.32-2.72-.82zM6.52 7.12a3.4 3.4 0 00-1.35 3.1c.2 3.68 2.15 7.05 5.23 9.07a2.841 2.841 0 004.01-.13c.65-.69.91-1.66.69-2.58-.2-3.68-2.15-7.05-5.23-9.07-.65-.42-1.4-.65-2.18-.69-.41 0-.82.1-1.17.3z"
                    id="prefix__\uD328\uC2A4_467"
                  />
                </g>
                <path
                  className="prefix__st0"
                  d="M9.77 14.65a3.9 3.9 0 001.76 3.05c.97.56 1.76.11 1.76-1.02a3.9 3.9 0 00-1.76-3.05c-.97-.55-1.76-.09-1.76 1.02z"
                  id="prefix__\uD328\uC2A4_468"
                />
              </g>
              <g
                id="prefix__\uADF8\uB8F9_321"
                transform="translate(23.127 13.356)"
              >
                <g id="prefix__\uADF8\uB8F9_317" transform="translate(2.376)">
                  <path
                    className="prefix__st0"
                    d="M.91 5.74l13.4 22.41s4.24-2.48 5.9-3.41c1.71-.95 2.77-3.03 2.77-6.01 0-5.89-4.14-13.05-9.24-16C11 1.14 8.53 1.1 6.84 2.32L.91 5.74z"
                    id="prefix__\uD328\uC2A4_469"
                  />
                  <path
                    className="prefix__st0"
                    d="M13.85 28.42L.45 6.01a.537.537 0 01.19-.73h.01l5.89-3.39C8.46.51 11.18.65 14 2.27c5.24 3.02 9.5 10.4 9.5 16.45 0 3.09-1.08 5.39-3.04 6.48-1.66.92-5.89 3.4-5.89 3.4-.08.05-.17.07-.27.07a.495.495 0 01-.45-.25zM7.1 2.78L1.64 5.93l12.85 21.5c1.14-.67 4.13-2.41 5.46-3.15 1.61-.89 2.5-2.87 2.5-5.55 0-5.71-4.03-12.68-8.97-15.54A7.873 7.873 0 009.63 2c-.91-.01-1.79.26-2.53.78z"
                    id="prefix__\uD328\uC2A4_470"
                  />
                </g>
                <g
                  id="prefix__\uADF8\uB8F9_318"
                  transform="translate(3.738 8.264)"
                >
                  <path
                    className="prefix__st0"
                    d="M.91 5.4a12.13 12.13 0 005.5 9.52c3.04 1.75 5.5.33 5.5-3.18a12.13 12.13 0 00-5.5-9.52C3.37.47.91 1.9.91 5.4z"
                    id="prefix__\uD328\uC2A4_471"
                  />
                  <path
                    d="M6.14 15.38C2.74 13.16.6 9.46.38 5.4A3.866 3.866 0 013.52.92c1.12-.2 2.28.11 3.15.84 3.4 2.22 5.54 5.93 5.76 9.98.15 1.58-.56 3.12-1.88 4.02-.52.29-1.1.45-1.7.44-.95-.03-1.89-.31-2.71-.82zM2.78 2.3c-.97.71-1.48 1.9-1.34 3.1.2 3.68 2.15 7.05 5.23 9.07a2.853 2.853 0 004.02-.13c.65-.69.91-1.66.69-2.58-.2-3.68-2.15-7.05-5.23-9.07A4.45 4.45 0 003.95 2c-.41-.01-.81.1-1.17.3z"
                    id="prefix__\uD328\uC2A4_472"
                  />
                </g>
                <g
                  id="prefix__\uADF8\uB8F9_319"
                  transform="translate(5.655 7.631)"
                >
                  <path
                    className="prefix__st3"
                    d="M.38 4.87a12.13 12.13 0 005.5 9.52c3.04 1.75 5.5.33 5.5-3.18a12.13 12.13 0 00-5.5-9.52C2.84-.06.38 1.36.38 4.87z"
                    id="prefix__\uD328\uC2A4_473"
                  />
                </g>
                <g id="prefix__\uADF8\uB8F9_320" transform="translate(0 3.436)">
                  <path
                    className="prefix__st3"
                    d="M.91 8.07c0 5.89 4.14 13.05 9.24 16s9.24.56 9.24-5.33-4.14-13.05-9.24-16S.91 2.18.91 8.07zm3.74 2.16c0-3.51 2.46-4.93 5.5-3.18 3.25 2.12 5.29 5.65 5.5 9.52 0 3.51-2.46 4.93-5.5 3.18-3.25-2.11-5.3-5.65-5.5-9.52z"
                    id="prefix__\uD328\uC2A4_474"
                  />
                  <path
                    className="prefix__st0"
                    d="M9.88 24.53C4.64 21.51.38 14.12.38 8.07c0-3.04 1.05-5.33 2.97-6.44s4.42-.88 7.06.65c5.24 3.02 9.5 10.41 9.5 16.46 0 3.04-1.05 5.33-2.97 6.43-.81.46-1.73.7-2.67.69a8.848 8.848 0 01-4.39-1.33zm-6-21.98c-1.58.91-2.44 2.87-2.44 5.52 0 5.71 4.03 12.68 8.97 15.54 2.3 1.33 4.43 1.56 6 .65s2.44-2.87 2.44-5.52c0-5.71-4.03-12.68-8.97-15.54A7.889 7.889 0 006.02 2c-.75-.01-1.49.18-2.14.55zm6 17.66c-3.4-2.22-5.54-5.93-5.76-9.98a3.874 3.874 0 013.14-4.48c1.12-.2 2.28.11 3.15.84 3.4 2.22 5.54 5.93 5.76 9.98.15 1.58-.56 3.12-1.88 4.02-.52.29-1.1.45-1.7.44-.95-.03-1.89-.31-2.71-.82zM6.52 7.13c-.97.72-1.49 1.9-1.35 3.1.2 3.68 2.15 7.05 5.23 9.07a2.844 2.844 0 004.02-.14c.64-.69.9-1.65.69-2.57-.2-3.68-2.15-7.05-5.23-9.07-.66-.42-1.41-.66-2.19-.69-.41-.01-.81.1-1.17.3z"
                    id="prefix__\uD328\uC2A4_475"
                  />
                </g>
                <path
                  className="prefix__st0"
                  d="M9.77 14.66a3.9 3.9 0 001.76 3.05c.97.56 1.76.11 1.76-1.02a3.9 3.9 0 00-1.76-3.05c-.97-.56-1.76-.1-1.76 1.02z"
                  id="prefix__\uD328\uC2A4_476"
                />
              </g>
              <g
                id="prefix__\uADF8\uB8F9_326"
                transform="translate(46.266 26.585)"
              >
                <g id="prefix__\uADF8\uB8F9_322" transform="translate(2.376)">
                  <path
                    className="prefix__st0"
                    d="M.91 5.74l13.4 22.41s4.24-2.48 5.9-3.4c1.71-.95 2.77-3.03 2.77-6.01 0-5.89-4.14-13.05-9.24-16-2.74-1.58-5.21-1.62-6.9-.41L.91 5.74z"
                    id="prefix__\uD328\uC2A4_477"
                  />
                  <path
                    className="prefix__st0"
                    d="M13.85 28.42L.45 6.01a.534.534 0 01.18-.73h.01l5.89-3.39C8.45.51 11.18.65 14 2.27c5.24 3.02 9.5 10.41 9.5 16.46 0 3.09-1.08 5.39-3.04 6.48-1.66.92-5.89 3.4-5.89 3.4a.53.53 0 01-.72-.19zM7.1 2.78L1.64 5.93l12.85 21.5c1.14-.66 4.13-2.41 5.46-3.15 1.61-.9 2.5-2.87 2.5-5.55 0-5.71-4.03-12.68-8.97-15.54a7.83 7.83 0 00-3.85-1.18c-.9-.02-1.79.25-2.53.77z"
                    id="prefix__\uD328\uC2A4_478"
                  />
                </g>
                <g
                  id="prefix__\uADF8\uB8F9_323"
                  transform="translate(3.738 8.264)"
                >
                  <path
                    className="prefix__st0"
                    d="M.91 5.4a12.13 12.13 0 005.5 9.52c3.04 1.75 5.5.33 5.5-3.18a12.13 12.13 0 00-5.5-9.52C3.37.47.91 1.89.91 5.4z"
                    id="prefix__\uD328\uC2A4_479"
                  />
                  <path
                    d="M6.14 15.38C2.74 13.16.6 9.46.38 5.4A3.866 3.866 0 013.52.92c1.12-.2 2.28.11 3.15.84 3.4 2.22 5.54 5.93 5.76 9.98a4.33 4.33 0 01-1.88 4.01c-.52.3-1.1.45-1.7.44-.95-.02-1.89-.3-2.71-.81zM2.78 2.3a3.37 3.37 0 00-1.34 3.1c.2 3.68 2.15 7.05 5.23 9.07a2.841 2.841 0 004.01-.13c.65-.69.91-1.66.69-2.59-.2-3.68-2.15-7.05-5.23-9.07-.66-.41-1.41-.65-2.19-.68-.41-.01-.81.1-1.17.3z"
                    id="prefix__\uD328\uC2A4_480"
                  />
                </g>
                <g
                  id="prefix__\uADF8\uB8F9_324"
                  transform="translate(5.655 7.63)"
                >
                  <path
                    className="prefix__st3"
                    d="M.38 4.87a12.13 12.13 0 005.5 9.52c3.04 1.75 5.5.33 5.5-3.18a12.13 12.13 0 00-5.5-9.52C2.84-.06.38 1.36.38 4.87z"
                    id="prefix__\uD328\uC2A4_481"
                  />
                </g>
                <g id="prefix__\uADF8\uB8F9_325" transform="translate(0 3.436)">
                  <path
                    className="prefix__st3"
                    d="M.91 8.08c0 5.89 4.14 13.05 9.24 16s9.24.56 9.24-5.33-4.14-13.05-9.24-16S.91 2.18.91 8.08zm3.74 2.15c0-3.51 2.46-4.93 5.5-3.18 3.25 2.12 5.29 5.65 5.5 9.52 0 3.51-2.46 4.93-5.5 3.18-3.25-2.11-5.3-5.65-5.5-9.52z"
                    id="prefix__\uD328\uC2A4_482"
                  />
                  <path
                    className="prefix__st0"
                    d="M9.88 24.52C4.64 21.5.38 14.12.38 8.07c0-3.04 1.05-5.33 2.97-6.44s4.42-.88 7.06.65c5.24 3.02 9.5 10.4 9.5 16.45 0 3.04-1.05 5.33-2.97 6.44-.81.46-1.73.7-2.67.69a8.961 8.961 0 01-4.39-1.34zm-6-21.98c-1.58.91-2.45 2.87-2.45 5.53 0 5.71 4.03 12.68 8.97 15.54 2.3 1.33 4.43 1.56 6 .65s2.44-2.87 2.44-5.52c0-5.71-4.03-12.68-8.97-15.54A7.889 7.889 0 006.01 2c-.74-.02-1.48.18-2.13.54zm6 17.67c-3.4-2.22-5.54-5.93-5.76-9.98a3.874 3.874 0 013.14-4.48c1.12-.2 2.28.11 3.15.84 3.4 2.22 5.54 5.93 5.76 9.98a4.33 4.33 0 01-1.88 4.01c-.52.3-1.1.45-1.7.44a5.3 5.3 0 01-2.71-.81zM6.52 7.12c-.97.72-1.49 1.9-1.35 3.1.2 3.68 2.15 7.05 5.23 9.07a2.841 2.841 0 004.01-.13c.65-.69.91-1.66.69-2.59-.2-3.68-2.15-7.05-5.23-9.07-.65-.41-1.41-.64-2.18-.68-.41 0-.81.1-1.17.3z"
                    id="prefix__\uD328\uC2A4_483"
                  />
                </g>
                <path
                  className="prefix__st0"
                  d="M9.77 14.66a3.9 3.9 0 001.76 3.05c.97.56 1.76.11 1.76-1.02a3.9 3.9 0 00-1.76-3.05c-.97-.56-1.76-.1-1.76 1.02z"
                  id="prefix__\uD328\uC2A4_484"
                />
              </g>
            </g>
            <g id="prefix__\uADF8\uB8F9_332">
              <g id="prefix__\uADF8\uB8F9_328" transform="translate(2.375)">
                <path
                  className="prefix__st0"
                  d="M.91 5.74L14.3 28.15s4.24-2.48 5.9-3.4c1.71-.95 2.77-3.03 2.77-6.01 0-5.89-4.14-13.05-9.24-16-2.74-1.58-5.21-1.63-6.9-.41L.91 5.74z"
                  id="prefix__\uD328\uC2A4_485"
                />
                <path
                  className="prefix__st0"
                  d="M13.85 28.42L.45 6.01a.537.537 0 01.19-.73h.01l5.89-3.39C8.46.51 11.18.65 14 2.27c5.24 3.03 9.5 10.41 9.5 16.46 0 3.09-1.08 5.39-3.04 6.48-1.66.92-5.89 3.4-5.89 3.4-.08.05-.17.07-.27.07-.18 0-.36-.1-.45-.26zM7.1 2.78L1.64 5.93l12.85 21.5c1.14-.66 4.13-2.41 5.46-3.15 1.61-.89 2.5-2.87 2.5-5.55 0-5.71-4.02-12.68-8.97-15.54a7.861 7.861 0 00-3.85-1.18c-.9-.02-1.79.25-2.53.77z"
                  id="prefix__\uD328\uC2A4_486"
                />
              </g>
              <g
                id="prefix__\uADF8\uB8F9_329"
                transform="translate(3.736 8.265)"
              >
                <path
                  className="prefix__st0"
                  d="M.91 5.4a12.13 12.13 0 005.5 9.52c3.04 1.75 5.5.33 5.5-3.18a12.13 12.13 0 00-5.5-9.52C3.37.47.91 1.89.91 5.4z"
                  id="prefix__\uD328\uC2A4_487"
                />
                <path
                  d="M6.14 15.38C2.74 13.16.6 9.45.38 5.4a3.869 3.869 0 016.29-3.64c3.4 2.22 5.54 5.93 5.76 9.98a4.37 4.37 0 01-1.87 4.02c-.52.29-1.1.45-1.7.44-.96-.03-1.9-.31-2.72-.82zM2.78 2.3c-.97.71-1.48 1.9-1.34 3.1.2 3.68 2.15 7.05 5.23 9.07a2.841 2.841 0 004.01-.13c.65-.69.91-1.66.69-2.58-.2-3.68-2.15-7.05-5.23-9.07-.66-.42-1.41-.66-2.19-.69-.41-.01-.81.09-1.17.3z"
                  id="prefix__\uD328\uC2A4_488"
                />
              </g>
              <g
                id="prefix__\uADF8\uB8F9_330"
                transform="translate(5.654 7.63)"
              >
                <path
                  className="prefix__st3"
                  d="M.38 4.87a12.13 12.13 0 005.5 9.52c3.04 1.75 5.5.33 5.5-3.18a12.13 12.13 0 00-5.5-9.52C2.84-.06.38 1.36.38 4.87z"
                  id="prefix__\uD328\uC2A4_489"
                />
              </g>
              <g id="prefix__\uADF8\uB8F9_331" transform="translate(0 3.437)">
                <path
                  className="prefix__st3"
                  d="M.91 8.07c0 5.89 4.14 13.05 9.24 16s9.24.56 9.24-5.33-4.14-13.05-9.24-16S.91 2.18.91 8.07zm3.73 2.16c0-3.51 2.46-4.93 5.5-3.18 3.25 2.12 5.29 5.65 5.5 9.52 0 3.51-2.46 4.93-5.5 3.18a12.172 12.172 0 01-5.5-9.52z"
                  id="prefix__\uD328\uC2A4_490"
                />
                <path
                  className="prefix__st0"
                  d="M9.88 24.52C4.64 21.5.38 14.12.38 8.07c0-3.04 1.05-5.33 2.97-6.44s4.42-.87 7.06.65c5.24 3.02 9.5 10.4 9.5 16.45 0 3.04-1.05 5.33-2.97 6.44-.81.46-1.73.7-2.67.69a8.89 8.89 0 01-4.39-1.34zm-6-21.97c-1.57.91-2.44 2.87-2.44 5.52 0 5.71 4.02 12.68 8.97 15.54 2.3 1.33 4.43 1.56 6 .65s2.44-2.87 2.44-5.52c0-5.71-4.03-12.68-8.97-15.54A7.786 7.786 0 006.02 2c-.75-.01-1.49.18-2.14.55zm6 17.66c-3.4-2.22-5.54-5.93-5.76-9.98a3.874 3.874 0 013.14-4.48c1.12-.2 2.28.11 3.15.84 3.4 2.22 5.54 5.93 5.76 9.98a4.37 4.37 0 01-1.87 4.02c-.52.29-1.1.45-1.7.44-.97-.03-1.9-.32-2.72-.82zM6.52 7.12c-.97.72-1.49 1.9-1.35 3.1.2 3.68 2.15 7.05 5.23 9.07a2.841 2.841 0 004.01-.13c.65-.69.91-1.66.69-2.58-.2-3.68-2.15-7.05-5.23-9.06-.65-.42-1.4-.66-2.18-.69-.41-.01-.81.09-1.17.29z"
                  id="prefix__\uD328\uC2A4_491"
                />
              </g>
              <path
                className="prefix__st0"
                d="M9.77 14.66a3.9 3.9 0 001.76 3.05c.97.56 1.76.11 1.76-1.02a3.9 3.9 0 00-1.76-3.05c-.97-.56-1.76-.1-1.76 1.02z"
                id="prefix__\uD328\uC2A4_492"
              />
            </g>
            <g
              id="prefix__\uADF8\uB8F9_337"
              transform="translate(50.104 28.877)"
            >
              <g id="prefix__\uADF8\uB8F9_333" transform="translate(2.374)">
                <path
                  className="prefix__st0"
                  d="M.91 5.74L14.3 28.15s4.24-2.48 5.9-3.4c1.71-.95 2.77-3.03 2.77-6.01 0-5.89-4.14-13.05-9.24-16-2.74-1.58-5.21-1.63-6.9-.41L.91 5.74z"
                  id="prefix__\uD328\uC2A4_493"
                />
                <path
                  className="prefix__st0"
                  d="M13.85 28.42L.45 6.01a.523.523 0 01.18-.72s.01 0 .01-.01l5.89-3.39C8.45.51 11.18.65 14 2.28c5.24 3.02 9.5 10.41 9.5 16.45 0 3.09-1.08 5.39-3.04 6.48-1.66.92-5.89 3.4-5.89 3.4-.08.05-.17.07-.27.07-.18 0-.36-.1-.45-.26zM7.1 2.78L1.64 5.93l12.85 21.5c1.14-.67 4.13-2.41 5.46-3.15 1.61-.89 2.5-2.86 2.5-5.55 0-5.71-4.03-12.68-8.97-15.54a7.861 7.861 0 00-3.85-1.18c-.91-.02-1.79.25-2.53.77z"
                  id="prefix__\uD328\uC2A4_494"
                />
              </g>
              <g
                id="prefix__\uADF8\uB8F9_334"
                transform="translate(3.736 8.265)"
              >
                <path
                  className="prefix__st0"
                  d="M.91 5.4a12.13 12.13 0 005.5 9.52c3.04 1.75 5.5.33 5.5-3.18a12.13 12.13 0 00-5.5-9.52C3.37.47.91 1.89.91 5.4z"
                  id="prefix__\uD328\uC2A4_495"
                />
                <path
                  d="M6.14 15.38C2.74 13.16.6 9.46.38 5.4A3.866 3.866 0 013.52.92c1.12-.2 2.28.11 3.15.84 3.4 2.22 5.54 5.93 5.76 9.98.15 1.58-.56 3.12-1.88 4.02-.52.3-1.1.45-1.7.44-.95-.03-1.89-.31-2.71-.82zM2.78 2.3a3.4 3.4 0 00-1.35 3.1c.2 3.68 2.15 7.05 5.24 9.07a2.841 2.841 0 004.01-.13c.65-.69.91-1.66.69-2.58-.2-3.68-2.15-7.05-5.23-9.07-.66-.42-1.41-.66-2.19-.69-.41-.01-.81.1-1.17.3z"
                  id="prefix__\uD328\uC2A4_496"
                />
              </g>
              <g
                id="prefix__\uADF8\uB8F9_335"
                transform="translate(5.654 7.632)"
              >
                <path
                  className="prefix__st3"
                  d="M.38 4.87a12.13 12.13 0 005.5 9.52c3.04 1.75 5.5.33 5.5-3.18a12.13 12.13 0 00-5.5-9.52C2.84-.06.38 1.36.38 4.87z"
                  id="prefix__\uD328\uC2A4_497"
                />
              </g>
              <g id="prefix__\uADF8\uB8F9_336" transform="translate(0 3.437)">
                <path
                  className="prefix__st3"
                  d="M.91 8.07c0 5.89 4.14 13.05 9.24 16s9.24.56 9.24-5.33-4.14-13.05-9.24-16S.91 2.18.91 8.07zm3.73 2.16c0-3.51 2.46-4.93 5.5-3.18 3.25 2.12 5.29 5.65 5.5 9.52 0 3.51-2.46 4.93-5.5 3.17-3.25-2.1-5.29-5.64-5.5-9.51z"
                  id="prefix__\uD328\uC2A4_498"
                />
                <path
                  className="prefix__st0"
                  d="M9.88 24.53C4.64 21.51.38 14.12.38 8.08c0-3.05 1.05-5.33 2.97-6.44s4.42-.88 7.06.65c5.24 3.03 9.5 10.41 9.5 16.46 0 3.04-1.05 5.33-2.97 6.44-.81.46-1.73.7-2.67.69a9.078 9.078 0 01-4.39-1.35zm-6-21.98c-1.57.91-2.44 2.87-2.44 5.52 0 5.71 4.02 12.68 8.97 15.54 2.3 1.33 4.43 1.56 6 .65s2.44-2.87 2.44-5.52c0-5.71-4.03-12.68-8.97-15.54A7.786 7.786 0 006.02 2c-.75-.01-1.49.18-2.14.55zm6 17.66c-3.4-2.22-5.54-5.93-5.76-9.98a3.874 3.874 0 013.14-4.48c1.12-.2 2.28.11 3.15.84 3.4 2.22 5.54 5.93 5.76 9.98.15 1.58-.57 3.12-1.88 4.02-.52.3-1.1.45-1.7.44-.96-.03-1.89-.31-2.71-.82zM6.52 7.13a3.4 3.4 0 00-1.35 3.1c.2 3.68 2.15 7.05 5.24 9.07 1.15 1.07 2.94 1 4.01-.14.64-.69.9-1.65.69-2.57-.2-3.68-2.14-7.05-5.23-9.07a4.42 4.42 0 00-2.2-.69c-.4-.01-.81.1-1.16.3z"
                  id="prefix__\uD328\uC2A4_499"
                />
              </g>
              <path
                className="prefix__st0"
                d="M9.77 14.66a3.9 3.9 0 001.76 3.05c.97.56 1.76.11 1.76-1.02a3.9 3.9 0 00-1.76-3.05c-.97-.56-1.76-.1-1.76 1.02z"
                id="prefix__\uD328\uC2A4_500"
              />
            </g>
          </g>
          <g id="prefix__\uADF8\uB8F9_339" transform="translate(47.389 91.398)">
            <path
              className="prefix__st3"
              d="M.91 1.65s4.02 6.36 4.02 16.72c0 0 22.76 13.14 23.83 13.76 1.08.62 2.06.45 2.06-1.47v-8.72c-.08-1.41-.8-2.71-1.96-3.51C27 17.36 3.75 4.28 3.75 4.28S1.76.64.91 1.65z"
              id="prefix__\uD328\uC2A4_501"
            />
            <path
              className="prefix__st0"
              d="M28.5 32.59L4.66 18.84a.541.541 0 01-.26-.46C4.4 8.31.5 2 .46 1.94c-.12-.2-.1-.45.04-.62.2-.27.53-.41.87-.36 1.08.1 2.32 2.13 2.77 2.94 1.88 1.06 23.21 13.06 24.98 14.08 1.33.9 2.15 2.37 2.23 3.97v8.72c.12.82-.21 1.64-.87 2.14-.22.13-.47.19-.72.19a2.65 2.65 0 01-1.26-.41zM5.46 18.07l23.57 13.61c.4.24.75.31.93.21.21-.12.34-.58.34-1.22v-8.7a4.023 4.023 0 00-1.7-3.07C26.75 17.83 3.72 4.87 3.49 4.74a.578.578 0 01-.2-.21c-.35-.65-.76-1.26-1.21-1.84a35.849 35.849 0 013.38 15.38z"
              id="prefix__\uD328\uC2A4_502"
            />
          </g>
          <g id="prefix__\uADF8\uB8F9_340" transform="translate(47.389 84.858)">
            <path
              className="prefix__st3"
              d="M.91 8.2l11.5-6.72S31.1 22.45 32.18 23.07l-3.32 1.92C27 23.9 3.76 10.82 3.76 10.82S1.76 7.18.91 8.2z"
              id="prefix__\uD328\uC2A4_503"
            />
            <path
              className="prefix__st0"
              d="M28.6 25.43C26.76 24.37 3.73 11.41 3.5 11.28a.578.578 0 01-.2-.21 8.2 8.2 0 00-1.92-2.53l-.2.11a.526.526 0 01-.67-.79c.13-.16.31-.28.51-.34l11.12-6.5a.52.52 0 01.66.1C20.2 9.42 31.55 22 32.45 22.6a.526.526 0 010 .91l-3.32 1.92c-.16.09-.36.09-.53 0zM12.3 2.15L2.31 7.98c.73.72 1.35 1.54 1.83 2.45 1.81 1.02 21.7 12.21 24.72 13.93l2.43-1.4C28.21 19.95 15.66 5.91 12.3 2.15z"
              id="prefix__\uD328\uC2A4_504"
            />
          </g>
          <g id="prefix__\uADF8\uB8F9_342" transform="translate(20.165)">
            <g id="prefix__\uADF8\uB8F9_341">
              <path
                className="prefix__st3"
                d="M.91 21.42L31.84 3.56l5.38-1.97.21-.12 25.11 14.5L27.9 35.89 2.84 21.43l-1.31.75-.62-.76z"
                id="prefix__\uD328\uC2A4_505"
              />
              <path
                className="prefix__st0"
                d="M27.63 36.35L2.84 22.04l-1.04.6c-.22.13-.51.08-.67-.12l-.63-.76a.524.524 0 01.14-.79l31.02-17.9 5.3-1.94.21-.12c.16-.09.36-.09.53 0l25.1 14.5c.25.14.34.47.2.72-.05.08-.11.15-.2.2L28.16 36.35c-.08.05-.17.07-.26.07-.1 0-.19-.02-.27-.07zm9.79-34.27l-5.31 1.94L2.87 20.9c.08 0 .16.03.24.07L27.9 35.29l33.58-19.32L37.42 2.08z"
                id="prefix__\uD328\uC2A4_506"
              />
            </g>
          </g>
          <g
            id="prefix__\uADF8\uB8F9_343"
            transform="translate(236.348 121.098)"
          >
            <path
              className="prefix__st3"
              d="M.91 23.15L38.47 1.47l-.05 50.86L.93 74.15.91 30.72v-7.57z"
              id="prefix__\uD328\uC2A4_507"
            />
            <path
              className="prefix__st0"
              d="M.67 74.6a.502.502 0 01-.27-.45L.38 30.72V23.15c0-.19.1-.36.27-.46L38.21 1.02a.523.523 0 01.79.45l-.05 50.86c0 .19-.1.36-.26.46L1.2 74.6c-.08.05-.17.07-.27.07-.09 0-.18-.02-.26-.07zM37.94 2.39L1.44 23.45V30.78l.02 42.45 36.43-21.2.05-49.64z"
              id="prefix__\uD328\uC2A4_508"
            />
          </g>
          <path
            className="prefix__st3"
            d="M235.64 143.32l.03 54.73 40.71-23.69.05-54.58-40.79 23.54zm1.61.93l37.56-21.68-.05 50.86-37.49 21.81-.02-50.99z"
            id="prefix__\uD328\uC2A4_509"
          />
          <g
            id="prefix__\uADF8\uB8F9_348"
            transform="translate(234.656 172.936)"
          >
            <g id="prefix__\uADF8\uB8F9_344">
              <path
                className="prefix__st3"
                d="M41.25 1.47l1.23.78v4.91L2.13 30.49.9 29.71V24.8L41.25 1.47z"
                id="prefix__\uD328\uC2A4_510"
              />
              <path
                className="prefix__st0"
                d="M1.85 30.94l-1.23-.78a.542.542 0 01-.24-.45V24.8c0-.19.1-.36.26-.46L40.99 1.02c.17-.1.38-.1.55.01l1.23.78c.15.1.24.27.24.45v4.91c0 .19-.1.36-.27.46L2.4 30.95c-.08.05-.17.07-.27.07s-.2-.03-.28-.08zm40.1-28.4l-.71-.45-39.8 23.02v4.32l.71.45 39.8-23.02V2.54z"
                id="prefix__\uD328\uC2A4_511"
              />
            </g>
            <g id="prefix__\uADF8\uB8F9_347" transform="translate(2.783 2.028)">
              <g id="prefix__\uADF8\uB8F9_345" transform="translate(0 16.719)">
                <path
                  className="prefix__st3"
                  d="M9.09 1.12v3.67L.55 9.72V6.05l8.54-4.93z"
                  id="prefix__\uD328\uC2A4_512"
                />
                <path
                  className="prefix__st0"
                  d="M.47 9.88a.18.18 0 01-.09-.16V6.05c0-.06.03-.12.09-.15L9 .97c.05-.03.12-.03.18 0 .05.03.09.09.09.15v3.67c0 .06-.03.12-.09.15L.64 9.87c-.02.02-.05.03-.08.03a.2.2 0 01-.09-.02z"
                  id="prefix__\uD328\uC2A4_513"
                />
              </g>
              <g id="prefix__\uADF8\uB8F9_346" transform="translate(28.955)">
                <path
                  className="prefix__st3"
                  d="M9.09 4.79L.55 9.73V6.05l8.54-4.93v3.67z"
                  id="prefix__\uD328\uC2A4_514"
                />
                <path
                  className="prefix__st0"
                  d="M.47 9.88a.18.18 0 01-.09-.16V6.05c0-.06.03-.12.09-.15L9.01.97c.05-.03.12-.03.18 0 .04.03.08.09.08.15V4.8c0 .06-.03.12-.09.15L.64 9.88a.188.188 0 01-.17 0z"
                  id="prefix__\uD328\uC2A4_515"
                />
              </g>
            </g>
          </g>
          <g id="prefix__\uADF8\uB8F9_349" transform="translate(24.328 61.313)">
            <path
              className="prefix__st3"
              d="M22.17 12.35L.91 24.62V13.75L22.17 1.47v10.88z"
              id="prefix__\uD328\uC2A4_516"
            />
            <path
              className="prefix__st0"
              d="M.64 25.08a.56.56 0 01-.27-.46V13.75c0-.19.1-.36.27-.46L21.9 1.01a.526.526 0 01.79.46v10.88c0 .19-.1.36-.26.46L1.17 25.08c-.16.09-.36.09-.53 0zm21-22.69L1.44 14.05v9.65l20.2-11.67V2.39z"
              id="prefix__\uD328\uC2A4_517"
            />
          </g>
          <g id="prefix__\uADF8\uB8F9_351" transform="translate(12.69 19.641)">
            <g id="prefix__\uADF8\uB8F9_350">
              <path
                className="prefix__st3"
                d="M10.23 1.72l25.11 14.5v51.3l-13.93-8.04c-6.34-3.66-7.94 4.25-8.34 6.06-.39 1.81-2.45.51-2.45.51L.91 60.44V30.09c0-7.93 4.31-31.26 9.32-28.37z"
                id="prefix__\uD328\uC2A4_518"
              />
              <path
                className="prefix__st0"
                d="M35.07 67.98l-13.93-8.04c-1.57-.91-2.93-1.11-4.05-.62-2.37 1.05-3.21 4.97-3.51 6.33-.08.55-.43 1.03-.94 1.26-.79.2-1.62.05-2.29-.4L.64 60.9a.529.529 0 01-.26-.46V30.09c0-7.04 3.39-26.25 7.83-28.81.7-.44 1.58-.44 2.28-.01l25.11 14.5c.16.09.26.27.26.46v51.3c0 .19-.1.36-.26.46-.08.05-.17.07-.27.07a.786.786 0 01-.26-.08zM9.96 2.18c-.37-.24-.85-.24-1.22.01-3.77 2.18-7.3 20.5-7.3 27.9v30.05l9.46 5.47c.27.17.97.49 1.34.33.05-.02.23-.1.33-.59.3-1.41 1.23-5.73 4.1-7 1.44-.64 3.12-.41 5 .67l13.14 7.58V16.52L9.96 2.18z"
                id="prefix__\uD328\uC2A4_519"
              />
            </g>
          </g>
          <g id="prefix__\uADF8\uB8F9_353" transform="translate(14.005 25.364)">
            <g id="prefix__\uADF8\uB8F9_352">
              <path
                className="prefix__st3"
                d="M8.8 1.68c1.83 1.05 16.53 9.31 16.53 9.31v37.5s-3.32-1.68-7.06-3.84c-5.37-3.1-6.73 3.6-7.06 5.13s-2.08.43-2.08.43L.9 45.46V25.71C.91 19 4.56-.76 8.8 1.68z"
                id="prefix__\uD328\uC2A4_520"
              />
              <path
                className="prefix__st0"
                d="M8.87 50.67L.65 45.92a.56.56 0 01-.27-.46V25.71C.38 20.41 3 3.57 7.05 1.23c.61-.38 1.39-.39 2.01-.01 1.8 1.04 16.38 9.22 16.52 9.3.17.09.27.27.27.46v37.5c0 .29-.24.53-.53.53-.08 0-.16-.02-.24-.06-.03-.02-3.37-1.71-7.08-3.85a3.67 3.67 0 00-3.36-.52c-1.97.87-2.68 4.16-2.93 5.31-.08.72-.7 1.25-1.42 1.23-.49-.02-.99-.18-1.42-.45zm9.67-6.48c2.63 1.52 5.07 2.81 6.27 3.43V11.3c-2.02-1.13-14.6-8.2-16.27-9.16a.88.88 0 00-.96.01C4.41 3.98 1.44 19.46 1.44 25.71v19.45l7.98 4.61c.24.15.8.39 1.06.28.1-.04.17-.17.23-.44.26-1.21 1.06-4.89 3.52-5.98.46-.2.96-.31 1.47-.31 1 .03 1.98.34 2.84.87z"
                id="prefix__\uD328\uC2A4_521"
              />
            </g>
          </g>
          <g id="prefix__\uADF8\uB8F9_355" transform="translate(17.804 29.036)">
            <g id="prefix__\uADF8\uB8F9_354">
              <path
                className="prefix__st0"
                d="M17.66 20.8c-.11-.03-.21-.07-.31-.12l-7.57-4.37c-.23-.13-.44-.17-.54-.09l-1.67 1.23c-.49.32-1.13.31-1.62-.03l-3.9-2.25c-.14-.08-.26-.18-.38-.29l-.11-.1a.934.934 0 01-.18-.16c-.63-.66-.99-1.54-1-2.45l1.66-9.76c-.03-.53.22-1.03.66-1.31.48-.24 1.06-.2 1.51.1l13.63 7.87c1 .64 1.63 1.73 1.68 2.92v7.45c.03.49-.18.96-.56 1.26-.21.15-.46.22-.71.22a2 2 0 01-.59-.12zM3.2 1.96c-.11.06-.17.25-.17.5l-1.67 9.8c.04.65.33 1.27.79 1.73.04.03.08.06.19.16.06.06.13.12.21.17l3.89 2.25c.23.13.44.17.54.09l1.67-1.23a1.45 1.45 0 011.62.03l7.64 4.39.13.04c.11.05.25.05.36 0a.6.6 0 00.15-.46v-7.44c-.05-.84-.49-1.6-1.19-2.06L3.72 2.06a.792.792 0 00-.4-.13c-.04 0-.08.01-.12.03z"
                id="prefix__\uD328\uC2A4_522"
              />
            </g>
          </g>
          <g
            id="prefix__\uADF8\uB8F9_380"
            transform="translate(238.126 128.685)"
          >
            <g
              id="prefix__\uADF8\uB8F9_360"
              transform="translate(5.396 23.805)"
            >
              <g
                id="prefix__\uADF8\uB8F9_356"
                transform="translate(11.918 6.881)"
              >
                <path
                  className="prefix__st3"
                  d="M12.83 15.23L.91 22.11V8.35l11.92-6.88v13.76z"
                  id="prefix__\uD328\uC2A4_523"
                />
                <path
                  className="prefix__st0"
                  d="M.64 22.57a.56.56 0 01-.27-.46V8.35c0-.19.1-.36.27-.46l11.92-6.88c.16-.09.36-.09.53 0 .16.09.26.27.26.46v13.76c0 .19-.1.36-.26.46L1.17 22.57c-.08.05-.17.07-.26.07-.1 0-.19-.02-.27-.07zM12.3 2.39L1.44 8.66V21.2l10.86-6.27V2.39z"
                  id="prefix__\uD328\uC2A4_524"
                />
              </g>
              <g id="prefix__\uADF8\uB8F9_357" transform="translate(.001)">
                <path
                  className="prefix__st3"
                  d="M24.74 8.35l-11.92 6.88L.91 8.35l11.92-6.88 11.91 6.88z"
                  id="prefix__\uD328\uC2A4_525"
                />
                <path
                  d="M12.56 15.69L.64 8.81a.526.526 0 01-.19-.72c.05-.08.11-.15.19-.2l11.92-6.88c.16-.09.36-.09.53 0l11.92 6.88a.526.526 0 010 .91l-11.92 6.88c-.08.05-.17.07-.27.07a.402.402 0 01-.26-.06zm.26-13.61L1.97 8.35l10.86 6.27 10.86-6.27-10.87-6.27z"
                  id="prefix__\uD328\uC2A4_526"
                />
              </g>
              <g id="prefix__\uADF8\uB8F9_358" transform="translate(0 6.881)">
                <path
                  d="M.91 15.23l11.92 6.88V8.35L.91 1.47v13.76z"
                  id="prefix__\uD328\uC2A4_527"
                />
                <path
                  d="M12.56 22.57L.64 15.69a.56.56 0 01-.27-.46V1.47c0-.19.1-.36.27-.46.16-.09.36-.09.53 0L13.09 7.9c.16.09.26.27.26.46v13.76c0 .19-.1.36-.26.46-.08.05-.17.07-.27.07a.607.607 0 01-.26-.08zM1.44 2.39v12.54L12.3 21.2V8.66L1.44 2.39z"
                  id="prefix__\uD328\uC2A4_528"
                />
              </g>
              <g
                id="prefix__\uADF8\uB8F9_359"
                transform="translate(6.526 3.827)"
              >
                <path
                  className="prefix__st5"
                  d="M.73 7.8L12 1.3.73 7.8z"
                  id="prefix__\uD328\uC2A4_529"
                />
                <path
                  d="M.73 8.15c.06 0 .12-.02.18-.05l11.27-6.5c.17-.1.23-.31.13-.48a.348.348 0 00-.48-.13L.56 7.5c-.17.1-.22.31-.13.48.06.11.18.17.3.17z"
                  id="prefix__\uD328\uC2A4_530"
                />
              </g>
            </g>
            <g id="prefix__\uADF8\uB8F9_363" transform="translate(.003 34.513)">
              <g
                id="prefix__\uADF8\uB8F9_361"
                transform="translate(5.392 3.054)"
              >
                <path
                  className="prefix__st3"
                  d="M12.83 15.23L.91 22.12V8.35l11.92-6.88v13.76z"
                  id="prefix__\uD328\uC2A4_531"
                />
                <path
                  className="prefix__st0"
                  d="M.64 22.57a.538.538 0 01-.27-.46V8.35c0-.19.1-.36.27-.46l11.92-6.88a.526.526 0 01.79.46v13.76c0 .19-.1.36-.26.46L1.17 22.57c-.08.05-.17.07-.27.07-.09 0-.18-.02-.26-.07zM12.3 2.39L1.44 8.66V21.2l10.86-6.27V2.39z"
                  id="prefix__\uD328\uC2A4_532"
                />
              </g>
              <g id="prefix__\uADF8\uB8F9_362">
                <path
                  className="prefix__st5"
                  d="M.73 7.8L12 1.29.73 7.8z"
                  id="prefix__\uD328\uC2A4_533"
                />
                <path
                  d="M.73 8.15c.06 0 .12-.02.18-.05l11.27-6.5c.17-.1.23-.31.13-.48a.357.357 0 00-.48-.13L.56 7.5c-.17.1-.22.32-.12.48.05.11.17.17.29.17z"
                  id="prefix__\uD328\uC2A4_534"
                />
              </g>
            </g>
            <g
              id="prefix__\uADF8\uB8F9_368"
              transform="translate(5.396 10.066)"
            >
              <g
                id="prefix__\uADF8\uB8F9_364"
                transform="translate(11.918 6.881)"
              >
                <path
                  className="prefix__st3"
                  d="M12.83 15.23L.91 22.11V8.35l11.92-6.88v13.76z"
                  id="prefix__\uD328\uC2A4_535"
                />
                <path
                  className="prefix__st0"
                  d="M.64 22.57a.56.56 0 01-.27-.46V8.35c0-.19.1-.36.27-.46l11.92-6.88a.526.526 0 01.79.46v13.76c0 .19-.1.36-.26.46L1.17 22.57c-.08.05-.17.07-.26.07-.1 0-.19-.02-.27-.07zM12.3 2.39L1.44 8.66V21.2l10.86-6.27V2.39z"
                  id="prefix__\uD328\uC2A4_536"
                />
              </g>
              <g id="prefix__\uADF8\uB8F9_365" transform="translate(.001)">
                <path
                  className="prefix__st3"
                  d="M24.74 8.35l-11.92 6.88L.91 8.35l11.92-6.88 11.91 6.88z"
                  id="prefix__\uD328\uC2A4_537"
                />
                <path
                  d="M12.56 15.69L.64 8.81a.526.526 0 010-.91l11.92-6.88c.16-.09.36-.09.53 0L25.01 7.9a.526.526 0 010 .91l-11.92 6.88c-.08.05-.17.07-.27.07-.09 0-.18-.02-.26-.07zm.26-13.61L1.97 8.35l10.86 6.27 10.86-6.27-10.87-6.27z"
                  id="prefix__\uD328\uC2A4_538"
                />
              </g>
              <g id="prefix__\uADF8\uB8F9_366" transform="translate(0 6.881)">
                <path
                  d="M.91 15.23l11.92 6.88V8.35L.91 1.47v13.76z"
                  id="prefix__\uD328\uC2A4_539"
                />
                <path
                  d="M12.56 22.57L.64 15.69a.538.538 0 01-.27-.46V1.47c0-.29.24-.53.53-.53.09 0 .18.02.26.07l11.92 6.88c.16.09.26.27.26.46v13.76c0 .29-.24.53-.53.53a.59.59 0 01-.25-.07zM1.44 2.39v12.54L12.3 21.2V8.66L1.44 2.39z"
                  id="prefix__\uD328\uC2A4_540"
                />
              </g>
              <g
                id="prefix__\uADF8\uB8F9_367"
                transform="translate(6.526 3.827)"
              >
                <path
                  className="prefix__st5"
                  d="M.73 7.8L12 1.3.73 7.8z"
                  id="prefix__\uD328\uC2A4_541"
                />
                <path
                  d="M.73 8.15c.06 0 .12-.02.18-.05l11.27-6.5c.17-.1.23-.31.13-.48a.348.348 0 00-.48-.13L.56 7.5c-.17.1-.22.32-.12.48.05.11.17.17.29.17z"
                  id="prefix__\uD328\uC2A4_542"
                />
              </g>
            </g>
            <g id="prefix__\uADF8\uB8F9_371" transform="translate(.003 20.774)">
              <g
                id="prefix__\uADF8\uB8F9_369"
                transform="translate(5.392 3.054)"
              >
                <path
                  className="prefix__st3"
                  d="M12.83 15.23L.91 22.11V8.35l11.92-6.88v13.76z"
                  id="prefix__\uD328\uC2A4_543"
                />
                <path
                  className="prefix__st0"
                  d="M.64 22.57a.538.538 0 01-.27-.46V8.35c0-.19.1-.36.27-.46l11.92-6.88c.16-.09.36-.09.53 0 .16.09.26.27.26.46v13.76c0 .19-.1.36-.26.46L1.17 22.57c-.08.05-.17.07-.27.07-.09 0-.18-.02-.26-.07zM12.3 2.39L1.44 8.66V21.2l10.86-6.27V2.39z"
                  id="prefix__\uD328\uC2A4_544"
                />
              </g>
              <g id="prefix__\uADF8\uB8F9_370">
                <path
                  className="prefix__st5"
                  d="M.73 7.8L12 1.29.73 7.8z"
                  id="prefix__\uD328\uC2A4_545"
                />
                <path
                  d="M.73 8.15c.06 0 .12-.02.18-.05l11.27-6.5c.17-.1.23-.31.13-.48a.357.357 0 00-.48-.13L.56 7.49c-.17.1-.22.31-.13.48.06.11.18.18.3.18z"
                  id="prefix__\uD328\uC2A4_546"
                />
              </g>
            </g>
            <g id="prefix__\uADF8\uB8F9_375" transform="translate(5.396)">
              <g
                id="prefix__\uADF8\uB8F9_372"
                transform="translate(11.918 3.163)"
              >
                <path
                  className="prefix__st3"
                  d="M12.83 15.23L.91 22.11V8.35l11.92-6.88v13.76z"
                  id="prefix__\uD328\uC2A4_547"
                />
                <path
                  className="prefix__st0"
                  d="M.64 22.57a.56.56 0 01-.27-.46V8.35c0-.19.1-.36.27-.46l11.92-6.88a.526.526 0 01.79.46v13.76c0 .19-.1.36-.26.46L1.17 22.57c-.08.05-.17.07-.26.07-.1 0-.19-.02-.27-.07zM12.3 2.39L1.44 8.66V21.2l10.86-6.27V2.39z"
                  id="prefix__\uD328\uC2A4_548"
                />
              </g>
              <g id="prefix__\uADF8\uB8F9_373" transform="translate(6.522)">
                <path
                  className="prefix__st3"
                  d="M18.22 4.64L6.3 11.52.9 8.4l11.84-6.93 5.48 3.17z"
                  id="prefix__\uD328\uC2A4_549"
                />
                <path
                  className="prefix__st0"
                  d="M6.04 11.97L.64 8.85a.513.513 0 01-.26-.45c0-.19.1-.36.26-.46l11.84-6.93c.16-.09.37-.09.53 0l5.48 3.16a.526.526 0 010 .91l-11.92 6.9c-.08.05-.17.07-.27.07-.09 0-.18-.03-.26-.08zm6.71-9.89L1.96 8.4l4.35 2.51 10.86-6.27-4.42-2.56z"
                  id="prefix__\uD328\uC2A4_550"
                />
              </g>
              <g id="prefix__\uADF8\uB8F9_374" transform="translate(0 6.955)">
                <path
                  d="M.91 11.44l11.92 6.88V4.56L7.54 1.47.91 11.44z"
                  id="prefix__\uD328\uC2A4_551"
                />
                <path
                  d="M12.57 18.78L.65 11.9a.488.488 0 01-.25-.34.501.501 0 01.08-.41l6.64-9.97c.15-.23.46-.3.71-.16l5.28 3.09c.16.09.26.27.26.46v13.76c0 .29-.24.53-.53.53a.608.608 0 01-.27-.08zM7.71 2.18l-6.05 9.09 10.63 6.14V4.86L7.71 2.18z"
                  id="prefix__\uD328\uC2A4_552"
                />
              </g>
            </g>
            <g id="prefix__\uADF8\uB8F9_379" transform="translate(0 6.929)">
              <g
                id="prefix__\uADF8\uB8F9_376"
                transform="translate(5.396 3.115)"
              >
                <path
                  className="prefix__st3"
                  d="M12.83 15.23L.91 22.11V8.35l11.92-6.88v13.76z"
                  id="prefix__\uD328\uC2A4_553"
                />
                <path
                  className="prefix__st0"
                  d="M.64 22.57a.538.538 0 01-.27-.46V8.35c0-.19.1-.36.27-.46l11.92-6.88c.16-.09.36-.09.53 0 .16.09.26.27.26.46v13.76c0 .19-.1.36-.26.46L1.17 22.57c-.08.05-.17.07-.26.07-.1 0-.19-.02-.27-.07zM12.3 2.39L1.44 8.66V21.2l10.86-6.27V2.39z"
                  id="prefix__\uD328\uC2A4_554"
                />
              </g>
              <g id="prefix__\uADF8\uB8F9_377">
                <path
                  className="prefix__st3"
                  d="M18.22 4.59L6.3 11.47.9 8.35l11.92-6.88 5.4 3.12z"
                  id="prefix__\uD328\uC2A4_555"
                />
                <path
                  className="prefix__st0"
                  d="M6.04 11.93L.64 8.81a.523.523 0 01-.19-.72c.04-.08.11-.15.19-.19l11.92-6.88c.16-.09.36-.09.53 0l5.4 3.12c.25.15.34.47.19.72-.05.07-.12.14-.2.19L6.57 11.93a.6.6 0 01-.27.07c-.09 0-.18-.03-.26-.07zm6.78-9.85L1.96 8.35l4.34 2.51 10.86-6.27-4.34-2.51z"
                  id="prefix__\uD328\uC2A4_556"
                />
              </g>
              <g id="prefix__\uADF8\uB8F9_378" transform="translate(0 6.88)">
                <path
                  className="prefix__st3"
                  d="M6.3 18.37v27.5L.9 42.75V1.47l5.4 3.12v13.76"
                  id="prefix__\uD328\uC2A4_557"
                />
                <path
                  className="prefix__st0"
                  d="M6.04 46.33l-5.4-3.12a.538.538 0 01-.27-.46V1.47c0-.29.24-.53.53-.53.09 0 .18.02.26.07l5.4 3.12c.17.09.27.27.27.46v41.29c0 .19-.1.36-.27.46-.16.08-.36.08-.52-.01zm-.26-28.47V4.89l-4.34-2.5v40.07l4.34 2.51v-26.3"
                  id="prefix__\uD328\uC2A4_558"
                />
              </g>
            </g>
          </g>
          <g id="prefix__\uADF8\uB8F9_381" transform="translate(58.884 16.725)">
            <path
              className="prefix__st3"
              d="M41.7 1.47L.91 25.01l175.85 101.58 40.79-23.54L41.7 1.47z"
              id="prefix__\uD328\uC2A4_559"
            />
            <path
              className="prefix__st0"
              d="M176.49 127.05L.64 25.47a.526.526 0 010-.91L41.43 1.02c.16-.1.37-.1.53 0L217.81 102.6a.526.526 0 010 .91l-40.79 23.54c-.08.05-.17.07-.27.07-.08 0-.18-.03-.26-.07zM41.7 2.08L1.96 25.01l174.79 100.96 39.74-22.93L41.7 2.08z"
              id="prefix__\uD328\uC2A4_560"
            />
          </g>
          <g id="prefix__\uADF8\uB8F9_383" transform="translate(58.863 40.322)">
            <g id="prefix__\uADF8\uB8F9_382">
              <path
                className="prefix__st3"
                d="M176.81 157.72L.91 56.17V1.47L176.81 103v54.72z"
                id="prefix__\uD328\uC2A4_561"
              />
              <path
                className="prefix__st0"
                d="M176.54 158.18L.64 56.63a.541.541 0 01-.26-.46V1.47c0-.29.24-.53.53-.53.09 0 .18.02.26.07l175.9 101.53c.16.09.26.27.26.46v54.72c0 .19-.1.36-.26.46-.17.09-.37.09-.53 0zM1.44 2.39v53.48l174.84 100.95v-53.49L1.44 2.39z"
                id="prefix__\uD328\uC2A4_562"
              />
            </g>
          </g>
          <g id="prefix__\uADF8\uB8F9_385" transform="translate(63.087 47.637)">
            <g id="prefix__\uADF8\uB8F9_384">
              <path
                className="prefix__st3"
                d="M168.36 98.13L.91 1.47v44.94l167.45 96.68V98.13z"
                id="prefix__\uD328\uC2A4_563"
              />
              <path
                className="prefix__st0"
                d="M168.1 143.55L.64 46.87a.529.529 0 01-.26-.46V1.47c0-.29.23-.53.53-.53.09 0 .19.02.27.07l167.45 96.66c.16.09.26.27.26.46v44.96c0 .19-.1.36-.26.46-.17.1-.38.1-.53 0zM1.44 2.39v43.72l166.4 96.07V98.44L1.44 2.39z"
                id="prefix__\uD328\uC2A4_564"
              />
            </g>
          </g>
          <g
            id="prefix__\uADF8\uB8F9_389"
            transform="translate(214.345 106.498)"
          >
            <g id="prefix__\uADF8\uB8F9_388">
              <g id="prefix__\uADF8\uB8F9_386" transform="translate(61.113)">
                <path
                  className="prefix__st0"
                  d="M21.37 1.47l-.13 54.43L.91 67.67.96 13.1 21.37 1.47z"
                  id="prefix__\uD328\uC2A4_565"
                />
                <path
                  className="prefix__st0"
                  d="M.64 68.13a.538.538 0 01-.27-.46l.05-54.58c0-.19.1-.36.27-.46L21.11 1.01c.25-.14.58-.05.72.2.05.08.07.17.07.26l-.14 54.43c0 .19-.1.36-.26.46L1.17 68.13c-.08.04-.17.07-.26.07a.6.6 0 01-.27-.07zm20.2-65.75L1.49 13.4l-.05 53.36L20.71 55.6l.13-53.22z"
                  id="prefix__\uD328\uC2A4_566"
                />
              </g>
              <g id="prefix__\uADF8\uB8F9_387" transform="translate(0 35.306)">
                <path
                  className="prefix__st0"
                  d="M.96 13.09L21.37 1.47l-.14 54.43L.91 67.67l.05-54.58z"
                  id="prefix__\uD328\uC2A4_567"
                />
                <path
                  className="prefix__st0"
                  d="M.64 68.13a.529.529 0 01-.26-.46L.43 13.1c0-.19.1-.37.27-.46L21.11 1.02a.526.526 0 01.79.46l-.14 54.42c0 .19-.1.36-.26.46L1.17 68.13c-.08.05-.17.07-.26.07-.1 0-.19-.02-.27-.07zm20.2-65.75L1.49 13.4l-.05 53.36L20.71 55.6l.13-53.22z"
                  id="prefix__\uD328\uC2A4_568"
                />
              </g>
            </g>
          </g>
        </g>
        <g id="prefix__\uADF8\uB8F9_401" transform="translate(66.348 266.854)">
          <g id="prefix__\uADF8\uB8F9_396">
            <path
              className="prefix__st2"
              d="M14.39 84.56l-4.73 2.67a2.57 2.57 0 00-.97 3.49c.23.41.56.74.97.97l19.56 11.04c.91.51 1.23 1.67.72 2.58-.17.3-.41.54-.71.71l-8.39 4.78a2.86 2.86 0 00-1.07 3.91c.25.44.61.81 1.05 1.06l15.49 8.98a9.734 9.734 0 009.75.01l9.87-5.7 33.22 19.17c1.71.99 3.81.99 5.52.02l1.71-.98c1.07-.61 1.44-1.96.83-3.03-.2-.35-.48-.63-.83-.83L17.03 88.19l-2.64-3.63z"
              id="prefix__\uD328\uC2A4_569"
            />
            <g id="prefix__\uADF8\uB8F9_395">
              <g id="prefix___4">
                <g id="prefix__\uADF8\uB8F9_391" transform="translate(.928)">
                  <path
                    className="prefix__st3"
                    d="M93.16 50.44L7.87 1.8c-.81-.59-1.9-.6-2.72-.04L.97 4.26l3.1 4.4v66.13c0 2.77 1.71 5.99 3.81 7.19l79.64 45.42 4.1 5.83 3.95-2.37c.86-.43 1.41-1.52 1.41-3.09V57.63c-.01-2.77-1.72-5.99-3.82-7.19z"
                    id="prefix__\uD328\uC2A4_570"
                  />
                  <path
                    className="prefix__st3"
                    d="M72.98 46.93L2.09 7.27.96 4.67l3.15-1.89a2.36 2.36 0 012.72.04l85.29 48.64c1.29.84 2.31 2.04 2.91 3.45l.11.43-5.05 3.03-17.11-11.44z"
                    id="prefix__\uD328\uC2A4_571"
                  />
                  <path
                    className="prefix__st3"
                    d="M95.71 57.9l-4.59 2.73.4 1.72 4.18-2.58.01-1.87z"
                    id="prefix__\uD328\uC2A4_572"
                  />
                  <path
                    className="prefix__st0"
                    d="M4.89 1.44c.95-.66 2.22-.66 3.18.01l85.29 48.64c2.21 1.26 4.01 4.64 4.01 7.54v70.15c0 1.67-.6 2.93-1.61 3.44l-4.27 2.56-4.26-6.07L7.67 82.34c-2.21-1.26-4.01-4.65-4.01-7.54v-66L.38 4.14l4.51-2.7zm-.43 7.1V74.8c0 2.64 1.62 5.71 3.61 6.84l79.65 45.42 4.01 5.65.85-.51.16-1.26 3.12-1.92c.43-.26.7-.72.71-1.22V57.63c0-2.64-1.62-5.71-3.61-6.84L7.67 2.15c-.69-.51-1.62-.53-2.32-.05L1.54 4.38l2.92 4.16z"
                    id="prefix__\uD328\uC2A4_573"
                  />
                </g>
                <g id="prefix___18" transform="translate(23.853 97.032)">
                  <path
                    className="prefix__st0"
                    d="M5.64 13.17l17.29-9.82c2.05-.94 4.43-.85 6.41.25l10.52 6 5 2.23v2.29h-.03c-.16.65-.6 1.2-1.21 1.5L22.31 27.73c-.87.45-1.84.68-2.81.65-1.25.01-2.49-.3-3.59-.9l-13.85-7.9a2.914 2.914 0 01-1.67-2.2v-2.04l5.25-2.17z"
                    id="prefix__\uD328\uC2A4_574"
                  />
                  <g id="prefix__\uADF8\uB8F9_392">
                    <path
                      className="prefix__st3"
                      d="M43.42 13.52L22.1 25.62c-1.93.86-4.15.77-6.01-.24l-13.85-7.9c-1.78-1.01-1.97-2.55-.43-3.42L23.13 1.94c1.93-.86 4.15-.77 6.01.25l13.85 7.9c1.77 1.01 1.97 2.55.43 3.43z"
                      id="prefix__\uD328\uC2A4_575"
                    />
                    <path
                      className="prefix__st3"
                      d="M4.16 15.83l5.79-3.43a6.12 6.12 0 016.15-.05l14.51 8.3-8.77 4.98c-1.93.86-4.15.77-6-.24L4.59 19.25c-1.78-1.01-1.97-2.55-.43-3.42z"
                      id="prefix__\uD328\uC2A4_576"
                    />
                    <path
                      className="prefix__st0"
                      d="M19.49 26.62c.98.03 1.95-.2 2.81-.65l21.31-12.11c.72-.34 1.2-1.04 1.24-1.84a2.845 2.845 0 00-1.67-2.29l-13.85-7.9a7.1 7.1 0 00-6.41-.25L1.62 13.7c-.72.34-1.2 1.04-1.24 1.84.11 1.01.75 1.88 1.67 2.29l13.85 7.9c1.11.6 2.34.9 3.59.89zm23.73-13.46L21.91 25.27c-1.8.8-3.87.71-5.6-.25l-13.85-7.9c-.81-.46-1.27-1.04-1.27-1.58.06-.5.37-.93.83-1.14l21.3-12.1c1.8-.8 3.87-.71 5.6.25l13.85 7.9c.8.46 1.27 1.04 1.27 1.58-.05.5-.37.94-.82 1.13z"
                      id="prefix__\uD328\uC2A4_577"
                    />
                  </g>
                </g>
                <path
                  className="prefix__st0"
                  d="M1.89 3.78c.93-.48 2.04-.41 2.91.19l85.29 48.64c2.21 1.26 4.01 4.65 4.01 7.54v70.15c.13 1.01-.32 2-1.16 2.58-.37.21-.79.31-1.21.3-.86-.04-1.69-.29-2.43-.74L4.39 84.86C2.18 83.6.38 80.21.38 77.32V7.16c-.13-1.31.44-2.6 1.51-3.38z"
                  id="prefix__\uD328\uC2A4_578"
                />
                <g
                  id="prefix__\uADF8\uB8F9_393"
                  transform="translate(0 67.518)"
                >
                  <path
                    className="prefix__st3"
                    d="M.78 1.64v8.5a8.958 8.958 0 004.1 7.01L89.6 65.47c2.26 1.29 4.09.24 4.09-2.33v-8.5L.78 1.64z"
                    id="prefix__\uD328\uC2A4_579"
                  />
                  <path
                    className="prefix__st0"
                    d="M91.43 66.43c.45 0 .89-.11 1.28-.33.97-.66 1.5-1.8 1.39-2.96V54.4l-.2-.12L.38.94v9.2c.17 3 1.77 5.73 4.3 7.36L89.4 65.82c.62.37 1.31.58 2.03.61zm1.86-11.57v8.27c.1.88-.27 1.74-.99 2.26a2.5 2.5 0 01-2.5-.28L5.08 16.8a8.49 8.49 0 01-3.89-6.65V2.34l92.1 52.52z"
                    id="prefix__\uD328\uC2A4_580"
                  />
                </g>
                <g
                  id="prefix__\uADF8\uB8F9_394"
                  transform="translate(4.605 8.797)"
                >
                  <path
                    className="prefix__st3"
                    d="M83.55 104.89L1.72 58.23a3.212 3.212 0 01-1.34-2.54V2.11c0-.98.6-1.43 1.34-1l81.82 46.67c.83.58 1.33 1.53 1.34 2.54v53.58c.01.96-.59 1.41-1.33.99z"
                    id="prefix__\uD328\uC2A4_581"
                  />
                  <path
                    className="prefix__st3"
                    d="M84.49 103.89c.03.27-.06.54-.26.72-.16.06-.34.03-.48-.07L1.92 57.88c-.7-.51-1.12-1.32-1.14-2.19V2.11c-.03-.27.07-.54.26-.72.05-.03.1-.04.15-.04.12.01.23.04.33.11l81.83 46.66c.7.51 1.12 1.32 1.14 2.19"
                    id="prefix__\uD328\uC2A4_582"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="prefix__\uADF8\uB8F9_398" transform="translate(14.959 46.241)">
            <g id="prefix__\uADF8\uB8F9_397">
              <path
                className="prefix__st3"
                d="M22.91 52.23L1.02 39.59V14.32l21.89 12.63v25.28z"
                id="prefix__\uD328\uC2A4_583"
              />
              <path
                className="prefix__st3"
                d="M44.79 14.32L22.91 26.96 1.02 14.32 22.91 1.69l21.88 12.63z"
                id="prefix__\uD328\uC2A4_584"
              />
              <path
                className="prefix__st3"
                d="M44.79 18.7L26.9 28.94 5.02 16.3 22.91 6.06 44.79 18.7z"
                id="prefix__\uD328\uC2A4_585"
              />
              <path
                className="prefix__st0"
                d="M22.91.94L1.02 13.58l-.64.4v25.99l21.89 12.64.64.37.64-.37 21.89-12.64V13.95L22.91.94zm0 1.49l20.6 11.89-10.34 5.97-10.26 5.92-3.23-1.86L2.31 14.32l20.6-11.89zM1.66 39.23V15.44l20.6 11.89v23.79L1.66 39.23z"
                id="prefix__\uD328\uC2A4_586"
              />
            </g>
            <path
              className="prefix__st3"
              d="M17.63 44.66l-3.34-1.93c-.6-.43-.96-1.12-.97-1.85v-3.51c0-.71.43-1.04.97-.73l3.34 1.93c.6.43.96 1.11.97 1.85v3.51c0 .71-.43 1.04-.97.73z"
              id="prefix__\uD328\uC2A4_587"
            />
          </g>
          <g id="prefix__\uADF8\uB8F9_400" transform="translate(29.705 28.464)">
            <path
              className="prefix__st0"
              d="M1.16 11.98c.21.64.45 1.27.72 1.89l7.25 19.05 6.36-12.05c1-1.6 1.42-3.5 1.19-5.37-.38-5.7-4.33-11.86-8.75-13.89C3.63-.37.29 2.23.38 7.53c.04 1.51.3 3.01.78 4.45z"
              id="prefix__\uD328\uC2A4_588"
            />
            <g id="prefix__\uADF8\uB8F9_399" transform="translate(2.999 8.249)">
              <path
                className="prefix__st3"
                d="M5.73 12.3l3.23-2.2c.46-.31.42-1.21-.07-2.02s-1.26-1.22-1.71-.91L5.55 8.28 2.54 2.17C2.11 1.32 1.35.79.83.98c-.52.19-.6 1.03-.17 1.87l4.51 9.1c.14.3.41.46.56.35z"
                id="prefix__\uD328\uC2A4_589"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
