import React, { useEffect, useReducer } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import gql from "graphql-tag";
import "./App.css";
import Layout from "./components/layouts/Layout";
import SignIn from "./pages/SignIn";
import Upload from "./pages/Upload";
import { useQuery } from "@apollo/react-hooks";

// const SignIn = lazy(() => import("./pages/SignIn"));

const GET_LOCAL_STATE = gql`
  query {
    isAuthenticated @client
    companyId @client
    logisticId @client
  }
`;

function App() {
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0); // eslint-disable-line
  const { loading, error, data } = useQuery(GET_LOCAL_STATE, {
    onError: (e) => console.log(e),
  });

  const token = localStorage.getItem("token");
  // const expireTime = +localStorage.getItem('expireTime');
  // const now = new Date();
  const isAuthenticated = token && data.isAuthenticated; // && expireTime && now < new Date(expireTime);

  useEffect(() => {
    if (!isAuthenticated) {
      localStorage.clear();
    }
  }, [data, isAuthenticated]);

  if (loading) return <p>Loading...</p>;
  if (error) {
    console.log("get local state error");
    console.log(error);
    return <p>Error :(</p>;
  }

  return (
    <div className="App">
      {isAuthenticated ? (
        <Router>
          <Layout>
            <Upload />
          </Layout>
        </Router>
      ) : (
        <SignIn onSignIn={forceUpdate} />
      )}
    </div>
  );
}

export default App;
