import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { ApolloProvider } from "@apollo/react-hooks";
import { ApolloClient } from "apollo-client";
import { InMemoryCache, NormalizedCacheObject } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import { ApolloLink } from "apollo-link";
import { setContext } from "apollo-link-context";
import { persistCache } from "apollo-cache-persist";
import { PersistentStorage, PersistedData } from "apollo-cache-persist/types";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";
import { StylesProvider } from "@material-ui/styles";
import "./index.css";
import App from "./App";
import { CssBaseline } from "@material-ui/core";
import defaultTheme from "./constants/defaultTheme";

const theme = responsiveFontSizes(createMuiTheme(defaultTheme));

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

export const cache = new InMemoryCache();

const Root = () => {
  const [client, setClient] = useState(undefined);
  useEffect(() => {
    const cache = new InMemoryCache();
    const client = new ApolloClient({
      link: ApolloLink.from([
        authLink,
        onError(({ graphQLErrors, networkError = {}, forward, operation }) => {
          const graphQLErrorMessage = graphQLErrors?.[0]?.message ?? "";

          if (graphQLErrorMessage === "Not Authorised!") {
            localStorage.removeItem("token");
            localStorage.removeItem("auth");
            client.clearStore();
            return;
          }
        }),
        new HttpLink({
          uri: process.env.REACT_APP_GRAPHQL_URL,
        }),
      ]),
      cache,
      resolvers: {},
      defaultOptions: {
        watchQuery: {
          fetchPolicy: "cache-and-network",
        },
      },
    });

    const initData = {
      isAuthenticated: false,
      companyId: "",
      logisticId: "", // selected logistic
      user: {
        id: "",
        email: "",
        name: "",
        phone: "",
        role: "",
        type: "",
        team: {
          name: "",
          id: "",
          __typename: "Team",
        },
        company: {
          id: "",
          __typename: "Company",
        },
        __typename: "User",
      },
    };

    const serializedAuth = localStorage.getItem("auth");
    const deserializedAuth = serializedAuth ? JSON.parse(serializedAuth) : {};

    cache.writeData({
      data: {
        ...initData,
        ...deserializedAuth,
      },
    });

    persistCache({
      cache,
      storage: window.localStorage as PersistentStorage<
        PersistedData<NormalizedCacheObject>
      >,
    }).then(() => {
      client.onClearStore(async () => {
        cache.writeData({ data: { ...initData } });
        window.location.reload();
      });
      // @ts-ignore
      setClient(client);
    });
    return () => {};
  }, []);

  if (client === undefined) return <div>Loading...</div>;
  return (
    <ApolloProvider client={client as any}>
      <StylesProvider injectFirst>
        <ThemeProvider theme={theme}>
          <StyledThemeProvider theme={theme}>
            <CssBaseline />
            <App />
          </StyledThemeProvider>
        </ThemeProvider>
      </StylesProvider>
    </ApolloProvider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById("root")
);
