import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  DateTime: any,
  Json: any,
};

export type Query = {
   __typename?: 'Query',
  autoMatch?: Maybe<AutoMatch>,
  autoMatches: Array<AutoMatch>,
  brand?: Maybe<Brand>,
  brands: Array<Brand>,
  brandMarket?: Maybe<BrandMarket>,
  brandMarkets: Array<BrandMarket>,
  brandMarketSummary: BrandMarketSummary,
  brandMarketList: Array<BrandMarketResultType>,
  center?: Maybe<Center>,
  centers: Array<Center>,
  company?: Maybe<Company>,
  companies: Array<Company>,
  courier?: Maybe<Courier>,
  couriers: Array<Courier>,
  courierInvoice?: Maybe<CourierInvoice>,
  courierInvoices: Array<CourierInvoice>,
  invoiceSummary: InvoiceSummaryResultType,
  classifyInvoices: Array<ClassifyInvoicesResultType>,
  showInvoices: Array<CourierInvoice>,
  classifiedInvoices: Array<ClassifiedInvoiceResultType>,
  printedInvoices: Array<CourierInvoice>,
  pickingInvoices: Array<PickingInvoiceResultType>,
  additionalInvoice: AdditionalInvoiceResultType,
  invoices: Array<CourierInvoice>,
  invoiceOrderCount: Array<InvoiceOrderCountResultType>,
  finalPackage?: Maybe<FinalPackage>,
  finalPackages: Array<FinalPackage>,
  combine: Array<CourierInvoice>,
  location?: Maybe<Location>,
  locations: Array<Location>,
  locationSetting?: Maybe<LocationSetting>,
  locationSettings: Array<LocationSetting>,
  logistic?: Maybe<Logistic>,
  logistics: Array<Logistic>,
  market?: Maybe<Market>,
  markets: Array<Market>,
  marketProduct?: Maybe<MarketProduct>,
  marketProducts: Array<MarketProduct>,
  matchings: Array<MarketProduct>,
  notices: Array<NoticeListResultType>,
  order?: Maybe<Order>,
  orders: Array<Order>,
  marketNewOrderCnt: Array<MarketNewOrderCntResultType>,
  marketOrders: Array<MarketOrdersResultType>,
  detailedOrderInfo: DetailedOrderInfoResultType,
  orderReturnOrders: Array<OrderReturnOrderResultType>,
  orderNotes: Array<OrderNote>,
  currentPacket: Array<Packet>,
  checkPacket: ReturnCheckPacket,
  preCombines: Array<Invoice>,
  preStock?: Maybe<PreStock>,
  preStocks: Array<PreStock>,
  preStockList: Array<PreStock>,
  pricings: Array<Pricing>,
  product?: Maybe<Product>,
  products: Array<Product>,
  productMatch?: Maybe<ProductMatch>,
  productMatches: Array<ProductMatch>,
  productOption?: Maybe<ProductOption>,
  productOptions: Array<ProductOption>,
  associatedProducts: Array<ProductOption>,
  returnOrders: Array<ReturnOrder>,
  seller?: Maybe<Seller>,
  sellers: Array<Seller>,
  stocktaking?: Maybe<Stocktaking>,
  stocktakings: Array<Stocktaking>,
  superAdmins: Array<SuperAdmin>,
  teams: Array<Team>,
  transaction?: Maybe<Transaction>,
  transactions: Array<Transaction>,
  stockWithProduct: Array<StockWithProductResultType>,
  stockWithProductExcel: Array<StockWithProductResultType>,
  stockWithLocation: Array<StockWithLocationResultType>,
  stockWithLocationExcel: Array<StockWithLocationResultType>,
  ingoingOutgoing: Array<IngoingOutgoingResultType>,
  detailedStock: Array<DetailedStockResultType>,
  stockByProductOption: Array<DetailedStockResultType>,
  moveStock: Array<MoveStockResultType>,
  stockHistory: Array<StockHistoryResultType>,
  stockDetailedHistory: Array<StockDetailedHistoryResultType>,
  forcedOutboundTransactions: ForcedOutboundTransactionsResultType,
  users: Array<User>,
  me?: Maybe<User>,
  checkPassword: Scalars['Boolean'],
  currentVersion?: Maybe<Version>,
  widgets: Array<Widget>,
};


export type QueryAutoMatchArgs = {
  where: AutoMatchWhereUniqueInput
};


export type QueryAutoMatchesArgs = {
  where?: Maybe<AutoMatchWhereInput>,
  orderBy?: Maybe<Array<AutoMatchOrderByInput>>,
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<AutoMatchWhereUniqueInput>
};


export type QueryBrandArgs = {
  where: BrandWhereUniqueInput
};


export type QueryBrandsArgs = {
  where?: Maybe<BrandWhereInput>,
  orderBy?: Maybe<Array<BrandOrderByInput>>,
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<BrandWhereUniqueInput>
};


export type QueryBrandMarketArgs = {
  where: BrandMarketWhereUniqueInput
};


export type QueryBrandMarketsArgs = {
  where?: Maybe<BrandMarketWhereInput>,
  orderBy?: Maybe<Array<BrandMarketOrderByInput>>,
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<BrandMarketWhereUniqueInput>
};


export type QueryBrandMarketSummaryArgs = {
  logisticId?: Maybe<Scalars['ID']>,
  sellerId?: Maybe<Scalars['ID']>,
  startDate?: Maybe<Scalars['DateTime']>,
  endDate?: Maybe<Scalars['DateTime']>,
  currentStartDate?: Maybe<Scalars['DateTime']>,
  currentEndDate?: Maybe<Scalars['DateTime']>
};


export type QueryBrandMarketListArgs = {
  logisticId?: Maybe<Scalars['ID']>,
  sellerId?: Maybe<Scalars['ID']>,
  brandId?: Maybe<Scalars['ID']>,
  startDate: Scalars['DateTime'],
  endDate: Scalars['DateTime'],
  isExcel?: Maybe<Scalars['Boolean']>
};


export type QueryCenterArgs = {
  where: CenterWhereUniqueInput
};


export type QueryCentersArgs = {
  where?: Maybe<CenterWhereInput>,
  orderBy?: Maybe<Array<CenterOrderByInput>>,
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<CenterWhereUniqueInput>
};


export type QueryCompanyArgs = {
  where: CompanyWhereUniqueInput
};


export type QueryCompaniesArgs = {
  where?: Maybe<CompanyWhereInput>,
  orderBy?: Maybe<Array<CompanyOrderByInput>>,
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<CompanyWhereUniqueInput>
};


export type QueryCourierArgs = {
  where: CourierWhereUniqueInput
};


export type QueryCouriersArgs = {
  where?: Maybe<CourierWhereInput>,
  orderBy?: Maybe<Array<CourierOrderByInput>>,
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<CourierWhereUniqueInput>
};


export type QueryCourierInvoiceArgs = {
  where: CourierInvoiceWhereUniqueInput
};


export type QueryCourierInvoicesArgs = {
  where?: Maybe<CourierInvoiceWhereInput>,
  orderBy?: Maybe<Array<CourierInvoiceOrderByInput>>,
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<CourierInvoiceWhereUniqueInput>
};


export type QueryInvoiceSummaryArgs = {
  logisticId?: Maybe<Scalars['ID']>,
  sellerId?: Maybe<Scalars['ID']>,
  startDate: Scalars['String'],
  endDate: Scalars['String']
};


export type QueryClassifyInvoicesArgs = {
  logisticId?: Maybe<Scalars['ID']>,
  sellerId?: Maybe<Scalars['ID']>,
  startDate?: Maybe<Scalars['String']>,
  endDate?: Maybe<Scalars['String']>,
  standard?: Maybe<Array<Scalars['String']>>
};


export type QueryShowInvoicesArgs = {
  logisticId?: Maybe<Scalars['ID']>,
  sellerId?: Maybe<Scalars['ID']>,
  startDate?: Maybe<Scalars['String']>,
  endDate?: Maybe<Scalars['String']>,
  currentStartDate: Scalars['String'],
  currentEndDate: Scalars['String'],
  standard?: Maybe<Array<Scalars['String']>>,
  classifyInvoices?: Maybe<Array<ClassifyInvoicesInputType>>
};


export type QueryClassifiedInvoicesArgs = {
  logisticId?: Maybe<Scalars['ID']>,
  sellerId?: Maybe<Scalars['ID']>,
  startDate?: Maybe<Scalars['String']>,
  endDate?: Maybe<Scalars['String']>
};


export type QueryPrintedInvoicesArgs = {
  logisticId?: Maybe<Scalars['ID']>,
  sellerId?: Maybe<Scalars['ID']>,
  dateAndGroupNums: Array<DateAndGroupNumsInputType>
};


export type QueryPickingInvoicesArgs = {
  logisticId?: Maybe<Scalars['ID']>,
  sellerId?: Maybe<Scalars['ID']>,
  dateAndGroupNums: Array<DateAndGroupNumsInputType>
};


export type QueryAdditionalInvoiceArgs = {
  logisticCourierId: Scalars['ID'],
  senderAddress: Scalars['String'],
  senderDetailedAddress?: Maybe<Scalars['String']>,
  receiverAddress: Scalars['String'],
  receiverDetailedAddress?: Maybe<Scalars['String']>
};


export type QueryInvoicesArgs = {
  logisticId: Scalars['ID'],
  sellerId?: Maybe<Scalars['ID']>,
  brandId?: Maybe<Scalars['ID']>,
  startDate: Scalars['DateTime'],
  endDate: Scalars['DateTime'],
  printStartDate?: Maybe<Scalars['DateTime']>,
  printEndDate?: Maybe<Scalars['DateTime']>,
  logisticCourierId?: Maybe<Scalars['ID']>,
  type?: Maybe<CourierInvoiceType>,
  finalPackageStatus?: Maybe<FinalPackageStatus>,
  invoiceNum?: Maybe<Scalars['String']>,
  groupNum?: Maybe<Scalars['Int']>,
  receiver?: Maybe<Scalars['String']>,
  receiverTel?: Maybe<Scalars['String']>,
  packageStatus?: Maybe<PackageStatus>
};


export type QueryInvoiceOrderCountArgs = {
  logisticId?: Maybe<Scalars['ID']>,
  sellerId?: Maybe<Scalars['ID']>,
  startDate: Scalars['DateTime'],
  endDate: Scalars['DateTime']
};


export type QueryFinalPackageArgs = {
  where: FinalPackageWhereUniqueInput
};


export type QueryFinalPackagesArgs = {
  where?: Maybe<FinalPackageWhereInput>,
  orderBy?: Maybe<Array<FinalPackageOrderByInput>>,
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<FinalPackageWhereUniqueInput>
};


export type QueryCombineArgs = {
  logisticId?: Maybe<Scalars['ID']>,
  sellerId?: Maybe<Scalars['ID']>,
  startDate?: Maybe<Scalars['String']>,
  endDate?: Maybe<Scalars['String']>,
  productId?: Maybe<Scalars['ID']>,
  isPrinted?: Maybe<Scalars['Boolean']>,
  searchStartDate?: Maybe<Scalars['DateTime']>,
  searchEndDate?: Maybe<Scalars['DateTime']>
};


export type QueryLocationArgs = {
  where: LocationWhereUniqueInput
};


export type QueryLocationsArgs = {
  where?: Maybe<LocationWhereInput>,
  orderBy?: Maybe<Array<LocationOrderByInput>>,
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<LocationWhereUniqueInput>
};


export type QueryLocationSettingArgs = {
  where: LocationSettingWhereUniqueInput
};


export type QueryLocationSettingsArgs = {
  where?: Maybe<LocationSettingWhereInput>,
  orderBy?: Maybe<Array<LocationSettingOrderByInput>>,
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<LocationSettingWhereUniqueInput>
};


export type QueryLogisticArgs = {
  where: LogisticWhereUniqueInput
};


export type QueryLogisticsArgs = {
  where?: Maybe<LogisticWhereInput>,
  orderBy?: Maybe<Array<LogisticOrderByInput>>,
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<LogisticWhereUniqueInput>
};


export type QueryMarketArgs = {
  where: MarketWhereUniqueInput
};


export type QueryMarketsArgs = {
  where?: Maybe<MarketWhereInput>,
  orderBy?: Maybe<Array<MarketOrderByInput>>,
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<MarketWhereUniqueInput>
};


export type QueryMarketProductArgs = {
  where: MarketProductWhereUniqueInput
};


export type QueryMarketProductsArgs = {
  where?: Maybe<MarketProductWhereInput>,
  orderBy?: Maybe<Array<MarketProductOrderByInput>>,
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<MarketProductWhereUniqueInput>
};


export type QueryMatchingsArgs = {
  logisticId?: Maybe<Scalars['ID']>,
  sellerId?: Maybe<Scalars['ID']>,
  brandId?: Maybe<Scalars['ID']>,
  marketProductSearchString?: Maybe<Scalars['String']>,
  productMatchingSearchString?: Maybe<Scalars['String']>,
  orderSearchString?: Maybe<Scalars['String']>,
  marketProductStatus?: Maybe<MatchingMarketProductStatus>,
  startDate?: Maybe<Scalars['String']>,
  endDate?: Maybe<Scalars['String']>,
  isMatched?: Maybe<Scalars['Boolean']>
};


export type QueryNoticesArgs = {
  logisticId: Scalars['ID'],
  sellerId?: Maybe<Scalars['ID']>,
  isSummary?: Maybe<Scalars['Boolean']>
};


export type QueryOrderArgs = {
  where: OrderWhereUniqueInput
};


export type QueryOrdersArgs = {
  where?: Maybe<OrderWhereInput>,
  orderBy?: Maybe<Array<OrderOrderByInput>>,
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<OrderWhereUniqueInput>
};


export type QueryMarketNewOrderCntArgs = {
  currentDate: Scalars['String'],
  brandMarketIds: Array<Scalars['ID']>
};


export type QueryMarketOrdersArgs = {
  logisticId?: Maybe<Scalars['ID']>,
  sellerId?: Maybe<Scalars['ID']>,
  brandId?: Maybe<Scalars['ID']>,
  brandMarketId?: Maybe<Scalars['ID']>,
  startDate?: Maybe<Scalars['String']>,
  endDate?: Maybe<Scalars['String']>,
  orderNum?: Maybe<Scalars['String']>,
  sender?: Maybe<Scalars['String']>,
  receiver?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  isOverseas?: Maybe<Scalars['Boolean']>,
  searchMarketProduct?: Maybe<Scalars['String']>,
  searchProduct?: Maybe<Scalars['String']>,
  searchProductOption?: Maybe<Scalars['String']>,
  courierInvoiceNum?: Maybe<Scalars['String']>,
  isPrinted?: Maybe<Scalars['Boolean']>,
  marketProductStatus?: Maybe<MarketProductStatus>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>,
  cursor?: Maybe<Scalars['ID']>
};


export type QueryDetailedOrderInfoArgs = {
  orderId: Scalars['ID']
};


export type QueryOrderReturnOrdersArgs = {
  orderId: Scalars['ID']
};


export type QueryOrderNotesArgs = {
  where?: Maybe<OrderNoteWhereInput>,
  orderBy?: Maybe<Array<OrderNoteOrderByInput>>,
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<OrderNoteWhereUniqueInput>
};


export type QueryCurrentPacketArgs = {
  logisticId?: Maybe<Scalars['ID']>
};


export type QueryCheckPacketArgs = {
  logisticId?: Maybe<Scalars['ID']>
};


export type QueryPreCombinesArgs = {
  logisticId?: Maybe<Scalars['ID']>,
  sellerId?: Maybe<Scalars['ID']>
};


export type QueryPreStockArgs = {
  where: PreStockWhereUniqueInput
};


export type QueryPreStocksArgs = {
  where?: Maybe<PreStockWhereInput>,
  orderBy?: Maybe<Array<PreStockOrderByInput>>,
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<PreStockWhereUniqueInput>
};


export type QueryPreStockListArgs = {
  logisticId?: Maybe<Scalars['ID']>,
  sellerId?: Maybe<Scalars['ID']>,
  startDate?: Maybe<Scalars['String']>,
  endDate?: Maybe<Scalars['String']>,
  isConfirmed?: Maybe<Scalars['Boolean']>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


export type QueryPricingsArgs = {
  where?: Maybe<PricingWhereInput>,
  orderBy?: Maybe<Array<PricingOrderByInput>>,
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<PricingWhereUniqueInput>
};


export type QueryProductArgs = {
  where: ProductWhereUniqueInput
};


export type QueryProductsArgs = {
  where?: Maybe<ProductWhereInput>,
  orderBy?: Maybe<Array<ProductOrderByInput>>,
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<ProductWhereUniqueInput>
};


export type QueryProductMatchArgs = {
  where: ProductMatchWhereUniqueInput
};


export type QueryProductMatchesArgs = {
  where?: Maybe<ProductMatchWhereInput>,
  orderBy?: Maybe<Array<ProductMatchOrderByInput>>,
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<ProductMatchWhereUniqueInput>
};


export type QueryProductOptionArgs = {
  where: ProductOptionWhereUniqueInput
};


export type QueryProductOptionsArgs = {
  where?: Maybe<ProductOptionWhereInput>,
  orderBy?: Maybe<Array<ProductOptionOrderByInput>>,
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<ProductOptionWhereUniqueInput>
};


export type QueryAssociatedProductsArgs = {
  logisticId?: Maybe<Scalars['ID']>,
  sellerId?: Maybe<Scalars['ID']>,
  brandId?: Maybe<Scalars['ID']>,
  searchName?: Maybe<Scalars['String']>,
  searchString?: Maybe<Scalars['String']>
};


export type QueryReturnOrdersArgs = {
  logisticId?: Maybe<Scalars['ID']>,
  sellerId?: Maybe<Scalars['ID']>,
  brandId?: Maybe<Scalars['ID']>,
  startDate?: Maybe<Scalars['String']>,
  endDate?: Maybe<Scalars['String']>,
  ciNum?: Maybe<Scalars['String']>,
  returnCiNum?: Maybe<Scalars['String']>,
  receiver?: Maybe<Scalars['String']>,
  receiverTel?: Maybe<Scalars['String']>,
  status?: Maybe<ReturnStatus>
};


export type QuerySellerArgs = {
  where: SellerWhereUniqueInput
};


export type QuerySellersArgs = {
  where?: Maybe<SellerWhereInput>,
  orderBy?: Maybe<Array<SellerOrderByInput>>,
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<SellerWhereUniqueInput>
};


export type QueryStocktakingArgs = {
  where: StocktakingWhereUniqueInput
};


export type QueryStocktakingsArgs = {
  where?: Maybe<StocktakingWhereInput>,
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<StocktakingWhereUniqueInput>
};


export type QuerySuperAdminsArgs = {
  where?: Maybe<SuperAdminWhereInput>,
  orderBy?: Maybe<Array<SuperAdminOrderByInput>>,
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<SuperAdminWhereUniqueInput>
};


export type QueryTeamsArgs = {
  where?: Maybe<TeamWhereInput>,
  orderBy?: Maybe<Array<TeamOrderByInput>>,
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<TeamWhereUniqueInput>
};


export type QueryTransactionArgs = {
  where: TransactionWhereUniqueInput
};


export type QueryTransactionsArgs = {
  where?: Maybe<TransactionWhereInput>,
  orderBy?: Maybe<Array<TransactionOrderByInput>>,
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<TransactionWhereUniqueInput>
};


export type QueryStockWithProductArgs = {
  logisticId?: Maybe<Scalars['ID']>,
  sellerId?: Maybe<Scalars['ID']>,
  brandId?: Maybe<Scalars['ID']>,
  endDate?: Maybe<Scalars['String']>,
  searchProduct?: Maybe<Scalars['String']>,
  searchProductOption?: Maybe<Scalars['String']>,
  centerId?: Maybe<Scalars['ID']>,
  zone?: Maybe<Scalars['String']>,
  rack?: Maybe<Scalars['String']>,
  level?: Maybe<Scalars['String']>
};


export type QueryStockWithProductExcelArgs = {
  logisticId?: Maybe<Scalars['ID']>,
  sellerId?: Maybe<Scalars['ID']>,
  brandId?: Maybe<Scalars['ID']>,
  endDate?: Maybe<Scalars['String']>,
  searchProduct?: Maybe<Scalars['String']>,
  searchProductOption?: Maybe<Scalars['String']>,
  centerId?: Maybe<Scalars['ID']>,
  zone?: Maybe<Scalars['String']>,
  rack?: Maybe<Scalars['String']>,
  level?: Maybe<Scalars['String']>,
  selectedStocks?: Maybe<Array<SelectedStocksInputTypeForProduct>>
};


export type QueryStockWithLocationArgs = {
  logisticId: Scalars['ID'],
  sellerId?: Maybe<Scalars['ID']>,
  brandId?: Maybe<Scalars['ID']>,
  endDate?: Maybe<Scalars['String']>,
  searchProduct?: Maybe<Scalars['String']>,
  searchProductOption?: Maybe<Scalars['String']>,
  centerId?: Maybe<Scalars['ID']>,
  zone?: Maybe<Scalars['String']>,
  rack?: Maybe<Scalars['String']>,
  level?: Maybe<Scalars['String']>
};


export type QueryStockWithLocationExcelArgs = {
  logisticId: Scalars['ID'],
  sellerId?: Maybe<Scalars['ID']>,
  brandId?: Maybe<Scalars['ID']>,
  endDate?: Maybe<Scalars['String']>,
  searchProduct?: Maybe<Scalars['String']>,
  searchProductOption?: Maybe<Scalars['String']>,
  centerId?: Maybe<Scalars['ID']>,
  zone?: Maybe<Scalars['String']>,
  rack?: Maybe<Scalars['String']>,
  level?: Maybe<Scalars['String']>,
  selectedStocks?: Maybe<Array<SelectedStocksInputTypeForLocation>>
};


export type QueryIngoingOutgoingArgs = {
  logisticId?: Maybe<Scalars['ID']>,
  sellerId?: Maybe<Scalars['ID']>,
  brandId?: Maybe<Scalars['ID']>,
  startDate?: Maybe<Scalars['String']>,
  endDate?: Maybe<Scalars['String']>,
  productId?: Maybe<Scalars['ID']>,
  productOptionId?: Maybe<Scalars['ID']>,
  centerId?: Maybe<Scalars['ID']>,
  zone?: Maybe<Scalars['String']>,
  rack?: Maybe<Scalars['String']>,
  level?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['Int']>,
  detailedType?: Maybe<Scalars['Int']>,
  transportType?: Maybe<TransportType>
};


export type QueryDetailedStockArgs = {
  productOptionId: Scalars['ID'],
  locationId: Scalars['ID']
};


export type QueryStockByProductOptionArgs = {
  productOptionId: Scalars['ID']
};


export type QueryMoveStockArgs = {
  logisticId: Scalars['ID'],
  sellerId?: Maybe<Scalars['ID']>,
  brandId?: Maybe<Scalars['ID']>,
  startDate?: Maybe<Scalars['String']>,
  endDate?: Maybe<Scalars['String']>,
  productId?: Maybe<Scalars['ID']>,
  productOptionId?: Maybe<Scalars['ID']>
};


export type QueryStockHistoryArgs = {
  transactionId: Scalars['ID']
};


export type QueryStockDetailedHistoryArgs = {
  transactionId: Scalars['ID']
};


export type QueryForcedOutboundTransactionsArgs = {
  productOptionId: Scalars['ID']
};


export type QueryUsersArgs = {
  where?: Maybe<UserWhereInput>,
  orderBy?: Maybe<Array<UserOrderByInput>>,
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<UserWhereUniqueInput>
};


export type QueryCheckPasswordArgs = {
  password: Scalars['String']
};


export type QueryWidgetsArgs = {
  where?: Maybe<WidgetWhereInput>,
  orderBy?: Maybe<Array<WidgetOrderByInput>>,
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<WidgetWhereUniqueInput>
};

export type AutoMatchWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
};

export type AutoMatch = {
   __typename?: 'AutoMatch',
  id: Scalars['String'],
  brandMarket: BrandMarket,
  brandMarketId: Scalars['String'],
  marketProductNum?: Maybe<Scalars['String']>,
  marketProductName: Scalars['String'],
  marketProductOptionNum?: Maybe<Scalars['String']>,
  marketProductOptionName?: Maybe<Scalars['String']>,
  marketProductOptionAmount: Scalars['Int'],
  productOption: ProductOption,
  productOptionId: Scalars['String'],
  productAmount: Scalars['Int'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  productMatches: Array<ProductMatch>,
};


export type AutoMatchProductMatchesArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<ProductMatchWhereUniqueInput>
};

export type BrandMarket = {
   __typename?: 'BrandMarket',
  id: Scalars['String'],
  brand: Brand,
  brandId: Scalars['String'],
  market: Market,
  marketId: Scalars['String'],
  name: Scalars['String'],
  sender?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  zipcode?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  detailedAddress?: Maybe<Scalars['String']>,
  credential?: Maybe<Scalars['String']>,
  isActive: Scalars['Boolean'],
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  orders: Array<Order>,
  autoMatches: Array<AutoMatch>,
};


export type BrandMarketOrdersArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<OrderWhereUniqueInput>
};


export type BrandMarketAutoMatchesArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<AutoMatchWhereUniqueInput>
};

export type Brand = {
   __typename?: 'Brand',
  id: Scalars['String'],
  seller: Seller,
  sellerId: Scalars['String'],
  name: Scalars['String'],
  phone?: Maybe<Scalars['String']>,
  zipcode?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  detailedAddress?: Maybe<Scalars['String']>,
  isActive: Scalars['Boolean'],
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  brandMarkets: Array<BrandMarket>,
  products: Array<Product>,
  courierInvoices: Array<CourierInvoice>,
};


export type BrandBrandMarketsArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<BrandMarketWhereUniqueInput>
};


export type BrandProductsArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<ProductWhereUniqueInput>
};


export type BrandCourierInvoicesArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<CourierInvoiceWhereUniqueInput>
};

export type Seller = {
   __typename?: 'Seller',
  id: Scalars['String'],
  company: Company,
  companyId: Scalars['String'],
  logistic: Logistic,
  logisticId: Scalars['String'],
  defaultCourier?: Maybe<LogisticCourier>,
  defaultCourierId?: Maybe<Scalars['String']>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  brands: Array<Brand>,
  notices: Array<Notice>,
};


export type SellerBrandsArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<BrandWhereUniqueInput>
};


export type SellerNoticesArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<NoticeWhereUniqueInput>
};

export type Company = {
   __typename?: 'Company',
  id: Scalars['String'],
  type: CompanyType,
  name?: Maybe<Scalars['String']>,
  ceo?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  zipcode?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  detailedAddress?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  status: CompanyStatus,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  taxInfo: TaxInfo,
  users: Array<User>,
  teams: Array<Team>,
  bankAccounts: Array<BankAccount>,
  superAdmin?: Maybe<SuperAdmin>,
  logistic?: Maybe<Logistic>,
  seller?: Maybe<Seller>,
};


export type CompanyUsersArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<UserWhereUniqueInput>
};


export type CompanyTeamsArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<TeamWhereUniqueInput>
};


export type CompanyBankAccountsArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<BankAccountWhereUniqueInput>
};

export enum CompanyType {
  Logistic = 'LOGISTIC',
  Seller = 'SELLER',
  Super = 'SUPER'
}

export enum CompanyStatus {
  Waiting = 'WAITING',
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Suspended = 'SUSPENDED'
}


export type TaxInfo = {
   __typename?: 'TaxInfo',
  id: Scalars['String'],
  company: Company,
  companyId: Scalars['String'],
  corporationName?: Maybe<Scalars['String']>,
  businessRegNum?: Maybe<Scalars['String']>,
  businessType?: Maybe<Scalars['String']>,
  businessItem?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  zipcode?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  detailedAddress?: Maybe<Scalars['String']>,
  needTaxInfo: Scalars['Boolean'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
};

export type UserWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
};

export type User = {
   __typename?: 'User',
  id: Scalars['String'],
  email: Scalars['String'],
  emailVerified: Scalars['Boolean'],
  type: CompanyType,
  password: Scalars['String'],
  name?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  company: Company,
  companyId: Scalars['String'],
  team?: Maybe<Team>,
  teamId?: Maybe<Scalars['String']>,
  role: RoleType,
  marketing: Scalars['Boolean'],
  settings?: Maybe<Scalars['Json']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  confirmedTransactions: Array<Transaction>,
  registeredPreStocks: Array<PreStock>,
  confirmedPreStocks: Array<PreStock>,
  confirmedFinalPackages: Array<FinalPackage>,
  confirmedStocktaking: Array<Stocktaking>,
  confirmedReturnOrder: Array<ReturnOrder>,
  logisticdefaultOf: Array<Logistic>,
  notices: Array<Notice>,
};


export type UserConfirmedTransactionsArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<TransactionWhereUniqueInput>
};


export type UserRegisteredPreStocksArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<PreStockWhereUniqueInput>
};


export type UserConfirmedPreStocksArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<PreStockWhereUniqueInput>
};


export type UserConfirmedFinalPackagesArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<FinalPackageWhereUniqueInput>
};


export type UserConfirmedStocktakingArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<StocktakingWhereUniqueInput>
};


export type UserConfirmedReturnOrderArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<ReturnOrderWhereUniqueInput>
};


export type UserLogisticdefaultOfArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<LogisticWhereUniqueInput>
};


export type UserNoticesArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<NoticeWhereUniqueInput>
};

export type Team = {
   __typename?: 'Team',
  id: Scalars['String'],
  company: Company,
  companyId: Scalars['String'],
  name: Scalars['String'],
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  users: Array<User>,
};


export type TeamUsersArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<UserWhereUniqueInput>
};

export enum RoleType {
  Admin = 'ADMIN',
  Manager = 'MANAGER',
  Staff = 'STAFF'
}


export type TransactionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
};

export type Transaction = {
   __typename?: 'Transaction',
  id: Scalars['String'],
  logistic: Logistic,
  logisticId: Scalars['String'],
  preStock?: Maybe<PreStock>,
  preStockId?: Maybe<Scalars['String']>,
  parent?: Maybe<Transaction>,
  parentId?: Maybe<Scalars['String']>,
  location?: Maybe<Location>,
  locationId?: Maybe<Scalars['String']>,
  productOption: ProductOption,
  productOptionId: Scalars['String'],
  manufacturedDate?: Maybe<Scalars['DateTime']>,
  expiryDate?: Maybe<Scalars['DateTime']>,
  eventType: EventType,
  transportType: TransportType,
  confirmedUser?: Maybe<User>,
  confirmedUserId?: Maybe<Scalars['String']>,
  finalPackage?: Maybe<FinalPackage>,
  finalPackageId?: Maybe<Scalars['String']>,
  usableAmount: Scalars['Int'],
  brokenAmount: Scalars['Int'],
  note?: Maybe<Scalars['String']>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  children: Array<Transaction>,
  stocktaking?: Maybe<Stocktaking>,
};


export type TransactionChildrenArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<TransactionWhereUniqueInput>
};

export type Logistic = {
   __typename?: 'Logistic',
  id: Scalars['String'],
  company: Company,
  companyId: Scalars['String'],
  defaultUser: User,
  defaultUserId: Scalars['String'],
  defaultCourier?: Maybe<LogisticCourier>,
  defaultCourierId?: Maybe<Scalars['String']>,
  marketing: Scalars['Boolean'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  sellers: Array<Seller>,
  availableCouriers: Array<LogisticCourier>,
  packets: Array<Packet>,
  centers: Array<Center>,
  preStocks: Array<PreStock>,
  transactions: Array<Transaction>,
  notices: Array<Notice>,
};


export type LogisticSellersArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<SellerWhereUniqueInput>
};


export type LogisticAvailableCouriersArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<LogisticCourierWhereUniqueInput>
};


export type LogisticPacketsArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<PacketWhereUniqueInput>
};


export type LogisticCentersArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<CenterWhereUniqueInput>
};


export type LogisticPreStocksArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<PreStockWhereUniqueInput>
};


export type LogisticTransactionsArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<TransactionWhereUniqueInput>
};


export type LogisticNoticesArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<NoticeWhereUniqueInput>
};

export type LogisticCourier = {
   __typename?: 'LogisticCourier',
  id: Scalars['String'],
  logistic: Logistic,
  logisticId: Scalars['String'],
  courier: Courier,
  courierId: Scalars['String'],
  config: Scalars['String'],
  isActive: Scalars['Boolean'],
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  logisticDefaultOf?: Maybe<Logistic>,
  sellerDefaultOf: Array<Seller>,
  courierInvoices: Array<CourierInvoice>,
  courierInvoiceTrackings: Array<CourierInvoiceTracking>,
};


export type LogisticCourierSellerDefaultOfArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<SellerWhereUniqueInput>
};


export type LogisticCourierCourierInvoicesArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<CourierInvoiceWhereUniqueInput>
};


export type LogisticCourierCourierInvoiceTrackingsArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<CourierInvoiceTrackingWhereUniqueInput>
};

export type Courier = {
   __typename?: 'Courier',
  id: Scalars['String'],
  name: Scalars['String'],
  configFields: Scalars['String'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  priceRanges: Array<CourierPriceRange>,
  logisticCouriers: Array<LogisticCourier>,
};


export type CourierPriceRangesArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<CourierPriceRangeWhereUniqueInput>
};


export type CourierLogisticCouriersArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<LogisticCourierWhereUniqueInput>
};

export type CourierPriceRangeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
  courierId_basic?: Maybe<CourierIdBasicCompoundUniqueInput>,
};

export type CourierIdBasicCompoundUniqueInput = {
  courierId: Scalars['String'],
  basic: BasicCourierPriceRange,
};

export enum BasicCourierPriceRange {
  Kg2 = 'KG2',
  Kg5 = 'KG5',
  Kg10 = 'KG10',
  Kg15 = 'KG15',
  Kg20 = 'KG20',
  Kg25 = 'KG25',
  Kg30 = 'KG30',
  Kg35 = 'KG35',
  Kg40 = 'KG40'
}

export type CourierPriceRange = {
   __typename?: 'CourierPriceRange',
  id: Scalars['String'],
  courier: Courier,
  courierId: Scalars['String'],
  basic: BasicCourierPriceRange,
  name: Scalars['String'],
  value?: Maybe<Scalars['String']>,
  price: Scalars['Int'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  courierInvoices: Array<CourierInvoice>,
};


export type CourierPriceRangeCourierInvoicesArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<CourierInvoiceWhereUniqueInput>
};

export type CourierInvoiceWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
};

export type CourierInvoice = {
   __typename?: 'CourierInvoice',
  id: Scalars['String'],
  type: CourierInvoiceType,
  brand: Brand,
  brandId: Scalars['String'],
  logisticCourier?: Maybe<LogisticCourier>,
  logisticCourierId?: Maybe<Scalars['String']>,
  courierName?: Maybe<Scalars['String']>,
  num?: Maybe<Scalars['String']>,
  groupNum?: Maybe<Scalars['Int']>,
  printedNum: Scalars['Int'],
  invoiceStatus: CourierInvoiceStatus,
  status: PackageStatus,
  parent?: Maybe<CourierInvoice>,
  parentId?: Maybe<Scalars['String']>,
  sender: Scalars['String'],
  senderTel: Scalars['String'],
  senderZipcode: Scalars['String'],
  senderAddress: Scalars['String'],
  senderDetailedAddress?: Maybe<Scalars['String']>,
  receiver: Scalars['String'],
  receiverTel: Scalars['String'],
  receiverZipcode: Scalars['String'],
  receiverAddress: Scalars['String'],
  receiverDetailedAddress?: Maybe<Scalars['String']>,
  content?: Maybe<Scalars['String']>,
  message?: Maybe<Scalars['String']>,
  note?: Maybe<Scalars['String']>,
  addressRefinedDate?: Maybe<Scalars['DateTime']>,
  addressRefinedValues?: Maybe<Scalars['String']>,
  courierPriceRange?: Maybe<CourierPriceRange>,
  courierPriceRangeId?: Maybe<Scalars['String']>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  printedAt?: Maybe<Scalars['DateTime']>,
  children: Array<CourierInvoice>,
  finalPackages: Array<FinalPackage>,
  returnOrders: Array<ReturnOrder>,
};


export type CourierInvoiceChildrenArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<CourierInvoiceWhereUniqueInput>
};


export type CourierInvoiceFinalPackagesArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<FinalPackageWhereUniqueInput>
};


export type CourierInvoiceReturnOrdersArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<ReturnOrderWhereUniqueInput>
};

export enum CourierInvoiceType {
  Normal = 'NORMAL',
  New = 'NEW',
  Additional = 'ADDITIONAL',
  Etc = 'ETC'
}

export enum CourierInvoiceStatus {
  Normal = 'NORMAL',
  Canceled = 'CANCELED',
  PartialCanceled = 'PARTIAL_CANCELED',
  Suspended = 'SUSPENDED',
  PartialSuspended = 'PARTIAL_SUSPENDED',
  CanceledSuspended = 'CANCELED_SUSPENDED'
}

export enum PackageStatus {
  Ready = 'READY',
  Request = 'REQUEST',
  Confirmed = 'CONFIRMED',
  Ongoing = 'ONGOING',
  Delivered = 'DELIVERED',
  DeliveryFailed = 'DELIVERY_FAILED',
  Canceled = 'CANCELED',
  Suspended = 'SUSPENDED'
}

export type FinalPackageWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
};

export type FinalPackage = {
   __typename?: 'FinalPackage',
  id: Scalars['String'],
  courierInvoice: CourierInvoice,
  courierInvoiceId: Scalars['String'],
  productMatch: ProductMatch,
  productMatchId: Scalars['String'],
  amount: Scalars['Int'],
  confirmedUser?: Maybe<User>,
  confirmedUserId?: Maybe<Scalars['String']>,
  status: FinalPackageStatus,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  returnOrderProducts: Array<ReturnOrderProduct>,
  transactions: Array<Transaction>,
};


export type FinalPackageReturnOrderProductsArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<ReturnOrderProductWhereUniqueInput>
};


export type FinalPackageTransactionsArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<TransactionWhereUniqueInput>
};

export type ProductMatch = {
   __typename?: 'ProductMatch',
  id: Scalars['String'],
  marketProduct: MarketProduct,
  marketProductId: Scalars['String'],
  autoMatch?: Maybe<AutoMatch>,
  autoMatchId?: Maybe<Scalars['String']>,
  productOption: ProductOption,
  productOptionId: Scalars['String'],
  amount: Scalars['Int'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  finalPackages: Array<FinalPackage>,
};


export type ProductMatchFinalPackagesArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<FinalPackageWhereUniqueInput>
};

export type MarketProduct = {
   __typename?: 'MarketProduct',
  id: Scalars['String'],
  order: Order,
  orderId: Scalars['String'],
  num?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  optionNum?: Maybe<Scalars['String']>,
  optionName?: Maybe<Scalars['String']>,
  amount: Scalars['Int'],
  totalPrice: Scalars['Int'],
  status: MarketProductStatus,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  productMatches: Array<ProductMatch>,
};


export type MarketProductProductMatchesArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<ProductMatchWhereUniqueInput>
};

export type Order = {
   __typename?: 'Order',
  id: Scalars['String'],
  num: Scalars['String'],
  brandMarket: BrandMarket,
  brandMarketId: Scalars['String'],
  marketOrderNum: Scalars['String'],
  orderedDate?: Maybe<Scalars['DateTime']>,
  paidDate?: Maybe<Scalars['DateTime']>,
  sender?: Maybe<Scalars['String']>,
  senderTel?: Maybe<Scalars['String']>,
  senderTel2?: Maybe<Scalars['String']>,
  receiver: Scalars['String'],
  receiverTel: Scalars['String'],
  receiverTel2?: Maybe<Scalars['String']>,
  receiverZipcode?: Maybe<Scalars['String']>,
  receiverAddress: Scalars['String'],
  receiverDetailedAddress?: Maybe<Scalars['String']>,
  message?: Maybe<Scalars['String']>,
  deliveryPaid: Scalars['Boolean'],
  deliveryPrice: Scalars['Int'],
  isOverseas: Scalars['Boolean'],
  remoteAreaPrice: Scalars['Int'],
  detailedOrder?: Maybe<Scalars['String']>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  marketProducts: Array<MarketProduct>,
  orderNotes: Array<OrderNote>,
};


export type OrderMarketProductsArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<MarketProductWhereUniqueInput>
};


export type OrderOrderNotesArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<OrderNoteWhereUniqueInput>
};

export type MarketProductWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
};

export type OrderNoteWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
};

export type OrderNote = {
   __typename?: 'OrderNote',
  id: Scalars['String'],
  order: Order,
  orderId: Scalars['String'],
  type: OrderNoteType,
  note?: Maybe<Scalars['String']>,
  status: OrderNoteStatus,
  writer: User,
  writerId: Scalars['String'],
  confirmedUser?: Maybe<User>,
  confirmedUserId?: Maybe<Scalars['String']>,
  confirmedAt?: Maybe<Scalars['DateTime']>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
};

export enum OrderNoteType {
  Normal = 'NORMAL',
  Emergency = 'EMERGENCY',
  ChangeMind = 'CHANGE_MIND',
  BrokenProduct = 'BROKEN_PRODUCT',
  WrongProduct = 'WRONG_PRODUCT',
  MissingProduct = 'MISSING_PRODUCT',
  Exchange = 'EXCHANGE',
  UpdateOrder = 'UPDATE_ORDER',
  ReleaseOrder = 'RELEASE_ORDER',
  CancelOrder = 'CANCEL_ORDER',
  SuspendOrder = 'SUSPEND_ORDER',
  DoneOrder = 'DONE_ORDER',
  UpdateMatching = 'UPDATE_MATCHING',
  AddSend = 'ADD_SEND',
  PrintInvoice = 'PRINT_INVOICE',
  CancelCombine = 'CANCEL_COMBINE',
  CancelInvoice = 'CANCEL_INVOICE',
  SuspendInvoice = 'SUSPEND_INVOICE',
  ReleaseInvoice = 'RELEASE_INVOICE',
  RequestReturn = 'REQUEST_RETURN',
  AddInvoice = 'ADD_INVOICE',
  UpdateInvoice = 'UPDATE_INVOICE',
  Etc = 'ETC'
}

export enum OrderNoteStatus {
  Waiting = 'WAITING',
  Done = 'DONE'
}

export enum MarketProductStatus {
  Waiting = 'WAITING',
  Done = 'DONE',
  SellerCanceled = 'SELLER_CANCELED',
  BuyerCanceled = 'BUYER_CANCELED',
  Suspended = 'SUSPENDED'
}

export type ProductMatchWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
};

export type ProductOption = {
   __typename?: 'ProductOption',
  id: Scalars['String'],
  product: Product,
  productId: Scalars['String'],
  levelOne?: Maybe<Scalars['String']>,
  levelTwo?: Maybe<Scalars['String']>,
  originNum?: Maybe<Scalars['String']>,
  category?: Maybe<ProductCategory>,
  categoryId?: Maybe<Scalars['String']>,
  originBarcode?: Maybe<Scalars['String']>,
  newBarcode?: Maybe<Scalars['String']>,
  needInspection: Scalars['Boolean'],
  temperature: TemperatureType,
  bestPeriodDay?: Maybe<Scalars['Int']>,
  basicCourierPriceRange: BasicCourierPriceRange,
  isActive: Scalars['Boolean'],
  canBeMixed: Scalars['Boolean'],
  maxAmount?: Maybe<Scalars['Int']>,
  note?: Maybe<Scalars['String']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  preStocks: Array<PreStock>,
  transactions: Array<Transaction>,
  autoMatches: Array<AutoMatch>,
  productMatches: Array<ProductMatch>,
};


export type ProductOptionPreStocksArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<PreStockWhereUniqueInput>
};


export type ProductOptionTransactionsArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<TransactionWhereUniqueInput>
};


export type ProductOptionAutoMatchesArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<AutoMatchWhereUniqueInput>
};


export type ProductOptionProductMatchesArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<ProductMatchWhereUniqueInput>
};

export type Product = {
   __typename?: 'Product',
  id: Scalars['String'],
  brand: Brand,
  brandId: Scalars['String'],
  name: Scalars['String'],
  note?: Maybe<Scalars['String']>,
  isActive: Scalars['Boolean'],
  hasLevel: Scalars['Boolean'],
  levelOneName?: Maybe<Scalars['String']>,
  levelTwoName?: Maybe<Scalars['String']>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  productOptions: Array<ProductOption>,
};


export type ProductProductOptionsArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<ProductOptionWhereUniqueInput>
};

export type ProductOptionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
  productId_levelOne_levelTwo?: Maybe<ProductIdLevelOneLevelTwoCompoundUniqueInput>,
};

export type ProductIdLevelOneLevelTwoCompoundUniqueInput = {
  productId: Scalars['String'],
  levelOne: Scalars['String'],
  levelTwo: Scalars['String'],
};

export type ProductCategory = {
   __typename?: 'ProductCategory',
  id: Scalars['String'],
  name: Scalars['String'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  productOptions: Array<ProductOption>,
};


export type ProductCategoryProductOptionsArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<ProductOptionWhereUniqueInput>
};

export enum TemperatureType {
  Room = 'ROOM',
  Freezing = 'FREEZING',
  Refrigerating = 'REFRIGERATING'
}

export type PreStockWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
};

export type PreStock = {
   __typename?: 'PreStock',
  id: Scalars['String'],
  logistic: Logistic,
  logisticId: Scalars['String'],
  productOption: ProductOption,
  productOptionId: Scalars['String'],
  amount: Scalars['Int'],
  loadUnit?: Maybe<LoadUnit>,
  loadUnitAmount?: Maybe<Scalars['Int']>,
  scheduledDate: Scalars['DateTime'],
  registeredUser: User,
  registeredUserId: Scalars['String'],
  confirmedUser?: Maybe<User>,
  confirmedUserId?: Maybe<Scalars['String']>,
  confirmedDate?: Maybe<Scalars['DateTime']>,
  note?: Maybe<Scalars['String']>,
  manufacturedDate?: Maybe<Scalars['DateTime']>,
  expiryDate?: Maybe<Scalars['DateTime']>,
  canceledAt?: Maybe<Scalars['DateTime']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  transactions: Array<Transaction>,
};


export type PreStockTransactionsArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<TransactionWhereUniqueInput>
};

export enum LoadUnit {
  Each = 'EACH',
  Box = 'BOX',
  Pallet = 'PALLET',
  Container = 'CONTAINER'
}

export enum FinalPackageStatus {
  Normal = 'NORMAL',
  Canceled = 'CANCELED',
  Suspended = 'SUSPENDED'
}

export type ReturnOrderProductWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
};

export type ReturnOrderProduct = {
   __typename?: 'ReturnOrderProduct',
  id: Scalars['String'],
  returnOrder: ReturnOrder,
  returnOrderId: Scalars['String'],
  finalPackage: FinalPackage,
  finalPackageId: Scalars['String'],
  requestAmount: Scalars['Int'],
  category: ReturnOrderType,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
};

export type ReturnOrder = {
   __typename?: 'ReturnOrder',
  id: Scalars['String'],
  courierInvoice: CourierInvoice,
  courierInvoiceId: Scalars['String'],
  returnCourierInvoiceNum?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  tel: Scalars['String'],
  zipcode: Scalars['String'],
  address: Scalars['String'],
  detailedAddress?: Maybe<Scalars['String']>,
  message: Scalars['String'],
  status: ReturnStatus,
  note?: Maybe<Scalars['String']>,
  confirmedUser: User,
  confirmedUserId: Scalars['String'],
  deliveryPaidType: DeliveryPaidType,
  returnedAt?: Maybe<Scalars['DateTime']>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  returnOrderProducts: Array<ReturnOrderProduct>,
};


export type ReturnOrderReturnOrderProductsArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<ReturnOrderProductWhereUniqueInput>
};

export enum ReturnStatus {
  Request = 'REQUEST',
  Confirmed = 'CONFIRMED',
  Canceled = 'CANCELED',
  Ongoing = 'ONGOING',
  Done = 'DONE',
  WithdrawalRequest = 'WITHDRAWAL_REQUEST',
  WithdrawalConfirmed = 'WITHDRAWAL_CONFIRMED',
  WithdrawalCanceled = 'WITHDRAWAL_CANCELED'
}

export enum DeliveryPaidType {
  Contract = 'CONTRACT',
  DeliveryMan = 'DELIVERY_MAN',
  Enclosed = 'ENCLOSED',
  Etc = 'ETC'
}

export enum ReturnOrderType {
  ChangeMind = 'CHANGE_MIND',
  BrokenProduct = 'BROKEN_PRODUCT',
  WrongProduct = 'WRONG_PRODUCT',
  Exchange = 'EXCHANGE',
  Etc = 'ETC'
}

export type ReturnOrderWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
};

export type LogisticCourierWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
};

export type SellerWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
  companyId?: Maybe<Scalars['String']>,
};

export type CourierInvoiceTrackingWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
};

export type CourierInvoiceTracking = {
   __typename?: 'CourierInvoiceTracking',
  id: Scalars['String'],
  logisticCourier: LogisticCourier,
  logisticCourierId: Scalars['String'],
  courierInvoiceNum: Scalars['String'],
  status: Scalars['String'],
  trackedDate: Scalars['DateTime'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
};

export type PacketWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
};

export type Packet = {
   __typename?: 'Packet',
  id: Scalars['String'],
  logistic: Logistic,
  logisticId: Scalars['String'],
  pricing: Pricing,
  pricingId: Scalars['String'],
  startDate: Scalars['DateTime'],
  endDate: Scalars['DateTime'],
  isPaid: Scalars['Boolean'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
};

export type Pricing = {
   __typename?: 'Pricing',
  id: Scalars['String'],
  name: Scalars['String'],
  count?: Maybe<Scalars['Int']>,
  price: Scalars['Int'],
  period?: Maybe<Scalars['String']>,
  description: Scalars['String'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  packets: Array<Packet>,
};


export type PricingPacketsArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<PacketWhereUniqueInput>
};

export type CenterWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
  logisticId_name?: Maybe<LogisticIdNameCompoundUniqueInput>,
};

export type LogisticIdNameCompoundUniqueInput = {
  logisticId: Scalars['String'],
  name: Scalars['String'],
};

export type Center = {
   __typename?: 'Center',
  id: Scalars['String'],
  logistic: Logistic,
  logisticId: Scalars['String'],
  name: Scalars['String'],
  zipcode: Scalars['String'],
  address: Scalars['String'],
  detailedAddress?: Maybe<Scalars['String']>,
  shippingAvailable: Scalars['Boolean'],
  isActive: Scalars['Boolean'],
  useLocation: Scalars['Boolean'],
  totalQuantity?: Maybe<Scalars['Int']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  settings: Array<LocationSetting>,
  locations: Array<Location>,
};


export type CenterSettingsArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<LocationSettingWhereUniqueInput>
};


export type CenterLocationsArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<LocationWhereUniqueInput>
};

export type LocationSettingWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
};

export type LocationSetting = {
   __typename?: 'LocationSetting',
  id: Scalars['String'],
  center: Center,
  centerId: Scalars['String'],
  zone: Scalars['String'],
  rack: Scalars['String'],
  level: Scalars['String'],
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  locations: Array<Location>,
};


export type LocationSettingLocationsArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<LocationWhereUniqueInput>
};

export type LocationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
};

export type Location = {
   __typename?: 'Location',
  id: Scalars['String'],
  center: Center,
  centerId: Scalars['String'],
  setting: LocationSetting,
  settingId: Scalars['String'],
  zone: Scalars['String'],
  rack: Scalars['String'],
  level: Scalars['String'],
  isActive: Scalars['Boolean'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  incomingRecords: Array<Transaction>,
};


export type LocationIncomingRecordsArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<TransactionWhereUniqueInput>
};

export type NoticeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
};

export type Notice = {
   __typename?: 'Notice',
  id: Scalars['String'],
  logistic: Logistic,
  logisticId: Scalars['String'],
  groupId: Scalars['String'],
  expiryDate?: Maybe<Scalars['DateTime']>,
  title: Scalars['String'],
  content: Scalars['String'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  isPublic: Scalars['Boolean'],
  isImportant: Scalars['Boolean'],
  author: User,
  authorId: Scalars['String'],
  seller: Seller,
  sellerId: Scalars['String'],
};

export enum EventType {
  Prestock = 'PRESTOCK',
  Order = 'ORDER',
  Transfer = 'TRANSFER',
  Exchange = 'EXCHANGE',
  Return = 'RETURN',
  Stocktaking = 'STOCKTAKING',
  Discard = 'DISCARD',
  Etc = 'ETC'
}

export enum TransportType {
  Truck = 'TRUCK',
  Courier = 'COURIER',
  Quick = 'QUICK',
  Direct = 'DIRECT',
  Container = 'CONTAINER',
  Forklift = 'FORKLIFT',
  Etc = 'ETC'
}

export type Stocktaking = {
   __typename?: 'Stocktaking',
  id: Scalars['String'],
  inspectedDate: Scalars['DateTime'],
  baseTransaction: Transaction,
  baseTransactionId: Scalars['String'],
  baseAmount: Scalars['Int'],
  confirmedUser?: Maybe<User>,
  confirmedUserId?: Maybe<Scalars['String']>,
  confirmedDate?: Maybe<Scalars['DateTime']>,
  confirmedNote?: Maybe<Scalars['String']>,
  amount: Scalars['Int'],
  note?: Maybe<Scalars['String']>,
  status: StocktakingStatus,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  deletedAt?: Maybe<Scalars['DateTime']>,
  transactions: Array<Transaction>,
};


export type StocktakingTransactionsArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<TransactionWhereUniqueInput>
};

export enum StocktakingStatus {
  Pending = 'PENDING',
  Confirmed = 'CONFIRMED',
  Canceled = 'CANCELED'
}

export type StocktakingWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
};

export type LogisticWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
  companyId?: Maybe<Scalars['String']>,
};

export type TeamWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
  company?: Maybe<CompanyCompoundUniqueInput>,
};

export type CompanyCompoundUniqueInput = {
  companyId: Scalars['String'],
  name: Scalars['String'],
};

export type BankAccountWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
  companyId_bank_num?: Maybe<CompanyIdBankNumCompoundUniqueInput>,
};

export type CompanyIdBankNumCompoundUniqueInput = {
  companyId: Scalars['String'],
  bank: Scalars['String'],
  num: Scalars['String'],
};

export type BankAccount = {
   __typename?: 'BankAccount',
  id: Scalars['String'],
  company: Company,
  companyId: Scalars['String'],
  bank: Scalars['String'],
  num: Scalars['String'],
  holder: Scalars['String'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
};

export type SuperAdmin = {
   __typename?: 'SuperAdmin',
  id: Scalars['String'],
  company: Company,
  companyId: Scalars['String'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
};

export type BrandWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
  sellerId_name?: Maybe<SellerIdNameCompoundUniqueInput>,
};

export type SellerIdNameCompoundUniqueInput = {
  sellerId: Scalars['String'],
  name: Scalars['String'],
};

export type BrandMarketWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
  brandId_marketId_name?: Maybe<BrandIdMarketIdNameCompoundUniqueInput>,
};

export type BrandIdMarketIdNameCompoundUniqueInput = {
  brandId: Scalars['String'],
  marketId: Scalars['String'],
  name: Scalars['String'],
};

export type ProductWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
  brandId_name?: Maybe<BrandIdNameCompoundUniqueInput>,
};

export type BrandIdNameCompoundUniqueInput = {
  brandId: Scalars['String'],
  name: Scalars['String'],
};

export type Market = {
   __typename?: 'Market',
  id: Scalars['String'],
  name: Scalars['String'],
  type: CommunicationType,
  credentialFields?: Maybe<Scalars['String']>,
  dashboardUri?: Maybe<Scalars['String']>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  brandMarkets: Array<BrandMarket>,
};


export type MarketBrandMarketsArgs = {
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<BrandMarketWhereUniqueInput>
};

export enum CommunicationType {
  Api = 'API',
  Headless = 'HEADLESS',
  Excel = 'EXCEL'
}

export type OrderWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
};

export type AutoMatchWhereInput = {
  id?: Maybe<StringFilter>,
  brandMarketId?: Maybe<StringFilter>,
  marketProductNum?: Maybe<NullableStringFilter>,
  marketProductName?: Maybe<StringFilter>,
  marketProductOptionNum?: Maybe<NullableStringFilter>,
  marketProductOptionName?: Maybe<NullableStringFilter>,
  marketProductOptionAmount?: Maybe<IntFilter>,
  productOptionId?: Maybe<StringFilter>,
  productAmount?: Maybe<IntFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  productMatches?: Maybe<ProductMatchFilter>,
  AND?: Maybe<Array<AutoMatchWhereInput>>,
  OR?: Maybe<Array<AutoMatchWhereInput>>,
  NOT?: Maybe<Array<AutoMatchWhereInput>>,
  brandMarket?: Maybe<BrandMarketWhereInput>,
  productOption?: Maybe<ProductOptionWhereInput>,
};

export type StringFilter = {
  equals?: Maybe<Scalars['String']>,
  not?: Maybe<Scalars['String']>,
  in?: Maybe<Array<Scalars['String']>>,
  notIn?: Maybe<Array<Scalars['String']>>,
  lt?: Maybe<Scalars['String']>,
  lte?: Maybe<Scalars['String']>,
  gt?: Maybe<Scalars['String']>,
  gte?: Maybe<Scalars['String']>,
  contains?: Maybe<Scalars['String']>,
  startsWith?: Maybe<Scalars['String']>,
  endsWith?: Maybe<Scalars['String']>,
};

export type NullableStringFilter = {
  equals?: Maybe<Scalars['String']>,
  not?: Maybe<Scalars['String']>,
  in?: Maybe<Array<Scalars['String']>>,
  notIn?: Maybe<Array<Scalars['String']>>,
  lt?: Maybe<Scalars['String']>,
  lte?: Maybe<Scalars['String']>,
  gt?: Maybe<Scalars['String']>,
  gte?: Maybe<Scalars['String']>,
  contains?: Maybe<Scalars['String']>,
  startsWith?: Maybe<Scalars['String']>,
  endsWith?: Maybe<Scalars['String']>,
};

export type IntFilter = {
  equals?: Maybe<Scalars['Int']>,
  not?: Maybe<Scalars['Int']>,
  in?: Maybe<Array<Scalars['Int']>>,
  notIn?: Maybe<Array<Scalars['Int']>>,
  lt?: Maybe<Scalars['Int']>,
  lte?: Maybe<Scalars['Int']>,
  gt?: Maybe<Scalars['Int']>,
  gte?: Maybe<Scalars['Int']>,
};

export type DateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>,
  not?: Maybe<Scalars['DateTime']>,
  in?: Maybe<Array<Scalars['DateTime']>>,
  notIn?: Maybe<Array<Scalars['DateTime']>>,
  lt?: Maybe<Scalars['DateTime']>,
  lte?: Maybe<Scalars['DateTime']>,
  gt?: Maybe<Scalars['DateTime']>,
  gte?: Maybe<Scalars['DateTime']>,
};

export type ProductMatchFilter = {
  every?: Maybe<ProductMatchWhereInput>,
  some?: Maybe<ProductMatchWhereInput>,
  none?: Maybe<ProductMatchWhereInput>,
};

export type ProductMatchWhereInput = {
  id?: Maybe<StringFilter>,
  marketProductId?: Maybe<StringFilter>,
  autoMatchId?: Maybe<NullableStringFilter>,
  productOptionId?: Maybe<StringFilter>,
  amount?: Maybe<IntFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  finalPackages?: Maybe<FinalPackageFilter>,
  AND?: Maybe<Array<ProductMatchWhereInput>>,
  OR?: Maybe<Array<ProductMatchWhereInput>>,
  NOT?: Maybe<Array<ProductMatchWhereInput>>,
  marketProduct?: Maybe<MarketProductWhereInput>,
  autoMatch?: Maybe<AutoMatchWhereInput>,
  productOption?: Maybe<ProductOptionWhereInput>,
};

export type FinalPackageFilter = {
  every?: Maybe<FinalPackageWhereInput>,
  some?: Maybe<FinalPackageWhereInput>,
  none?: Maybe<FinalPackageWhereInput>,
};

export type FinalPackageWhereInput = {
  id?: Maybe<StringFilter>,
  courierInvoiceId?: Maybe<StringFilter>,
  productMatchId?: Maybe<StringFilter>,
  amount?: Maybe<IntFilter>,
  confirmedUserId?: Maybe<NullableStringFilter>,
  status?: Maybe<FinalPackageStatus>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  returnOrderProducts?: Maybe<ReturnOrderProductFilter>,
  transactions?: Maybe<TransactionFilter>,
  AND?: Maybe<Array<FinalPackageWhereInput>>,
  OR?: Maybe<Array<FinalPackageWhereInput>>,
  NOT?: Maybe<Array<FinalPackageWhereInput>>,
  courierInvoice?: Maybe<CourierInvoiceWhereInput>,
  productMatch?: Maybe<ProductMatchWhereInput>,
  confirmedUser?: Maybe<UserWhereInput>,
};

export type ReturnOrderProductFilter = {
  every?: Maybe<ReturnOrderProductWhereInput>,
  some?: Maybe<ReturnOrderProductWhereInput>,
  none?: Maybe<ReturnOrderProductWhereInput>,
};

export type ReturnOrderProductWhereInput = {
  id?: Maybe<StringFilter>,
  returnOrderId?: Maybe<StringFilter>,
  finalPackageId?: Maybe<StringFilter>,
  requestAmount?: Maybe<IntFilter>,
  category?: Maybe<ReturnOrderType>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  AND?: Maybe<Array<ReturnOrderProductWhereInput>>,
  OR?: Maybe<Array<ReturnOrderProductWhereInput>>,
  NOT?: Maybe<Array<ReturnOrderProductWhereInput>>,
  returnOrder?: Maybe<ReturnOrderWhereInput>,
  finalPackage?: Maybe<FinalPackageWhereInput>,
};

export type ReturnOrderWhereInput = {
  id?: Maybe<StringFilter>,
  courierInvoiceId?: Maybe<StringFilter>,
  returnCourierInvoiceNum?: Maybe<NullableStringFilter>,
  name?: Maybe<StringFilter>,
  tel?: Maybe<StringFilter>,
  zipcode?: Maybe<StringFilter>,
  address?: Maybe<StringFilter>,
  detailedAddress?: Maybe<NullableStringFilter>,
  message?: Maybe<StringFilter>,
  status?: Maybe<ReturnStatus>,
  note?: Maybe<NullableStringFilter>,
  confirmedUserId?: Maybe<StringFilter>,
  deliveryPaidType?: Maybe<DeliveryPaidType>,
  returnedAt?: Maybe<NullableDateTimeFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  returnOrderProducts?: Maybe<ReturnOrderProductFilter>,
  AND?: Maybe<Array<ReturnOrderWhereInput>>,
  OR?: Maybe<Array<ReturnOrderWhereInput>>,
  NOT?: Maybe<Array<ReturnOrderWhereInput>>,
  courierInvoice?: Maybe<CourierInvoiceWhereInput>,
  confirmedUser?: Maybe<UserWhereInput>,
};

export type NullableDateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>,
  not?: Maybe<Scalars['DateTime']>,
  in?: Maybe<Array<Scalars['DateTime']>>,
  notIn?: Maybe<Array<Scalars['DateTime']>>,
  lt?: Maybe<Scalars['DateTime']>,
  lte?: Maybe<Scalars['DateTime']>,
  gt?: Maybe<Scalars['DateTime']>,
  gte?: Maybe<Scalars['DateTime']>,
};

export type CourierInvoiceWhereInput = {
  id?: Maybe<StringFilter>,
  type?: Maybe<CourierInvoiceType>,
  brandId?: Maybe<StringFilter>,
  logisticCourierId?: Maybe<NullableStringFilter>,
  courierName?: Maybe<NullableStringFilter>,
  num?: Maybe<NullableStringFilter>,
  groupNum?: Maybe<NullableIntFilter>,
  printedNum?: Maybe<IntFilter>,
  invoiceStatus?: Maybe<CourierInvoiceStatus>,
  status?: Maybe<PackageStatus>,
  parentId?: Maybe<NullableStringFilter>,
  sender?: Maybe<StringFilter>,
  senderTel?: Maybe<StringFilter>,
  senderZipcode?: Maybe<StringFilter>,
  senderAddress?: Maybe<StringFilter>,
  senderDetailedAddress?: Maybe<NullableStringFilter>,
  receiver?: Maybe<StringFilter>,
  receiverTel?: Maybe<StringFilter>,
  receiverZipcode?: Maybe<StringFilter>,
  receiverAddress?: Maybe<StringFilter>,
  receiverDetailedAddress?: Maybe<NullableStringFilter>,
  content?: Maybe<NullableStringFilter>,
  message?: Maybe<NullableStringFilter>,
  note?: Maybe<NullableStringFilter>,
  addressRefinedDate?: Maybe<NullableDateTimeFilter>,
  addressRefinedValues?: Maybe<NullableStringFilter>,
  courierPriceRangeId?: Maybe<NullableStringFilter>,
  printedAt?: Maybe<NullableDateTimeFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  children?: Maybe<CourierInvoiceFilter>,
  finalPackages?: Maybe<FinalPackageFilter>,
  returnOrders?: Maybe<ReturnOrderFilter>,
  AND?: Maybe<Array<CourierInvoiceWhereInput>>,
  OR?: Maybe<Array<CourierInvoiceWhereInput>>,
  NOT?: Maybe<Array<CourierInvoiceWhereInput>>,
  brand?: Maybe<BrandWhereInput>,
  logisticCourier?: Maybe<LogisticCourierWhereInput>,
  parent?: Maybe<CourierInvoiceWhereInput>,
  courierPriceRange?: Maybe<CourierPriceRangeWhereInput>,
};

export type NullableIntFilter = {
  equals?: Maybe<Scalars['Int']>,
  not?: Maybe<Scalars['Int']>,
  in?: Maybe<Array<Scalars['Int']>>,
  notIn?: Maybe<Array<Scalars['Int']>>,
  lt?: Maybe<Scalars['Int']>,
  lte?: Maybe<Scalars['Int']>,
  gt?: Maybe<Scalars['Int']>,
  gte?: Maybe<Scalars['Int']>,
};

export type CourierInvoiceFilter = {
  every?: Maybe<CourierInvoiceWhereInput>,
  some?: Maybe<CourierInvoiceWhereInput>,
  none?: Maybe<CourierInvoiceWhereInput>,
};

export type ReturnOrderFilter = {
  every?: Maybe<ReturnOrderWhereInput>,
  some?: Maybe<ReturnOrderWhereInput>,
  none?: Maybe<ReturnOrderWhereInput>,
};

export type BrandWhereInput = {
  id?: Maybe<StringFilter>,
  sellerId?: Maybe<StringFilter>,
  name?: Maybe<StringFilter>,
  phone?: Maybe<NullableStringFilter>,
  zipcode?: Maybe<NullableStringFilter>,
  address?: Maybe<NullableStringFilter>,
  detailedAddress?: Maybe<NullableStringFilter>,
  isActive?: Maybe<BooleanFilter>,
  deletedAt?: Maybe<NullableDateTimeFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  brandMarkets?: Maybe<BrandMarketFilter>,
  products?: Maybe<ProductFilter>,
  courierInvoices?: Maybe<CourierInvoiceFilter>,
  AND?: Maybe<Array<BrandWhereInput>>,
  OR?: Maybe<Array<BrandWhereInput>>,
  NOT?: Maybe<Array<BrandWhereInput>>,
  seller?: Maybe<SellerWhereInput>,
};

export type BooleanFilter = {
  equals?: Maybe<Scalars['Boolean']>,
  not?: Maybe<Scalars['Boolean']>,
};

export type BrandMarketFilter = {
  every?: Maybe<BrandMarketWhereInput>,
  some?: Maybe<BrandMarketWhereInput>,
  none?: Maybe<BrandMarketWhereInput>,
};

export type BrandMarketWhereInput = {
  id?: Maybe<StringFilter>,
  brandId?: Maybe<StringFilter>,
  marketId?: Maybe<StringFilter>,
  name?: Maybe<StringFilter>,
  sender?: Maybe<NullableStringFilter>,
  phone?: Maybe<NullableStringFilter>,
  zipcode?: Maybe<NullableStringFilter>,
  address?: Maybe<NullableStringFilter>,
  detailedAddress?: Maybe<NullableStringFilter>,
  credential?: Maybe<NullableStringFilter>,
  isActive?: Maybe<BooleanFilter>,
  deletedAt?: Maybe<NullableDateTimeFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  orders?: Maybe<OrderFilter>,
  autoMatches?: Maybe<AutoMatchFilter>,
  AND?: Maybe<Array<BrandMarketWhereInput>>,
  OR?: Maybe<Array<BrandMarketWhereInput>>,
  NOT?: Maybe<Array<BrandMarketWhereInput>>,
  brand?: Maybe<BrandWhereInput>,
  market?: Maybe<MarketWhereInput>,
};

export type OrderFilter = {
  every?: Maybe<OrderWhereInput>,
  some?: Maybe<OrderWhereInput>,
  none?: Maybe<OrderWhereInput>,
};

export type OrderWhereInput = {
  id?: Maybe<StringFilter>,
  num?: Maybe<StringFilter>,
  brandMarketId?: Maybe<StringFilter>,
  marketOrderNum?: Maybe<StringFilter>,
  orderedDate?: Maybe<NullableDateTimeFilter>,
  paidDate?: Maybe<NullableDateTimeFilter>,
  sender?: Maybe<NullableStringFilter>,
  senderTel?: Maybe<NullableStringFilter>,
  senderTel2?: Maybe<NullableStringFilter>,
  receiver?: Maybe<StringFilter>,
  receiverTel?: Maybe<StringFilter>,
  receiverTel2?: Maybe<NullableStringFilter>,
  receiverZipcode?: Maybe<NullableStringFilter>,
  receiverAddress?: Maybe<StringFilter>,
  receiverDetailedAddress?: Maybe<NullableStringFilter>,
  message?: Maybe<NullableStringFilter>,
  deliveryPaid?: Maybe<BooleanFilter>,
  deliveryPrice?: Maybe<IntFilter>,
  isOverseas?: Maybe<BooleanFilter>,
  remoteAreaPrice?: Maybe<IntFilter>,
  detailedOrder?: Maybe<NullableStringFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  marketProducts?: Maybe<MarketProductFilter>,
  orderNotes?: Maybe<OrderNoteFilter>,
  AND?: Maybe<Array<OrderWhereInput>>,
  OR?: Maybe<Array<OrderWhereInput>>,
  NOT?: Maybe<Array<OrderWhereInput>>,
  brandMarket?: Maybe<BrandMarketWhereInput>,
};

export type MarketProductFilter = {
  every?: Maybe<MarketProductWhereInput>,
  some?: Maybe<MarketProductWhereInput>,
  none?: Maybe<MarketProductWhereInput>,
};

export type MarketProductWhereInput = {
  id?: Maybe<StringFilter>,
  orderId?: Maybe<StringFilter>,
  num?: Maybe<NullableStringFilter>,
  name?: Maybe<StringFilter>,
  optionNum?: Maybe<NullableStringFilter>,
  optionName?: Maybe<NullableStringFilter>,
  amount?: Maybe<IntFilter>,
  totalPrice?: Maybe<IntFilter>,
  status?: Maybe<MarketProductStatus>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  productMatches?: Maybe<ProductMatchFilter>,
  AND?: Maybe<Array<MarketProductWhereInput>>,
  OR?: Maybe<Array<MarketProductWhereInput>>,
  NOT?: Maybe<Array<MarketProductWhereInput>>,
  order?: Maybe<OrderWhereInput>,
};

export type OrderNoteFilter = {
  every?: Maybe<OrderNoteWhereInput>,
  some?: Maybe<OrderNoteWhereInput>,
  none?: Maybe<OrderNoteWhereInput>,
};

export type OrderNoteWhereInput = {
  id?: Maybe<StringFilter>,
  orderId?: Maybe<StringFilter>,
  type?: Maybe<OrderNoteType>,
  note?: Maybe<NullableStringFilter>,
  status?: Maybe<OrderNoteStatus>,
  writerId?: Maybe<StringFilter>,
  confirmedUserId?: Maybe<NullableStringFilter>,
  confirmedAt?: Maybe<NullableDateTimeFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  AND?: Maybe<Array<OrderNoteWhereInput>>,
  OR?: Maybe<Array<OrderNoteWhereInput>>,
  NOT?: Maybe<Array<OrderNoteWhereInput>>,
  order?: Maybe<OrderWhereInput>,
  writer?: Maybe<UserWhereInput>,
  confirmedUser?: Maybe<UserWhereInput>,
};

export type UserWhereInput = {
  id?: Maybe<StringFilter>,
  email?: Maybe<StringFilter>,
  emailVerified?: Maybe<BooleanFilter>,
  type?: Maybe<CompanyType>,
  password?: Maybe<StringFilter>,
  name?: Maybe<NullableStringFilter>,
  phone?: Maybe<NullableStringFilter>,
  companyId?: Maybe<StringFilter>,
  teamId?: Maybe<NullableStringFilter>,
  role?: Maybe<RoleType>,
  marketing?: Maybe<BooleanFilter>,
  deletedAt?: Maybe<NullableDateTimeFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  confirmedTransactions?: Maybe<TransactionFilter>,
  registeredPreStocks?: Maybe<PreStockFilter>,
  confirmedPreStocks?: Maybe<PreStockFilter>,
  confirmedFinalPackages?: Maybe<FinalPackageFilter>,
  confirmedStocktaking?: Maybe<StocktakingFilter>,
  confirmedReturnOrder?: Maybe<ReturnOrderFilter>,
  logisticdefaultOf?: Maybe<LogisticFilter>,
  logs?: Maybe<LogFilter>,
  orderNotes?: Maybe<OrderNoteFilter>,
  confirmedOrderNotes?: Maybe<OrderNoteFilter>,
  notices?: Maybe<NoticeFilter>,
  AND?: Maybe<Array<UserWhereInput>>,
  OR?: Maybe<Array<UserWhereInput>>,
  NOT?: Maybe<Array<UserWhereInput>>,
  company?: Maybe<CompanyWhereInput>,
  team?: Maybe<TeamWhereInput>,
};

export type TransactionFilter = {
  every?: Maybe<TransactionWhereInput>,
  some?: Maybe<TransactionWhereInput>,
  none?: Maybe<TransactionWhereInput>,
};

export type TransactionWhereInput = {
  id?: Maybe<StringFilter>,
  logisticId?: Maybe<StringFilter>,
  preStockId?: Maybe<NullableStringFilter>,
  parentId?: Maybe<NullableStringFilter>,
  locationId?: Maybe<NullableStringFilter>,
  productOptionId?: Maybe<StringFilter>,
  manufacturedDate?: Maybe<NullableDateTimeFilter>,
  expiryDate?: Maybe<NullableDateTimeFilter>,
  eventType?: Maybe<EventType>,
  transportType?: Maybe<TransportType>,
  confirmedUserId?: Maybe<NullableStringFilter>,
  finalPackageId?: Maybe<NullableStringFilter>,
  stocktakingId?: Maybe<NullableStringFilter>,
  usableAmount?: Maybe<IntFilter>,
  brokenAmount?: Maybe<IntFilter>,
  note?: Maybe<NullableStringFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  children?: Maybe<TransactionFilter>,
  stocktakings?: Maybe<StocktakingFilter>,
  AND?: Maybe<Array<TransactionWhereInput>>,
  OR?: Maybe<Array<TransactionWhereInput>>,
  NOT?: Maybe<Array<TransactionWhereInput>>,
  logistic?: Maybe<LogisticWhereInput>,
  preStock?: Maybe<PreStockWhereInput>,
  parent?: Maybe<TransactionWhereInput>,
  location?: Maybe<LocationWhereInput>,
  productOption?: Maybe<ProductOptionWhereInput>,
  confirmedUser?: Maybe<UserWhereInput>,
  finalPackage?: Maybe<FinalPackageWhereInput>,
  stocktaking?: Maybe<StocktakingWhereInput>,
};

export type StocktakingFilter = {
  every?: Maybe<StocktakingWhereInput>,
  some?: Maybe<StocktakingWhereInput>,
  none?: Maybe<StocktakingWhereInput>,
};

export type StocktakingWhereInput = {
  id?: Maybe<StringFilter>,
  inspectedDate?: Maybe<DateTimeFilter>,
  baseTransactionId?: Maybe<StringFilter>,
  baseAmount?: Maybe<IntFilter>,
  confirmedUserId?: Maybe<NullableStringFilter>,
  confirmedDate?: Maybe<NullableDateTimeFilter>,
  confirmedNote?: Maybe<NullableStringFilter>,
  amount?: Maybe<IntFilter>,
  note?: Maybe<NullableStringFilter>,
  status?: Maybe<StocktakingStatus>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  deletedAt?: Maybe<NullableDateTimeFilter>,
  transactions?: Maybe<TransactionFilter>,
  AND?: Maybe<Array<StocktakingWhereInput>>,
  OR?: Maybe<Array<StocktakingWhereInput>>,
  NOT?: Maybe<Array<StocktakingWhereInput>>,
  baseTransaction?: Maybe<TransactionWhereInput>,
  confirmedUser?: Maybe<UserWhereInput>,
};

export type LogisticWhereInput = {
  id?: Maybe<StringFilter>,
  companyId?: Maybe<StringFilter>,
  defaultUserId?: Maybe<StringFilter>,
  defaultCourierId?: Maybe<NullableStringFilter>,
  marketing?: Maybe<BooleanFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  sellers?: Maybe<SellerFilter>,
  availableCouriers?: Maybe<LogisticCourierFilter>,
  packets?: Maybe<PacketFilter>,
  centers?: Maybe<CenterFilter>,
  preStocks?: Maybe<PreStockFilter>,
  transactions?: Maybe<TransactionFilter>,
  notices?: Maybe<NoticeFilter>,
  AND?: Maybe<Array<LogisticWhereInput>>,
  OR?: Maybe<Array<LogisticWhereInput>>,
  NOT?: Maybe<Array<LogisticWhereInput>>,
  company?: Maybe<CompanyWhereInput>,
  defaultUser?: Maybe<UserWhereInput>,
  defaultCourier?: Maybe<LogisticCourierWhereInput>,
};

export type SellerFilter = {
  every?: Maybe<SellerWhereInput>,
  some?: Maybe<SellerWhereInput>,
  none?: Maybe<SellerWhereInput>,
};

export type SellerWhereInput = {
  id?: Maybe<StringFilter>,
  companyId?: Maybe<StringFilter>,
  logisticId?: Maybe<StringFilter>,
  defaultCourierId?: Maybe<NullableStringFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  brands?: Maybe<BrandFilter>,
  notices?: Maybe<NoticeFilter>,
  AND?: Maybe<Array<SellerWhereInput>>,
  OR?: Maybe<Array<SellerWhereInput>>,
  NOT?: Maybe<Array<SellerWhereInput>>,
  company?: Maybe<CompanyWhereInput>,
  logistic?: Maybe<LogisticWhereInput>,
  defaultCourier?: Maybe<LogisticCourierWhereInput>,
};

export type BrandFilter = {
  every?: Maybe<BrandWhereInput>,
  some?: Maybe<BrandWhereInput>,
  none?: Maybe<BrandWhereInput>,
};

export type NoticeFilter = {
  every?: Maybe<NoticeWhereInput>,
  some?: Maybe<NoticeWhereInput>,
  none?: Maybe<NoticeWhereInput>,
};

export type NoticeWhereInput = {
  id?: Maybe<StringFilter>,
  logisticId?: Maybe<StringFilter>,
  groupId?: Maybe<StringFilter>,
  expiryDate?: Maybe<NullableDateTimeFilter>,
  title?: Maybe<StringFilter>,
  content?: Maybe<StringFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  isPublic?: Maybe<BooleanFilter>,
  isImportant?: Maybe<BooleanFilter>,
  authorId?: Maybe<StringFilter>,
  sellerId?: Maybe<StringFilter>,
  AND?: Maybe<Array<NoticeWhereInput>>,
  OR?: Maybe<Array<NoticeWhereInput>>,
  NOT?: Maybe<Array<NoticeWhereInput>>,
  logistic?: Maybe<LogisticWhereInput>,
  author?: Maybe<UserWhereInput>,
  seller?: Maybe<SellerWhereInput>,
};

export type CompanyWhereInput = {
  id?: Maybe<StringFilter>,
  type?: Maybe<CompanyType>,
  name?: Maybe<NullableStringFilter>,
  ceo?: Maybe<NullableStringFilter>,
  phone?: Maybe<NullableStringFilter>,
  zipcode?: Maybe<NullableStringFilter>,
  address?: Maybe<NullableStringFilter>,
  detailedAddress?: Maybe<NullableStringFilter>,
  email?: Maybe<NullableStringFilter>,
  status?: Maybe<CompanyStatus>,
  deletedAt?: Maybe<NullableDateTimeFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  users?: Maybe<UserFilter>,
  teams?: Maybe<TeamFilter>,
  bankAccounts?: Maybe<BankAccountFilter>,
  AND?: Maybe<Array<CompanyWhereInput>>,
  OR?: Maybe<Array<CompanyWhereInput>>,
  NOT?: Maybe<Array<CompanyWhereInput>>,
  taxInfo?: Maybe<TaxInfoWhereInput>,
  superAdmin?: Maybe<SuperAdminWhereInput>,
  logistic?: Maybe<LogisticWhereInput>,
  seller?: Maybe<SellerWhereInput>,
};

export type UserFilter = {
  every?: Maybe<UserWhereInput>,
  some?: Maybe<UserWhereInput>,
  none?: Maybe<UserWhereInput>,
};

export type TeamFilter = {
  every?: Maybe<TeamWhereInput>,
  some?: Maybe<TeamWhereInput>,
  none?: Maybe<TeamWhereInput>,
};

export type TeamWhereInput = {
  id?: Maybe<StringFilter>,
  companyId?: Maybe<StringFilter>,
  name?: Maybe<StringFilter>,
  deletedAt?: Maybe<NullableDateTimeFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  users?: Maybe<UserFilter>,
  AND?: Maybe<Array<TeamWhereInput>>,
  OR?: Maybe<Array<TeamWhereInput>>,
  NOT?: Maybe<Array<TeamWhereInput>>,
  company?: Maybe<CompanyWhereInput>,
};

export type BankAccountFilter = {
  every?: Maybe<BankAccountWhereInput>,
  some?: Maybe<BankAccountWhereInput>,
  none?: Maybe<BankAccountWhereInput>,
};

export type BankAccountWhereInput = {
  id?: Maybe<StringFilter>,
  companyId?: Maybe<StringFilter>,
  bank?: Maybe<StringFilter>,
  num?: Maybe<StringFilter>,
  holder?: Maybe<StringFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  AND?: Maybe<Array<BankAccountWhereInput>>,
  OR?: Maybe<Array<BankAccountWhereInput>>,
  NOT?: Maybe<Array<BankAccountWhereInput>>,
  company?: Maybe<CompanyWhereInput>,
};

export type TaxInfoWhereInput = {
  id?: Maybe<StringFilter>,
  companyId?: Maybe<StringFilter>,
  corporationName?: Maybe<NullableStringFilter>,
  businessRegNum?: Maybe<NullableStringFilter>,
  businessType?: Maybe<NullableStringFilter>,
  businessItem?: Maybe<NullableStringFilter>,
  email?: Maybe<NullableStringFilter>,
  zipcode?: Maybe<NullableStringFilter>,
  address?: Maybe<NullableStringFilter>,
  detailedAddress?: Maybe<NullableStringFilter>,
  needTaxInfo?: Maybe<BooleanFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  AND?: Maybe<Array<TaxInfoWhereInput>>,
  OR?: Maybe<Array<TaxInfoWhereInput>>,
  NOT?: Maybe<Array<TaxInfoWhereInput>>,
  company?: Maybe<CompanyWhereInput>,
};

export type SuperAdminWhereInput = {
  id?: Maybe<StringFilter>,
  companyId?: Maybe<StringFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  AND?: Maybe<Array<SuperAdminWhereInput>>,
  OR?: Maybe<Array<SuperAdminWhereInput>>,
  NOT?: Maybe<Array<SuperAdminWhereInput>>,
  company?: Maybe<CompanyWhereInput>,
};

export type LogisticCourierWhereInput = {
  id?: Maybe<StringFilter>,
  logisticId?: Maybe<StringFilter>,
  courierId?: Maybe<StringFilter>,
  config?: Maybe<StringFilter>,
  isActive?: Maybe<BooleanFilter>,
  deletedAt?: Maybe<NullableDateTimeFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  sellerDefaultOf?: Maybe<SellerFilter>,
  courierInvoices?: Maybe<CourierInvoiceFilter>,
  courierInvoiceTrackings?: Maybe<CourierInvoiceTrackingFilter>,
  AND?: Maybe<Array<LogisticCourierWhereInput>>,
  OR?: Maybe<Array<LogisticCourierWhereInput>>,
  NOT?: Maybe<Array<LogisticCourierWhereInput>>,
  logistic?: Maybe<LogisticWhereInput>,
  courier?: Maybe<CourierWhereInput>,
  logisticDefaultOf?: Maybe<LogisticWhereInput>,
};

export type CourierInvoiceTrackingFilter = {
  every?: Maybe<CourierInvoiceTrackingWhereInput>,
  some?: Maybe<CourierInvoiceTrackingWhereInput>,
  none?: Maybe<CourierInvoiceTrackingWhereInput>,
};

export type CourierInvoiceTrackingWhereInput = {
  id?: Maybe<StringFilter>,
  logisticCourierId?: Maybe<StringFilter>,
  courierInvoiceNum?: Maybe<StringFilter>,
  status?: Maybe<StringFilter>,
  trackedDate?: Maybe<DateTimeFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  AND?: Maybe<Array<CourierInvoiceTrackingWhereInput>>,
  OR?: Maybe<Array<CourierInvoiceTrackingWhereInput>>,
  NOT?: Maybe<Array<CourierInvoiceTrackingWhereInput>>,
  logisticCourier?: Maybe<LogisticCourierWhereInput>,
};

export type CourierWhereInput = {
  id?: Maybe<StringFilter>,
  name?: Maybe<StringFilter>,
  configFields?: Maybe<StringFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  priceRanges?: Maybe<CourierPriceRangeFilter>,
  logisticCouriers?: Maybe<LogisticCourierFilter>,
  AND?: Maybe<Array<CourierWhereInput>>,
  OR?: Maybe<Array<CourierWhereInput>>,
  NOT?: Maybe<Array<CourierWhereInput>>,
};

export type CourierPriceRangeFilter = {
  every?: Maybe<CourierPriceRangeWhereInput>,
  some?: Maybe<CourierPriceRangeWhereInput>,
  none?: Maybe<CourierPriceRangeWhereInput>,
};

export type CourierPriceRangeWhereInput = {
  id?: Maybe<StringFilter>,
  courierId?: Maybe<StringFilter>,
  basic?: Maybe<BasicCourierPriceRange>,
  name?: Maybe<StringFilter>,
  value?: Maybe<NullableStringFilter>,
  price?: Maybe<IntFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  courierInvoices?: Maybe<CourierInvoiceFilter>,
  AND?: Maybe<Array<CourierPriceRangeWhereInput>>,
  OR?: Maybe<Array<CourierPriceRangeWhereInput>>,
  NOT?: Maybe<Array<CourierPriceRangeWhereInput>>,
  courier?: Maybe<CourierWhereInput>,
};

export type LogisticCourierFilter = {
  every?: Maybe<LogisticCourierWhereInput>,
  some?: Maybe<LogisticCourierWhereInput>,
  none?: Maybe<LogisticCourierWhereInput>,
};

export type PacketFilter = {
  every?: Maybe<PacketWhereInput>,
  some?: Maybe<PacketWhereInput>,
  none?: Maybe<PacketWhereInput>,
};

export type PacketWhereInput = {
  id?: Maybe<StringFilter>,
  logisticId?: Maybe<StringFilter>,
  pricingId?: Maybe<StringFilter>,
  startDate?: Maybe<DateTimeFilter>,
  endDate?: Maybe<DateTimeFilter>,
  isPaid?: Maybe<BooleanFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  AND?: Maybe<Array<PacketWhereInput>>,
  OR?: Maybe<Array<PacketWhereInput>>,
  NOT?: Maybe<Array<PacketWhereInput>>,
  logistic?: Maybe<LogisticWhereInput>,
  pricing?: Maybe<PricingWhereInput>,
};

export type PricingWhereInput = {
  id?: Maybe<StringFilter>,
  name?: Maybe<StringFilter>,
  count?: Maybe<NullableIntFilter>,
  price?: Maybe<IntFilter>,
  period?: Maybe<NullableStringFilter>,
  description?: Maybe<StringFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  packets?: Maybe<PacketFilter>,
  AND?: Maybe<Array<PricingWhereInput>>,
  OR?: Maybe<Array<PricingWhereInput>>,
  NOT?: Maybe<Array<PricingWhereInput>>,
};

export type CenterFilter = {
  every?: Maybe<CenterWhereInput>,
  some?: Maybe<CenterWhereInput>,
  none?: Maybe<CenterWhereInput>,
};

export type CenterWhereInput = {
  id?: Maybe<StringFilter>,
  logisticId?: Maybe<StringFilter>,
  name?: Maybe<StringFilter>,
  zipcode?: Maybe<StringFilter>,
  address?: Maybe<StringFilter>,
  detailedAddress?: Maybe<NullableStringFilter>,
  shippingAvailable?: Maybe<BooleanFilter>,
  isActive?: Maybe<BooleanFilter>,
  useLocation?: Maybe<BooleanFilter>,
  totalQuantity?: Maybe<NullableIntFilter>,
  deletedAt?: Maybe<NullableDateTimeFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  settings?: Maybe<LocationSettingFilter>,
  locations?: Maybe<LocationFilter>,
  AND?: Maybe<Array<CenterWhereInput>>,
  OR?: Maybe<Array<CenterWhereInput>>,
  NOT?: Maybe<Array<CenterWhereInput>>,
  logistic?: Maybe<LogisticWhereInput>,
};

export type LocationSettingFilter = {
  every?: Maybe<LocationSettingWhereInput>,
  some?: Maybe<LocationSettingWhereInput>,
  none?: Maybe<LocationSettingWhereInput>,
};

export type LocationSettingWhereInput = {
  id?: Maybe<StringFilter>,
  centerId?: Maybe<StringFilter>,
  zone?: Maybe<StringFilter>,
  rack?: Maybe<StringFilter>,
  level?: Maybe<StringFilter>,
  deletedAt?: Maybe<NullableDateTimeFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  locations?: Maybe<LocationFilter>,
  AND?: Maybe<Array<LocationSettingWhereInput>>,
  OR?: Maybe<Array<LocationSettingWhereInput>>,
  NOT?: Maybe<Array<LocationSettingWhereInput>>,
  center?: Maybe<CenterWhereInput>,
};

export type LocationFilter = {
  every?: Maybe<LocationWhereInput>,
  some?: Maybe<LocationWhereInput>,
  none?: Maybe<LocationWhereInput>,
};

export type LocationWhereInput = {
  id?: Maybe<StringFilter>,
  centerId?: Maybe<StringFilter>,
  settingId?: Maybe<StringFilter>,
  zone?: Maybe<StringFilter>,
  rack?: Maybe<StringFilter>,
  level?: Maybe<StringFilter>,
  isActive?: Maybe<BooleanFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  incomingRecords?: Maybe<TransactionFilter>,
  AND?: Maybe<Array<LocationWhereInput>>,
  OR?: Maybe<Array<LocationWhereInput>>,
  NOT?: Maybe<Array<LocationWhereInput>>,
  center?: Maybe<CenterWhereInput>,
  setting?: Maybe<LocationSettingWhereInput>,
};

export type PreStockFilter = {
  every?: Maybe<PreStockWhereInput>,
  some?: Maybe<PreStockWhereInput>,
  none?: Maybe<PreStockWhereInput>,
};

export type PreStockWhereInput = {
  id?: Maybe<StringFilter>,
  logisticId?: Maybe<StringFilter>,
  productOptionId?: Maybe<StringFilter>,
  amount?: Maybe<IntFilter>,
  loadUnit?: Maybe<LoadUnit>,
  loadUnitAmount?: Maybe<NullableIntFilter>,
  scheduledDate?: Maybe<DateTimeFilter>,
  registeredUserId?: Maybe<StringFilter>,
  confirmedUserId?: Maybe<NullableStringFilter>,
  confirmedDate?: Maybe<NullableDateTimeFilter>,
  note?: Maybe<NullableStringFilter>,
  manufacturedDate?: Maybe<NullableDateTimeFilter>,
  expiryDate?: Maybe<NullableDateTimeFilter>,
  canceledAt?: Maybe<NullableDateTimeFilter>,
  deletedAt?: Maybe<NullableDateTimeFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  transactions?: Maybe<TransactionFilter>,
  AND?: Maybe<Array<PreStockWhereInput>>,
  OR?: Maybe<Array<PreStockWhereInput>>,
  NOT?: Maybe<Array<PreStockWhereInput>>,
  logistic?: Maybe<LogisticWhereInput>,
  productOption?: Maybe<ProductOptionWhereInput>,
  registeredUser?: Maybe<UserWhereInput>,
  confirmedUser?: Maybe<UserWhereInput>,
};

export type ProductOptionWhereInput = {
  id?: Maybe<StringFilter>,
  productId?: Maybe<StringFilter>,
  levelOne?: Maybe<NullableStringFilter>,
  levelTwo?: Maybe<NullableStringFilter>,
  originNum?: Maybe<NullableStringFilter>,
  categoryId?: Maybe<NullableStringFilter>,
  originBarcode?: Maybe<NullableStringFilter>,
  newBarcode?: Maybe<NullableStringFilter>,
  needInspection?: Maybe<BooleanFilter>,
  temperature?: Maybe<TemperatureType>,
  bestPeriodDay?: Maybe<NullableIntFilter>,
  basicCourierPriceRange?: Maybe<BasicCourierPriceRange>,
  isActive?: Maybe<BooleanFilter>,
  canBeMixed?: Maybe<BooleanFilter>,
  maxAmount?: Maybe<NullableIntFilter>,
  note?: Maybe<NullableStringFilter>,
  deletedAt?: Maybe<NullableDateTimeFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  preStocks?: Maybe<PreStockFilter>,
  transactions?: Maybe<TransactionFilter>,
  autoMatches?: Maybe<AutoMatchFilter>,
  productMatches?: Maybe<ProductMatchFilter>,
  AND?: Maybe<Array<ProductOptionWhereInput>>,
  OR?: Maybe<Array<ProductOptionWhereInput>>,
  NOT?: Maybe<Array<ProductOptionWhereInput>>,
  product?: Maybe<ProductWhereInput>,
  category?: Maybe<ProductCategoryWhereInput>,
};

export type AutoMatchFilter = {
  every?: Maybe<AutoMatchWhereInput>,
  some?: Maybe<AutoMatchWhereInput>,
  none?: Maybe<AutoMatchWhereInput>,
};

export type ProductWhereInput = {
  id?: Maybe<StringFilter>,
  brandId?: Maybe<StringFilter>,
  name?: Maybe<StringFilter>,
  note?: Maybe<NullableStringFilter>,
  isActive?: Maybe<BooleanFilter>,
  hasLevel?: Maybe<BooleanFilter>,
  levelOneName?: Maybe<NullableStringFilter>,
  levelTwoName?: Maybe<NullableStringFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  productOptions?: Maybe<ProductOptionFilter>,
  AND?: Maybe<Array<ProductWhereInput>>,
  OR?: Maybe<Array<ProductWhereInput>>,
  NOT?: Maybe<Array<ProductWhereInput>>,
  brand?: Maybe<BrandWhereInput>,
};

export type ProductOptionFilter = {
  every?: Maybe<ProductOptionWhereInput>,
  some?: Maybe<ProductOptionWhereInput>,
  none?: Maybe<ProductOptionWhereInput>,
};

export type ProductCategoryWhereInput = {
  id?: Maybe<StringFilter>,
  name?: Maybe<StringFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  productOptions?: Maybe<ProductOptionFilter>,
  AND?: Maybe<Array<ProductCategoryWhereInput>>,
  OR?: Maybe<Array<ProductCategoryWhereInput>>,
  NOT?: Maybe<Array<ProductCategoryWhereInput>>,
};

export type LogisticFilter = {
  every?: Maybe<LogisticWhereInput>,
  some?: Maybe<LogisticWhereInput>,
  none?: Maybe<LogisticWhereInput>,
};

export type LogFilter = {
  every?: Maybe<LogWhereInput>,
  some?: Maybe<LogWhereInput>,
  none?: Maybe<LogWhereInput>,
};

export type LogWhereInput = {
  id?: Maybe<StringFilter>,
  content?: Maybe<StringFilter>,
  userId?: Maybe<NullableStringFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  AND?: Maybe<Array<LogWhereInput>>,
  OR?: Maybe<Array<LogWhereInput>>,
  NOT?: Maybe<Array<LogWhereInput>>,
  user?: Maybe<UserWhereInput>,
};

export type MarketWhereInput = {
  id?: Maybe<StringFilter>,
  name?: Maybe<StringFilter>,
  type?: Maybe<CommunicationType>,
  credentialFields?: Maybe<NullableStringFilter>,
  dashboardUri?: Maybe<NullableStringFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  brandMarkets?: Maybe<BrandMarketFilter>,
  AND?: Maybe<Array<MarketWhereInput>>,
  OR?: Maybe<Array<MarketWhereInput>>,
  NOT?: Maybe<Array<MarketWhereInput>>,
};

export type ProductFilter = {
  every?: Maybe<ProductWhereInput>,
  some?: Maybe<ProductWhereInput>,
  none?: Maybe<ProductWhereInput>,
};

export type AutoMatchOrderByInput = {
  id?: Maybe<SortOrder>,
  brandMarketId?: Maybe<SortOrder>,
  marketProductNum?: Maybe<SortOrder>,
  marketProductName?: Maybe<SortOrder>,
  marketProductOptionNum?: Maybe<SortOrder>,
  marketProductOptionName?: Maybe<SortOrder>,
  marketProductOptionAmount?: Maybe<SortOrder>,
  productOptionId?: Maybe<SortOrder>,
  productAmount?: Maybe<SortOrder>,
  createdAt?: Maybe<SortOrder>,
  updatedAt?: Maybe<SortOrder>,
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type BrandOrderByInput = {
  id?: Maybe<SortOrder>,
  sellerId?: Maybe<SortOrder>,
  name?: Maybe<SortOrder>,
  phone?: Maybe<SortOrder>,
  zipcode?: Maybe<SortOrder>,
  address?: Maybe<SortOrder>,
  detailedAddress?: Maybe<SortOrder>,
  isActive?: Maybe<SortOrder>,
  deletedAt?: Maybe<SortOrder>,
  createdAt?: Maybe<SortOrder>,
  updatedAt?: Maybe<SortOrder>,
};

export type BrandMarketOrderByInput = {
  id?: Maybe<SortOrder>,
  brandId?: Maybe<SortOrder>,
  marketId?: Maybe<SortOrder>,
  name?: Maybe<SortOrder>,
  sender?: Maybe<SortOrder>,
  phone?: Maybe<SortOrder>,
  zipcode?: Maybe<SortOrder>,
  address?: Maybe<SortOrder>,
  detailedAddress?: Maybe<SortOrder>,
  credential?: Maybe<SortOrder>,
  isActive?: Maybe<SortOrder>,
  deletedAt?: Maybe<SortOrder>,
  createdAt?: Maybe<SortOrder>,
  updatedAt?: Maybe<SortOrder>,
};

export type BrandMarketSummary = {
   __typename?: 'BrandMarketSummary',
  marketCount: Scalars['Int'],
  excelCount: Scalars['Int'],
  todayOrdersTotalCount: Scalars['Int'],
};

export type BrandMarketResultType = {
   __typename?: 'BrandMarketResultType',
  brandMarket: BrandMarket,
  todayOrderCount: Scalars['Int'],
  lastOrderDate?: Maybe<Scalars['DateTime']>,
};

export type CenterOrderByInput = {
  id?: Maybe<SortOrder>,
  logisticId?: Maybe<SortOrder>,
  name?: Maybe<SortOrder>,
  zipcode?: Maybe<SortOrder>,
  address?: Maybe<SortOrder>,
  detailedAddress?: Maybe<SortOrder>,
  shippingAvailable?: Maybe<SortOrder>,
  isActive?: Maybe<SortOrder>,
  useLocation?: Maybe<SortOrder>,
  totalQuantity?: Maybe<SortOrder>,
  deletedAt?: Maybe<SortOrder>,
  createdAt?: Maybe<SortOrder>,
  updatedAt?: Maybe<SortOrder>,
};

export type CompanyWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
};

export type CompanyOrderByInput = {
  id?: Maybe<SortOrder>,
  type?: Maybe<SortOrder>,
  name?: Maybe<SortOrder>,
  ceo?: Maybe<SortOrder>,
  phone?: Maybe<SortOrder>,
  zipcode?: Maybe<SortOrder>,
  address?: Maybe<SortOrder>,
  detailedAddress?: Maybe<SortOrder>,
  email?: Maybe<SortOrder>,
  status?: Maybe<SortOrder>,
  deletedAt?: Maybe<SortOrder>,
  createdAt?: Maybe<SortOrder>,
  updatedAt?: Maybe<SortOrder>,
};

export type CourierWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
};

export type CourierOrderByInput = {
  id?: Maybe<SortOrder>,
  name?: Maybe<SortOrder>,
  configFields?: Maybe<SortOrder>,
  createdAt?: Maybe<SortOrder>,
  updatedAt?: Maybe<SortOrder>,
};

export type CourierInvoiceOrderByInput = {
  id?: Maybe<SortOrder>,
  type?: Maybe<SortOrder>,
  brandId?: Maybe<SortOrder>,
  logisticCourierId?: Maybe<SortOrder>,
  courierName?: Maybe<SortOrder>,
  num?: Maybe<SortOrder>,
  groupNum?: Maybe<SortOrder>,
  printedNum?: Maybe<SortOrder>,
  invoiceStatus?: Maybe<SortOrder>,
  status?: Maybe<SortOrder>,
  parentId?: Maybe<SortOrder>,
  sender?: Maybe<SortOrder>,
  senderTel?: Maybe<SortOrder>,
  senderZipcode?: Maybe<SortOrder>,
  senderAddress?: Maybe<SortOrder>,
  senderDetailedAddress?: Maybe<SortOrder>,
  receiver?: Maybe<SortOrder>,
  receiverTel?: Maybe<SortOrder>,
  receiverZipcode?: Maybe<SortOrder>,
  receiverAddress?: Maybe<SortOrder>,
  receiverDetailedAddress?: Maybe<SortOrder>,
  content?: Maybe<SortOrder>,
  message?: Maybe<SortOrder>,
  note?: Maybe<SortOrder>,
  addressRefinedDate?: Maybe<SortOrder>,
  addressRefinedValues?: Maybe<SortOrder>,
  courierPriceRangeId?: Maybe<SortOrder>,
  printedAt?: Maybe<SortOrder>,
  createdAt?: Maybe<SortOrder>,
  updatedAt?: Maybe<SortOrder>,
};

export type InvoiceSummaryResultType = {
   __typename?: 'InvoiceSummaryResultType',
  unclassifiedCnt: Scalars['Int'],
  classifiedOrderCnt: Scalars['Int'],
  classifiedCnt: Scalars['Int'],
  standard: Array<Scalars['String']>,
};

export type ClassifyInvoicesResultType = {
   __typename?: 'ClassifyInvoicesResultType',
  orderCnt: Scalars['Int'],
  invoiceCnt: Scalars['Int'],
  brand: Brand,
  courier: Courier,
  standard: Array<Scalars['String']>,
};

export type ClassifyInvoicesInputType = {
  brandId: Scalars['ID'],
  courierId?: Maybe<Scalars['ID']>,
  standard?: Maybe<Array<Scalars['String']>>,
};

export type ClassifiedInvoiceResultType = {
   __typename?: 'ClassifiedInvoiceResultType',
  groupNum: Scalars['Int'],
  type: Scalars['String'],
  orderCnt: Scalars['Int'],
  invoiceCnt: Scalars['Int'],
  brand: Brand,
  courier: Courier,
  createdAt: Scalars['String'],
  printedAt?: Maybe<Scalars['String']>,
};

export type DateAndGroupNumsInputType = {
  groupNums: Array<Scalars['Int']>,
  startDate: Scalars['DateTime'],
  endDate: Scalars['DateTime'],
};

export type PickingInvoiceResultType = {
   __typename?: 'PickingInvoiceResultType',
  groupNum: Scalars['Int'],
  locations?: Maybe<Array<StockByLoaction>>,
  brand?: Maybe<Brand>,
  productOption?: Maybe<ProductOption>,
  printedAt?: Maybe<Scalars['String']>,
  pickingNum: Scalars['Int'],
  stock: Scalars['Int'],
};

export type StockByLoaction = {
   __typename?: 'StockByLoaction',
  location: Location,
  usableAmount: Scalars['Int'],
  expiryDate?: Maybe<Scalars['String']>,
};

export type AdditionalInvoiceResultType = {
   __typename?: 'AdditionalInvoiceResultType',
  addressRefinedValues: Scalars['String'],
};

export type InvoiceOrderCountResultType = {
   __typename?: 'InvoiceOrderCountResultType',
  printedDate: Scalars['String'],
  invoiceCnt: Scalars['Int'],
  orderCnt: Scalars['Int'],
};

export type FinalPackageOrderByInput = {
  id?: Maybe<SortOrder>,
  courierInvoiceId?: Maybe<SortOrder>,
  productMatchId?: Maybe<SortOrder>,
  amount?: Maybe<SortOrder>,
  confirmedUserId?: Maybe<SortOrder>,
  status?: Maybe<SortOrder>,
  createdAt?: Maybe<SortOrder>,
  updatedAt?: Maybe<SortOrder>,
};

export type LocationOrderByInput = {
  id?: Maybe<SortOrder>,
  centerId?: Maybe<SortOrder>,
  settingId?: Maybe<SortOrder>,
  zone?: Maybe<SortOrder>,
  rack?: Maybe<SortOrder>,
  level?: Maybe<SortOrder>,
  isActive?: Maybe<SortOrder>,
  createdAt?: Maybe<SortOrder>,
  updatedAt?: Maybe<SortOrder>,
};

export type LocationSettingOrderByInput = {
  id?: Maybe<SortOrder>,
  centerId?: Maybe<SortOrder>,
  zone?: Maybe<SortOrder>,
  rack?: Maybe<SortOrder>,
  level?: Maybe<SortOrder>,
  deletedAt?: Maybe<SortOrder>,
  createdAt?: Maybe<SortOrder>,
  updatedAt?: Maybe<SortOrder>,
};

export type LogisticOrderByInput = {
  id?: Maybe<SortOrder>,
  companyId?: Maybe<SortOrder>,
  defaultUserId?: Maybe<SortOrder>,
  defaultCourierId?: Maybe<SortOrder>,
  marketing?: Maybe<SortOrder>,
  createdAt?: Maybe<SortOrder>,
  updatedAt?: Maybe<SortOrder>,
};

export type MarketWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
  name_type?: Maybe<NameTypeCompoundUniqueInput>,
};

export type NameTypeCompoundUniqueInput = {
  name: Scalars['String'],
  type: CommunicationType,
};

export type MarketOrderByInput = {
  id?: Maybe<SortOrder>,
  name?: Maybe<SortOrder>,
  type?: Maybe<SortOrder>,
  credentialFields?: Maybe<SortOrder>,
  dashboardUri?: Maybe<SortOrder>,
  createdAt?: Maybe<SortOrder>,
  updatedAt?: Maybe<SortOrder>,
};

export type MarketProductOrderByInput = {
  id?: Maybe<SortOrder>,
  orderId?: Maybe<SortOrder>,
  num?: Maybe<SortOrder>,
  name?: Maybe<SortOrder>,
  optionNum?: Maybe<SortOrder>,
  optionName?: Maybe<SortOrder>,
  amount?: Maybe<SortOrder>,
  totalPrice?: Maybe<SortOrder>,
  status?: Maybe<SortOrder>,
  createdAt?: Maybe<SortOrder>,
  updatedAt?: Maybe<SortOrder>,
};

export enum MatchingMarketProductStatus {
  Waiting = 'WAITING',
  Done = 'DONE',
  SellerCanceled = 'SELLER_CANCELED',
  BuyerCanceled = 'BUYER_CANCELED',
  Suspended = 'SUSPENDED'
}

export type NoticeListResultType = {
   __typename?: 'NoticeListResultType',
  notice: Notice,
  sellers?: Maybe<Array<Seller>>,
};

export type OrderOrderByInput = {
  id?: Maybe<SortOrder>,
  num?: Maybe<SortOrder>,
  brandMarketId?: Maybe<SortOrder>,
  marketOrderNum?: Maybe<SortOrder>,
  orderedDate?: Maybe<SortOrder>,
  paidDate?: Maybe<SortOrder>,
  sender?: Maybe<SortOrder>,
  senderTel?: Maybe<SortOrder>,
  senderTel2?: Maybe<SortOrder>,
  receiver?: Maybe<SortOrder>,
  receiverTel?: Maybe<SortOrder>,
  receiverTel2?: Maybe<SortOrder>,
  receiverZipcode?: Maybe<SortOrder>,
  receiverAddress?: Maybe<SortOrder>,
  receiverDetailedAddress?: Maybe<SortOrder>,
  message?: Maybe<SortOrder>,
  deliveryPaid?: Maybe<SortOrder>,
  deliveryPrice?: Maybe<SortOrder>,
  isOverseas?: Maybe<SortOrder>,
  remoteAreaPrice?: Maybe<SortOrder>,
  detailedOrder?: Maybe<SortOrder>,
  createdAt?: Maybe<SortOrder>,
  updatedAt?: Maybe<SortOrder>,
};

export type MarketNewOrderCntResultType = {
   __typename?: 'MarketNewOrderCntResultType',
  brandMarket?: Maybe<BrandMarket>,
  count: Scalars['Int'],
  error?: Maybe<Scalars['String']>,
};

export type MarketOrdersResultType = {
   __typename?: 'MarketOrdersResultType',
  id?: Maybe<Scalars['ID']>,
  brandMarket?: Maybe<BrandMarket>,
  num?: Maybe<Scalars['String']>,
  marketProducts?: Maybe<Array<MarketProduct>>,
  sender?: Maybe<Scalars['String']>,
  receiver?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  courierInvoices?: Maybe<Array<CourierInvoice>>,
  createdAt?: Maybe<Scalars['DateTime']>,
  orderNotes?: Maybe<Array<OrderNote>>,
};

export type DetailedOrderInfoResultType = {
   __typename?: 'DetailedOrderInfoResultType',
  order: Order,
  combines: Array<DatailedOrderInfoCombineType>,
};

export type DatailedOrderInfoCombineType = {
   __typename?: 'DatailedOrderInfoCombineType',
  courierInvoice: CourierInvoice,
  type: CourierInvoiceType,
  invoiceNum?: Maybe<Scalars['String']>,
  invoiceGroupNum?: Maybe<Scalars['Int']>,
  additionalCnt: Scalars['Int'],
  courierName: Scalars['String'],
  isCombine: Scalars['Boolean'],
  returnOrderCnt: Scalars['Int'],
  tracking: DatailedOrderInfoCombineTrackingType,
  children: Array<DatailedOrderInfoCombineChildType>,
};

export type DatailedOrderInfoCombineTrackingType = {
   __typename?: 'DatailedOrderInfoCombineTrackingType',
  status: PackageStatus,
  date?: Maybe<Scalars['DateTime']>,
};

export type DatailedOrderInfoCombineChildType = {
   __typename?: 'DatailedOrderInfoCombineChildType',
  id: Scalars['String'],
  productOption: ProductOption,
  amount: Scalars['Int'],
  stock: Scalars['Int'],
  status: FinalPackageStatus,
};

export type OrderReturnOrderResultType = {
   __typename?: 'OrderReturnOrderResultType',
  returnOrder: ReturnOrder,
  isCombine: Scalars['Boolean'],
  tracking: OrderReturnOrderTrackingType,
  children: Array<ReturnOrderProduct>,
};

export type OrderReturnOrderTrackingType = {
   __typename?: 'OrderReturnOrderTrackingType',
  status: ReturnStatus,
  date?: Maybe<Scalars['DateTime']>,
};

export type OrderNoteOrderByInput = {
  id?: Maybe<SortOrder>,
  orderId?: Maybe<SortOrder>,
  type?: Maybe<SortOrder>,
  note?: Maybe<SortOrder>,
  status?: Maybe<SortOrder>,
  writerId?: Maybe<SortOrder>,
  confirmedUserId?: Maybe<SortOrder>,
  confirmedAt?: Maybe<SortOrder>,
  createdAt?: Maybe<SortOrder>,
  updatedAt?: Maybe<SortOrder>,
};

export type ReturnCheckPacket = {
   __typename?: 'ReturnCheckPacket',
  code: Scalars['Int'],
  active?: Maybe<Packet>,
  nextPacket?: Maybe<Packet>,
};

export type Invoice = {
   __typename?: 'Invoice',
  brandMarket: BrandMarket,
  receiver: PreCombineReceiver,
  list: Array<PreCombineProductMatche>,
};

export type PreCombineReceiver = {
   __typename?: 'PreCombineReceiver',
  name: Scalars['String'],
  tel: Scalars['String'],
  zipcode: Scalars['String'],
  address: Scalars['String'],
  detailedAddress?: Maybe<Scalars['String']>,
};

export type PreCombineProductMatche = {
   __typename?: 'PreCombineProductMatche',
  id: Scalars['ID'],
  product: Product,
  productOption: ProductOption,
  originAmount: Scalars['Int'],
  amount: Scalars['Int'],
  stock: Scalars['Int'],
};

export type PreStockOrderByInput = {
  id?: Maybe<SortOrder>,
  logisticId?: Maybe<SortOrder>,
  productOptionId?: Maybe<SortOrder>,
  amount?: Maybe<SortOrder>,
  loadUnit?: Maybe<SortOrder>,
  loadUnitAmount?: Maybe<SortOrder>,
  scheduledDate?: Maybe<SortOrder>,
  registeredUserId?: Maybe<SortOrder>,
  confirmedUserId?: Maybe<SortOrder>,
  confirmedDate?: Maybe<SortOrder>,
  note?: Maybe<SortOrder>,
  manufacturedDate?: Maybe<SortOrder>,
  expiryDate?: Maybe<SortOrder>,
  canceledAt?: Maybe<SortOrder>,
  deletedAt?: Maybe<SortOrder>,
  createdAt?: Maybe<SortOrder>,
  updatedAt?: Maybe<SortOrder>,
};

export type PricingOrderByInput = {
  id?: Maybe<SortOrder>,
  name?: Maybe<SortOrder>,
  count?: Maybe<SortOrder>,
  price?: Maybe<SortOrder>,
  period?: Maybe<SortOrder>,
  description?: Maybe<SortOrder>,
  createdAt?: Maybe<SortOrder>,
  updatedAt?: Maybe<SortOrder>,
};

export type PricingWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
};

export type ProductOrderByInput = {
  id?: Maybe<SortOrder>,
  brandId?: Maybe<SortOrder>,
  name?: Maybe<SortOrder>,
  note?: Maybe<SortOrder>,
  isActive?: Maybe<SortOrder>,
  hasLevel?: Maybe<SortOrder>,
  levelOneName?: Maybe<SortOrder>,
  levelTwoName?: Maybe<SortOrder>,
  createdAt?: Maybe<SortOrder>,
  updatedAt?: Maybe<SortOrder>,
};

export type ProductMatchOrderByInput = {
  id?: Maybe<SortOrder>,
  marketProductId?: Maybe<SortOrder>,
  autoMatchId?: Maybe<SortOrder>,
  productOptionId?: Maybe<SortOrder>,
  amount?: Maybe<SortOrder>,
  createdAt?: Maybe<SortOrder>,
  updatedAt?: Maybe<SortOrder>,
};

export type ProductOptionOrderByInput = {
  id?: Maybe<SortOrder>,
  productId?: Maybe<SortOrder>,
  levelOne?: Maybe<SortOrder>,
  levelTwo?: Maybe<SortOrder>,
  originNum?: Maybe<SortOrder>,
  categoryId?: Maybe<SortOrder>,
  originBarcode?: Maybe<SortOrder>,
  newBarcode?: Maybe<SortOrder>,
  needInspection?: Maybe<SortOrder>,
  temperature?: Maybe<SortOrder>,
  bestPeriodDay?: Maybe<SortOrder>,
  basicCourierPriceRange?: Maybe<SortOrder>,
  isActive?: Maybe<SortOrder>,
  canBeMixed?: Maybe<SortOrder>,
  maxAmount?: Maybe<SortOrder>,
  note?: Maybe<SortOrder>,
  deletedAt?: Maybe<SortOrder>,
  createdAt?: Maybe<SortOrder>,
  updatedAt?: Maybe<SortOrder>,
};

export type SellerOrderByInput = {
  id?: Maybe<SortOrder>,
  companyId?: Maybe<SortOrder>,
  logisticId?: Maybe<SortOrder>,
  defaultCourierId?: Maybe<SortOrder>,
  createdAt?: Maybe<SortOrder>,
  updatedAt?: Maybe<SortOrder>,
};

export type SuperAdminOrderByInput = {
  id?: Maybe<SortOrder>,
  companyId?: Maybe<SortOrder>,
  createdAt?: Maybe<SortOrder>,
  updatedAt?: Maybe<SortOrder>,
};

export type SuperAdminWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
  companyId?: Maybe<Scalars['String']>,
};

export type TeamOrderByInput = {
  id?: Maybe<SortOrder>,
  companyId?: Maybe<SortOrder>,
  name?: Maybe<SortOrder>,
  deletedAt?: Maybe<SortOrder>,
  createdAt?: Maybe<SortOrder>,
  updatedAt?: Maybe<SortOrder>,
};

export type TransactionOrderByInput = {
  id?: Maybe<SortOrder>,
  logisticId?: Maybe<SortOrder>,
  preStockId?: Maybe<SortOrder>,
  parentId?: Maybe<SortOrder>,
  locationId?: Maybe<SortOrder>,
  productOptionId?: Maybe<SortOrder>,
  manufacturedDate?: Maybe<SortOrder>,
  expiryDate?: Maybe<SortOrder>,
  eventType?: Maybe<SortOrder>,
  transportType?: Maybe<SortOrder>,
  confirmedUserId?: Maybe<SortOrder>,
  finalPackageId?: Maybe<SortOrder>,
  stocktakingId?: Maybe<SortOrder>,
  usableAmount?: Maybe<SortOrder>,
  brokenAmount?: Maybe<SortOrder>,
  note?: Maybe<SortOrder>,
  createdAt?: Maybe<SortOrder>,
  updatedAt?: Maybe<SortOrder>,
};

export type StockWithProductResultType = {
   __typename?: 'StockWithProductResultType',
  productOption?: Maybe<ProductOption>,
  locations?: Maybe<Array<StockWithProductLocationsType>>,
  totalUsable?: Maybe<Scalars['Int']>,
  totalBroken?: Maybe<Scalars['Int']>,
  totalRecent?: Maybe<Scalars['Int']>,
};

export type StockWithProductLocationsType = {
   __typename?: 'StockWithProductLocationsType',
  location?: Maybe<Location>,
  usableAmount: Scalars['Int'],
  brokenAmount: Scalars['Int'],
  recent: Scalars['Int'],
  expiryDate?: Maybe<Scalars['String']>,
};

export type SelectedStocksInputTypeForProduct = {
  productOptionId: Scalars['ID'],
  locationId?: Maybe<Scalars['ID']>,
};

export type StockWithLocationResultType = {
   __typename?: 'StockWithLocationResultType',
  location?: Maybe<Location>,
  productOptions?: Maybe<Array<StockWithLocationproductsType>>,
};

export type StockWithLocationproductsType = {
   __typename?: 'StockWithLocationproductsType',
  productOption: ProductOption,
  usableAmount: Scalars['Int'],
  brokenAmount: Scalars['Int'],
  recent: Scalars['Int'],
  expiryDate?: Maybe<Scalars['String']>,
};

export type SelectedStocksInputTypeForLocation = {
  productOptionId: Scalars['ID'],
  locationId: Scalars['ID'],
};

export type IngoingOutgoingResultType = {
   __typename?: 'IngoingOutgoingResultType',
  type?: Maybe<Scalars['Int']>,
  transaction?: Maybe<Transaction>,
};

export type DetailedStockResultType = {
   __typename?: 'DetailedStockResultType',
  transaction?: Maybe<Transaction>,
  calculateUsable?: Maybe<Scalars['Int']>,
  calculateBroken?: Maybe<Scalars['Int']>,
};

export type MoveStockResultType = {
   __typename?: 'MoveStockResultType',
  type?: Maybe<Scalars['Int']>,
  transaction?: Maybe<Transaction>,
};

export type StockHistoryResultType = {
   __typename?: 'StockHistoryResultType',
  type: Scalars['Int'],
  transaction: Transaction,
  calculateUsable?: Maybe<Scalars['Int']>,
  calculateBroken?: Maybe<Scalars['Int']>,
};

export type StockDetailedHistoryResultType = {
   __typename?: 'StockDetailedHistoryResultType',
  type: Scalars['Int'],
  transaction: Transaction,
};

export type ForcedOutboundTransactionsResultType = {
   __typename?: 'ForcedOutboundTransactionsResultType',
  totalForcedOutbound?: Maybe<Scalars['Int']>,
  transactions?: Maybe<Array<Transaction>>,
};

export type UserOrderByInput = {
  id?: Maybe<SortOrder>,
  email?: Maybe<SortOrder>,
  emailVerified?: Maybe<SortOrder>,
  type?: Maybe<SortOrder>,
  password?: Maybe<SortOrder>,
  name?: Maybe<SortOrder>,
  phone?: Maybe<SortOrder>,
  companyId?: Maybe<SortOrder>,
  teamId?: Maybe<SortOrder>,
  role?: Maybe<SortOrder>,
  marketing?: Maybe<SortOrder>,
  settings?: Maybe<SortOrder>,
  deletedAt?: Maybe<SortOrder>,
  createdAt?: Maybe<SortOrder>,
  updatedAt?: Maybe<SortOrder>,
};

export type Version = {
   __typename?: 'Version',
  num: Scalars['String'],
  date: Scalars['String'],
  description: Scalars['String'],
};

export type WidgetWhereInput = {
  id?: Maybe<StringFilter>,
  widgetId?: Maybe<WidgetId>,
  name?: Maybe<StringFilter>,
  companyType?: Maybe<CompanyType>,
  isActive?: Maybe<BooleanFilter>,
  AND?: Maybe<Array<WidgetWhereInput>>,
  OR?: Maybe<Array<WidgetWhereInput>>,
  NOT?: Maybe<Array<WidgetWhereInput>>,
};

export enum WidgetId {
  Notice = 'NOTICE',
  Recentinvoice = 'RECENTINVOICE',
  Companyinfo = 'COMPANYINFO',
  Packetinfo = 'PACKETINFO',
  Shippingstatus = 'SHIPPINGSTATUS',
  Marketorderstatus = 'MARKETORDERSTATUS'
}

export type WidgetOrderByInput = {
  id?: Maybe<SortOrder>,
  widgetId?: Maybe<SortOrder>,
  name?: Maybe<SortOrder>,
  companyType?: Maybe<SortOrder>,
  props?: Maybe<SortOrder>,
  isActive?: Maybe<SortOrder>,
};

export type WidgetWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
};

export type Widget = {
   __typename?: 'Widget',
  id: Scalars['String'],
  widgetId: WidgetId,
  name: Scalars['String'],
  companyType?: Maybe<CompanyType>,
  props?: Maybe<Scalars['Json']>,
  isActive: Scalars['Boolean'],
};

export type Mutation = {
   __typename?: 'Mutation',
  deleteManyAutoMatch: BatchPayload,
  upsertOneBrand: Brand,
  updateOneBrand?: Maybe<Brand>,
  upsertOneBrandMarket: BrandMarket,
  updateOneBrandMarket?: Maybe<BrandMarket>,
  createBrandMarket: BrandMarket,
  updateBrandMarket: BrandMarket,
  deleteBrandMarket: BrandMarket,
  createOneCenter: Center,
  updateOneCenter: Center,
  updateOneCompany?: Maybe<Company>,
  printInvoices: Array<CourierInvoice>,
  printInvoices2: Array<CourierInvoice>,
  printSelectedInvoices: Array<CourierInvoice>,
  printAdditionalOrNewInvoice: Array<CourierInvoice>,
  printAdditionalInvoice: Array<CourierInvoice>,
  updatedInvoiceAddress: CourierInvoice,
  updateInvoiceAddress: CourierInvoice,
  updateInvoiceStatus: Array<CourierInvoice>,
  updateFinalPackageStatus: Array<FinalPackage>,
  updateCourierTracking: Scalars['Int'],
  combine: CombineResult,
  disconnectCombine: Array<FinalPackage>,
  createOneLocation: Location,
  updateLocationStatus?: Maybe<Location>,
  createOneLocationSetting: LocationSetting,
  updateOneLocationSetting?: Maybe<LocationSetting>,
  updateOneLogistic?: Maybe<Logistic>,
  updateLogisticInfo: Company,
  updateLogisticCompanyInfo: Logistic,
  createOneLogisticCourier: LogisticCourier,
  updateOneLogisticCourier?: Maybe<LogisticCourier>,
  updateOneMarketProduct?: Maybe<MarketProduct>,
  matching: MarketProduct,
  updateMarketProductStatus: BatchPayload,
  updateMatching: MarketProduct,
  createOneNotice: Notice,
  updateOneNotice?: Maybe<Notice>,
  createNotice: Array<Notice>,
  updateNotice: BatchPayload,
  getMarketOrders: Array<GetMarketOrdersResultType>,
  uploadExcelOrders: Array<Order>,
  updateOrdererInfo: Order,
  additionalShipping: Order,
  upsertOneOrderNote: OrderNote,
  choosePacket: Packet,
  upsertOnePreStock: PreStock,
  updateOnePreStock?: Maybe<PreStock>,
  deleteOnePreStock?: Maybe<PreStock>,
  createManyPreStocks: Array<PreStock>,
  uploadExcelPreStocks: Array<PreStock>,
  confirmPreStock: PreStock,
  createOneProduct: Product,
  updateOneProduct: Product,
  createManyProducts: Array<Product>,
  updateOneProductMatch?: Maybe<ProductMatch>,
  disconnectMatching: ProductMatch,
  createOneProductOption: ProductOption,
  updateOneProductOption: ProductOption,
  updateManyProductOption: BatchPayload,
  requestReturnOrder: ReturnOrder,
  confirmReturnOrder: ReturnOrder,
  cancelReturnOrder: ReturnOrder,
  selectCombine: SelectCombineResult,
  createOneSeller: Seller,
  updateOneSeller?: Maybe<Seller>,
  upsertOneSeller: Seller,
  createSeller: Seller,
  updateSeller: Seller,
  deleteSeller: Seller,
  registerStocktaking: Stocktaking,
  confirmStocktakings: Array<Stocktaking>,
  cancelStocktakings: Array<Stocktaking>,
  createOneTeam: Team,
  updateOneTeam: Team,
  deleteOneTeam: Team,
  selectTransactionLocation: Transaction,
  moveStock: Transaction,
  registerOutgoing: Transaction,
  uploadExcelStock: Array<Transaction>,
  createOneUser: User,
  updateOneUser: User,
  login: AuthPayload,
  signup: AuthPayload,
  changePassword: Scalars['Boolean'],
  updateUserSettings: UserSettings,
  updateUserDashboardSettings: UpdateUserDashboardSettingsResultType,
};


export type MutationDeleteManyAutoMatchArgs = {
  where?: Maybe<AutoMatchWhereInput>
};


export type MutationUpsertOneBrandArgs = {
  where: BrandWhereUniqueInput,
  create: BrandCreateInput,
  update: BrandUpdateInput
};


export type MutationUpdateOneBrandArgs = {
  data: BrandUpdateInput,
  where: BrandWhereUniqueInput
};


export type MutationUpsertOneBrandMarketArgs = {
  where: BrandMarketWhereUniqueInput,
  create: BrandMarketCreateInput,
  update: BrandMarketUpdateInput
};


export type MutationUpdateOneBrandMarketArgs = {
  data: BrandMarketUpdateInput,
  where: BrandMarketWhereUniqueInput
};


export type MutationCreateBrandMarketArgs = {
  brandId: Scalars['ID'],
  marketId: Scalars['ID'],
  name: Scalars['String'],
  sender?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  zipcode?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  detailedAddress?: Maybe<Scalars['String']>,
  credential?: Maybe<Scalars['String']>,
  isActive?: Maybe<Scalars['Boolean']>
};


export type MutationUpdateBrandMarketArgs = {
  id: Scalars['ID'],
  name?: Maybe<Scalars['String']>,
  sender?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  zipcode?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  detailedAddress?: Maybe<Scalars['String']>,
  credential?: Maybe<Scalars['String']>,
  isActive?: Maybe<Scalars['Boolean']>
};


export type MutationDeleteBrandMarketArgs = {
  id: Scalars['ID'],
  deletedAt?: Maybe<Scalars['String']>
};


export type MutationCreateOneCenterArgs = {
  data: CenterCreateInput
};


export type MutationUpdateOneCenterArgs = {
  data: CenterUpdateInput,
  where: CenterWhereUniqueInput
};


export type MutationUpdateOneCompanyArgs = {
  data: CompanyUpdateInput,
  where: CompanyWhereUniqueInput
};


export type MutationPrintInvoicesArgs = {
  logisticId?: Maybe<Scalars['ID']>,
  sellerId?: Maybe<Scalars['ID']>,
  startDate?: Maybe<Scalars['String']>,
  endDate?: Maybe<Scalars['String']>,
  currentStartDate: Scalars['String'],
  currentEndDate: Scalars['String'],
  standard?: Maybe<Array<Scalars['String']>>,
  classifyInvoices?: Maybe<Array<ClassifyInvoicesInputType>>
};


export type MutationPrintInvoices2Args = {
  logisticId: Scalars['ID'],
  currentStartDate: Scalars['DateTime'],
  currentEndDate: Scalars['DateTime'],
  sellerId?: Maybe<Scalars['ID']>,
  startDate?: Maybe<Scalars['DateTime']>,
  endDate?: Maybe<Scalars['DateTime']>
};


export type MutationPrintSelectedInvoicesArgs = {
  logisticId: Scalars['ID'],
  courierInvoiceIds: Array<Scalars['ID']>,
  currentStartDate: Scalars['DateTime'],
  currentEndDate: Scalars['DateTime'],
  isForce?: Maybe<Scalars['Boolean']>
};


export type MutationPrintAdditionalOrNewInvoiceArgs = {
  logisticId?: Maybe<Scalars['ID']>,
  currentStartDate: Scalars['String'],
  currentEndDate: Scalars['String'],
  courierInvoices: Array<CourierInvoicesInputType>
};


export type MutationPrintAdditionalInvoiceArgs = {
  courierInvoiceId: Scalars['ID'],
  copyNum: Scalars['Int'],
  currentStartDate: Scalars['DateTime'],
  currentEndDate: Scalars['DateTime']
};


export type MutationUpdatedInvoiceAddressArgs = {
  courierInvoiceId: Scalars['ID'],
  sender?: Maybe<Scalars['String']>,
  senderTel?: Maybe<Scalars['String']>,
  senderZipcode?: Maybe<Scalars['String']>,
  senderAddress?: Maybe<Scalars['String']>,
  senderDetailedAddress?: Maybe<Scalars['String']>,
  receiver?: Maybe<Scalars['String']>,
  receiverTel?: Maybe<Scalars['String']>,
  receiverZipcode?: Maybe<Scalars['String']>,
  receiverAddress?: Maybe<Scalars['String']>,
  receiverDetailedAddress?: Maybe<Scalars['String']>,
  message?: Maybe<Scalars['String']>
};


export type MutationUpdateInvoiceAddressArgs = {
  courierInvoiceId: Scalars['ID'],
  sender?: Maybe<Scalars['String']>,
  senderTel?: Maybe<Scalars['String']>,
  senderZipcode?: Maybe<Scalars['String']>,
  senderAddress?: Maybe<Scalars['String']>,
  senderDetailedAddress?: Maybe<Scalars['String']>,
  receiver?: Maybe<Scalars['String']>,
  receiverTel?: Maybe<Scalars['String']>,
  receiverZipcode?: Maybe<Scalars['String']>,
  receiverAddress?: Maybe<Scalars['String']>,
  receiverDetailedAddress?: Maybe<Scalars['String']>,
  message?: Maybe<Scalars['String']>
};


export type MutationUpdateInvoiceStatusArgs = {
  logisticId: Scalars['ID'],
  courierInvoiceIds: Array<Scalars['ID']>,
  status?: Maybe<PackageStatus>,
  invoiceStatus?: Maybe<CourierInvoiceStatus>
};


export type MutationUpdateFinalPackageStatusArgs = {
  logisticId: Scalars['ID'],
  finalPackageIds: Array<Scalars['ID']>,
  status: FinalPackageStatus
};


export type MutationUpdateCourierTrackingArgs = {
  logisticId?: Maybe<Scalars['ID']>,
  sellerId?: Maybe<Scalars['ID']>,
  brandId?: Maybe<Scalars['ID']>,
  invoiceIds?: Maybe<Array<Scalars['ID']>>,
  returnOrderIds?: Maybe<Array<Scalars['ID']>>
};


export type MutationCombineArgs = {
  logisticId?: Maybe<Scalars['ID']>,
  sellerId?: Maybe<Scalars['ID']>,
  replaceAddresses?: Maybe<Array<ReplaceAddressInputType>>
};


export type MutationDisconnectCombineArgs = {
  finalPackageIds: Array<Scalars['ID']>
};


export type MutationCreateOneLocationArgs = {
  data: LocationCreateInput
};


export type MutationUpdateLocationStatusArgs = {
  centerId: Scalars['ID'],
  settingId: Scalars['ID'],
  zone: Scalars['String'],
  rack: Scalars['String'],
  level: Scalars['String'],
  isActive: Scalars['Boolean']
};


export type MutationCreateOneLocationSettingArgs = {
  data: LocationSettingCreateInput
};


export type MutationUpdateOneLocationSettingArgs = {
  data: LocationSettingUpdateInput,
  where: LocationSettingWhereUniqueInput
};


export type MutationUpdateOneLogisticArgs = {
  data: LogisticUpdateInput,
  where: LogisticWhereUniqueInput
};


export type MutationUpdateLogisticInfoArgs = {
  email: Scalars['String'],
  name: Scalars['String'],
  ceo: Scalars['String'],
  phone: Scalars['String'],
  zipcode: Scalars['String'],
  address: Scalars['String'],
  detailedAddress?: Maybe<Scalars['String']>
};


export type MutationUpdateLogisticCompanyInfoArgs = {
  logisticId: Scalars['ID'],
  email?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  ceo?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  zipcode?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  detailedAddress?: Maybe<Scalars['String']>,
  defaultCourierId?: Maybe<Scalars['ID']>,
  defaultUserId?: Maybe<Scalars['ID']>
};


export type MutationCreateOneLogisticCourierArgs = {
  data: LogisticCourierCreateInput
};


export type MutationUpdateOneLogisticCourierArgs = {
  data: LogisticCourierUpdateInput,
  where: LogisticCourierWhereUniqueInput
};


export type MutationUpdateOneMarketProductArgs = {
  data: MarketProductUpdateInput,
  where: MarketProductWhereUniqueInput
};


export type MutationMatchingArgs = {
  marketProductId: Scalars['ID'],
  productMatches: Array<ProductMatchesInputType>
};


export type MutationUpdateMarketProductStatusArgs = {
  marketProductIds: Array<Scalars['ID']>,
  status: MarketProductStatus
};


export type MutationUpdateMatchingArgs = {
  marketProductId: Scalars['ID'],
  productMatches: Array<ProductMatchesInputType>
};


export type MutationCreateOneNoticeArgs = {
  data: NoticeCreateInput
};


export type MutationUpdateOneNoticeArgs = {
  data: NoticeUpdateInput,
  where: NoticeWhereUniqueInput
};


export type MutationCreateNoticeArgs = {
  logisticId: Scalars['ID'],
  title: Scalars['String'],
  content: Scalars['String'],
  isPublic: Scalars['Boolean'],
  sellerIds?: Maybe<Array<Scalars['String']>>
};


export type MutationUpdateNoticeArgs = {
  title?: Maybe<Scalars['String']>,
  content?: Maybe<Scalars['String']>,
  isPublic?: Maybe<Scalars['Boolean']>,
  isImportant?: Maybe<Scalars['Boolean']>,
  groupId: Scalars['String']
};


export type MutationGetMarketOrdersArgs = {
  currentDate: Scalars['String'],
  brandMarketIds: Array<Scalars['ID']>
};


export type MutationUploadExcelOrdersArgs = {
  currentDate?: Maybe<Scalars['String']>,
  brandMarketId: Scalars['ID'],
  orders: Array<UploadExcelOrdersInputType>
};


export type MutationUpdateOrdererInfoArgs = {
  orderId: Scalars['ID'],
  sender?: Maybe<Scalars['String']>,
  senderTel?: Maybe<Scalars['String']>,
  receiver?: Maybe<Scalars['String']>,
  receiverTel?: Maybe<Scalars['String']>,
  zipcode?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  detailedAddress?: Maybe<Scalars['String']>,
  message?: Maybe<Scalars['String']>
};


export type MutationAdditionalShippingArgs = {
  orderId: Scalars['ID'],
  productMatches: Array<AdditionalShippingInputType>
};


export type MutationUpsertOneOrderNoteArgs = {
  where: OrderNoteWhereUniqueInput,
  create: OrderNoteCreateInput,
  update: OrderNoteUpdateInput
};


export type MutationChoosePacketArgs = {
  logisticId?: Maybe<Scalars['ID']>,
  pricingId: Scalars['ID']
};


export type MutationUpsertOnePreStockArgs = {
  where: PreStockWhereUniqueInput,
  create: PreStockCreateInput,
  update: PreStockUpdateInput
};


export type MutationUpdateOnePreStockArgs = {
  data: PreStockUpdateInput,
  where: PreStockWhereUniqueInput
};


export type MutationDeleteOnePreStockArgs = {
  where: PreStockWhereUniqueInput
};


export type MutationCreateManyPreStocksArgs = {
  data?: Maybe<CreateManyPreStocksInput>
};


export type MutationUploadExcelPreStocksArgs = {
  preStocks: Array<UploadExcelPreStocksInput>
};


export type MutationConfirmPreStockArgs = {
  logisticId?: Maybe<Scalars['ID']>,
  preStockId: Scalars['ID'],
  confirmedDate?: Maybe<Scalars['String']>,
  transactions: Array<ConfirmPreStockInputType>,
  note?: Maybe<Scalars['String']>
};


export type MutationCreateOneProductArgs = {
  data: ProductCreateInput
};


export type MutationUpdateOneProductArgs = {
  data: ProductUpdateInput,
  where: ProductWhereUniqueInput
};


export type MutationCreateManyProductsArgs = {
  data?: Maybe<CreateManyProductsInput>
};


export type MutationUpdateOneProductMatchArgs = {
  data: ProductMatchUpdateInput,
  where: ProductMatchWhereUniqueInput
};


export type MutationDisconnectMatchingArgs = {
  where: ProductMatchWhereUniqueInput
};


export type MutationCreateOneProductOptionArgs = {
  data: ProductOptionCreateInput
};


export type MutationUpdateOneProductOptionArgs = {
  data: ProductOptionUpdateInput,
  where: ProductOptionWhereUniqueInput
};


export type MutationUpdateManyProductOptionArgs = {
  data: ProductOptionUpdateManyMutationInput,
  where?: Maybe<ProductOptionWhereInput>
};


export type MutationRequestReturnOrderArgs = {
  courierInvoiceId: Scalars['ID'],
  name: Scalars['String'],
  tel: Scalars['String'],
  zipcode: Scalars['String'],
  address: Scalars['String'],
  detailedAddress?: Maybe<Scalars['String']>,
  message?: Maybe<Scalars['String']>,
  paidType?: Maybe<DeliveryPaidType>,
  note?: Maybe<Scalars['String']>,
  finalPackages: Array<RequsetReturnOrderFinalPackageType>
};


export type MutationConfirmReturnOrderArgs = {
  returnOrderId: Scalars['ID'],
  returnedAt?: Maybe<Scalars['String']>,
  products: Array<ConfirmReturnOrderProductInputType>,
  note?: Maybe<Scalars['String']>
};


export type MutationCancelReturnOrderArgs = {
  returnOrderId: Scalars['ID']
};


export type MutationSelectCombineArgs = {
  logisticId?: Maybe<Scalars['ID']>,
  sellerId?: Maybe<Scalars['ID']>,
  selectInvoice: SelectInvoiceInputType
};


export type MutationCreateOneSellerArgs = {
  data: SellerCreateInput
};


export type MutationUpdateOneSellerArgs = {
  data: SellerUpdateInput,
  where: SellerWhereUniqueInput
};


export type MutationUpsertOneSellerArgs = {
  where: SellerWhereUniqueInput,
  create: SellerCreateInput,
  update: SellerUpdateInput
};


export type MutationCreateSellerArgs = {
  logisticId: Scalars['ID'],
  name: Scalars['String'],
  ceo: Scalars['String'],
  zipcode: Scalars['String'],
  address: Scalars['String'],
  detailedAddress?: Maybe<Scalars['String']>,
  email: Scalars['String'],
  phone: Scalars['String']
};


export type MutationUpdateSellerArgs = {
  sellerId: Scalars['ID'],
  name?: Maybe<Scalars['String']>,
  zipcode?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  detailedAddress?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  ceo?: Maybe<Scalars['String']>
};


export type MutationDeleteSellerArgs = {
  sellerId: Scalars['ID']
};


export type MutationRegisterStocktakingArgs = {
  transactionId: Scalars['ID'],
  amount: Scalars['Int'],
  inspectedDate?: Maybe<Scalars['String']>,
  note?: Maybe<Scalars['String']>
};


export type MutationConfirmStocktakingsArgs = {
  data: Array<ConfirmStocktakingsInput>
};


export type MutationCancelStocktakingsArgs = {
  data: Array<CancelStocktakingsInput>
};


export type MutationCreateOneTeamArgs = {
  data: TeamCreateInput
};


export type MutationUpdateOneTeamArgs = {
  data: TeamUpdateInput,
  where: TeamWhereUniqueInput
};


export type MutationDeleteOneTeamArgs = {
  where: TeamWhereUniqueInput
};


export type MutationSelectTransactionLocationArgs = {
  transactionId: Scalars['ID'],
  locations: Array<SelectTransactionLocationInputType>
};


export type MutationMoveStockArgs = {
  transactionId: Scalars['ID'],
  locations: Array<MoveStockInputType>
};


export type MutationRegisterOutgoingArgs = {
  transactionId: Scalars['ID'],
  eventType?: Maybe<EventType>,
  transportType?: Maybe<TransportType>,
  note?: Maybe<Scalars['String']>,
  amount?: Maybe<Scalars['Int']>
};


export type MutationUploadExcelStockArgs = {
  logisticId: Scalars['ID'],
  records: Array<UploadExcelStockInputType>
};


export type MutationCreateOneUserArgs = {
  data: UserCreateInput
};


export type MutationUpdateOneUserArgs = {
  data: UserUpdateInput,
  where: UserWhereUniqueInput
};


export type MutationLoginArgs = {
  email: Scalars['String'],
  password: Scalars['String']
};


export type MutationSignupArgs = {
  email: Scalars['String'],
  password: Scalars['String'],
  marketing?: Maybe<Scalars['Boolean']>
};


export type MutationChangePasswordArgs = {
  password?: Maybe<Scalars['String']>,
  newPassword?: Maybe<Scalars['String']>
};


export type MutationUpdateUserSettingsArgs = {
  settings?: Maybe<UserSettingsInput>
};


export type MutationUpdateUserDashboardSettingsArgs = {
  companyType: CompanyType,
  dashboard?: Maybe<UserSettingsDashboardInput>
};

export type BatchPayload = {
   __typename?: 'BatchPayload',
  count: Scalars['Int'],
};

export type BrandCreateInput = {
  id?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  phone?: Maybe<Scalars['String']>,
  zipcode?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  detailedAddress?: Maybe<Scalars['String']>,
  isActive?: Maybe<Scalars['Boolean']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  seller: SellerCreateOneWithoutBrandsInput,
  brandMarkets?: Maybe<BrandMarketCreateManyWithoutBrandInput>,
  products?: Maybe<ProductCreateManyWithoutBrandInput>,
  courierInvoices?: Maybe<CourierInvoiceCreateManyWithoutBrandInput>,
};

export type SellerCreateOneWithoutBrandsInput = {
  create?: Maybe<SellerCreateWithoutBrandsInput>,
  connect?: Maybe<SellerWhereUniqueInput>,
};

export type SellerCreateWithoutBrandsInput = {
  id?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  company: CompanyCreateOneWithoutSellerInput,
  logistic: LogisticCreateOneWithoutSellersInput,
  defaultCourier?: Maybe<LogisticCourierCreateOneWithoutSellerDefaultOfInput>,
  notices?: Maybe<NoticeCreateManyWithoutSellerInput>,
};

export type CompanyCreateOneWithoutSellerInput = {
  create?: Maybe<CompanyCreateWithoutSellerInput>,
  connect?: Maybe<CompanyWhereUniqueInput>,
};

export type CompanyCreateWithoutSellerInput = {
  id?: Maybe<Scalars['String']>,
  type?: Maybe<CompanyType>,
  name?: Maybe<Scalars['String']>,
  ceo?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  zipcode?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  detailedAddress?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  status?: Maybe<CompanyStatus>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  taxInfo?: Maybe<TaxInfoCreateOneWithoutCompanyInput>,
  users?: Maybe<UserCreateManyWithoutCompanyInput>,
  teams?: Maybe<TeamCreateManyWithoutCompanyInput>,
  bankAccounts?: Maybe<BankAccountCreateManyWithoutCompanyInput>,
  superAdmin?: Maybe<SuperAdminCreateOneWithoutCompanyInput>,
  logistic?: Maybe<LogisticCreateOneWithoutCompanyInput>,
};

export type TaxInfoCreateOneWithoutCompanyInput = {
  create?: Maybe<TaxInfoCreateWithoutCompanyInput>,
  connect?: Maybe<TaxInfoWhereUniqueInput>,
};

export type TaxInfoCreateWithoutCompanyInput = {
  id?: Maybe<Scalars['String']>,
  corporationName?: Maybe<Scalars['String']>,
  businessRegNum?: Maybe<Scalars['String']>,
  businessType?: Maybe<Scalars['String']>,
  businessItem?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  zipcode?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  detailedAddress?: Maybe<Scalars['String']>,
  needTaxInfo?: Maybe<Scalars['Boolean']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type TaxInfoWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
  companyId?: Maybe<Scalars['String']>,
};

export type UserCreateManyWithoutCompanyInput = {
  create?: Maybe<Array<UserCreateWithoutCompanyInput>>,
  connect?: Maybe<Array<UserWhereUniqueInput>>,
};

export type UserCreateWithoutCompanyInput = {
  id?: Maybe<Scalars['String']>,
  email: Scalars['String'],
  emailVerified?: Maybe<Scalars['Boolean']>,
  type?: Maybe<CompanyType>,
  password: Scalars['String'],
  name?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  role?: Maybe<RoleType>,
  marketing?: Maybe<Scalars['Boolean']>,
  settings?: Maybe<Scalars['Json']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  team?: Maybe<TeamCreateOneWithoutUsersInput>,
  confirmedTransactions?: Maybe<TransactionCreateManyWithoutConfirmedUserInput>,
  registeredPreStocks?: Maybe<PreStockCreateManyWithoutRegisteredUserInput>,
  confirmedPreStocks?: Maybe<PreStockCreateManyWithoutConfirmedUserInput>,
  confirmedFinalPackages?: Maybe<FinalPackageCreateManyWithoutConfirmedUserInput>,
  confirmedStocktaking?: Maybe<StocktakingCreateManyWithoutConfirmedUserInput>,
  confirmedReturnOrder?: Maybe<ReturnOrderCreateManyWithoutConfirmedUserInput>,
  logisticdefaultOf?: Maybe<LogisticCreateManyWithoutDefaultUserInput>,
  logs?: Maybe<LogCreateManyWithoutUserInput>,
  orderNotes?: Maybe<OrderNoteCreateManyWithoutWriterInput>,
  confirmedOrderNotes?: Maybe<OrderNoteCreateManyWithoutConfirmedUserInput>,
  notices?: Maybe<NoticeCreateManyWithoutAuthorInput>,
};

export type TeamCreateOneWithoutUsersInput = {
  create?: Maybe<TeamCreateWithoutUsersInput>,
  connect?: Maybe<TeamWhereUniqueInput>,
};

export type TeamCreateWithoutUsersInput = {
  id?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  company: CompanyCreateOneWithoutTeamsInput,
};

export type CompanyCreateOneWithoutTeamsInput = {
  create?: Maybe<CompanyCreateWithoutTeamsInput>,
  connect?: Maybe<CompanyWhereUniqueInput>,
};

export type CompanyCreateWithoutTeamsInput = {
  id?: Maybe<Scalars['String']>,
  type?: Maybe<CompanyType>,
  name?: Maybe<Scalars['String']>,
  ceo?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  zipcode?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  detailedAddress?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  status?: Maybe<CompanyStatus>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  taxInfo?: Maybe<TaxInfoCreateOneWithoutCompanyInput>,
  users?: Maybe<UserCreateManyWithoutCompanyInput>,
  bankAccounts?: Maybe<BankAccountCreateManyWithoutCompanyInput>,
  superAdmin?: Maybe<SuperAdminCreateOneWithoutCompanyInput>,
  logistic?: Maybe<LogisticCreateOneWithoutCompanyInput>,
  seller?: Maybe<SellerCreateOneWithoutCompanyInput>,
};

export type BankAccountCreateManyWithoutCompanyInput = {
  create?: Maybe<Array<BankAccountCreateWithoutCompanyInput>>,
  connect?: Maybe<Array<BankAccountWhereUniqueInput>>,
};

export type BankAccountCreateWithoutCompanyInput = {
  id?: Maybe<Scalars['String']>,
  bank: Scalars['String'],
  num: Scalars['String'],
  holder: Scalars['String'],
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type SuperAdminCreateOneWithoutCompanyInput = {
  create?: Maybe<SuperAdminCreateWithoutCompanyInput>,
  connect?: Maybe<SuperAdminWhereUniqueInput>,
};

export type SuperAdminCreateWithoutCompanyInput = {
  id?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type LogisticCreateOneWithoutCompanyInput = {
  create?: Maybe<LogisticCreateWithoutCompanyInput>,
  connect?: Maybe<LogisticWhereUniqueInput>,
};

export type LogisticCreateWithoutCompanyInput = {
  id?: Maybe<Scalars['String']>,
  marketing?: Maybe<Scalars['Boolean']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  defaultUser: UserCreateOneWithoutLogisticdefaultOfInput,
  defaultCourier?: Maybe<LogisticCourierCreateOneWithoutLogisticDefaultOfInput>,
  sellers?: Maybe<SellerCreateManyWithoutLogisticInput>,
  availableCouriers?: Maybe<LogisticCourierCreateManyWithoutLogisticInput>,
  packets?: Maybe<PacketCreateManyWithoutLogisticInput>,
  centers?: Maybe<CenterCreateManyWithoutLogisticInput>,
  preStocks?: Maybe<PreStockCreateManyWithoutLogisticInput>,
  transactions?: Maybe<TransactionCreateManyWithoutLogisticInput>,
  notices?: Maybe<NoticeCreateManyWithoutLogisticInput>,
};

export type UserCreateOneWithoutLogisticdefaultOfInput = {
  create?: Maybe<UserCreateWithoutLogisticdefaultOfInput>,
  connect?: Maybe<UserWhereUniqueInput>,
};

export type UserCreateWithoutLogisticdefaultOfInput = {
  id?: Maybe<Scalars['String']>,
  email: Scalars['String'],
  emailVerified?: Maybe<Scalars['Boolean']>,
  type?: Maybe<CompanyType>,
  password: Scalars['String'],
  name?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  role?: Maybe<RoleType>,
  marketing?: Maybe<Scalars['Boolean']>,
  settings?: Maybe<Scalars['Json']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  company: CompanyCreateOneWithoutUsersInput,
  team?: Maybe<TeamCreateOneWithoutUsersInput>,
  confirmedTransactions?: Maybe<TransactionCreateManyWithoutConfirmedUserInput>,
  registeredPreStocks?: Maybe<PreStockCreateManyWithoutRegisteredUserInput>,
  confirmedPreStocks?: Maybe<PreStockCreateManyWithoutConfirmedUserInput>,
  confirmedFinalPackages?: Maybe<FinalPackageCreateManyWithoutConfirmedUserInput>,
  confirmedStocktaking?: Maybe<StocktakingCreateManyWithoutConfirmedUserInput>,
  confirmedReturnOrder?: Maybe<ReturnOrderCreateManyWithoutConfirmedUserInput>,
  logs?: Maybe<LogCreateManyWithoutUserInput>,
  orderNotes?: Maybe<OrderNoteCreateManyWithoutWriterInput>,
  confirmedOrderNotes?: Maybe<OrderNoteCreateManyWithoutConfirmedUserInput>,
  notices?: Maybe<NoticeCreateManyWithoutAuthorInput>,
};

export type CompanyCreateOneWithoutUsersInput = {
  create?: Maybe<CompanyCreateWithoutUsersInput>,
  connect?: Maybe<CompanyWhereUniqueInput>,
};

export type CompanyCreateWithoutUsersInput = {
  id?: Maybe<Scalars['String']>,
  type?: Maybe<CompanyType>,
  name?: Maybe<Scalars['String']>,
  ceo?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  zipcode?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  detailedAddress?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  status?: Maybe<CompanyStatus>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  taxInfo?: Maybe<TaxInfoCreateOneWithoutCompanyInput>,
  teams?: Maybe<TeamCreateManyWithoutCompanyInput>,
  bankAccounts?: Maybe<BankAccountCreateManyWithoutCompanyInput>,
  superAdmin?: Maybe<SuperAdminCreateOneWithoutCompanyInput>,
  logistic?: Maybe<LogisticCreateOneWithoutCompanyInput>,
  seller?: Maybe<SellerCreateOneWithoutCompanyInput>,
};

export type TeamCreateManyWithoutCompanyInput = {
  create?: Maybe<Array<TeamCreateWithoutCompanyInput>>,
  connect?: Maybe<Array<TeamWhereUniqueInput>>,
};

export type TeamCreateWithoutCompanyInput = {
  id?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  users?: Maybe<UserCreateManyWithoutTeamInput>,
};

export type UserCreateManyWithoutTeamInput = {
  create?: Maybe<Array<UserCreateWithoutTeamInput>>,
  connect?: Maybe<Array<UserWhereUniqueInput>>,
};

export type UserCreateWithoutTeamInput = {
  id?: Maybe<Scalars['String']>,
  email: Scalars['String'],
  emailVerified?: Maybe<Scalars['Boolean']>,
  type?: Maybe<CompanyType>,
  password: Scalars['String'],
  name?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  role?: Maybe<RoleType>,
  marketing?: Maybe<Scalars['Boolean']>,
  settings?: Maybe<Scalars['Json']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  company: CompanyCreateOneWithoutUsersInput,
  confirmedTransactions?: Maybe<TransactionCreateManyWithoutConfirmedUserInput>,
  registeredPreStocks?: Maybe<PreStockCreateManyWithoutRegisteredUserInput>,
  confirmedPreStocks?: Maybe<PreStockCreateManyWithoutConfirmedUserInput>,
  confirmedFinalPackages?: Maybe<FinalPackageCreateManyWithoutConfirmedUserInput>,
  confirmedStocktaking?: Maybe<StocktakingCreateManyWithoutConfirmedUserInput>,
  confirmedReturnOrder?: Maybe<ReturnOrderCreateManyWithoutConfirmedUserInput>,
  logisticdefaultOf?: Maybe<LogisticCreateManyWithoutDefaultUserInput>,
  logs?: Maybe<LogCreateManyWithoutUserInput>,
  orderNotes?: Maybe<OrderNoteCreateManyWithoutWriterInput>,
  confirmedOrderNotes?: Maybe<OrderNoteCreateManyWithoutConfirmedUserInput>,
  notices?: Maybe<NoticeCreateManyWithoutAuthorInput>,
};

export type TransactionCreateManyWithoutConfirmedUserInput = {
  create?: Maybe<Array<TransactionCreateWithoutConfirmedUserInput>>,
  connect?: Maybe<Array<TransactionWhereUniqueInput>>,
};

export type TransactionCreateWithoutConfirmedUserInput = {
  id?: Maybe<Scalars['String']>,
  manufacturedDate?: Maybe<Scalars['DateTime']>,
  expiryDate?: Maybe<Scalars['DateTime']>,
  eventType?: Maybe<EventType>,
  transportType?: Maybe<TransportType>,
  usableAmount?: Maybe<Scalars['Int']>,
  brokenAmount?: Maybe<Scalars['Int']>,
  note?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  logistic: LogisticCreateOneWithoutTransactionsInput,
  preStock?: Maybe<PreStockCreateOneWithoutTransactionsInput>,
  parent?: Maybe<TransactionCreateOneWithoutChildrenInput>,
  location?: Maybe<LocationCreateOneWithoutIncomingRecordsInput>,
  productOption: ProductOptionCreateOneWithoutTransactionsInput,
  finalPackage?: Maybe<FinalPackageCreateOneWithoutTransactionsInput>,
  stocktaking?: Maybe<StocktakingCreateOneWithoutTransactionsInput>,
  children?: Maybe<TransactionCreateManyWithoutParentInput>,
  stocktakings?: Maybe<StocktakingCreateManyWithoutBaseTransactionInput>,
};

export type LogisticCreateOneWithoutTransactionsInput = {
  create?: Maybe<LogisticCreateWithoutTransactionsInput>,
  connect?: Maybe<LogisticWhereUniqueInput>,
};

export type LogisticCreateWithoutTransactionsInput = {
  id?: Maybe<Scalars['String']>,
  marketing?: Maybe<Scalars['Boolean']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  company: CompanyCreateOneWithoutLogisticInput,
  defaultUser: UserCreateOneWithoutLogisticdefaultOfInput,
  defaultCourier?: Maybe<LogisticCourierCreateOneWithoutLogisticDefaultOfInput>,
  sellers?: Maybe<SellerCreateManyWithoutLogisticInput>,
  availableCouriers?: Maybe<LogisticCourierCreateManyWithoutLogisticInput>,
  packets?: Maybe<PacketCreateManyWithoutLogisticInput>,
  centers?: Maybe<CenterCreateManyWithoutLogisticInput>,
  preStocks?: Maybe<PreStockCreateManyWithoutLogisticInput>,
  notices?: Maybe<NoticeCreateManyWithoutLogisticInput>,
};

export type CompanyCreateOneWithoutLogisticInput = {
  create?: Maybe<CompanyCreateWithoutLogisticInput>,
  connect?: Maybe<CompanyWhereUniqueInput>,
};

export type CompanyCreateWithoutLogisticInput = {
  id?: Maybe<Scalars['String']>,
  type?: Maybe<CompanyType>,
  name?: Maybe<Scalars['String']>,
  ceo?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  zipcode?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  detailedAddress?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  status?: Maybe<CompanyStatus>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  taxInfo?: Maybe<TaxInfoCreateOneWithoutCompanyInput>,
  users?: Maybe<UserCreateManyWithoutCompanyInput>,
  teams?: Maybe<TeamCreateManyWithoutCompanyInput>,
  bankAccounts?: Maybe<BankAccountCreateManyWithoutCompanyInput>,
  superAdmin?: Maybe<SuperAdminCreateOneWithoutCompanyInput>,
  seller?: Maybe<SellerCreateOneWithoutCompanyInput>,
};

export type SellerCreateOneWithoutCompanyInput = {
  create?: Maybe<SellerCreateWithoutCompanyInput>,
  connect?: Maybe<SellerWhereUniqueInput>,
};

export type SellerCreateWithoutCompanyInput = {
  id?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  logistic: LogisticCreateOneWithoutSellersInput,
  defaultCourier?: Maybe<LogisticCourierCreateOneWithoutSellerDefaultOfInput>,
  brands?: Maybe<BrandCreateManyWithoutSellerInput>,
  notices?: Maybe<NoticeCreateManyWithoutSellerInput>,
};

export type LogisticCreateOneWithoutSellersInput = {
  create?: Maybe<LogisticCreateWithoutSellersInput>,
  connect?: Maybe<LogisticWhereUniqueInput>,
};

export type LogisticCreateWithoutSellersInput = {
  id?: Maybe<Scalars['String']>,
  marketing?: Maybe<Scalars['Boolean']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  company: CompanyCreateOneWithoutLogisticInput,
  defaultUser: UserCreateOneWithoutLogisticdefaultOfInput,
  defaultCourier?: Maybe<LogisticCourierCreateOneWithoutLogisticDefaultOfInput>,
  availableCouriers?: Maybe<LogisticCourierCreateManyWithoutLogisticInput>,
  packets?: Maybe<PacketCreateManyWithoutLogisticInput>,
  centers?: Maybe<CenterCreateManyWithoutLogisticInput>,
  preStocks?: Maybe<PreStockCreateManyWithoutLogisticInput>,
  transactions?: Maybe<TransactionCreateManyWithoutLogisticInput>,
  notices?: Maybe<NoticeCreateManyWithoutLogisticInput>,
};

export type LogisticCourierCreateOneWithoutLogisticDefaultOfInput = {
  create?: Maybe<LogisticCourierCreateWithoutLogisticDefaultOfInput>,
  connect?: Maybe<LogisticCourierWhereUniqueInput>,
};

export type LogisticCourierCreateWithoutLogisticDefaultOfInput = {
  id?: Maybe<Scalars['String']>,
  config: Scalars['String'],
  isActive?: Maybe<Scalars['Boolean']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  logistic: LogisticCreateOneWithoutAvailableCouriersInput,
  courier: CourierCreateOneWithoutLogisticCouriersInput,
  sellerDefaultOf?: Maybe<SellerCreateManyWithoutDefaultCourierInput>,
  courierInvoices?: Maybe<CourierInvoiceCreateManyWithoutLogisticCourierInput>,
  courierInvoiceTrackings?: Maybe<CourierInvoiceTrackingCreateManyWithoutLogisticCourierInput>,
};

export type LogisticCreateOneWithoutAvailableCouriersInput = {
  create?: Maybe<LogisticCreateWithoutAvailableCouriersInput>,
  connect?: Maybe<LogisticWhereUniqueInput>,
};

export type LogisticCreateWithoutAvailableCouriersInput = {
  id?: Maybe<Scalars['String']>,
  marketing?: Maybe<Scalars['Boolean']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  company: CompanyCreateOneWithoutLogisticInput,
  defaultUser: UserCreateOneWithoutLogisticdefaultOfInput,
  defaultCourier?: Maybe<LogisticCourierCreateOneWithoutLogisticDefaultOfInput>,
  sellers?: Maybe<SellerCreateManyWithoutLogisticInput>,
  packets?: Maybe<PacketCreateManyWithoutLogisticInput>,
  centers?: Maybe<CenterCreateManyWithoutLogisticInput>,
  preStocks?: Maybe<PreStockCreateManyWithoutLogisticInput>,
  transactions?: Maybe<TransactionCreateManyWithoutLogisticInput>,
  notices?: Maybe<NoticeCreateManyWithoutLogisticInput>,
};

export type SellerCreateManyWithoutLogisticInput = {
  create?: Maybe<Array<SellerCreateWithoutLogisticInput>>,
  connect?: Maybe<Array<SellerWhereUniqueInput>>,
};

export type SellerCreateWithoutLogisticInput = {
  id?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  company: CompanyCreateOneWithoutSellerInput,
  defaultCourier?: Maybe<LogisticCourierCreateOneWithoutSellerDefaultOfInput>,
  brands?: Maybe<BrandCreateManyWithoutSellerInput>,
  notices?: Maybe<NoticeCreateManyWithoutSellerInput>,
};

export type LogisticCourierCreateOneWithoutSellerDefaultOfInput = {
  create?: Maybe<LogisticCourierCreateWithoutSellerDefaultOfInput>,
  connect?: Maybe<LogisticCourierWhereUniqueInput>,
};

export type LogisticCourierCreateWithoutSellerDefaultOfInput = {
  id?: Maybe<Scalars['String']>,
  config: Scalars['String'],
  isActive?: Maybe<Scalars['Boolean']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  logistic: LogisticCreateOneWithoutAvailableCouriersInput,
  courier: CourierCreateOneWithoutLogisticCouriersInput,
  logisticDefaultOf?: Maybe<LogisticCreateOneWithoutDefaultCourierInput>,
  courierInvoices?: Maybe<CourierInvoiceCreateManyWithoutLogisticCourierInput>,
  courierInvoiceTrackings?: Maybe<CourierInvoiceTrackingCreateManyWithoutLogisticCourierInput>,
};

export type CourierCreateOneWithoutLogisticCouriersInput = {
  create?: Maybe<CourierCreateWithoutLogisticCouriersInput>,
  connect?: Maybe<CourierWhereUniqueInput>,
};

export type CourierCreateWithoutLogisticCouriersInput = {
  id?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  configFields: Scalars['String'],
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  priceRanges?: Maybe<CourierPriceRangeCreateManyWithoutCourierInput>,
};

export type CourierPriceRangeCreateManyWithoutCourierInput = {
  create?: Maybe<Array<CourierPriceRangeCreateWithoutCourierInput>>,
  connect?: Maybe<Array<CourierPriceRangeWhereUniqueInput>>,
};

export type CourierPriceRangeCreateWithoutCourierInput = {
  id?: Maybe<Scalars['String']>,
  basic?: Maybe<BasicCourierPriceRange>,
  name: Scalars['String'],
  value?: Maybe<Scalars['String']>,
  price: Scalars['Int'],
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  courierInvoices?: Maybe<CourierInvoiceCreateManyWithoutCourierPriceRangeInput>,
};

export type CourierInvoiceCreateManyWithoutCourierPriceRangeInput = {
  create?: Maybe<Array<CourierInvoiceCreateWithoutCourierPriceRangeInput>>,
  connect?: Maybe<Array<CourierInvoiceWhereUniqueInput>>,
};

export type CourierInvoiceCreateWithoutCourierPriceRangeInput = {
  id?: Maybe<Scalars['String']>,
  type?: Maybe<CourierInvoiceType>,
  courierName?: Maybe<Scalars['String']>,
  num?: Maybe<Scalars['String']>,
  groupNum?: Maybe<Scalars['Int']>,
  printedNum?: Maybe<Scalars['Int']>,
  invoiceStatus?: Maybe<CourierInvoiceStatus>,
  status?: Maybe<PackageStatus>,
  sender: Scalars['String'],
  senderTel: Scalars['String'],
  senderZipcode: Scalars['String'],
  senderAddress: Scalars['String'],
  senderDetailedAddress?: Maybe<Scalars['String']>,
  receiver: Scalars['String'],
  receiverTel: Scalars['String'],
  receiverZipcode: Scalars['String'],
  receiverAddress: Scalars['String'],
  receiverDetailedAddress?: Maybe<Scalars['String']>,
  content?: Maybe<Scalars['String']>,
  message?: Maybe<Scalars['String']>,
  note?: Maybe<Scalars['String']>,
  addressRefinedDate?: Maybe<Scalars['DateTime']>,
  addressRefinedValues?: Maybe<Scalars['String']>,
  printedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  brand: BrandCreateOneWithoutCourierInvoicesInput,
  logisticCourier?: Maybe<LogisticCourierCreateOneWithoutCourierInvoicesInput>,
  parent?: Maybe<CourierInvoiceCreateOneWithoutChildrenInput>,
  children?: Maybe<CourierInvoiceCreateManyWithoutParentInput>,
  finalPackages?: Maybe<FinalPackageCreateManyWithoutCourierInvoiceInput>,
  returnOrders?: Maybe<ReturnOrderCreateManyWithoutCourierInvoiceInput>,
};

export type BrandCreateOneWithoutCourierInvoicesInput = {
  create?: Maybe<BrandCreateWithoutCourierInvoicesInput>,
  connect?: Maybe<BrandWhereUniqueInput>,
};

export type BrandCreateWithoutCourierInvoicesInput = {
  id?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  phone?: Maybe<Scalars['String']>,
  zipcode?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  detailedAddress?: Maybe<Scalars['String']>,
  isActive?: Maybe<Scalars['Boolean']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  seller: SellerCreateOneWithoutBrandsInput,
  brandMarkets?: Maybe<BrandMarketCreateManyWithoutBrandInput>,
  products?: Maybe<ProductCreateManyWithoutBrandInput>,
};

export type BrandMarketCreateManyWithoutBrandInput = {
  create?: Maybe<Array<BrandMarketCreateWithoutBrandInput>>,
  connect?: Maybe<Array<BrandMarketWhereUniqueInput>>,
};

export type BrandMarketCreateWithoutBrandInput = {
  id?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  sender?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  zipcode?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  detailedAddress?: Maybe<Scalars['String']>,
  credential?: Maybe<Scalars['String']>,
  isActive?: Maybe<Scalars['Boolean']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  market: MarketCreateOneWithoutBrandMarketsInput,
  orders?: Maybe<OrderCreateManyWithoutBrandMarketInput>,
  autoMatches?: Maybe<AutoMatchCreateManyWithoutBrandMarketInput>,
};

export type MarketCreateOneWithoutBrandMarketsInput = {
  create?: Maybe<MarketCreateWithoutBrandMarketsInput>,
  connect?: Maybe<MarketWhereUniqueInput>,
};

export type MarketCreateWithoutBrandMarketsInput = {
  id?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  type?: Maybe<CommunicationType>,
  credentialFields?: Maybe<Scalars['String']>,
  dashboardUri?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type OrderCreateManyWithoutBrandMarketInput = {
  create?: Maybe<Array<OrderCreateWithoutBrandMarketInput>>,
  connect?: Maybe<Array<OrderWhereUniqueInput>>,
};

export type OrderCreateWithoutBrandMarketInput = {
  id?: Maybe<Scalars['String']>,
  num: Scalars['String'],
  marketOrderNum: Scalars['String'],
  orderedDate?: Maybe<Scalars['DateTime']>,
  paidDate?: Maybe<Scalars['DateTime']>,
  sender?: Maybe<Scalars['String']>,
  senderTel?: Maybe<Scalars['String']>,
  senderTel2?: Maybe<Scalars['String']>,
  receiver: Scalars['String'],
  receiverTel: Scalars['String'],
  receiverTel2?: Maybe<Scalars['String']>,
  receiverZipcode?: Maybe<Scalars['String']>,
  receiverAddress: Scalars['String'],
  receiverDetailedAddress?: Maybe<Scalars['String']>,
  message?: Maybe<Scalars['String']>,
  deliveryPaid?: Maybe<Scalars['Boolean']>,
  deliveryPrice?: Maybe<Scalars['Int']>,
  isOverseas?: Maybe<Scalars['Boolean']>,
  remoteAreaPrice?: Maybe<Scalars['Int']>,
  detailedOrder?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  marketProducts?: Maybe<MarketProductCreateManyWithoutOrderInput>,
  orderNotes?: Maybe<OrderNoteCreateManyWithoutOrderInput>,
};

export type MarketProductCreateManyWithoutOrderInput = {
  create?: Maybe<Array<MarketProductCreateWithoutOrderInput>>,
  connect?: Maybe<Array<MarketProductWhereUniqueInput>>,
};

export type MarketProductCreateWithoutOrderInput = {
  id?: Maybe<Scalars['String']>,
  num?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  optionNum?: Maybe<Scalars['String']>,
  optionName?: Maybe<Scalars['String']>,
  amount?: Maybe<Scalars['Int']>,
  totalPrice?: Maybe<Scalars['Int']>,
  status?: Maybe<MarketProductStatus>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  productMatches?: Maybe<ProductMatchCreateManyWithoutMarketProductInput>,
};

export type ProductMatchCreateManyWithoutMarketProductInput = {
  create?: Maybe<Array<ProductMatchCreateWithoutMarketProductInput>>,
  connect?: Maybe<Array<ProductMatchWhereUniqueInput>>,
};

export type ProductMatchCreateWithoutMarketProductInput = {
  id?: Maybe<Scalars['String']>,
  amount?: Maybe<Scalars['Int']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  autoMatch?: Maybe<AutoMatchCreateOneWithoutProductMatchesInput>,
  productOption: ProductOptionCreateOneWithoutProductMatchesInput,
  finalPackages?: Maybe<FinalPackageCreateManyWithoutProductMatchInput>,
};

export type AutoMatchCreateOneWithoutProductMatchesInput = {
  create?: Maybe<AutoMatchCreateWithoutProductMatchesInput>,
  connect?: Maybe<AutoMatchWhereUniqueInput>,
};

export type AutoMatchCreateWithoutProductMatchesInput = {
  id?: Maybe<Scalars['String']>,
  marketProductNum?: Maybe<Scalars['String']>,
  marketProductName: Scalars['String'],
  marketProductOptionNum?: Maybe<Scalars['String']>,
  marketProductOptionName?: Maybe<Scalars['String']>,
  marketProductOptionAmount?: Maybe<Scalars['Int']>,
  productAmount?: Maybe<Scalars['Int']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  brandMarket: BrandMarketCreateOneWithoutAutoMatchesInput,
  productOption: ProductOptionCreateOneWithoutAutoMatchesInput,
};

export type BrandMarketCreateOneWithoutAutoMatchesInput = {
  create?: Maybe<BrandMarketCreateWithoutAutoMatchesInput>,
  connect?: Maybe<BrandMarketWhereUniqueInput>,
};

export type BrandMarketCreateWithoutAutoMatchesInput = {
  id?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  sender?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  zipcode?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  detailedAddress?: Maybe<Scalars['String']>,
  credential?: Maybe<Scalars['String']>,
  isActive?: Maybe<Scalars['Boolean']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  brand: BrandCreateOneWithoutBrandMarketsInput,
  market: MarketCreateOneWithoutBrandMarketsInput,
  orders?: Maybe<OrderCreateManyWithoutBrandMarketInput>,
};

export type BrandCreateOneWithoutBrandMarketsInput = {
  create?: Maybe<BrandCreateWithoutBrandMarketsInput>,
  connect?: Maybe<BrandWhereUniqueInput>,
};

export type BrandCreateWithoutBrandMarketsInput = {
  id?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  phone?: Maybe<Scalars['String']>,
  zipcode?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  detailedAddress?: Maybe<Scalars['String']>,
  isActive?: Maybe<Scalars['Boolean']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  seller: SellerCreateOneWithoutBrandsInput,
  products?: Maybe<ProductCreateManyWithoutBrandInput>,
  courierInvoices?: Maybe<CourierInvoiceCreateManyWithoutBrandInput>,
};

export type ProductCreateManyWithoutBrandInput = {
  create?: Maybe<Array<ProductCreateWithoutBrandInput>>,
  connect?: Maybe<Array<ProductWhereUniqueInput>>,
};

export type ProductCreateWithoutBrandInput = {
  id?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  note?: Maybe<Scalars['String']>,
  isActive?: Maybe<Scalars['Boolean']>,
  hasLevel?: Maybe<Scalars['Boolean']>,
  levelOneName?: Maybe<Scalars['String']>,
  levelTwoName?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  productOptions?: Maybe<ProductOptionCreateManyWithoutProductInput>,
};

export type ProductOptionCreateManyWithoutProductInput = {
  create?: Maybe<Array<ProductOptionCreateWithoutProductInput>>,
  connect?: Maybe<Array<ProductOptionWhereUniqueInput>>,
};

export type ProductOptionCreateWithoutProductInput = {
  id?: Maybe<Scalars['String']>,
  levelOne?: Maybe<Scalars['String']>,
  levelTwo?: Maybe<Scalars['String']>,
  originNum?: Maybe<Scalars['String']>,
  originBarcode?: Maybe<Scalars['String']>,
  newBarcode?: Maybe<Scalars['String']>,
  needInspection?: Maybe<Scalars['Boolean']>,
  temperature?: Maybe<TemperatureType>,
  bestPeriodDay?: Maybe<Scalars['Int']>,
  basicCourierPriceRange: BasicCourierPriceRange,
  isActive?: Maybe<Scalars['Boolean']>,
  canBeMixed?: Maybe<Scalars['Boolean']>,
  maxAmount?: Maybe<Scalars['Int']>,
  note?: Maybe<Scalars['String']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  category?: Maybe<ProductCategoryCreateOneWithoutProductOptionsInput>,
  preStocks?: Maybe<PreStockCreateManyWithoutProductOptionInput>,
  transactions?: Maybe<TransactionCreateManyWithoutProductOptionInput>,
  autoMatches?: Maybe<AutoMatchCreateManyWithoutProductOptionInput>,
  productMatches?: Maybe<ProductMatchCreateManyWithoutProductOptionInput>,
};

export type ProductCategoryCreateOneWithoutProductOptionsInput = {
  create?: Maybe<ProductCategoryCreateWithoutProductOptionsInput>,
  connect?: Maybe<ProductCategoryWhereUniqueInput>,
};

export type ProductCategoryCreateWithoutProductOptionsInput = {
  id?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type ProductCategoryWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
};

export type PreStockCreateManyWithoutProductOptionInput = {
  create?: Maybe<Array<PreStockCreateWithoutProductOptionInput>>,
  connect?: Maybe<Array<PreStockWhereUniqueInput>>,
};

export type PreStockCreateWithoutProductOptionInput = {
  id?: Maybe<Scalars['String']>,
  amount?: Maybe<Scalars['Int']>,
  loadUnit?: Maybe<LoadUnit>,
  loadUnitAmount?: Maybe<Scalars['Int']>,
  scheduledDate: Scalars['DateTime'],
  confirmedDate?: Maybe<Scalars['DateTime']>,
  note?: Maybe<Scalars['String']>,
  manufacturedDate?: Maybe<Scalars['DateTime']>,
  expiryDate?: Maybe<Scalars['DateTime']>,
  canceledAt?: Maybe<Scalars['DateTime']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  logistic: LogisticCreateOneWithoutPreStocksInput,
  registeredUser: UserCreateOneWithoutRegisteredPreStocksInput,
  confirmedUser?: Maybe<UserCreateOneWithoutConfirmedPreStocksInput>,
  transactions?: Maybe<TransactionCreateManyWithoutPreStockInput>,
};

export type LogisticCreateOneWithoutPreStocksInput = {
  create?: Maybe<LogisticCreateWithoutPreStocksInput>,
  connect?: Maybe<LogisticWhereUniqueInput>,
};

export type LogisticCreateWithoutPreStocksInput = {
  id?: Maybe<Scalars['String']>,
  marketing?: Maybe<Scalars['Boolean']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  company: CompanyCreateOneWithoutLogisticInput,
  defaultUser: UserCreateOneWithoutLogisticdefaultOfInput,
  defaultCourier?: Maybe<LogisticCourierCreateOneWithoutLogisticDefaultOfInput>,
  sellers?: Maybe<SellerCreateManyWithoutLogisticInput>,
  availableCouriers?: Maybe<LogisticCourierCreateManyWithoutLogisticInput>,
  packets?: Maybe<PacketCreateManyWithoutLogisticInput>,
  centers?: Maybe<CenterCreateManyWithoutLogisticInput>,
  transactions?: Maybe<TransactionCreateManyWithoutLogisticInput>,
  notices?: Maybe<NoticeCreateManyWithoutLogisticInput>,
};

export type LogisticCourierCreateManyWithoutLogisticInput = {
  create?: Maybe<Array<LogisticCourierCreateWithoutLogisticInput>>,
  connect?: Maybe<Array<LogisticCourierWhereUniqueInput>>,
};

export type LogisticCourierCreateWithoutLogisticInput = {
  id?: Maybe<Scalars['String']>,
  config: Scalars['String'],
  isActive?: Maybe<Scalars['Boolean']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  courier: CourierCreateOneWithoutLogisticCouriersInput,
  logisticDefaultOf?: Maybe<LogisticCreateOneWithoutDefaultCourierInput>,
  sellerDefaultOf?: Maybe<SellerCreateManyWithoutDefaultCourierInput>,
  courierInvoices?: Maybe<CourierInvoiceCreateManyWithoutLogisticCourierInput>,
  courierInvoiceTrackings?: Maybe<CourierInvoiceTrackingCreateManyWithoutLogisticCourierInput>,
};

export type LogisticCreateOneWithoutDefaultCourierInput = {
  create?: Maybe<LogisticCreateWithoutDefaultCourierInput>,
  connect?: Maybe<LogisticWhereUniqueInput>,
};

export type LogisticCreateWithoutDefaultCourierInput = {
  id?: Maybe<Scalars['String']>,
  marketing?: Maybe<Scalars['Boolean']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  company: CompanyCreateOneWithoutLogisticInput,
  defaultUser: UserCreateOneWithoutLogisticdefaultOfInput,
  sellers?: Maybe<SellerCreateManyWithoutLogisticInput>,
  availableCouriers?: Maybe<LogisticCourierCreateManyWithoutLogisticInput>,
  packets?: Maybe<PacketCreateManyWithoutLogisticInput>,
  centers?: Maybe<CenterCreateManyWithoutLogisticInput>,
  preStocks?: Maybe<PreStockCreateManyWithoutLogisticInput>,
  transactions?: Maybe<TransactionCreateManyWithoutLogisticInput>,
  notices?: Maybe<NoticeCreateManyWithoutLogisticInput>,
};

export type PacketCreateManyWithoutLogisticInput = {
  create?: Maybe<Array<PacketCreateWithoutLogisticInput>>,
  connect?: Maybe<Array<PacketWhereUniqueInput>>,
};

export type PacketCreateWithoutLogisticInput = {
  id?: Maybe<Scalars['String']>,
  startDate: Scalars['DateTime'],
  endDate: Scalars['DateTime'],
  isPaid?: Maybe<Scalars['Boolean']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  pricing: PricingCreateOneWithoutPacketsInput,
};

export type PricingCreateOneWithoutPacketsInput = {
  create?: Maybe<PricingCreateWithoutPacketsInput>,
  connect?: Maybe<PricingWhereUniqueInput>,
};

export type PricingCreateWithoutPacketsInput = {
  id?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  count?: Maybe<Scalars['Int']>,
  price: Scalars['Int'],
  period?: Maybe<Scalars['String']>,
  description: Scalars['String'],
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type CenterCreateManyWithoutLogisticInput = {
  create?: Maybe<Array<CenterCreateWithoutLogisticInput>>,
  connect?: Maybe<Array<CenterWhereUniqueInput>>,
};

export type CenterCreateWithoutLogisticInput = {
  id?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  zipcode: Scalars['String'],
  address: Scalars['String'],
  detailedAddress?: Maybe<Scalars['String']>,
  shippingAvailable?: Maybe<Scalars['Boolean']>,
  isActive?: Maybe<Scalars['Boolean']>,
  useLocation?: Maybe<Scalars['Boolean']>,
  totalQuantity?: Maybe<Scalars['Int']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  settings?: Maybe<LocationSettingCreateManyWithoutCenterInput>,
  locations?: Maybe<LocationCreateManyWithoutCenterInput>,
};

export type LocationSettingCreateManyWithoutCenterInput = {
  create?: Maybe<Array<LocationSettingCreateWithoutCenterInput>>,
  connect?: Maybe<Array<LocationSettingWhereUniqueInput>>,
};

export type LocationSettingCreateWithoutCenterInput = {
  id?: Maybe<Scalars['String']>,
  zone: Scalars['String'],
  rack: Scalars['String'],
  level: Scalars['String'],
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  locations?: Maybe<LocationCreateManyWithoutSettingInput>,
};

export type LocationCreateManyWithoutSettingInput = {
  create?: Maybe<Array<LocationCreateWithoutSettingInput>>,
  connect?: Maybe<Array<LocationWhereUniqueInput>>,
};

export type LocationCreateWithoutSettingInput = {
  id?: Maybe<Scalars['String']>,
  zone: Scalars['String'],
  rack: Scalars['String'],
  level: Scalars['String'],
  isActive?: Maybe<Scalars['Boolean']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  center: CenterCreateOneWithoutLocationsInput,
  incomingRecords?: Maybe<TransactionCreateManyWithoutLocationInput>,
};

export type CenterCreateOneWithoutLocationsInput = {
  create?: Maybe<CenterCreateWithoutLocationsInput>,
  connect?: Maybe<CenterWhereUniqueInput>,
};

export type CenterCreateWithoutLocationsInput = {
  id?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  zipcode: Scalars['String'],
  address: Scalars['String'],
  detailedAddress?: Maybe<Scalars['String']>,
  shippingAvailable?: Maybe<Scalars['Boolean']>,
  isActive?: Maybe<Scalars['Boolean']>,
  useLocation?: Maybe<Scalars['Boolean']>,
  totalQuantity?: Maybe<Scalars['Int']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  logistic: LogisticCreateOneWithoutCentersInput,
  settings?: Maybe<LocationSettingCreateManyWithoutCenterInput>,
};

export type LogisticCreateOneWithoutCentersInput = {
  create?: Maybe<LogisticCreateWithoutCentersInput>,
  connect?: Maybe<LogisticWhereUniqueInput>,
};

export type LogisticCreateWithoutCentersInput = {
  id?: Maybe<Scalars['String']>,
  marketing?: Maybe<Scalars['Boolean']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  company: CompanyCreateOneWithoutLogisticInput,
  defaultUser: UserCreateOneWithoutLogisticdefaultOfInput,
  defaultCourier?: Maybe<LogisticCourierCreateOneWithoutLogisticDefaultOfInput>,
  sellers?: Maybe<SellerCreateManyWithoutLogisticInput>,
  availableCouriers?: Maybe<LogisticCourierCreateManyWithoutLogisticInput>,
  packets?: Maybe<PacketCreateManyWithoutLogisticInput>,
  preStocks?: Maybe<PreStockCreateManyWithoutLogisticInput>,
  transactions?: Maybe<TransactionCreateManyWithoutLogisticInput>,
  notices?: Maybe<NoticeCreateManyWithoutLogisticInput>,
};

export type PreStockCreateManyWithoutLogisticInput = {
  create?: Maybe<Array<PreStockCreateWithoutLogisticInput>>,
  connect?: Maybe<Array<PreStockWhereUniqueInput>>,
};

export type PreStockCreateWithoutLogisticInput = {
  id?: Maybe<Scalars['String']>,
  amount?: Maybe<Scalars['Int']>,
  loadUnit?: Maybe<LoadUnit>,
  loadUnitAmount?: Maybe<Scalars['Int']>,
  scheduledDate: Scalars['DateTime'],
  confirmedDate?: Maybe<Scalars['DateTime']>,
  note?: Maybe<Scalars['String']>,
  manufacturedDate?: Maybe<Scalars['DateTime']>,
  expiryDate?: Maybe<Scalars['DateTime']>,
  canceledAt?: Maybe<Scalars['DateTime']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  productOption: ProductOptionCreateOneWithoutPreStocksInput,
  registeredUser: UserCreateOneWithoutRegisteredPreStocksInput,
  confirmedUser?: Maybe<UserCreateOneWithoutConfirmedPreStocksInput>,
  transactions?: Maybe<TransactionCreateManyWithoutPreStockInput>,
};

export type ProductOptionCreateOneWithoutPreStocksInput = {
  create?: Maybe<ProductOptionCreateWithoutPreStocksInput>,
  connect?: Maybe<ProductOptionWhereUniqueInput>,
};

export type ProductOptionCreateWithoutPreStocksInput = {
  id?: Maybe<Scalars['String']>,
  levelOne?: Maybe<Scalars['String']>,
  levelTwo?: Maybe<Scalars['String']>,
  originNum?: Maybe<Scalars['String']>,
  originBarcode?: Maybe<Scalars['String']>,
  newBarcode?: Maybe<Scalars['String']>,
  needInspection?: Maybe<Scalars['Boolean']>,
  temperature?: Maybe<TemperatureType>,
  bestPeriodDay?: Maybe<Scalars['Int']>,
  basicCourierPriceRange: BasicCourierPriceRange,
  isActive?: Maybe<Scalars['Boolean']>,
  canBeMixed?: Maybe<Scalars['Boolean']>,
  maxAmount?: Maybe<Scalars['Int']>,
  note?: Maybe<Scalars['String']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  product: ProductCreateOneWithoutProductOptionsInput,
  category?: Maybe<ProductCategoryCreateOneWithoutProductOptionsInput>,
  transactions?: Maybe<TransactionCreateManyWithoutProductOptionInput>,
  autoMatches?: Maybe<AutoMatchCreateManyWithoutProductOptionInput>,
  productMatches?: Maybe<ProductMatchCreateManyWithoutProductOptionInput>,
};

export type ProductCreateOneWithoutProductOptionsInput = {
  create?: Maybe<ProductCreateWithoutProductOptionsInput>,
  connect?: Maybe<ProductWhereUniqueInput>,
};

export type ProductCreateWithoutProductOptionsInput = {
  id?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  note?: Maybe<Scalars['String']>,
  isActive?: Maybe<Scalars['Boolean']>,
  hasLevel?: Maybe<Scalars['Boolean']>,
  levelOneName?: Maybe<Scalars['String']>,
  levelTwoName?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  brand: BrandCreateOneWithoutProductsInput,
};

export type BrandCreateOneWithoutProductsInput = {
  create?: Maybe<BrandCreateWithoutProductsInput>,
  connect?: Maybe<BrandWhereUniqueInput>,
};

export type BrandCreateWithoutProductsInput = {
  id?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  phone?: Maybe<Scalars['String']>,
  zipcode?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  detailedAddress?: Maybe<Scalars['String']>,
  isActive?: Maybe<Scalars['Boolean']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  seller: SellerCreateOneWithoutBrandsInput,
  brandMarkets?: Maybe<BrandMarketCreateManyWithoutBrandInput>,
  courierInvoices?: Maybe<CourierInvoiceCreateManyWithoutBrandInput>,
};

export type CourierInvoiceCreateManyWithoutBrandInput = {
  create?: Maybe<Array<CourierInvoiceCreateWithoutBrandInput>>,
  connect?: Maybe<Array<CourierInvoiceWhereUniqueInput>>,
};

export type CourierInvoiceCreateWithoutBrandInput = {
  id?: Maybe<Scalars['String']>,
  type?: Maybe<CourierInvoiceType>,
  courierName?: Maybe<Scalars['String']>,
  num?: Maybe<Scalars['String']>,
  groupNum?: Maybe<Scalars['Int']>,
  printedNum?: Maybe<Scalars['Int']>,
  invoiceStatus?: Maybe<CourierInvoiceStatus>,
  status?: Maybe<PackageStatus>,
  sender: Scalars['String'],
  senderTel: Scalars['String'],
  senderZipcode: Scalars['String'],
  senderAddress: Scalars['String'],
  senderDetailedAddress?: Maybe<Scalars['String']>,
  receiver: Scalars['String'],
  receiverTel: Scalars['String'],
  receiverZipcode: Scalars['String'],
  receiverAddress: Scalars['String'],
  receiverDetailedAddress?: Maybe<Scalars['String']>,
  content?: Maybe<Scalars['String']>,
  message?: Maybe<Scalars['String']>,
  note?: Maybe<Scalars['String']>,
  addressRefinedDate?: Maybe<Scalars['DateTime']>,
  addressRefinedValues?: Maybe<Scalars['String']>,
  printedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  logisticCourier?: Maybe<LogisticCourierCreateOneWithoutCourierInvoicesInput>,
  parent?: Maybe<CourierInvoiceCreateOneWithoutChildrenInput>,
  courierPriceRange?: Maybe<CourierPriceRangeCreateOneWithoutCourierInvoicesInput>,
  children?: Maybe<CourierInvoiceCreateManyWithoutParentInput>,
  finalPackages?: Maybe<FinalPackageCreateManyWithoutCourierInvoiceInput>,
  returnOrders?: Maybe<ReturnOrderCreateManyWithoutCourierInvoiceInput>,
};

export type LogisticCourierCreateOneWithoutCourierInvoicesInput = {
  create?: Maybe<LogisticCourierCreateWithoutCourierInvoicesInput>,
  connect?: Maybe<LogisticCourierWhereUniqueInput>,
};

export type LogisticCourierCreateWithoutCourierInvoicesInput = {
  id?: Maybe<Scalars['String']>,
  config: Scalars['String'],
  isActive?: Maybe<Scalars['Boolean']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  logistic: LogisticCreateOneWithoutAvailableCouriersInput,
  courier: CourierCreateOneWithoutLogisticCouriersInput,
  logisticDefaultOf?: Maybe<LogisticCreateOneWithoutDefaultCourierInput>,
  sellerDefaultOf?: Maybe<SellerCreateManyWithoutDefaultCourierInput>,
  courierInvoiceTrackings?: Maybe<CourierInvoiceTrackingCreateManyWithoutLogisticCourierInput>,
};

export type SellerCreateManyWithoutDefaultCourierInput = {
  create?: Maybe<Array<SellerCreateWithoutDefaultCourierInput>>,
  connect?: Maybe<Array<SellerWhereUniqueInput>>,
};

export type SellerCreateWithoutDefaultCourierInput = {
  id?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  company: CompanyCreateOneWithoutSellerInput,
  logistic: LogisticCreateOneWithoutSellersInput,
  brands?: Maybe<BrandCreateManyWithoutSellerInput>,
  notices?: Maybe<NoticeCreateManyWithoutSellerInput>,
};

export type BrandCreateManyWithoutSellerInput = {
  create?: Maybe<Array<BrandCreateWithoutSellerInput>>,
  connect?: Maybe<Array<BrandWhereUniqueInput>>,
};

export type BrandCreateWithoutSellerInput = {
  id?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  phone?: Maybe<Scalars['String']>,
  zipcode?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  detailedAddress?: Maybe<Scalars['String']>,
  isActive?: Maybe<Scalars['Boolean']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  brandMarkets?: Maybe<BrandMarketCreateManyWithoutBrandInput>,
  products?: Maybe<ProductCreateManyWithoutBrandInput>,
  courierInvoices?: Maybe<CourierInvoiceCreateManyWithoutBrandInput>,
};

export type NoticeCreateManyWithoutSellerInput = {
  create?: Maybe<Array<NoticeCreateWithoutSellerInput>>,
  connect?: Maybe<Array<NoticeWhereUniqueInput>>,
};

export type NoticeCreateWithoutSellerInput = {
  id?: Maybe<Scalars['String']>,
  groupId: Scalars['String'],
  expiryDate?: Maybe<Scalars['DateTime']>,
  title: Scalars['String'],
  content: Scalars['String'],
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  isPublic?: Maybe<Scalars['Boolean']>,
  isImportant?: Maybe<Scalars['Boolean']>,
  logistic: LogisticCreateOneWithoutNoticesInput,
  author: UserCreateOneWithoutNoticesInput,
};

export type LogisticCreateOneWithoutNoticesInput = {
  create?: Maybe<LogisticCreateWithoutNoticesInput>,
  connect?: Maybe<LogisticWhereUniqueInput>,
};

export type LogisticCreateWithoutNoticesInput = {
  id?: Maybe<Scalars['String']>,
  marketing?: Maybe<Scalars['Boolean']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  company: CompanyCreateOneWithoutLogisticInput,
  defaultUser: UserCreateOneWithoutLogisticdefaultOfInput,
  defaultCourier?: Maybe<LogisticCourierCreateOneWithoutLogisticDefaultOfInput>,
  sellers?: Maybe<SellerCreateManyWithoutLogisticInput>,
  availableCouriers?: Maybe<LogisticCourierCreateManyWithoutLogisticInput>,
  packets?: Maybe<PacketCreateManyWithoutLogisticInput>,
  centers?: Maybe<CenterCreateManyWithoutLogisticInput>,
  preStocks?: Maybe<PreStockCreateManyWithoutLogisticInput>,
  transactions?: Maybe<TransactionCreateManyWithoutLogisticInput>,
};

export type TransactionCreateManyWithoutLogisticInput = {
  create?: Maybe<Array<TransactionCreateWithoutLogisticInput>>,
  connect?: Maybe<Array<TransactionWhereUniqueInput>>,
};

export type TransactionCreateWithoutLogisticInput = {
  id?: Maybe<Scalars['String']>,
  manufacturedDate?: Maybe<Scalars['DateTime']>,
  expiryDate?: Maybe<Scalars['DateTime']>,
  eventType?: Maybe<EventType>,
  transportType?: Maybe<TransportType>,
  usableAmount?: Maybe<Scalars['Int']>,
  brokenAmount?: Maybe<Scalars['Int']>,
  note?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  preStock?: Maybe<PreStockCreateOneWithoutTransactionsInput>,
  parent?: Maybe<TransactionCreateOneWithoutChildrenInput>,
  location?: Maybe<LocationCreateOneWithoutIncomingRecordsInput>,
  productOption: ProductOptionCreateOneWithoutTransactionsInput,
  confirmedUser?: Maybe<UserCreateOneWithoutConfirmedTransactionsInput>,
  finalPackage?: Maybe<FinalPackageCreateOneWithoutTransactionsInput>,
  stocktaking?: Maybe<StocktakingCreateOneWithoutTransactionsInput>,
  children?: Maybe<TransactionCreateManyWithoutParentInput>,
  stocktakings?: Maybe<StocktakingCreateManyWithoutBaseTransactionInput>,
};

export type PreStockCreateOneWithoutTransactionsInput = {
  create?: Maybe<PreStockCreateWithoutTransactionsInput>,
  connect?: Maybe<PreStockWhereUniqueInput>,
};

export type PreStockCreateWithoutTransactionsInput = {
  id?: Maybe<Scalars['String']>,
  amount?: Maybe<Scalars['Int']>,
  loadUnit?: Maybe<LoadUnit>,
  loadUnitAmount?: Maybe<Scalars['Int']>,
  scheduledDate: Scalars['DateTime'],
  confirmedDate?: Maybe<Scalars['DateTime']>,
  note?: Maybe<Scalars['String']>,
  manufacturedDate?: Maybe<Scalars['DateTime']>,
  expiryDate?: Maybe<Scalars['DateTime']>,
  canceledAt?: Maybe<Scalars['DateTime']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  logistic: LogisticCreateOneWithoutPreStocksInput,
  productOption: ProductOptionCreateOneWithoutPreStocksInput,
  registeredUser: UserCreateOneWithoutRegisteredPreStocksInput,
  confirmedUser?: Maybe<UserCreateOneWithoutConfirmedPreStocksInput>,
};

export type UserCreateOneWithoutRegisteredPreStocksInput = {
  create?: Maybe<UserCreateWithoutRegisteredPreStocksInput>,
  connect?: Maybe<UserWhereUniqueInput>,
};

export type UserCreateWithoutRegisteredPreStocksInput = {
  id?: Maybe<Scalars['String']>,
  email: Scalars['String'],
  emailVerified?: Maybe<Scalars['Boolean']>,
  type?: Maybe<CompanyType>,
  password: Scalars['String'],
  name?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  role?: Maybe<RoleType>,
  marketing?: Maybe<Scalars['Boolean']>,
  settings?: Maybe<Scalars['Json']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  company: CompanyCreateOneWithoutUsersInput,
  team?: Maybe<TeamCreateOneWithoutUsersInput>,
  confirmedTransactions?: Maybe<TransactionCreateManyWithoutConfirmedUserInput>,
  confirmedPreStocks?: Maybe<PreStockCreateManyWithoutConfirmedUserInput>,
  confirmedFinalPackages?: Maybe<FinalPackageCreateManyWithoutConfirmedUserInput>,
  confirmedStocktaking?: Maybe<StocktakingCreateManyWithoutConfirmedUserInput>,
  confirmedReturnOrder?: Maybe<ReturnOrderCreateManyWithoutConfirmedUserInput>,
  logisticdefaultOf?: Maybe<LogisticCreateManyWithoutDefaultUserInput>,
  logs?: Maybe<LogCreateManyWithoutUserInput>,
  orderNotes?: Maybe<OrderNoteCreateManyWithoutWriterInput>,
  confirmedOrderNotes?: Maybe<OrderNoteCreateManyWithoutConfirmedUserInput>,
  notices?: Maybe<NoticeCreateManyWithoutAuthorInput>,
};

export type PreStockCreateManyWithoutConfirmedUserInput = {
  create?: Maybe<Array<PreStockCreateWithoutConfirmedUserInput>>,
  connect?: Maybe<Array<PreStockWhereUniqueInput>>,
};

export type PreStockCreateWithoutConfirmedUserInput = {
  id?: Maybe<Scalars['String']>,
  amount?: Maybe<Scalars['Int']>,
  loadUnit?: Maybe<LoadUnit>,
  loadUnitAmount?: Maybe<Scalars['Int']>,
  scheduledDate: Scalars['DateTime'],
  confirmedDate?: Maybe<Scalars['DateTime']>,
  note?: Maybe<Scalars['String']>,
  manufacturedDate?: Maybe<Scalars['DateTime']>,
  expiryDate?: Maybe<Scalars['DateTime']>,
  canceledAt?: Maybe<Scalars['DateTime']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  logistic: LogisticCreateOneWithoutPreStocksInput,
  productOption: ProductOptionCreateOneWithoutPreStocksInput,
  registeredUser: UserCreateOneWithoutRegisteredPreStocksInput,
  transactions?: Maybe<TransactionCreateManyWithoutPreStockInput>,
};

export type TransactionCreateManyWithoutPreStockInput = {
  create?: Maybe<Array<TransactionCreateWithoutPreStockInput>>,
  connect?: Maybe<Array<TransactionWhereUniqueInput>>,
};

export type TransactionCreateWithoutPreStockInput = {
  id?: Maybe<Scalars['String']>,
  manufacturedDate?: Maybe<Scalars['DateTime']>,
  expiryDate?: Maybe<Scalars['DateTime']>,
  eventType?: Maybe<EventType>,
  transportType?: Maybe<TransportType>,
  usableAmount?: Maybe<Scalars['Int']>,
  brokenAmount?: Maybe<Scalars['Int']>,
  note?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  logistic: LogisticCreateOneWithoutTransactionsInput,
  parent?: Maybe<TransactionCreateOneWithoutChildrenInput>,
  location?: Maybe<LocationCreateOneWithoutIncomingRecordsInput>,
  productOption: ProductOptionCreateOneWithoutTransactionsInput,
  confirmedUser?: Maybe<UserCreateOneWithoutConfirmedTransactionsInput>,
  finalPackage?: Maybe<FinalPackageCreateOneWithoutTransactionsInput>,
  stocktaking?: Maybe<StocktakingCreateOneWithoutTransactionsInput>,
  children?: Maybe<TransactionCreateManyWithoutParentInput>,
  stocktakings?: Maybe<StocktakingCreateManyWithoutBaseTransactionInput>,
};

export type TransactionCreateOneWithoutChildrenInput = {
  create?: Maybe<TransactionCreateWithoutChildrenInput>,
  connect?: Maybe<TransactionWhereUniqueInput>,
};

export type TransactionCreateWithoutChildrenInput = {
  id?: Maybe<Scalars['String']>,
  manufacturedDate?: Maybe<Scalars['DateTime']>,
  expiryDate?: Maybe<Scalars['DateTime']>,
  eventType?: Maybe<EventType>,
  transportType?: Maybe<TransportType>,
  usableAmount?: Maybe<Scalars['Int']>,
  brokenAmount?: Maybe<Scalars['Int']>,
  note?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  logistic: LogisticCreateOneWithoutTransactionsInput,
  preStock?: Maybe<PreStockCreateOneWithoutTransactionsInput>,
  parent?: Maybe<TransactionCreateOneWithoutChildrenInput>,
  location?: Maybe<LocationCreateOneWithoutIncomingRecordsInput>,
  productOption: ProductOptionCreateOneWithoutTransactionsInput,
  confirmedUser?: Maybe<UserCreateOneWithoutConfirmedTransactionsInput>,
  finalPackage?: Maybe<FinalPackageCreateOneWithoutTransactionsInput>,
  stocktaking?: Maybe<StocktakingCreateOneWithoutTransactionsInput>,
  stocktakings?: Maybe<StocktakingCreateManyWithoutBaseTransactionInput>,
};

export type LocationCreateOneWithoutIncomingRecordsInput = {
  create?: Maybe<LocationCreateWithoutIncomingRecordsInput>,
  connect?: Maybe<LocationWhereUniqueInput>,
};

export type LocationCreateWithoutIncomingRecordsInput = {
  id?: Maybe<Scalars['String']>,
  zone: Scalars['String'],
  rack: Scalars['String'],
  level: Scalars['String'],
  isActive?: Maybe<Scalars['Boolean']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  center: CenterCreateOneWithoutLocationsInput,
  setting: LocationSettingCreateOneWithoutLocationsInput,
};

export type LocationSettingCreateOneWithoutLocationsInput = {
  create?: Maybe<LocationSettingCreateWithoutLocationsInput>,
  connect?: Maybe<LocationSettingWhereUniqueInput>,
};

export type LocationSettingCreateWithoutLocationsInput = {
  id?: Maybe<Scalars['String']>,
  zone: Scalars['String'],
  rack: Scalars['String'],
  level: Scalars['String'],
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  center: CenterCreateOneWithoutSettingsInput,
};

export type CenterCreateOneWithoutSettingsInput = {
  create?: Maybe<CenterCreateWithoutSettingsInput>,
  connect?: Maybe<CenterWhereUniqueInput>,
};

export type CenterCreateWithoutSettingsInput = {
  id?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  zipcode: Scalars['String'],
  address: Scalars['String'],
  detailedAddress?: Maybe<Scalars['String']>,
  shippingAvailable?: Maybe<Scalars['Boolean']>,
  isActive?: Maybe<Scalars['Boolean']>,
  useLocation?: Maybe<Scalars['Boolean']>,
  totalQuantity?: Maybe<Scalars['Int']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  logistic: LogisticCreateOneWithoutCentersInput,
  locations?: Maybe<LocationCreateManyWithoutCenterInput>,
};

export type LocationCreateManyWithoutCenterInput = {
  create?: Maybe<Array<LocationCreateWithoutCenterInput>>,
  connect?: Maybe<Array<LocationWhereUniqueInput>>,
};

export type LocationCreateWithoutCenterInput = {
  id?: Maybe<Scalars['String']>,
  zone: Scalars['String'],
  rack: Scalars['String'],
  level: Scalars['String'],
  isActive?: Maybe<Scalars['Boolean']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  setting: LocationSettingCreateOneWithoutLocationsInput,
  incomingRecords?: Maybe<TransactionCreateManyWithoutLocationInput>,
};

export type TransactionCreateManyWithoutLocationInput = {
  create?: Maybe<Array<TransactionCreateWithoutLocationInput>>,
  connect?: Maybe<Array<TransactionWhereUniqueInput>>,
};

export type TransactionCreateWithoutLocationInput = {
  id?: Maybe<Scalars['String']>,
  manufacturedDate?: Maybe<Scalars['DateTime']>,
  expiryDate?: Maybe<Scalars['DateTime']>,
  eventType?: Maybe<EventType>,
  transportType?: Maybe<TransportType>,
  usableAmount?: Maybe<Scalars['Int']>,
  brokenAmount?: Maybe<Scalars['Int']>,
  note?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  logistic: LogisticCreateOneWithoutTransactionsInput,
  preStock?: Maybe<PreStockCreateOneWithoutTransactionsInput>,
  parent?: Maybe<TransactionCreateOneWithoutChildrenInput>,
  productOption: ProductOptionCreateOneWithoutTransactionsInput,
  confirmedUser?: Maybe<UserCreateOneWithoutConfirmedTransactionsInput>,
  finalPackage?: Maybe<FinalPackageCreateOneWithoutTransactionsInput>,
  stocktaking?: Maybe<StocktakingCreateOneWithoutTransactionsInput>,
  children?: Maybe<TransactionCreateManyWithoutParentInput>,
  stocktakings?: Maybe<StocktakingCreateManyWithoutBaseTransactionInput>,
};

export type ProductOptionCreateOneWithoutTransactionsInput = {
  create?: Maybe<ProductOptionCreateWithoutTransactionsInput>,
  connect?: Maybe<ProductOptionWhereUniqueInput>,
};

export type ProductOptionCreateWithoutTransactionsInput = {
  id?: Maybe<Scalars['String']>,
  levelOne?: Maybe<Scalars['String']>,
  levelTwo?: Maybe<Scalars['String']>,
  originNum?: Maybe<Scalars['String']>,
  originBarcode?: Maybe<Scalars['String']>,
  newBarcode?: Maybe<Scalars['String']>,
  needInspection?: Maybe<Scalars['Boolean']>,
  temperature?: Maybe<TemperatureType>,
  bestPeriodDay?: Maybe<Scalars['Int']>,
  basicCourierPriceRange: BasicCourierPriceRange,
  isActive?: Maybe<Scalars['Boolean']>,
  canBeMixed?: Maybe<Scalars['Boolean']>,
  maxAmount?: Maybe<Scalars['Int']>,
  note?: Maybe<Scalars['String']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  product: ProductCreateOneWithoutProductOptionsInput,
  category?: Maybe<ProductCategoryCreateOneWithoutProductOptionsInput>,
  preStocks?: Maybe<PreStockCreateManyWithoutProductOptionInput>,
  autoMatches?: Maybe<AutoMatchCreateManyWithoutProductOptionInput>,
  productMatches?: Maybe<ProductMatchCreateManyWithoutProductOptionInput>,
};

export type AutoMatchCreateManyWithoutProductOptionInput = {
  create?: Maybe<Array<AutoMatchCreateWithoutProductOptionInput>>,
  connect?: Maybe<Array<AutoMatchWhereUniqueInput>>,
};

export type AutoMatchCreateWithoutProductOptionInput = {
  id?: Maybe<Scalars['String']>,
  marketProductNum?: Maybe<Scalars['String']>,
  marketProductName: Scalars['String'],
  marketProductOptionNum?: Maybe<Scalars['String']>,
  marketProductOptionName?: Maybe<Scalars['String']>,
  marketProductOptionAmount?: Maybe<Scalars['Int']>,
  productAmount?: Maybe<Scalars['Int']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  brandMarket: BrandMarketCreateOneWithoutAutoMatchesInput,
  productMatches?: Maybe<ProductMatchCreateManyWithoutAutoMatchInput>,
};

export type ProductMatchCreateManyWithoutAutoMatchInput = {
  create?: Maybe<Array<ProductMatchCreateWithoutAutoMatchInput>>,
  connect?: Maybe<Array<ProductMatchWhereUniqueInput>>,
};

export type ProductMatchCreateWithoutAutoMatchInput = {
  id?: Maybe<Scalars['String']>,
  amount?: Maybe<Scalars['Int']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  marketProduct: MarketProductCreateOneWithoutProductMatchesInput,
  productOption: ProductOptionCreateOneWithoutProductMatchesInput,
  finalPackages?: Maybe<FinalPackageCreateManyWithoutProductMatchInput>,
};

export type MarketProductCreateOneWithoutProductMatchesInput = {
  create?: Maybe<MarketProductCreateWithoutProductMatchesInput>,
  connect?: Maybe<MarketProductWhereUniqueInput>,
};

export type MarketProductCreateWithoutProductMatchesInput = {
  id?: Maybe<Scalars['String']>,
  num?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  optionNum?: Maybe<Scalars['String']>,
  optionName?: Maybe<Scalars['String']>,
  amount?: Maybe<Scalars['Int']>,
  totalPrice?: Maybe<Scalars['Int']>,
  status?: Maybe<MarketProductStatus>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  order: OrderCreateOneWithoutMarketProductsInput,
};

export type OrderCreateOneWithoutMarketProductsInput = {
  create?: Maybe<OrderCreateWithoutMarketProductsInput>,
  connect?: Maybe<OrderWhereUniqueInput>,
};

export type OrderCreateWithoutMarketProductsInput = {
  id?: Maybe<Scalars['String']>,
  num: Scalars['String'],
  marketOrderNum: Scalars['String'],
  orderedDate?: Maybe<Scalars['DateTime']>,
  paidDate?: Maybe<Scalars['DateTime']>,
  sender?: Maybe<Scalars['String']>,
  senderTel?: Maybe<Scalars['String']>,
  senderTel2?: Maybe<Scalars['String']>,
  receiver: Scalars['String'],
  receiverTel: Scalars['String'],
  receiverTel2?: Maybe<Scalars['String']>,
  receiverZipcode?: Maybe<Scalars['String']>,
  receiverAddress: Scalars['String'],
  receiverDetailedAddress?: Maybe<Scalars['String']>,
  message?: Maybe<Scalars['String']>,
  deliveryPaid?: Maybe<Scalars['Boolean']>,
  deliveryPrice?: Maybe<Scalars['Int']>,
  isOverseas?: Maybe<Scalars['Boolean']>,
  remoteAreaPrice?: Maybe<Scalars['Int']>,
  detailedOrder?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  brandMarket: BrandMarketCreateOneWithoutOrdersInput,
  orderNotes?: Maybe<OrderNoteCreateManyWithoutOrderInput>,
};

export type BrandMarketCreateOneWithoutOrdersInput = {
  create?: Maybe<BrandMarketCreateWithoutOrdersInput>,
  connect?: Maybe<BrandMarketWhereUniqueInput>,
};

export type BrandMarketCreateWithoutOrdersInput = {
  id?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  sender?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  zipcode?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  detailedAddress?: Maybe<Scalars['String']>,
  credential?: Maybe<Scalars['String']>,
  isActive?: Maybe<Scalars['Boolean']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  brand: BrandCreateOneWithoutBrandMarketsInput,
  market: MarketCreateOneWithoutBrandMarketsInput,
  autoMatches?: Maybe<AutoMatchCreateManyWithoutBrandMarketInput>,
};

export type AutoMatchCreateManyWithoutBrandMarketInput = {
  create?: Maybe<Array<AutoMatchCreateWithoutBrandMarketInput>>,
  connect?: Maybe<Array<AutoMatchWhereUniqueInput>>,
};

export type AutoMatchCreateWithoutBrandMarketInput = {
  id?: Maybe<Scalars['String']>,
  marketProductNum?: Maybe<Scalars['String']>,
  marketProductName: Scalars['String'],
  marketProductOptionNum?: Maybe<Scalars['String']>,
  marketProductOptionName?: Maybe<Scalars['String']>,
  marketProductOptionAmount?: Maybe<Scalars['Int']>,
  productAmount?: Maybe<Scalars['Int']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  productOption: ProductOptionCreateOneWithoutAutoMatchesInput,
  productMatches?: Maybe<ProductMatchCreateManyWithoutAutoMatchInput>,
};

export type ProductOptionCreateOneWithoutAutoMatchesInput = {
  create?: Maybe<ProductOptionCreateWithoutAutoMatchesInput>,
  connect?: Maybe<ProductOptionWhereUniqueInput>,
};

export type ProductOptionCreateWithoutAutoMatchesInput = {
  id?: Maybe<Scalars['String']>,
  levelOne?: Maybe<Scalars['String']>,
  levelTwo?: Maybe<Scalars['String']>,
  originNum?: Maybe<Scalars['String']>,
  originBarcode?: Maybe<Scalars['String']>,
  newBarcode?: Maybe<Scalars['String']>,
  needInspection?: Maybe<Scalars['Boolean']>,
  temperature?: Maybe<TemperatureType>,
  bestPeriodDay?: Maybe<Scalars['Int']>,
  basicCourierPriceRange: BasicCourierPriceRange,
  isActive?: Maybe<Scalars['Boolean']>,
  canBeMixed?: Maybe<Scalars['Boolean']>,
  maxAmount?: Maybe<Scalars['Int']>,
  note?: Maybe<Scalars['String']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  product: ProductCreateOneWithoutProductOptionsInput,
  category?: Maybe<ProductCategoryCreateOneWithoutProductOptionsInput>,
  preStocks?: Maybe<PreStockCreateManyWithoutProductOptionInput>,
  transactions?: Maybe<TransactionCreateManyWithoutProductOptionInput>,
  productMatches?: Maybe<ProductMatchCreateManyWithoutProductOptionInput>,
};

export type TransactionCreateManyWithoutProductOptionInput = {
  create?: Maybe<Array<TransactionCreateWithoutProductOptionInput>>,
  connect?: Maybe<Array<TransactionWhereUniqueInput>>,
};

export type TransactionCreateWithoutProductOptionInput = {
  id?: Maybe<Scalars['String']>,
  manufacturedDate?: Maybe<Scalars['DateTime']>,
  expiryDate?: Maybe<Scalars['DateTime']>,
  eventType?: Maybe<EventType>,
  transportType?: Maybe<TransportType>,
  usableAmount?: Maybe<Scalars['Int']>,
  brokenAmount?: Maybe<Scalars['Int']>,
  note?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  logistic: LogisticCreateOneWithoutTransactionsInput,
  preStock?: Maybe<PreStockCreateOneWithoutTransactionsInput>,
  parent?: Maybe<TransactionCreateOneWithoutChildrenInput>,
  location?: Maybe<LocationCreateOneWithoutIncomingRecordsInput>,
  confirmedUser?: Maybe<UserCreateOneWithoutConfirmedTransactionsInput>,
  finalPackage?: Maybe<FinalPackageCreateOneWithoutTransactionsInput>,
  stocktaking?: Maybe<StocktakingCreateOneWithoutTransactionsInput>,
  children?: Maybe<TransactionCreateManyWithoutParentInput>,
  stocktakings?: Maybe<StocktakingCreateManyWithoutBaseTransactionInput>,
};

export type UserCreateOneWithoutConfirmedTransactionsInput = {
  create?: Maybe<UserCreateWithoutConfirmedTransactionsInput>,
  connect?: Maybe<UserWhereUniqueInput>,
};

export type UserCreateWithoutConfirmedTransactionsInput = {
  id?: Maybe<Scalars['String']>,
  email: Scalars['String'],
  emailVerified?: Maybe<Scalars['Boolean']>,
  type?: Maybe<CompanyType>,
  password: Scalars['String'],
  name?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  role?: Maybe<RoleType>,
  marketing?: Maybe<Scalars['Boolean']>,
  settings?: Maybe<Scalars['Json']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  company: CompanyCreateOneWithoutUsersInput,
  team?: Maybe<TeamCreateOneWithoutUsersInput>,
  registeredPreStocks?: Maybe<PreStockCreateManyWithoutRegisteredUserInput>,
  confirmedPreStocks?: Maybe<PreStockCreateManyWithoutConfirmedUserInput>,
  confirmedFinalPackages?: Maybe<FinalPackageCreateManyWithoutConfirmedUserInput>,
  confirmedStocktaking?: Maybe<StocktakingCreateManyWithoutConfirmedUserInput>,
  confirmedReturnOrder?: Maybe<ReturnOrderCreateManyWithoutConfirmedUserInput>,
  logisticdefaultOf?: Maybe<LogisticCreateManyWithoutDefaultUserInput>,
  logs?: Maybe<LogCreateManyWithoutUserInput>,
  orderNotes?: Maybe<OrderNoteCreateManyWithoutWriterInput>,
  confirmedOrderNotes?: Maybe<OrderNoteCreateManyWithoutConfirmedUserInput>,
  notices?: Maybe<NoticeCreateManyWithoutAuthorInput>,
};

export type PreStockCreateManyWithoutRegisteredUserInput = {
  create?: Maybe<Array<PreStockCreateWithoutRegisteredUserInput>>,
  connect?: Maybe<Array<PreStockWhereUniqueInput>>,
};

export type PreStockCreateWithoutRegisteredUserInput = {
  id?: Maybe<Scalars['String']>,
  amount?: Maybe<Scalars['Int']>,
  loadUnit?: Maybe<LoadUnit>,
  loadUnitAmount?: Maybe<Scalars['Int']>,
  scheduledDate: Scalars['DateTime'],
  confirmedDate?: Maybe<Scalars['DateTime']>,
  note?: Maybe<Scalars['String']>,
  manufacturedDate?: Maybe<Scalars['DateTime']>,
  expiryDate?: Maybe<Scalars['DateTime']>,
  canceledAt?: Maybe<Scalars['DateTime']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  logistic: LogisticCreateOneWithoutPreStocksInput,
  productOption: ProductOptionCreateOneWithoutPreStocksInput,
  confirmedUser?: Maybe<UserCreateOneWithoutConfirmedPreStocksInput>,
  transactions?: Maybe<TransactionCreateManyWithoutPreStockInput>,
};

export type UserCreateOneWithoutConfirmedPreStocksInput = {
  create?: Maybe<UserCreateWithoutConfirmedPreStocksInput>,
  connect?: Maybe<UserWhereUniqueInput>,
};

export type UserCreateWithoutConfirmedPreStocksInput = {
  id?: Maybe<Scalars['String']>,
  email: Scalars['String'],
  emailVerified?: Maybe<Scalars['Boolean']>,
  type?: Maybe<CompanyType>,
  password: Scalars['String'],
  name?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  role?: Maybe<RoleType>,
  marketing?: Maybe<Scalars['Boolean']>,
  settings?: Maybe<Scalars['Json']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  company: CompanyCreateOneWithoutUsersInput,
  team?: Maybe<TeamCreateOneWithoutUsersInput>,
  confirmedTransactions?: Maybe<TransactionCreateManyWithoutConfirmedUserInput>,
  registeredPreStocks?: Maybe<PreStockCreateManyWithoutRegisteredUserInput>,
  confirmedFinalPackages?: Maybe<FinalPackageCreateManyWithoutConfirmedUserInput>,
  confirmedStocktaking?: Maybe<StocktakingCreateManyWithoutConfirmedUserInput>,
  confirmedReturnOrder?: Maybe<ReturnOrderCreateManyWithoutConfirmedUserInput>,
  logisticdefaultOf?: Maybe<LogisticCreateManyWithoutDefaultUserInput>,
  logs?: Maybe<LogCreateManyWithoutUserInput>,
  orderNotes?: Maybe<OrderNoteCreateManyWithoutWriterInput>,
  confirmedOrderNotes?: Maybe<OrderNoteCreateManyWithoutConfirmedUserInput>,
  notices?: Maybe<NoticeCreateManyWithoutAuthorInput>,
};

export type FinalPackageCreateManyWithoutConfirmedUserInput = {
  create?: Maybe<Array<FinalPackageCreateWithoutConfirmedUserInput>>,
  connect?: Maybe<Array<FinalPackageWhereUniqueInput>>,
};

export type FinalPackageCreateWithoutConfirmedUserInput = {
  id?: Maybe<Scalars['String']>,
  amount: Scalars['Int'],
  status?: Maybe<FinalPackageStatus>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  courierInvoice: CourierInvoiceCreateOneWithoutFinalPackagesInput,
  productMatch: ProductMatchCreateOneWithoutFinalPackagesInput,
  returnOrderProducts?: Maybe<ReturnOrderProductCreateManyWithoutFinalPackageInput>,
  transactions?: Maybe<TransactionCreateManyWithoutFinalPackageInput>,
};

export type CourierInvoiceCreateOneWithoutFinalPackagesInput = {
  create?: Maybe<CourierInvoiceCreateWithoutFinalPackagesInput>,
  connect?: Maybe<CourierInvoiceWhereUniqueInput>,
};

export type CourierInvoiceCreateWithoutFinalPackagesInput = {
  id?: Maybe<Scalars['String']>,
  type?: Maybe<CourierInvoiceType>,
  courierName?: Maybe<Scalars['String']>,
  num?: Maybe<Scalars['String']>,
  groupNum?: Maybe<Scalars['Int']>,
  printedNum?: Maybe<Scalars['Int']>,
  invoiceStatus?: Maybe<CourierInvoiceStatus>,
  status?: Maybe<PackageStatus>,
  sender: Scalars['String'],
  senderTel: Scalars['String'],
  senderZipcode: Scalars['String'],
  senderAddress: Scalars['String'],
  senderDetailedAddress?: Maybe<Scalars['String']>,
  receiver: Scalars['String'],
  receiverTel: Scalars['String'],
  receiverZipcode: Scalars['String'],
  receiverAddress: Scalars['String'],
  receiverDetailedAddress?: Maybe<Scalars['String']>,
  content?: Maybe<Scalars['String']>,
  message?: Maybe<Scalars['String']>,
  note?: Maybe<Scalars['String']>,
  addressRefinedDate?: Maybe<Scalars['DateTime']>,
  addressRefinedValues?: Maybe<Scalars['String']>,
  printedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  brand: BrandCreateOneWithoutCourierInvoicesInput,
  logisticCourier?: Maybe<LogisticCourierCreateOneWithoutCourierInvoicesInput>,
  parent?: Maybe<CourierInvoiceCreateOneWithoutChildrenInput>,
  courierPriceRange?: Maybe<CourierPriceRangeCreateOneWithoutCourierInvoicesInput>,
  children?: Maybe<CourierInvoiceCreateManyWithoutParentInput>,
  returnOrders?: Maybe<ReturnOrderCreateManyWithoutCourierInvoiceInput>,
};

export type CourierInvoiceCreateOneWithoutChildrenInput = {
  create?: Maybe<CourierInvoiceCreateWithoutChildrenInput>,
  connect?: Maybe<CourierInvoiceWhereUniqueInput>,
};

export type CourierInvoiceCreateWithoutChildrenInput = {
  id?: Maybe<Scalars['String']>,
  type?: Maybe<CourierInvoiceType>,
  courierName?: Maybe<Scalars['String']>,
  num?: Maybe<Scalars['String']>,
  groupNum?: Maybe<Scalars['Int']>,
  printedNum?: Maybe<Scalars['Int']>,
  invoiceStatus?: Maybe<CourierInvoiceStatus>,
  status?: Maybe<PackageStatus>,
  sender: Scalars['String'],
  senderTel: Scalars['String'],
  senderZipcode: Scalars['String'],
  senderAddress: Scalars['String'],
  senderDetailedAddress?: Maybe<Scalars['String']>,
  receiver: Scalars['String'],
  receiverTel: Scalars['String'],
  receiverZipcode: Scalars['String'],
  receiverAddress: Scalars['String'],
  receiverDetailedAddress?: Maybe<Scalars['String']>,
  content?: Maybe<Scalars['String']>,
  message?: Maybe<Scalars['String']>,
  note?: Maybe<Scalars['String']>,
  addressRefinedDate?: Maybe<Scalars['DateTime']>,
  addressRefinedValues?: Maybe<Scalars['String']>,
  printedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  brand: BrandCreateOneWithoutCourierInvoicesInput,
  logisticCourier?: Maybe<LogisticCourierCreateOneWithoutCourierInvoicesInput>,
  parent?: Maybe<CourierInvoiceCreateOneWithoutChildrenInput>,
  courierPriceRange?: Maybe<CourierPriceRangeCreateOneWithoutCourierInvoicesInput>,
  finalPackages?: Maybe<FinalPackageCreateManyWithoutCourierInvoiceInput>,
  returnOrders?: Maybe<ReturnOrderCreateManyWithoutCourierInvoiceInput>,
};

export type CourierPriceRangeCreateOneWithoutCourierInvoicesInput = {
  create?: Maybe<CourierPriceRangeCreateWithoutCourierInvoicesInput>,
  connect?: Maybe<CourierPriceRangeWhereUniqueInput>,
};

export type CourierPriceRangeCreateWithoutCourierInvoicesInput = {
  id?: Maybe<Scalars['String']>,
  basic?: Maybe<BasicCourierPriceRange>,
  name: Scalars['String'],
  value?: Maybe<Scalars['String']>,
  price: Scalars['Int'],
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  courier: CourierCreateOneWithoutPriceRangesInput,
};

export type CourierCreateOneWithoutPriceRangesInput = {
  create?: Maybe<CourierCreateWithoutPriceRangesInput>,
  connect?: Maybe<CourierWhereUniqueInput>,
};

export type CourierCreateWithoutPriceRangesInput = {
  id?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  configFields: Scalars['String'],
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  logisticCouriers?: Maybe<LogisticCourierCreateManyWithoutCourierInput>,
};

export type LogisticCourierCreateManyWithoutCourierInput = {
  create?: Maybe<Array<LogisticCourierCreateWithoutCourierInput>>,
  connect?: Maybe<Array<LogisticCourierWhereUniqueInput>>,
};

export type LogisticCourierCreateWithoutCourierInput = {
  id?: Maybe<Scalars['String']>,
  config: Scalars['String'],
  isActive?: Maybe<Scalars['Boolean']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  logistic: LogisticCreateOneWithoutAvailableCouriersInput,
  logisticDefaultOf?: Maybe<LogisticCreateOneWithoutDefaultCourierInput>,
  sellerDefaultOf?: Maybe<SellerCreateManyWithoutDefaultCourierInput>,
  courierInvoices?: Maybe<CourierInvoiceCreateManyWithoutLogisticCourierInput>,
  courierInvoiceTrackings?: Maybe<CourierInvoiceTrackingCreateManyWithoutLogisticCourierInput>,
};

export type CourierInvoiceCreateManyWithoutLogisticCourierInput = {
  create?: Maybe<Array<CourierInvoiceCreateWithoutLogisticCourierInput>>,
  connect?: Maybe<Array<CourierInvoiceWhereUniqueInput>>,
};

export type CourierInvoiceCreateWithoutLogisticCourierInput = {
  id?: Maybe<Scalars['String']>,
  type?: Maybe<CourierInvoiceType>,
  courierName?: Maybe<Scalars['String']>,
  num?: Maybe<Scalars['String']>,
  groupNum?: Maybe<Scalars['Int']>,
  printedNum?: Maybe<Scalars['Int']>,
  invoiceStatus?: Maybe<CourierInvoiceStatus>,
  status?: Maybe<PackageStatus>,
  sender: Scalars['String'],
  senderTel: Scalars['String'],
  senderZipcode: Scalars['String'],
  senderAddress: Scalars['String'],
  senderDetailedAddress?: Maybe<Scalars['String']>,
  receiver: Scalars['String'],
  receiverTel: Scalars['String'],
  receiverZipcode: Scalars['String'],
  receiverAddress: Scalars['String'],
  receiverDetailedAddress?: Maybe<Scalars['String']>,
  content?: Maybe<Scalars['String']>,
  message?: Maybe<Scalars['String']>,
  note?: Maybe<Scalars['String']>,
  addressRefinedDate?: Maybe<Scalars['DateTime']>,
  addressRefinedValues?: Maybe<Scalars['String']>,
  printedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  brand: BrandCreateOneWithoutCourierInvoicesInput,
  parent?: Maybe<CourierInvoiceCreateOneWithoutChildrenInput>,
  courierPriceRange?: Maybe<CourierPriceRangeCreateOneWithoutCourierInvoicesInput>,
  children?: Maybe<CourierInvoiceCreateManyWithoutParentInput>,
  finalPackages?: Maybe<FinalPackageCreateManyWithoutCourierInvoiceInput>,
  returnOrders?: Maybe<ReturnOrderCreateManyWithoutCourierInvoiceInput>,
};

export type CourierInvoiceCreateManyWithoutParentInput = {
  create?: Maybe<Array<CourierInvoiceCreateWithoutParentInput>>,
  connect?: Maybe<Array<CourierInvoiceWhereUniqueInput>>,
};

export type CourierInvoiceCreateWithoutParentInput = {
  id?: Maybe<Scalars['String']>,
  type?: Maybe<CourierInvoiceType>,
  courierName?: Maybe<Scalars['String']>,
  num?: Maybe<Scalars['String']>,
  groupNum?: Maybe<Scalars['Int']>,
  printedNum?: Maybe<Scalars['Int']>,
  invoiceStatus?: Maybe<CourierInvoiceStatus>,
  status?: Maybe<PackageStatus>,
  sender: Scalars['String'],
  senderTel: Scalars['String'],
  senderZipcode: Scalars['String'],
  senderAddress: Scalars['String'],
  senderDetailedAddress?: Maybe<Scalars['String']>,
  receiver: Scalars['String'],
  receiverTel: Scalars['String'],
  receiverZipcode: Scalars['String'],
  receiverAddress: Scalars['String'],
  receiverDetailedAddress?: Maybe<Scalars['String']>,
  content?: Maybe<Scalars['String']>,
  message?: Maybe<Scalars['String']>,
  note?: Maybe<Scalars['String']>,
  addressRefinedDate?: Maybe<Scalars['DateTime']>,
  addressRefinedValues?: Maybe<Scalars['String']>,
  printedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  brand: BrandCreateOneWithoutCourierInvoicesInput,
  logisticCourier?: Maybe<LogisticCourierCreateOneWithoutCourierInvoicesInput>,
  courierPriceRange?: Maybe<CourierPriceRangeCreateOneWithoutCourierInvoicesInput>,
  children?: Maybe<CourierInvoiceCreateManyWithoutParentInput>,
  finalPackages?: Maybe<FinalPackageCreateManyWithoutCourierInvoiceInput>,
  returnOrders?: Maybe<ReturnOrderCreateManyWithoutCourierInvoiceInput>,
};

export type FinalPackageCreateManyWithoutCourierInvoiceInput = {
  create?: Maybe<Array<FinalPackageCreateWithoutCourierInvoiceInput>>,
  connect?: Maybe<Array<FinalPackageWhereUniqueInput>>,
};

export type FinalPackageCreateWithoutCourierInvoiceInput = {
  id?: Maybe<Scalars['String']>,
  amount: Scalars['Int'],
  status?: Maybe<FinalPackageStatus>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  productMatch: ProductMatchCreateOneWithoutFinalPackagesInput,
  confirmedUser?: Maybe<UserCreateOneWithoutConfirmedFinalPackagesInput>,
  returnOrderProducts?: Maybe<ReturnOrderProductCreateManyWithoutFinalPackageInput>,
  transactions?: Maybe<TransactionCreateManyWithoutFinalPackageInput>,
};

export type ProductMatchCreateOneWithoutFinalPackagesInput = {
  create?: Maybe<ProductMatchCreateWithoutFinalPackagesInput>,
  connect?: Maybe<ProductMatchWhereUniqueInput>,
};

export type ProductMatchCreateWithoutFinalPackagesInput = {
  id?: Maybe<Scalars['String']>,
  amount?: Maybe<Scalars['Int']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  marketProduct: MarketProductCreateOneWithoutProductMatchesInput,
  autoMatch?: Maybe<AutoMatchCreateOneWithoutProductMatchesInput>,
  productOption: ProductOptionCreateOneWithoutProductMatchesInput,
};

export type ProductOptionCreateOneWithoutProductMatchesInput = {
  create?: Maybe<ProductOptionCreateWithoutProductMatchesInput>,
  connect?: Maybe<ProductOptionWhereUniqueInput>,
};

export type ProductOptionCreateWithoutProductMatchesInput = {
  id?: Maybe<Scalars['String']>,
  levelOne?: Maybe<Scalars['String']>,
  levelTwo?: Maybe<Scalars['String']>,
  originNum?: Maybe<Scalars['String']>,
  originBarcode?: Maybe<Scalars['String']>,
  newBarcode?: Maybe<Scalars['String']>,
  needInspection?: Maybe<Scalars['Boolean']>,
  temperature?: Maybe<TemperatureType>,
  bestPeriodDay?: Maybe<Scalars['Int']>,
  basicCourierPriceRange: BasicCourierPriceRange,
  isActive?: Maybe<Scalars['Boolean']>,
  canBeMixed?: Maybe<Scalars['Boolean']>,
  maxAmount?: Maybe<Scalars['Int']>,
  note?: Maybe<Scalars['String']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  product: ProductCreateOneWithoutProductOptionsInput,
  category?: Maybe<ProductCategoryCreateOneWithoutProductOptionsInput>,
  preStocks?: Maybe<PreStockCreateManyWithoutProductOptionInput>,
  transactions?: Maybe<TransactionCreateManyWithoutProductOptionInput>,
  autoMatches?: Maybe<AutoMatchCreateManyWithoutProductOptionInput>,
};

export type UserCreateOneWithoutConfirmedFinalPackagesInput = {
  create?: Maybe<UserCreateWithoutConfirmedFinalPackagesInput>,
  connect?: Maybe<UserWhereUniqueInput>,
};

export type UserCreateWithoutConfirmedFinalPackagesInput = {
  id?: Maybe<Scalars['String']>,
  email: Scalars['String'],
  emailVerified?: Maybe<Scalars['Boolean']>,
  type?: Maybe<CompanyType>,
  password: Scalars['String'],
  name?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  role?: Maybe<RoleType>,
  marketing?: Maybe<Scalars['Boolean']>,
  settings?: Maybe<Scalars['Json']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  company: CompanyCreateOneWithoutUsersInput,
  team?: Maybe<TeamCreateOneWithoutUsersInput>,
  confirmedTransactions?: Maybe<TransactionCreateManyWithoutConfirmedUserInput>,
  registeredPreStocks?: Maybe<PreStockCreateManyWithoutRegisteredUserInput>,
  confirmedPreStocks?: Maybe<PreStockCreateManyWithoutConfirmedUserInput>,
  confirmedStocktaking?: Maybe<StocktakingCreateManyWithoutConfirmedUserInput>,
  confirmedReturnOrder?: Maybe<ReturnOrderCreateManyWithoutConfirmedUserInput>,
  logisticdefaultOf?: Maybe<LogisticCreateManyWithoutDefaultUserInput>,
  logs?: Maybe<LogCreateManyWithoutUserInput>,
  orderNotes?: Maybe<OrderNoteCreateManyWithoutWriterInput>,
  confirmedOrderNotes?: Maybe<OrderNoteCreateManyWithoutConfirmedUserInput>,
  notices?: Maybe<NoticeCreateManyWithoutAuthorInput>,
};

export type StocktakingCreateManyWithoutConfirmedUserInput = {
  create?: Maybe<Array<StocktakingCreateWithoutConfirmedUserInput>>,
  connect?: Maybe<Array<StocktakingWhereUniqueInput>>,
};

export type StocktakingCreateWithoutConfirmedUserInput = {
  id?: Maybe<Scalars['String']>,
  inspectedDate: Scalars['DateTime'],
  baseAmount: Scalars['Int'],
  confirmedDate?: Maybe<Scalars['DateTime']>,
  confirmedNote?: Maybe<Scalars['String']>,
  amount: Scalars['Int'],
  note?: Maybe<Scalars['String']>,
  status?: Maybe<StocktakingStatus>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  baseTransaction: TransactionCreateOneWithoutStocktakingsInput,
  transactions?: Maybe<TransactionCreateManyWithoutStocktakingInput>,
};

export type TransactionCreateOneWithoutStocktakingsInput = {
  create?: Maybe<TransactionCreateWithoutStocktakingsInput>,
  connect?: Maybe<TransactionWhereUniqueInput>,
};

export type TransactionCreateWithoutStocktakingsInput = {
  id?: Maybe<Scalars['String']>,
  manufacturedDate?: Maybe<Scalars['DateTime']>,
  expiryDate?: Maybe<Scalars['DateTime']>,
  eventType?: Maybe<EventType>,
  transportType?: Maybe<TransportType>,
  usableAmount?: Maybe<Scalars['Int']>,
  brokenAmount?: Maybe<Scalars['Int']>,
  note?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  logistic: LogisticCreateOneWithoutTransactionsInput,
  preStock?: Maybe<PreStockCreateOneWithoutTransactionsInput>,
  parent?: Maybe<TransactionCreateOneWithoutChildrenInput>,
  location?: Maybe<LocationCreateOneWithoutIncomingRecordsInput>,
  productOption: ProductOptionCreateOneWithoutTransactionsInput,
  confirmedUser?: Maybe<UserCreateOneWithoutConfirmedTransactionsInput>,
  finalPackage?: Maybe<FinalPackageCreateOneWithoutTransactionsInput>,
  stocktaking?: Maybe<StocktakingCreateOneWithoutTransactionsInput>,
  children?: Maybe<TransactionCreateManyWithoutParentInput>,
};

export type FinalPackageCreateOneWithoutTransactionsInput = {
  create?: Maybe<FinalPackageCreateWithoutTransactionsInput>,
  connect?: Maybe<FinalPackageWhereUniqueInput>,
};

export type FinalPackageCreateWithoutTransactionsInput = {
  id?: Maybe<Scalars['String']>,
  amount: Scalars['Int'],
  status?: Maybe<FinalPackageStatus>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  courierInvoice: CourierInvoiceCreateOneWithoutFinalPackagesInput,
  productMatch: ProductMatchCreateOneWithoutFinalPackagesInput,
  confirmedUser?: Maybe<UserCreateOneWithoutConfirmedFinalPackagesInput>,
  returnOrderProducts?: Maybe<ReturnOrderProductCreateManyWithoutFinalPackageInput>,
};

export type ReturnOrderProductCreateManyWithoutFinalPackageInput = {
  create?: Maybe<Array<ReturnOrderProductCreateWithoutFinalPackageInput>>,
  connect?: Maybe<Array<ReturnOrderProductWhereUniqueInput>>,
};

export type ReturnOrderProductCreateWithoutFinalPackageInput = {
  id?: Maybe<Scalars['String']>,
  requestAmount: Scalars['Int'],
  category?: Maybe<ReturnOrderType>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  returnOrder: ReturnOrderCreateOneWithoutReturnOrderProductsInput,
};

export type ReturnOrderCreateOneWithoutReturnOrderProductsInput = {
  create?: Maybe<ReturnOrderCreateWithoutReturnOrderProductsInput>,
  connect?: Maybe<ReturnOrderWhereUniqueInput>,
};

export type ReturnOrderCreateWithoutReturnOrderProductsInput = {
  id?: Maybe<Scalars['String']>,
  returnCourierInvoiceNum?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  tel: Scalars['String'],
  zipcode: Scalars['String'],
  address: Scalars['String'],
  detailedAddress?: Maybe<Scalars['String']>,
  message?: Maybe<Scalars['String']>,
  status?: Maybe<ReturnStatus>,
  note?: Maybe<Scalars['String']>,
  deliveryPaidType?: Maybe<DeliveryPaidType>,
  returnedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  courierInvoice: CourierInvoiceCreateOneWithoutReturnOrdersInput,
  confirmedUser: UserCreateOneWithoutConfirmedReturnOrderInput,
};

export type CourierInvoiceCreateOneWithoutReturnOrdersInput = {
  create?: Maybe<CourierInvoiceCreateWithoutReturnOrdersInput>,
  connect?: Maybe<CourierInvoiceWhereUniqueInput>,
};

export type CourierInvoiceCreateWithoutReturnOrdersInput = {
  id?: Maybe<Scalars['String']>,
  type?: Maybe<CourierInvoiceType>,
  courierName?: Maybe<Scalars['String']>,
  num?: Maybe<Scalars['String']>,
  groupNum?: Maybe<Scalars['Int']>,
  printedNum?: Maybe<Scalars['Int']>,
  invoiceStatus?: Maybe<CourierInvoiceStatus>,
  status?: Maybe<PackageStatus>,
  sender: Scalars['String'],
  senderTel: Scalars['String'],
  senderZipcode: Scalars['String'],
  senderAddress: Scalars['String'],
  senderDetailedAddress?: Maybe<Scalars['String']>,
  receiver: Scalars['String'],
  receiverTel: Scalars['String'],
  receiverZipcode: Scalars['String'],
  receiverAddress: Scalars['String'],
  receiverDetailedAddress?: Maybe<Scalars['String']>,
  content?: Maybe<Scalars['String']>,
  message?: Maybe<Scalars['String']>,
  note?: Maybe<Scalars['String']>,
  addressRefinedDate?: Maybe<Scalars['DateTime']>,
  addressRefinedValues?: Maybe<Scalars['String']>,
  printedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  brand: BrandCreateOneWithoutCourierInvoicesInput,
  logisticCourier?: Maybe<LogisticCourierCreateOneWithoutCourierInvoicesInput>,
  parent?: Maybe<CourierInvoiceCreateOneWithoutChildrenInput>,
  courierPriceRange?: Maybe<CourierPriceRangeCreateOneWithoutCourierInvoicesInput>,
  children?: Maybe<CourierInvoiceCreateManyWithoutParentInput>,
  finalPackages?: Maybe<FinalPackageCreateManyWithoutCourierInvoiceInput>,
};

export type UserCreateOneWithoutConfirmedReturnOrderInput = {
  create?: Maybe<UserCreateWithoutConfirmedReturnOrderInput>,
  connect?: Maybe<UserWhereUniqueInput>,
};

export type UserCreateWithoutConfirmedReturnOrderInput = {
  id?: Maybe<Scalars['String']>,
  email: Scalars['String'],
  emailVerified?: Maybe<Scalars['Boolean']>,
  type?: Maybe<CompanyType>,
  password: Scalars['String'],
  name?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  role?: Maybe<RoleType>,
  marketing?: Maybe<Scalars['Boolean']>,
  settings?: Maybe<Scalars['Json']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  company: CompanyCreateOneWithoutUsersInput,
  team?: Maybe<TeamCreateOneWithoutUsersInput>,
  confirmedTransactions?: Maybe<TransactionCreateManyWithoutConfirmedUserInput>,
  registeredPreStocks?: Maybe<PreStockCreateManyWithoutRegisteredUserInput>,
  confirmedPreStocks?: Maybe<PreStockCreateManyWithoutConfirmedUserInput>,
  confirmedFinalPackages?: Maybe<FinalPackageCreateManyWithoutConfirmedUserInput>,
  confirmedStocktaking?: Maybe<StocktakingCreateManyWithoutConfirmedUserInput>,
  logisticdefaultOf?: Maybe<LogisticCreateManyWithoutDefaultUserInput>,
  logs?: Maybe<LogCreateManyWithoutUserInput>,
  orderNotes?: Maybe<OrderNoteCreateManyWithoutWriterInput>,
  confirmedOrderNotes?: Maybe<OrderNoteCreateManyWithoutConfirmedUserInput>,
  notices?: Maybe<NoticeCreateManyWithoutAuthorInput>,
};

export type LogisticCreateManyWithoutDefaultUserInput = {
  create?: Maybe<Array<LogisticCreateWithoutDefaultUserInput>>,
  connect?: Maybe<Array<LogisticWhereUniqueInput>>,
};

export type LogisticCreateWithoutDefaultUserInput = {
  id?: Maybe<Scalars['String']>,
  marketing?: Maybe<Scalars['Boolean']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  company: CompanyCreateOneWithoutLogisticInput,
  defaultCourier?: Maybe<LogisticCourierCreateOneWithoutLogisticDefaultOfInput>,
  sellers?: Maybe<SellerCreateManyWithoutLogisticInput>,
  availableCouriers?: Maybe<LogisticCourierCreateManyWithoutLogisticInput>,
  packets?: Maybe<PacketCreateManyWithoutLogisticInput>,
  centers?: Maybe<CenterCreateManyWithoutLogisticInput>,
  preStocks?: Maybe<PreStockCreateManyWithoutLogisticInput>,
  transactions?: Maybe<TransactionCreateManyWithoutLogisticInput>,
  notices?: Maybe<NoticeCreateManyWithoutLogisticInput>,
};

export type NoticeCreateManyWithoutLogisticInput = {
  create?: Maybe<Array<NoticeCreateWithoutLogisticInput>>,
  connect?: Maybe<Array<NoticeWhereUniqueInput>>,
};

export type NoticeCreateWithoutLogisticInput = {
  id?: Maybe<Scalars['String']>,
  groupId: Scalars['String'],
  expiryDate?: Maybe<Scalars['DateTime']>,
  title: Scalars['String'],
  content: Scalars['String'],
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  isPublic?: Maybe<Scalars['Boolean']>,
  isImportant?: Maybe<Scalars['Boolean']>,
  author: UserCreateOneWithoutNoticesInput,
  seller: SellerCreateOneWithoutNoticesInput,
};

export type UserCreateOneWithoutNoticesInput = {
  create?: Maybe<UserCreateWithoutNoticesInput>,
  connect?: Maybe<UserWhereUniqueInput>,
};

export type UserCreateWithoutNoticesInput = {
  id?: Maybe<Scalars['String']>,
  email: Scalars['String'],
  emailVerified?: Maybe<Scalars['Boolean']>,
  type?: Maybe<CompanyType>,
  password: Scalars['String'],
  name?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  role?: Maybe<RoleType>,
  marketing?: Maybe<Scalars['Boolean']>,
  settings?: Maybe<Scalars['Json']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  company: CompanyCreateOneWithoutUsersInput,
  team?: Maybe<TeamCreateOneWithoutUsersInput>,
  confirmedTransactions?: Maybe<TransactionCreateManyWithoutConfirmedUserInput>,
  registeredPreStocks?: Maybe<PreStockCreateManyWithoutRegisteredUserInput>,
  confirmedPreStocks?: Maybe<PreStockCreateManyWithoutConfirmedUserInput>,
  confirmedFinalPackages?: Maybe<FinalPackageCreateManyWithoutConfirmedUserInput>,
  confirmedStocktaking?: Maybe<StocktakingCreateManyWithoutConfirmedUserInput>,
  confirmedReturnOrder?: Maybe<ReturnOrderCreateManyWithoutConfirmedUserInput>,
  logisticdefaultOf?: Maybe<LogisticCreateManyWithoutDefaultUserInput>,
  logs?: Maybe<LogCreateManyWithoutUserInput>,
  orderNotes?: Maybe<OrderNoteCreateManyWithoutWriterInput>,
  confirmedOrderNotes?: Maybe<OrderNoteCreateManyWithoutConfirmedUserInput>,
};

export type ReturnOrderCreateManyWithoutConfirmedUserInput = {
  create?: Maybe<Array<ReturnOrderCreateWithoutConfirmedUserInput>>,
  connect?: Maybe<Array<ReturnOrderWhereUniqueInput>>,
};

export type ReturnOrderCreateWithoutConfirmedUserInput = {
  id?: Maybe<Scalars['String']>,
  returnCourierInvoiceNum?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  tel: Scalars['String'],
  zipcode: Scalars['String'],
  address: Scalars['String'],
  detailedAddress?: Maybe<Scalars['String']>,
  message?: Maybe<Scalars['String']>,
  status?: Maybe<ReturnStatus>,
  note?: Maybe<Scalars['String']>,
  deliveryPaidType?: Maybe<DeliveryPaidType>,
  returnedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  courierInvoice: CourierInvoiceCreateOneWithoutReturnOrdersInput,
  returnOrderProducts?: Maybe<ReturnOrderProductCreateManyWithoutReturnOrderInput>,
};

export type ReturnOrderProductCreateManyWithoutReturnOrderInput = {
  create?: Maybe<Array<ReturnOrderProductCreateWithoutReturnOrderInput>>,
  connect?: Maybe<Array<ReturnOrderProductWhereUniqueInput>>,
};

export type ReturnOrderProductCreateWithoutReturnOrderInput = {
  id?: Maybe<Scalars['String']>,
  requestAmount: Scalars['Int'],
  category?: Maybe<ReturnOrderType>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  finalPackage: FinalPackageCreateOneWithoutReturnOrderProductsInput,
};

export type FinalPackageCreateOneWithoutReturnOrderProductsInput = {
  create?: Maybe<FinalPackageCreateWithoutReturnOrderProductsInput>,
  connect?: Maybe<FinalPackageWhereUniqueInput>,
};

export type FinalPackageCreateWithoutReturnOrderProductsInput = {
  id?: Maybe<Scalars['String']>,
  amount: Scalars['Int'],
  status?: Maybe<FinalPackageStatus>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  courierInvoice: CourierInvoiceCreateOneWithoutFinalPackagesInput,
  productMatch: ProductMatchCreateOneWithoutFinalPackagesInput,
  confirmedUser?: Maybe<UserCreateOneWithoutConfirmedFinalPackagesInput>,
  transactions?: Maybe<TransactionCreateManyWithoutFinalPackageInput>,
};

export type TransactionCreateManyWithoutFinalPackageInput = {
  create?: Maybe<Array<TransactionCreateWithoutFinalPackageInput>>,
  connect?: Maybe<Array<TransactionWhereUniqueInput>>,
};

export type TransactionCreateWithoutFinalPackageInput = {
  id?: Maybe<Scalars['String']>,
  manufacturedDate?: Maybe<Scalars['DateTime']>,
  expiryDate?: Maybe<Scalars['DateTime']>,
  eventType?: Maybe<EventType>,
  transportType?: Maybe<TransportType>,
  usableAmount?: Maybe<Scalars['Int']>,
  brokenAmount?: Maybe<Scalars['Int']>,
  note?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  logistic: LogisticCreateOneWithoutTransactionsInput,
  preStock?: Maybe<PreStockCreateOneWithoutTransactionsInput>,
  parent?: Maybe<TransactionCreateOneWithoutChildrenInput>,
  location?: Maybe<LocationCreateOneWithoutIncomingRecordsInput>,
  productOption: ProductOptionCreateOneWithoutTransactionsInput,
  confirmedUser?: Maybe<UserCreateOneWithoutConfirmedTransactionsInput>,
  stocktaking?: Maybe<StocktakingCreateOneWithoutTransactionsInput>,
  children?: Maybe<TransactionCreateManyWithoutParentInput>,
  stocktakings?: Maybe<StocktakingCreateManyWithoutBaseTransactionInput>,
};

export type StocktakingCreateOneWithoutTransactionsInput = {
  create?: Maybe<StocktakingCreateWithoutTransactionsInput>,
  connect?: Maybe<StocktakingWhereUniqueInput>,
};

export type StocktakingCreateWithoutTransactionsInput = {
  id?: Maybe<Scalars['String']>,
  inspectedDate: Scalars['DateTime'],
  baseAmount: Scalars['Int'],
  confirmedDate?: Maybe<Scalars['DateTime']>,
  confirmedNote?: Maybe<Scalars['String']>,
  amount: Scalars['Int'],
  note?: Maybe<Scalars['String']>,
  status?: Maybe<StocktakingStatus>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  baseTransaction: TransactionCreateOneWithoutStocktakingsInput,
  confirmedUser?: Maybe<UserCreateOneWithoutConfirmedStocktakingInput>,
};

export type UserCreateOneWithoutConfirmedStocktakingInput = {
  create?: Maybe<UserCreateWithoutConfirmedStocktakingInput>,
  connect?: Maybe<UserWhereUniqueInput>,
};

export type UserCreateWithoutConfirmedStocktakingInput = {
  id?: Maybe<Scalars['String']>,
  email: Scalars['String'],
  emailVerified?: Maybe<Scalars['Boolean']>,
  type?: Maybe<CompanyType>,
  password: Scalars['String'],
  name?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  role?: Maybe<RoleType>,
  marketing?: Maybe<Scalars['Boolean']>,
  settings?: Maybe<Scalars['Json']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  company: CompanyCreateOneWithoutUsersInput,
  team?: Maybe<TeamCreateOneWithoutUsersInput>,
  confirmedTransactions?: Maybe<TransactionCreateManyWithoutConfirmedUserInput>,
  registeredPreStocks?: Maybe<PreStockCreateManyWithoutRegisteredUserInput>,
  confirmedPreStocks?: Maybe<PreStockCreateManyWithoutConfirmedUserInput>,
  confirmedFinalPackages?: Maybe<FinalPackageCreateManyWithoutConfirmedUserInput>,
  confirmedReturnOrder?: Maybe<ReturnOrderCreateManyWithoutConfirmedUserInput>,
  logisticdefaultOf?: Maybe<LogisticCreateManyWithoutDefaultUserInput>,
  logs?: Maybe<LogCreateManyWithoutUserInput>,
  orderNotes?: Maybe<OrderNoteCreateManyWithoutWriterInput>,
  confirmedOrderNotes?: Maybe<OrderNoteCreateManyWithoutConfirmedUserInput>,
  notices?: Maybe<NoticeCreateManyWithoutAuthorInput>,
};

export type LogCreateManyWithoutUserInput = {
  create?: Maybe<Array<LogCreateWithoutUserInput>>,
  connect?: Maybe<Array<LogWhereUniqueInput>>,
};

export type LogCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>,
  content: Scalars['String'],
  createdAt?: Maybe<Scalars['DateTime']>,
};

export type LogWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
};

export type OrderNoteCreateManyWithoutWriterInput = {
  create?: Maybe<Array<OrderNoteCreateWithoutWriterInput>>,
  connect?: Maybe<Array<OrderNoteWhereUniqueInput>>,
};

export type OrderNoteCreateWithoutWriterInput = {
  id?: Maybe<Scalars['String']>,
  type?: Maybe<OrderNoteType>,
  note?: Maybe<Scalars['String']>,
  status?: Maybe<OrderNoteStatus>,
  confirmedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  order: OrderCreateOneWithoutOrderNotesInput,
  confirmedUser?: Maybe<UserCreateOneWithoutConfirmedOrderNotesInput>,
};

export type OrderCreateOneWithoutOrderNotesInput = {
  create?: Maybe<OrderCreateWithoutOrderNotesInput>,
  connect?: Maybe<OrderWhereUniqueInput>,
};

export type OrderCreateWithoutOrderNotesInput = {
  id?: Maybe<Scalars['String']>,
  num: Scalars['String'],
  marketOrderNum: Scalars['String'],
  orderedDate?: Maybe<Scalars['DateTime']>,
  paidDate?: Maybe<Scalars['DateTime']>,
  sender?: Maybe<Scalars['String']>,
  senderTel?: Maybe<Scalars['String']>,
  senderTel2?: Maybe<Scalars['String']>,
  receiver: Scalars['String'],
  receiverTel: Scalars['String'],
  receiverTel2?: Maybe<Scalars['String']>,
  receiverZipcode?: Maybe<Scalars['String']>,
  receiverAddress: Scalars['String'],
  receiverDetailedAddress?: Maybe<Scalars['String']>,
  message?: Maybe<Scalars['String']>,
  deliveryPaid?: Maybe<Scalars['Boolean']>,
  deliveryPrice?: Maybe<Scalars['Int']>,
  isOverseas?: Maybe<Scalars['Boolean']>,
  remoteAreaPrice?: Maybe<Scalars['Int']>,
  detailedOrder?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  brandMarket: BrandMarketCreateOneWithoutOrdersInput,
  marketProducts?: Maybe<MarketProductCreateManyWithoutOrderInput>,
};

export type UserCreateOneWithoutConfirmedOrderNotesInput = {
  create?: Maybe<UserCreateWithoutConfirmedOrderNotesInput>,
  connect?: Maybe<UserWhereUniqueInput>,
};

export type UserCreateWithoutConfirmedOrderNotesInput = {
  id?: Maybe<Scalars['String']>,
  email: Scalars['String'],
  emailVerified?: Maybe<Scalars['Boolean']>,
  type?: Maybe<CompanyType>,
  password: Scalars['String'],
  name?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  role?: Maybe<RoleType>,
  marketing?: Maybe<Scalars['Boolean']>,
  settings?: Maybe<Scalars['Json']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  company: CompanyCreateOneWithoutUsersInput,
  team?: Maybe<TeamCreateOneWithoutUsersInput>,
  confirmedTransactions?: Maybe<TransactionCreateManyWithoutConfirmedUserInput>,
  registeredPreStocks?: Maybe<PreStockCreateManyWithoutRegisteredUserInput>,
  confirmedPreStocks?: Maybe<PreStockCreateManyWithoutConfirmedUserInput>,
  confirmedFinalPackages?: Maybe<FinalPackageCreateManyWithoutConfirmedUserInput>,
  confirmedStocktaking?: Maybe<StocktakingCreateManyWithoutConfirmedUserInput>,
  confirmedReturnOrder?: Maybe<ReturnOrderCreateManyWithoutConfirmedUserInput>,
  logisticdefaultOf?: Maybe<LogisticCreateManyWithoutDefaultUserInput>,
  logs?: Maybe<LogCreateManyWithoutUserInput>,
  orderNotes?: Maybe<OrderNoteCreateManyWithoutWriterInput>,
  notices?: Maybe<NoticeCreateManyWithoutAuthorInput>,
};

export type NoticeCreateManyWithoutAuthorInput = {
  create?: Maybe<Array<NoticeCreateWithoutAuthorInput>>,
  connect?: Maybe<Array<NoticeWhereUniqueInput>>,
};

export type NoticeCreateWithoutAuthorInput = {
  id?: Maybe<Scalars['String']>,
  groupId: Scalars['String'],
  expiryDate?: Maybe<Scalars['DateTime']>,
  title: Scalars['String'],
  content: Scalars['String'],
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  isPublic?: Maybe<Scalars['Boolean']>,
  isImportant?: Maybe<Scalars['Boolean']>,
  logistic: LogisticCreateOneWithoutNoticesInput,
  seller: SellerCreateOneWithoutNoticesInput,
};

export type SellerCreateOneWithoutNoticesInput = {
  create?: Maybe<SellerCreateWithoutNoticesInput>,
  connect?: Maybe<SellerWhereUniqueInput>,
};

export type SellerCreateWithoutNoticesInput = {
  id?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  company: CompanyCreateOneWithoutSellerInput,
  logistic: LogisticCreateOneWithoutSellersInput,
  defaultCourier?: Maybe<LogisticCourierCreateOneWithoutSellerDefaultOfInput>,
  brands?: Maybe<BrandCreateManyWithoutSellerInput>,
};

export type OrderNoteCreateManyWithoutConfirmedUserInput = {
  create?: Maybe<Array<OrderNoteCreateWithoutConfirmedUserInput>>,
  connect?: Maybe<Array<OrderNoteWhereUniqueInput>>,
};

export type OrderNoteCreateWithoutConfirmedUserInput = {
  id?: Maybe<Scalars['String']>,
  type?: Maybe<OrderNoteType>,
  note?: Maybe<Scalars['String']>,
  status?: Maybe<OrderNoteStatus>,
  confirmedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  order: OrderCreateOneWithoutOrderNotesInput,
  writer: UserCreateOneWithoutOrderNotesInput,
};

export type UserCreateOneWithoutOrderNotesInput = {
  create?: Maybe<UserCreateWithoutOrderNotesInput>,
  connect?: Maybe<UserWhereUniqueInput>,
};

export type UserCreateWithoutOrderNotesInput = {
  id?: Maybe<Scalars['String']>,
  email: Scalars['String'],
  emailVerified?: Maybe<Scalars['Boolean']>,
  type?: Maybe<CompanyType>,
  password: Scalars['String'],
  name?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  role?: Maybe<RoleType>,
  marketing?: Maybe<Scalars['Boolean']>,
  settings?: Maybe<Scalars['Json']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  company: CompanyCreateOneWithoutUsersInput,
  team?: Maybe<TeamCreateOneWithoutUsersInput>,
  confirmedTransactions?: Maybe<TransactionCreateManyWithoutConfirmedUserInput>,
  registeredPreStocks?: Maybe<PreStockCreateManyWithoutRegisteredUserInput>,
  confirmedPreStocks?: Maybe<PreStockCreateManyWithoutConfirmedUserInput>,
  confirmedFinalPackages?: Maybe<FinalPackageCreateManyWithoutConfirmedUserInput>,
  confirmedStocktaking?: Maybe<StocktakingCreateManyWithoutConfirmedUserInput>,
  confirmedReturnOrder?: Maybe<ReturnOrderCreateManyWithoutConfirmedUserInput>,
  logisticdefaultOf?: Maybe<LogisticCreateManyWithoutDefaultUserInput>,
  logs?: Maybe<LogCreateManyWithoutUserInput>,
  confirmedOrderNotes?: Maybe<OrderNoteCreateManyWithoutConfirmedUserInput>,
  notices?: Maybe<NoticeCreateManyWithoutAuthorInput>,
};

export type TransactionCreateManyWithoutParentInput = {
  create?: Maybe<Array<TransactionCreateWithoutParentInput>>,
  connect?: Maybe<Array<TransactionWhereUniqueInput>>,
};

export type TransactionCreateWithoutParentInput = {
  id?: Maybe<Scalars['String']>,
  manufacturedDate?: Maybe<Scalars['DateTime']>,
  expiryDate?: Maybe<Scalars['DateTime']>,
  eventType?: Maybe<EventType>,
  transportType?: Maybe<TransportType>,
  usableAmount?: Maybe<Scalars['Int']>,
  brokenAmount?: Maybe<Scalars['Int']>,
  note?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  logistic: LogisticCreateOneWithoutTransactionsInput,
  preStock?: Maybe<PreStockCreateOneWithoutTransactionsInput>,
  location?: Maybe<LocationCreateOneWithoutIncomingRecordsInput>,
  productOption: ProductOptionCreateOneWithoutTransactionsInput,
  confirmedUser?: Maybe<UserCreateOneWithoutConfirmedTransactionsInput>,
  finalPackage?: Maybe<FinalPackageCreateOneWithoutTransactionsInput>,
  stocktaking?: Maybe<StocktakingCreateOneWithoutTransactionsInput>,
  children?: Maybe<TransactionCreateManyWithoutParentInput>,
  stocktakings?: Maybe<StocktakingCreateManyWithoutBaseTransactionInput>,
};

export type StocktakingCreateManyWithoutBaseTransactionInput = {
  create?: Maybe<Array<StocktakingCreateWithoutBaseTransactionInput>>,
  connect?: Maybe<Array<StocktakingWhereUniqueInput>>,
};

export type StocktakingCreateWithoutBaseTransactionInput = {
  id?: Maybe<Scalars['String']>,
  inspectedDate: Scalars['DateTime'],
  baseAmount: Scalars['Int'],
  confirmedDate?: Maybe<Scalars['DateTime']>,
  confirmedNote?: Maybe<Scalars['String']>,
  amount: Scalars['Int'],
  note?: Maybe<Scalars['String']>,
  status?: Maybe<StocktakingStatus>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  confirmedUser?: Maybe<UserCreateOneWithoutConfirmedStocktakingInput>,
  transactions?: Maybe<TransactionCreateManyWithoutStocktakingInput>,
};

export type TransactionCreateManyWithoutStocktakingInput = {
  create?: Maybe<Array<TransactionCreateWithoutStocktakingInput>>,
  connect?: Maybe<Array<TransactionWhereUniqueInput>>,
};

export type TransactionCreateWithoutStocktakingInput = {
  id?: Maybe<Scalars['String']>,
  manufacturedDate?: Maybe<Scalars['DateTime']>,
  expiryDate?: Maybe<Scalars['DateTime']>,
  eventType?: Maybe<EventType>,
  transportType?: Maybe<TransportType>,
  usableAmount?: Maybe<Scalars['Int']>,
  brokenAmount?: Maybe<Scalars['Int']>,
  note?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  logistic: LogisticCreateOneWithoutTransactionsInput,
  preStock?: Maybe<PreStockCreateOneWithoutTransactionsInput>,
  parent?: Maybe<TransactionCreateOneWithoutChildrenInput>,
  location?: Maybe<LocationCreateOneWithoutIncomingRecordsInput>,
  productOption: ProductOptionCreateOneWithoutTransactionsInput,
  confirmedUser?: Maybe<UserCreateOneWithoutConfirmedTransactionsInput>,
  finalPackage?: Maybe<FinalPackageCreateOneWithoutTransactionsInput>,
  children?: Maybe<TransactionCreateManyWithoutParentInput>,
  stocktakings?: Maybe<StocktakingCreateManyWithoutBaseTransactionInput>,
};

export type ReturnOrderCreateManyWithoutCourierInvoiceInput = {
  create?: Maybe<Array<ReturnOrderCreateWithoutCourierInvoiceInput>>,
  connect?: Maybe<Array<ReturnOrderWhereUniqueInput>>,
};

export type ReturnOrderCreateWithoutCourierInvoiceInput = {
  id?: Maybe<Scalars['String']>,
  returnCourierInvoiceNum?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  tel: Scalars['String'],
  zipcode: Scalars['String'],
  address: Scalars['String'],
  detailedAddress?: Maybe<Scalars['String']>,
  message?: Maybe<Scalars['String']>,
  status?: Maybe<ReturnStatus>,
  note?: Maybe<Scalars['String']>,
  deliveryPaidType?: Maybe<DeliveryPaidType>,
  returnedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  confirmedUser: UserCreateOneWithoutConfirmedReturnOrderInput,
  returnOrderProducts?: Maybe<ReturnOrderProductCreateManyWithoutReturnOrderInput>,
};

export type CourierInvoiceTrackingCreateManyWithoutLogisticCourierInput = {
  create?: Maybe<Array<CourierInvoiceTrackingCreateWithoutLogisticCourierInput>>,
  connect?: Maybe<Array<CourierInvoiceTrackingWhereUniqueInput>>,
};

export type CourierInvoiceTrackingCreateWithoutLogisticCourierInput = {
  id?: Maybe<Scalars['String']>,
  courierInvoiceNum: Scalars['String'],
  status: Scalars['String'],
  trackedDate: Scalars['DateTime'],
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type ProductMatchCreateManyWithoutProductOptionInput = {
  create?: Maybe<Array<ProductMatchCreateWithoutProductOptionInput>>,
  connect?: Maybe<Array<ProductMatchWhereUniqueInput>>,
};

export type ProductMatchCreateWithoutProductOptionInput = {
  id?: Maybe<Scalars['String']>,
  amount?: Maybe<Scalars['Int']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  marketProduct: MarketProductCreateOneWithoutProductMatchesInput,
  autoMatch?: Maybe<AutoMatchCreateOneWithoutProductMatchesInput>,
  finalPackages?: Maybe<FinalPackageCreateManyWithoutProductMatchInput>,
};

export type FinalPackageCreateManyWithoutProductMatchInput = {
  create?: Maybe<Array<FinalPackageCreateWithoutProductMatchInput>>,
  connect?: Maybe<Array<FinalPackageWhereUniqueInput>>,
};

export type FinalPackageCreateWithoutProductMatchInput = {
  id?: Maybe<Scalars['String']>,
  amount: Scalars['Int'],
  status?: Maybe<FinalPackageStatus>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  courierInvoice: CourierInvoiceCreateOneWithoutFinalPackagesInput,
  confirmedUser?: Maybe<UserCreateOneWithoutConfirmedFinalPackagesInput>,
  returnOrderProducts?: Maybe<ReturnOrderProductCreateManyWithoutFinalPackageInput>,
  transactions?: Maybe<TransactionCreateManyWithoutFinalPackageInput>,
};

export type OrderNoteCreateManyWithoutOrderInput = {
  create?: Maybe<Array<OrderNoteCreateWithoutOrderInput>>,
  connect?: Maybe<Array<OrderNoteWhereUniqueInput>>,
};

export type OrderNoteCreateWithoutOrderInput = {
  id?: Maybe<Scalars['String']>,
  type?: Maybe<OrderNoteType>,
  note?: Maybe<Scalars['String']>,
  status?: Maybe<OrderNoteStatus>,
  confirmedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  writer: UserCreateOneWithoutOrderNotesInput,
  confirmedUser?: Maybe<UserCreateOneWithoutConfirmedOrderNotesInput>,
};

export type BrandUpdateInput = {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  zipcode?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  detailedAddress?: Maybe<Scalars['String']>,
  isActive?: Maybe<Scalars['Boolean']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  seller?: Maybe<SellerUpdateOneRequiredWithoutBrandsInput>,
  brandMarkets?: Maybe<BrandMarketUpdateManyWithoutBrandInput>,
  products?: Maybe<ProductUpdateManyWithoutBrandInput>,
  courierInvoices?: Maybe<CourierInvoiceUpdateManyWithoutBrandInput>,
};

export type SellerUpdateOneRequiredWithoutBrandsInput = {
  create?: Maybe<SellerCreateWithoutBrandsInput>,
  connect?: Maybe<SellerWhereUniqueInput>,
  update?: Maybe<SellerUpdateWithoutBrandsDataInput>,
  upsert?: Maybe<SellerUpsertWithoutBrandsInput>,
};

export type SellerUpdateWithoutBrandsDataInput = {
  id?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  company?: Maybe<CompanyUpdateOneRequiredWithoutSellerInput>,
  logistic?: Maybe<LogisticUpdateOneRequiredWithoutSellersInput>,
  defaultCourier?: Maybe<LogisticCourierUpdateOneWithoutSellerDefaultOfInput>,
  notices?: Maybe<NoticeUpdateManyWithoutSellerInput>,
};

export type CompanyUpdateOneRequiredWithoutSellerInput = {
  create?: Maybe<CompanyCreateWithoutSellerInput>,
  connect?: Maybe<CompanyWhereUniqueInput>,
  update?: Maybe<CompanyUpdateWithoutSellerDataInput>,
  upsert?: Maybe<CompanyUpsertWithoutSellerInput>,
};

export type CompanyUpdateWithoutSellerDataInput = {
  id?: Maybe<Scalars['String']>,
  type?: Maybe<CompanyType>,
  name?: Maybe<Scalars['String']>,
  ceo?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  zipcode?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  detailedAddress?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  status?: Maybe<CompanyStatus>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  taxInfo?: Maybe<TaxInfoUpdateOneRequiredWithoutCompanyInput>,
  users?: Maybe<UserUpdateManyWithoutCompanyInput>,
  teams?: Maybe<TeamUpdateManyWithoutCompanyInput>,
  bankAccounts?: Maybe<BankAccountUpdateManyWithoutCompanyInput>,
  superAdmin?: Maybe<SuperAdminUpdateOneWithoutCompanyInput>,
  logistic?: Maybe<LogisticUpdateOneWithoutCompanyInput>,
};

export type TaxInfoUpdateOneRequiredWithoutCompanyInput = {
  create?: Maybe<TaxInfoCreateWithoutCompanyInput>,
  connect?: Maybe<TaxInfoWhereUniqueInput>,
  update?: Maybe<TaxInfoUpdateWithoutCompanyDataInput>,
  upsert?: Maybe<TaxInfoUpsertWithoutCompanyInput>,
};

export type TaxInfoUpdateWithoutCompanyDataInput = {
  id?: Maybe<Scalars['String']>,
  corporationName?: Maybe<Scalars['String']>,
  businessRegNum?: Maybe<Scalars['String']>,
  businessType?: Maybe<Scalars['String']>,
  businessItem?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  zipcode?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  detailedAddress?: Maybe<Scalars['String']>,
  needTaxInfo?: Maybe<Scalars['Boolean']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type TaxInfoUpsertWithoutCompanyInput = {
  update: TaxInfoUpdateWithoutCompanyDataInput,
  create: TaxInfoCreateWithoutCompanyInput,
};

export type UserUpdateManyWithoutCompanyInput = {
  create?: Maybe<Array<UserCreateWithoutCompanyInput>>,
  connect?: Maybe<Array<UserWhereUniqueInput>>,
  set?: Maybe<Array<UserWhereUniqueInput>>,
  disconnect?: Maybe<Array<UserWhereUniqueInput>>,
  delete?: Maybe<Array<UserWhereUniqueInput>>,
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutCompanyInput>>,
  updateMany?: Maybe<Array<UserUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<UserScalarWhereInput>>,
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutCompanyInput>>,
};

export type UserUpdateWithWhereUniqueWithoutCompanyInput = {
  where: UserWhereUniqueInput,
  data: UserUpdateWithoutCompanyDataInput,
};

export type UserUpdateWithoutCompanyDataInput = {
  id?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  emailVerified?: Maybe<Scalars['Boolean']>,
  type?: Maybe<CompanyType>,
  password?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  role?: Maybe<RoleType>,
  marketing?: Maybe<Scalars['Boolean']>,
  settings?: Maybe<Scalars['Json']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  team?: Maybe<TeamUpdateOneWithoutUsersInput>,
  confirmedTransactions?: Maybe<TransactionUpdateManyWithoutConfirmedUserInput>,
  registeredPreStocks?: Maybe<PreStockUpdateManyWithoutRegisteredUserInput>,
  confirmedPreStocks?: Maybe<PreStockUpdateManyWithoutConfirmedUserInput>,
  confirmedFinalPackages?: Maybe<FinalPackageUpdateManyWithoutConfirmedUserInput>,
  confirmedStocktaking?: Maybe<StocktakingUpdateManyWithoutConfirmedUserInput>,
  confirmedReturnOrder?: Maybe<ReturnOrderUpdateManyWithoutConfirmedUserInput>,
  logisticdefaultOf?: Maybe<LogisticUpdateManyWithoutDefaultUserInput>,
  logs?: Maybe<LogUpdateManyWithoutUserInput>,
  orderNotes?: Maybe<OrderNoteUpdateManyWithoutWriterInput>,
  confirmedOrderNotes?: Maybe<OrderNoteUpdateManyWithoutConfirmedUserInput>,
  notices?: Maybe<NoticeUpdateManyWithoutAuthorInput>,
};

export type TeamUpdateOneWithoutUsersInput = {
  create?: Maybe<TeamCreateWithoutUsersInput>,
  connect?: Maybe<TeamWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<TeamUpdateWithoutUsersDataInput>,
  upsert?: Maybe<TeamUpsertWithoutUsersInput>,
};

export type TeamUpdateWithoutUsersDataInput = {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  company?: Maybe<CompanyUpdateOneRequiredWithoutTeamsInput>,
};

export type CompanyUpdateOneRequiredWithoutTeamsInput = {
  create?: Maybe<CompanyCreateWithoutTeamsInput>,
  connect?: Maybe<CompanyWhereUniqueInput>,
  update?: Maybe<CompanyUpdateWithoutTeamsDataInput>,
  upsert?: Maybe<CompanyUpsertWithoutTeamsInput>,
};

export type CompanyUpdateWithoutTeamsDataInput = {
  id?: Maybe<Scalars['String']>,
  type?: Maybe<CompanyType>,
  name?: Maybe<Scalars['String']>,
  ceo?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  zipcode?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  detailedAddress?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  status?: Maybe<CompanyStatus>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  taxInfo?: Maybe<TaxInfoUpdateOneRequiredWithoutCompanyInput>,
  users?: Maybe<UserUpdateManyWithoutCompanyInput>,
  bankAccounts?: Maybe<BankAccountUpdateManyWithoutCompanyInput>,
  superAdmin?: Maybe<SuperAdminUpdateOneWithoutCompanyInput>,
  logistic?: Maybe<LogisticUpdateOneWithoutCompanyInput>,
  seller?: Maybe<SellerUpdateOneWithoutCompanyInput>,
};

export type BankAccountUpdateManyWithoutCompanyInput = {
  create?: Maybe<Array<BankAccountCreateWithoutCompanyInput>>,
  connect?: Maybe<Array<BankAccountWhereUniqueInput>>,
  set?: Maybe<Array<BankAccountWhereUniqueInput>>,
  disconnect?: Maybe<Array<BankAccountWhereUniqueInput>>,
  delete?: Maybe<Array<BankAccountWhereUniqueInput>>,
  update?: Maybe<Array<BankAccountUpdateWithWhereUniqueWithoutCompanyInput>>,
  updateMany?: Maybe<Array<BankAccountUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<BankAccountScalarWhereInput>>,
  upsert?: Maybe<Array<BankAccountUpsertWithWhereUniqueWithoutCompanyInput>>,
};

export type BankAccountUpdateWithWhereUniqueWithoutCompanyInput = {
  where: BankAccountWhereUniqueInput,
  data: BankAccountUpdateWithoutCompanyDataInput,
};

export type BankAccountUpdateWithoutCompanyDataInput = {
  id?: Maybe<Scalars['String']>,
  bank?: Maybe<Scalars['String']>,
  num?: Maybe<Scalars['String']>,
  holder?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type BankAccountUpdateManyWithWhereNestedInput = {
  where: BankAccountScalarWhereInput,
  data: BankAccountUpdateManyDataInput,
};

export type BankAccountScalarWhereInput = {
  id?: Maybe<StringFilter>,
  companyId?: Maybe<StringFilter>,
  bank?: Maybe<StringFilter>,
  num?: Maybe<StringFilter>,
  holder?: Maybe<StringFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  AND?: Maybe<Array<BankAccountScalarWhereInput>>,
  OR?: Maybe<Array<BankAccountScalarWhereInput>>,
  NOT?: Maybe<Array<BankAccountScalarWhereInput>>,
};

export type BankAccountUpdateManyDataInput = {
  id?: Maybe<Scalars['String']>,
  bank?: Maybe<Scalars['String']>,
  num?: Maybe<Scalars['String']>,
  holder?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type BankAccountUpsertWithWhereUniqueWithoutCompanyInput = {
  where: BankAccountWhereUniqueInput,
  update: BankAccountUpdateWithoutCompanyDataInput,
  create: BankAccountCreateWithoutCompanyInput,
};

export type SuperAdminUpdateOneWithoutCompanyInput = {
  create?: Maybe<SuperAdminCreateWithoutCompanyInput>,
  connect?: Maybe<SuperAdminWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<SuperAdminUpdateWithoutCompanyDataInput>,
  upsert?: Maybe<SuperAdminUpsertWithoutCompanyInput>,
};

export type SuperAdminUpdateWithoutCompanyDataInput = {
  id?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type SuperAdminUpsertWithoutCompanyInput = {
  update: SuperAdminUpdateWithoutCompanyDataInput,
  create: SuperAdminCreateWithoutCompanyInput,
};

export type LogisticUpdateOneWithoutCompanyInput = {
  create?: Maybe<LogisticCreateWithoutCompanyInput>,
  connect?: Maybe<LogisticWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<LogisticUpdateWithoutCompanyDataInput>,
  upsert?: Maybe<LogisticUpsertWithoutCompanyInput>,
};

export type LogisticUpdateWithoutCompanyDataInput = {
  id?: Maybe<Scalars['String']>,
  marketing?: Maybe<Scalars['Boolean']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  defaultUser?: Maybe<UserUpdateOneRequiredWithoutLogisticdefaultOfInput>,
  defaultCourier?: Maybe<LogisticCourierUpdateOneWithoutLogisticDefaultOfInput>,
  sellers?: Maybe<SellerUpdateManyWithoutLogisticInput>,
  availableCouriers?: Maybe<LogisticCourierUpdateManyWithoutLogisticInput>,
  packets?: Maybe<PacketUpdateManyWithoutLogisticInput>,
  centers?: Maybe<CenterUpdateManyWithoutLogisticInput>,
  preStocks?: Maybe<PreStockUpdateManyWithoutLogisticInput>,
  transactions?: Maybe<TransactionUpdateManyWithoutLogisticInput>,
  notices?: Maybe<NoticeUpdateManyWithoutLogisticInput>,
};

export type UserUpdateOneRequiredWithoutLogisticdefaultOfInput = {
  create?: Maybe<UserCreateWithoutLogisticdefaultOfInput>,
  connect?: Maybe<UserWhereUniqueInput>,
  update?: Maybe<UserUpdateWithoutLogisticdefaultOfDataInput>,
  upsert?: Maybe<UserUpsertWithoutLogisticdefaultOfInput>,
};

export type UserUpdateWithoutLogisticdefaultOfDataInput = {
  id?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  emailVerified?: Maybe<Scalars['Boolean']>,
  type?: Maybe<CompanyType>,
  password?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  role?: Maybe<RoleType>,
  marketing?: Maybe<Scalars['Boolean']>,
  settings?: Maybe<Scalars['Json']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  company?: Maybe<CompanyUpdateOneRequiredWithoutUsersInput>,
  team?: Maybe<TeamUpdateOneWithoutUsersInput>,
  confirmedTransactions?: Maybe<TransactionUpdateManyWithoutConfirmedUserInput>,
  registeredPreStocks?: Maybe<PreStockUpdateManyWithoutRegisteredUserInput>,
  confirmedPreStocks?: Maybe<PreStockUpdateManyWithoutConfirmedUserInput>,
  confirmedFinalPackages?: Maybe<FinalPackageUpdateManyWithoutConfirmedUserInput>,
  confirmedStocktaking?: Maybe<StocktakingUpdateManyWithoutConfirmedUserInput>,
  confirmedReturnOrder?: Maybe<ReturnOrderUpdateManyWithoutConfirmedUserInput>,
  logs?: Maybe<LogUpdateManyWithoutUserInput>,
  orderNotes?: Maybe<OrderNoteUpdateManyWithoutWriterInput>,
  confirmedOrderNotes?: Maybe<OrderNoteUpdateManyWithoutConfirmedUserInput>,
  notices?: Maybe<NoticeUpdateManyWithoutAuthorInput>,
};

export type CompanyUpdateOneRequiredWithoutUsersInput = {
  create?: Maybe<CompanyCreateWithoutUsersInput>,
  connect?: Maybe<CompanyWhereUniqueInput>,
  update?: Maybe<CompanyUpdateWithoutUsersDataInput>,
  upsert?: Maybe<CompanyUpsertWithoutUsersInput>,
};

export type CompanyUpdateWithoutUsersDataInput = {
  id?: Maybe<Scalars['String']>,
  type?: Maybe<CompanyType>,
  name?: Maybe<Scalars['String']>,
  ceo?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  zipcode?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  detailedAddress?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  status?: Maybe<CompanyStatus>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  taxInfo?: Maybe<TaxInfoUpdateOneRequiredWithoutCompanyInput>,
  teams?: Maybe<TeamUpdateManyWithoutCompanyInput>,
  bankAccounts?: Maybe<BankAccountUpdateManyWithoutCompanyInput>,
  superAdmin?: Maybe<SuperAdminUpdateOneWithoutCompanyInput>,
  logistic?: Maybe<LogisticUpdateOneWithoutCompanyInput>,
  seller?: Maybe<SellerUpdateOneWithoutCompanyInput>,
};

export type TeamUpdateManyWithoutCompanyInput = {
  create?: Maybe<Array<TeamCreateWithoutCompanyInput>>,
  connect?: Maybe<Array<TeamWhereUniqueInput>>,
  set?: Maybe<Array<TeamWhereUniqueInput>>,
  disconnect?: Maybe<Array<TeamWhereUniqueInput>>,
  delete?: Maybe<Array<TeamWhereUniqueInput>>,
  update?: Maybe<Array<TeamUpdateWithWhereUniqueWithoutCompanyInput>>,
  updateMany?: Maybe<Array<TeamUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<TeamScalarWhereInput>>,
  upsert?: Maybe<Array<TeamUpsertWithWhereUniqueWithoutCompanyInput>>,
};

export type TeamUpdateWithWhereUniqueWithoutCompanyInput = {
  where: TeamWhereUniqueInput,
  data: TeamUpdateWithoutCompanyDataInput,
};

export type TeamUpdateWithoutCompanyDataInput = {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  users?: Maybe<UserUpdateManyWithoutTeamInput>,
};

export type UserUpdateManyWithoutTeamInput = {
  create?: Maybe<Array<UserCreateWithoutTeamInput>>,
  connect?: Maybe<Array<UserWhereUniqueInput>>,
  set?: Maybe<Array<UserWhereUniqueInput>>,
  disconnect?: Maybe<Array<UserWhereUniqueInput>>,
  delete?: Maybe<Array<UserWhereUniqueInput>>,
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutTeamInput>>,
  updateMany?: Maybe<Array<UserUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<UserScalarWhereInput>>,
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutTeamInput>>,
};

export type UserUpdateWithWhereUniqueWithoutTeamInput = {
  where: UserWhereUniqueInput,
  data: UserUpdateWithoutTeamDataInput,
};

export type UserUpdateWithoutTeamDataInput = {
  id?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  emailVerified?: Maybe<Scalars['Boolean']>,
  type?: Maybe<CompanyType>,
  password?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  role?: Maybe<RoleType>,
  marketing?: Maybe<Scalars['Boolean']>,
  settings?: Maybe<Scalars['Json']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  company?: Maybe<CompanyUpdateOneRequiredWithoutUsersInput>,
  confirmedTransactions?: Maybe<TransactionUpdateManyWithoutConfirmedUserInput>,
  registeredPreStocks?: Maybe<PreStockUpdateManyWithoutRegisteredUserInput>,
  confirmedPreStocks?: Maybe<PreStockUpdateManyWithoutConfirmedUserInput>,
  confirmedFinalPackages?: Maybe<FinalPackageUpdateManyWithoutConfirmedUserInput>,
  confirmedStocktaking?: Maybe<StocktakingUpdateManyWithoutConfirmedUserInput>,
  confirmedReturnOrder?: Maybe<ReturnOrderUpdateManyWithoutConfirmedUserInput>,
  logisticdefaultOf?: Maybe<LogisticUpdateManyWithoutDefaultUserInput>,
  logs?: Maybe<LogUpdateManyWithoutUserInput>,
  orderNotes?: Maybe<OrderNoteUpdateManyWithoutWriterInput>,
  confirmedOrderNotes?: Maybe<OrderNoteUpdateManyWithoutConfirmedUserInput>,
  notices?: Maybe<NoticeUpdateManyWithoutAuthorInput>,
};

export type TransactionUpdateManyWithoutConfirmedUserInput = {
  create?: Maybe<Array<TransactionCreateWithoutConfirmedUserInput>>,
  connect?: Maybe<Array<TransactionWhereUniqueInput>>,
  set?: Maybe<Array<TransactionWhereUniqueInput>>,
  disconnect?: Maybe<Array<TransactionWhereUniqueInput>>,
  delete?: Maybe<Array<TransactionWhereUniqueInput>>,
  update?: Maybe<Array<TransactionUpdateWithWhereUniqueWithoutConfirmedUserInput>>,
  updateMany?: Maybe<Array<TransactionUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<TransactionScalarWhereInput>>,
  upsert?: Maybe<Array<TransactionUpsertWithWhereUniqueWithoutConfirmedUserInput>>,
};

export type TransactionUpdateWithWhereUniqueWithoutConfirmedUserInput = {
  where: TransactionWhereUniqueInput,
  data: TransactionUpdateWithoutConfirmedUserDataInput,
};

export type TransactionUpdateWithoutConfirmedUserDataInput = {
  id?: Maybe<Scalars['String']>,
  manufacturedDate?: Maybe<Scalars['DateTime']>,
  expiryDate?: Maybe<Scalars['DateTime']>,
  eventType?: Maybe<EventType>,
  transportType?: Maybe<TransportType>,
  usableAmount?: Maybe<Scalars['Int']>,
  brokenAmount?: Maybe<Scalars['Int']>,
  note?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  logistic?: Maybe<LogisticUpdateOneRequiredWithoutTransactionsInput>,
  preStock?: Maybe<PreStockUpdateOneWithoutTransactionsInput>,
  parent?: Maybe<TransactionUpdateOneWithoutChildrenInput>,
  location?: Maybe<LocationUpdateOneWithoutIncomingRecordsInput>,
  productOption?: Maybe<ProductOptionUpdateOneRequiredWithoutTransactionsInput>,
  finalPackage?: Maybe<FinalPackageUpdateOneWithoutTransactionsInput>,
  stocktaking?: Maybe<StocktakingUpdateOneWithoutTransactionsInput>,
  children?: Maybe<TransactionUpdateManyWithoutParentInput>,
  stocktakings?: Maybe<StocktakingUpdateManyWithoutBaseTransactionInput>,
};

export type LogisticUpdateOneRequiredWithoutTransactionsInput = {
  create?: Maybe<LogisticCreateWithoutTransactionsInput>,
  connect?: Maybe<LogisticWhereUniqueInput>,
  update?: Maybe<LogisticUpdateWithoutTransactionsDataInput>,
  upsert?: Maybe<LogisticUpsertWithoutTransactionsInput>,
};

export type LogisticUpdateWithoutTransactionsDataInput = {
  id?: Maybe<Scalars['String']>,
  marketing?: Maybe<Scalars['Boolean']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  company?: Maybe<CompanyUpdateOneRequiredWithoutLogisticInput>,
  defaultUser?: Maybe<UserUpdateOneRequiredWithoutLogisticdefaultOfInput>,
  defaultCourier?: Maybe<LogisticCourierUpdateOneWithoutLogisticDefaultOfInput>,
  sellers?: Maybe<SellerUpdateManyWithoutLogisticInput>,
  availableCouriers?: Maybe<LogisticCourierUpdateManyWithoutLogisticInput>,
  packets?: Maybe<PacketUpdateManyWithoutLogisticInput>,
  centers?: Maybe<CenterUpdateManyWithoutLogisticInput>,
  preStocks?: Maybe<PreStockUpdateManyWithoutLogisticInput>,
  notices?: Maybe<NoticeUpdateManyWithoutLogisticInput>,
};

export type CompanyUpdateOneRequiredWithoutLogisticInput = {
  create?: Maybe<CompanyCreateWithoutLogisticInput>,
  connect?: Maybe<CompanyWhereUniqueInput>,
  update?: Maybe<CompanyUpdateWithoutLogisticDataInput>,
  upsert?: Maybe<CompanyUpsertWithoutLogisticInput>,
};

export type CompanyUpdateWithoutLogisticDataInput = {
  id?: Maybe<Scalars['String']>,
  type?: Maybe<CompanyType>,
  name?: Maybe<Scalars['String']>,
  ceo?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  zipcode?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  detailedAddress?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  status?: Maybe<CompanyStatus>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  taxInfo?: Maybe<TaxInfoUpdateOneRequiredWithoutCompanyInput>,
  users?: Maybe<UserUpdateManyWithoutCompanyInput>,
  teams?: Maybe<TeamUpdateManyWithoutCompanyInput>,
  bankAccounts?: Maybe<BankAccountUpdateManyWithoutCompanyInput>,
  superAdmin?: Maybe<SuperAdminUpdateOneWithoutCompanyInput>,
  seller?: Maybe<SellerUpdateOneWithoutCompanyInput>,
};

export type SellerUpdateOneWithoutCompanyInput = {
  create?: Maybe<SellerCreateWithoutCompanyInput>,
  connect?: Maybe<SellerWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<SellerUpdateWithoutCompanyDataInput>,
  upsert?: Maybe<SellerUpsertWithoutCompanyInput>,
};

export type SellerUpdateWithoutCompanyDataInput = {
  id?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  logistic?: Maybe<LogisticUpdateOneRequiredWithoutSellersInput>,
  defaultCourier?: Maybe<LogisticCourierUpdateOneWithoutSellerDefaultOfInput>,
  brands?: Maybe<BrandUpdateManyWithoutSellerInput>,
  notices?: Maybe<NoticeUpdateManyWithoutSellerInput>,
};

export type LogisticUpdateOneRequiredWithoutSellersInput = {
  create?: Maybe<LogisticCreateWithoutSellersInput>,
  connect?: Maybe<LogisticWhereUniqueInput>,
  update?: Maybe<LogisticUpdateWithoutSellersDataInput>,
  upsert?: Maybe<LogisticUpsertWithoutSellersInput>,
};

export type LogisticUpdateWithoutSellersDataInput = {
  id?: Maybe<Scalars['String']>,
  marketing?: Maybe<Scalars['Boolean']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  company?: Maybe<CompanyUpdateOneRequiredWithoutLogisticInput>,
  defaultUser?: Maybe<UserUpdateOneRequiredWithoutLogisticdefaultOfInput>,
  defaultCourier?: Maybe<LogisticCourierUpdateOneWithoutLogisticDefaultOfInput>,
  availableCouriers?: Maybe<LogisticCourierUpdateManyWithoutLogisticInput>,
  packets?: Maybe<PacketUpdateManyWithoutLogisticInput>,
  centers?: Maybe<CenterUpdateManyWithoutLogisticInput>,
  preStocks?: Maybe<PreStockUpdateManyWithoutLogisticInput>,
  transactions?: Maybe<TransactionUpdateManyWithoutLogisticInput>,
  notices?: Maybe<NoticeUpdateManyWithoutLogisticInput>,
};

export type LogisticCourierUpdateOneWithoutLogisticDefaultOfInput = {
  create?: Maybe<LogisticCourierCreateWithoutLogisticDefaultOfInput>,
  connect?: Maybe<LogisticCourierWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<LogisticCourierUpdateWithoutLogisticDefaultOfDataInput>,
  upsert?: Maybe<LogisticCourierUpsertWithoutLogisticDefaultOfInput>,
};

export type LogisticCourierUpdateWithoutLogisticDefaultOfDataInput = {
  id?: Maybe<Scalars['String']>,
  config?: Maybe<Scalars['String']>,
  isActive?: Maybe<Scalars['Boolean']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  logistic?: Maybe<LogisticUpdateOneRequiredWithoutAvailableCouriersInput>,
  courier?: Maybe<CourierUpdateOneRequiredWithoutLogisticCouriersInput>,
  sellerDefaultOf?: Maybe<SellerUpdateManyWithoutDefaultCourierInput>,
  courierInvoices?: Maybe<CourierInvoiceUpdateManyWithoutLogisticCourierInput>,
  courierInvoiceTrackings?: Maybe<CourierInvoiceTrackingUpdateManyWithoutLogisticCourierInput>,
};

export type LogisticUpdateOneRequiredWithoutAvailableCouriersInput = {
  create?: Maybe<LogisticCreateWithoutAvailableCouriersInput>,
  connect?: Maybe<LogisticWhereUniqueInput>,
  update?: Maybe<LogisticUpdateWithoutAvailableCouriersDataInput>,
  upsert?: Maybe<LogisticUpsertWithoutAvailableCouriersInput>,
};

export type LogisticUpdateWithoutAvailableCouriersDataInput = {
  id?: Maybe<Scalars['String']>,
  marketing?: Maybe<Scalars['Boolean']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  company?: Maybe<CompanyUpdateOneRequiredWithoutLogisticInput>,
  defaultUser?: Maybe<UserUpdateOneRequiredWithoutLogisticdefaultOfInput>,
  defaultCourier?: Maybe<LogisticCourierUpdateOneWithoutLogisticDefaultOfInput>,
  sellers?: Maybe<SellerUpdateManyWithoutLogisticInput>,
  packets?: Maybe<PacketUpdateManyWithoutLogisticInput>,
  centers?: Maybe<CenterUpdateManyWithoutLogisticInput>,
  preStocks?: Maybe<PreStockUpdateManyWithoutLogisticInput>,
  transactions?: Maybe<TransactionUpdateManyWithoutLogisticInput>,
  notices?: Maybe<NoticeUpdateManyWithoutLogisticInput>,
};

export type SellerUpdateManyWithoutLogisticInput = {
  create?: Maybe<Array<SellerCreateWithoutLogisticInput>>,
  connect?: Maybe<Array<SellerWhereUniqueInput>>,
  set?: Maybe<Array<SellerWhereUniqueInput>>,
  disconnect?: Maybe<Array<SellerWhereUniqueInput>>,
  delete?: Maybe<Array<SellerWhereUniqueInput>>,
  update?: Maybe<Array<SellerUpdateWithWhereUniqueWithoutLogisticInput>>,
  updateMany?: Maybe<Array<SellerUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<SellerScalarWhereInput>>,
  upsert?: Maybe<Array<SellerUpsertWithWhereUniqueWithoutLogisticInput>>,
};

export type SellerUpdateWithWhereUniqueWithoutLogisticInput = {
  where: SellerWhereUniqueInput,
  data: SellerUpdateWithoutLogisticDataInput,
};

export type SellerUpdateWithoutLogisticDataInput = {
  id?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  company?: Maybe<CompanyUpdateOneRequiredWithoutSellerInput>,
  defaultCourier?: Maybe<LogisticCourierUpdateOneWithoutSellerDefaultOfInput>,
  brands?: Maybe<BrandUpdateManyWithoutSellerInput>,
  notices?: Maybe<NoticeUpdateManyWithoutSellerInput>,
};

export type LogisticCourierUpdateOneWithoutSellerDefaultOfInput = {
  create?: Maybe<LogisticCourierCreateWithoutSellerDefaultOfInput>,
  connect?: Maybe<LogisticCourierWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<LogisticCourierUpdateWithoutSellerDefaultOfDataInput>,
  upsert?: Maybe<LogisticCourierUpsertWithoutSellerDefaultOfInput>,
};

export type LogisticCourierUpdateWithoutSellerDefaultOfDataInput = {
  id?: Maybe<Scalars['String']>,
  config?: Maybe<Scalars['String']>,
  isActive?: Maybe<Scalars['Boolean']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  logistic?: Maybe<LogisticUpdateOneRequiredWithoutAvailableCouriersInput>,
  courier?: Maybe<CourierUpdateOneRequiredWithoutLogisticCouriersInput>,
  logisticDefaultOf?: Maybe<LogisticUpdateOneWithoutDefaultCourierInput>,
  courierInvoices?: Maybe<CourierInvoiceUpdateManyWithoutLogisticCourierInput>,
  courierInvoiceTrackings?: Maybe<CourierInvoiceTrackingUpdateManyWithoutLogisticCourierInput>,
};

export type CourierUpdateOneRequiredWithoutLogisticCouriersInput = {
  create?: Maybe<CourierCreateWithoutLogisticCouriersInput>,
  connect?: Maybe<CourierWhereUniqueInput>,
  update?: Maybe<CourierUpdateWithoutLogisticCouriersDataInput>,
  upsert?: Maybe<CourierUpsertWithoutLogisticCouriersInput>,
};

export type CourierUpdateWithoutLogisticCouriersDataInput = {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  configFields?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  priceRanges?: Maybe<CourierPriceRangeUpdateManyWithoutCourierInput>,
};

export type CourierPriceRangeUpdateManyWithoutCourierInput = {
  create?: Maybe<Array<CourierPriceRangeCreateWithoutCourierInput>>,
  connect?: Maybe<Array<CourierPriceRangeWhereUniqueInput>>,
  set?: Maybe<Array<CourierPriceRangeWhereUniqueInput>>,
  disconnect?: Maybe<Array<CourierPriceRangeWhereUniqueInput>>,
  delete?: Maybe<Array<CourierPriceRangeWhereUniqueInput>>,
  update?: Maybe<Array<CourierPriceRangeUpdateWithWhereUniqueWithoutCourierInput>>,
  updateMany?: Maybe<Array<CourierPriceRangeUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<CourierPriceRangeScalarWhereInput>>,
  upsert?: Maybe<Array<CourierPriceRangeUpsertWithWhereUniqueWithoutCourierInput>>,
};

export type CourierPriceRangeUpdateWithWhereUniqueWithoutCourierInput = {
  where: CourierPriceRangeWhereUniqueInput,
  data: CourierPriceRangeUpdateWithoutCourierDataInput,
};

export type CourierPriceRangeUpdateWithoutCourierDataInput = {
  id?: Maybe<Scalars['String']>,
  basic?: Maybe<BasicCourierPriceRange>,
  name?: Maybe<Scalars['String']>,
  value?: Maybe<Scalars['String']>,
  price?: Maybe<Scalars['Int']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  courierInvoices?: Maybe<CourierInvoiceUpdateManyWithoutCourierPriceRangeInput>,
};

export type CourierInvoiceUpdateManyWithoutCourierPriceRangeInput = {
  create?: Maybe<Array<CourierInvoiceCreateWithoutCourierPriceRangeInput>>,
  connect?: Maybe<Array<CourierInvoiceWhereUniqueInput>>,
  set?: Maybe<Array<CourierInvoiceWhereUniqueInput>>,
  disconnect?: Maybe<Array<CourierInvoiceWhereUniqueInput>>,
  delete?: Maybe<Array<CourierInvoiceWhereUniqueInput>>,
  update?: Maybe<Array<CourierInvoiceUpdateWithWhereUniqueWithoutCourierPriceRangeInput>>,
  updateMany?: Maybe<Array<CourierInvoiceUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<CourierInvoiceScalarWhereInput>>,
  upsert?: Maybe<Array<CourierInvoiceUpsertWithWhereUniqueWithoutCourierPriceRangeInput>>,
};

export type CourierInvoiceUpdateWithWhereUniqueWithoutCourierPriceRangeInput = {
  where: CourierInvoiceWhereUniqueInput,
  data: CourierInvoiceUpdateWithoutCourierPriceRangeDataInput,
};

export type CourierInvoiceUpdateWithoutCourierPriceRangeDataInput = {
  id?: Maybe<Scalars['String']>,
  type?: Maybe<CourierInvoiceType>,
  courierName?: Maybe<Scalars['String']>,
  num?: Maybe<Scalars['String']>,
  groupNum?: Maybe<Scalars['Int']>,
  printedNum?: Maybe<Scalars['Int']>,
  invoiceStatus?: Maybe<CourierInvoiceStatus>,
  status?: Maybe<PackageStatus>,
  sender?: Maybe<Scalars['String']>,
  senderTel?: Maybe<Scalars['String']>,
  senderZipcode?: Maybe<Scalars['String']>,
  senderAddress?: Maybe<Scalars['String']>,
  senderDetailedAddress?: Maybe<Scalars['String']>,
  receiver?: Maybe<Scalars['String']>,
  receiverTel?: Maybe<Scalars['String']>,
  receiverZipcode?: Maybe<Scalars['String']>,
  receiverAddress?: Maybe<Scalars['String']>,
  receiverDetailedAddress?: Maybe<Scalars['String']>,
  content?: Maybe<Scalars['String']>,
  message?: Maybe<Scalars['String']>,
  note?: Maybe<Scalars['String']>,
  addressRefinedDate?: Maybe<Scalars['DateTime']>,
  addressRefinedValues?: Maybe<Scalars['String']>,
  printedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  brand?: Maybe<BrandUpdateOneRequiredWithoutCourierInvoicesInput>,
  logisticCourier?: Maybe<LogisticCourierUpdateOneWithoutCourierInvoicesInput>,
  parent?: Maybe<CourierInvoiceUpdateOneWithoutChildrenInput>,
  children?: Maybe<CourierInvoiceUpdateManyWithoutParentInput>,
  finalPackages?: Maybe<FinalPackageUpdateManyWithoutCourierInvoiceInput>,
  returnOrders?: Maybe<ReturnOrderUpdateManyWithoutCourierInvoiceInput>,
};

export type BrandUpdateOneRequiredWithoutCourierInvoicesInput = {
  create?: Maybe<BrandCreateWithoutCourierInvoicesInput>,
  connect?: Maybe<BrandWhereUniqueInput>,
  update?: Maybe<BrandUpdateWithoutCourierInvoicesDataInput>,
  upsert?: Maybe<BrandUpsertWithoutCourierInvoicesInput>,
};

export type BrandUpdateWithoutCourierInvoicesDataInput = {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  zipcode?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  detailedAddress?: Maybe<Scalars['String']>,
  isActive?: Maybe<Scalars['Boolean']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  seller?: Maybe<SellerUpdateOneRequiredWithoutBrandsInput>,
  brandMarkets?: Maybe<BrandMarketUpdateManyWithoutBrandInput>,
  products?: Maybe<ProductUpdateManyWithoutBrandInput>,
};

export type BrandMarketUpdateManyWithoutBrandInput = {
  create?: Maybe<Array<BrandMarketCreateWithoutBrandInput>>,
  connect?: Maybe<Array<BrandMarketWhereUniqueInput>>,
  set?: Maybe<Array<BrandMarketWhereUniqueInput>>,
  disconnect?: Maybe<Array<BrandMarketWhereUniqueInput>>,
  delete?: Maybe<Array<BrandMarketWhereUniqueInput>>,
  update?: Maybe<Array<BrandMarketUpdateWithWhereUniqueWithoutBrandInput>>,
  updateMany?: Maybe<Array<BrandMarketUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<BrandMarketScalarWhereInput>>,
  upsert?: Maybe<Array<BrandMarketUpsertWithWhereUniqueWithoutBrandInput>>,
};

export type BrandMarketUpdateWithWhereUniqueWithoutBrandInput = {
  where: BrandMarketWhereUniqueInput,
  data: BrandMarketUpdateWithoutBrandDataInput,
};

export type BrandMarketUpdateWithoutBrandDataInput = {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  sender?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  zipcode?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  detailedAddress?: Maybe<Scalars['String']>,
  credential?: Maybe<Scalars['String']>,
  isActive?: Maybe<Scalars['Boolean']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  market?: Maybe<MarketUpdateOneRequiredWithoutBrandMarketsInput>,
  orders?: Maybe<OrderUpdateManyWithoutBrandMarketInput>,
  autoMatches?: Maybe<AutoMatchUpdateManyWithoutBrandMarketInput>,
};

export type MarketUpdateOneRequiredWithoutBrandMarketsInput = {
  create?: Maybe<MarketCreateWithoutBrandMarketsInput>,
  connect?: Maybe<MarketWhereUniqueInput>,
  update?: Maybe<MarketUpdateWithoutBrandMarketsDataInput>,
  upsert?: Maybe<MarketUpsertWithoutBrandMarketsInput>,
};

export type MarketUpdateWithoutBrandMarketsDataInput = {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  type?: Maybe<CommunicationType>,
  credentialFields?: Maybe<Scalars['String']>,
  dashboardUri?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type MarketUpsertWithoutBrandMarketsInput = {
  update: MarketUpdateWithoutBrandMarketsDataInput,
  create: MarketCreateWithoutBrandMarketsInput,
};

export type OrderUpdateManyWithoutBrandMarketInput = {
  create?: Maybe<Array<OrderCreateWithoutBrandMarketInput>>,
  connect?: Maybe<Array<OrderWhereUniqueInput>>,
  set?: Maybe<Array<OrderWhereUniqueInput>>,
  disconnect?: Maybe<Array<OrderWhereUniqueInput>>,
  delete?: Maybe<Array<OrderWhereUniqueInput>>,
  update?: Maybe<Array<OrderUpdateWithWhereUniqueWithoutBrandMarketInput>>,
  updateMany?: Maybe<Array<OrderUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<OrderScalarWhereInput>>,
  upsert?: Maybe<Array<OrderUpsertWithWhereUniqueWithoutBrandMarketInput>>,
};

export type OrderUpdateWithWhereUniqueWithoutBrandMarketInput = {
  where: OrderWhereUniqueInput,
  data: OrderUpdateWithoutBrandMarketDataInput,
};

export type OrderUpdateWithoutBrandMarketDataInput = {
  id?: Maybe<Scalars['String']>,
  num?: Maybe<Scalars['String']>,
  marketOrderNum?: Maybe<Scalars['String']>,
  orderedDate?: Maybe<Scalars['DateTime']>,
  paidDate?: Maybe<Scalars['DateTime']>,
  sender?: Maybe<Scalars['String']>,
  senderTel?: Maybe<Scalars['String']>,
  senderTel2?: Maybe<Scalars['String']>,
  receiver?: Maybe<Scalars['String']>,
  receiverTel?: Maybe<Scalars['String']>,
  receiverTel2?: Maybe<Scalars['String']>,
  receiverZipcode?: Maybe<Scalars['String']>,
  receiverAddress?: Maybe<Scalars['String']>,
  receiverDetailedAddress?: Maybe<Scalars['String']>,
  message?: Maybe<Scalars['String']>,
  deliveryPaid?: Maybe<Scalars['Boolean']>,
  deliveryPrice?: Maybe<Scalars['Int']>,
  isOverseas?: Maybe<Scalars['Boolean']>,
  remoteAreaPrice?: Maybe<Scalars['Int']>,
  detailedOrder?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  marketProducts?: Maybe<MarketProductUpdateManyWithoutOrderInput>,
  orderNotes?: Maybe<OrderNoteUpdateManyWithoutOrderInput>,
};

export type MarketProductUpdateManyWithoutOrderInput = {
  create?: Maybe<Array<MarketProductCreateWithoutOrderInput>>,
  connect?: Maybe<Array<MarketProductWhereUniqueInput>>,
  set?: Maybe<Array<MarketProductWhereUniqueInput>>,
  disconnect?: Maybe<Array<MarketProductWhereUniqueInput>>,
  delete?: Maybe<Array<MarketProductWhereUniqueInput>>,
  update?: Maybe<Array<MarketProductUpdateWithWhereUniqueWithoutOrderInput>>,
  updateMany?: Maybe<Array<MarketProductUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<MarketProductScalarWhereInput>>,
  upsert?: Maybe<Array<MarketProductUpsertWithWhereUniqueWithoutOrderInput>>,
};

export type MarketProductUpdateWithWhereUniqueWithoutOrderInput = {
  where: MarketProductWhereUniqueInput,
  data: MarketProductUpdateWithoutOrderDataInput,
};

export type MarketProductUpdateWithoutOrderDataInput = {
  id?: Maybe<Scalars['String']>,
  num?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  optionNum?: Maybe<Scalars['String']>,
  optionName?: Maybe<Scalars['String']>,
  amount?: Maybe<Scalars['Int']>,
  totalPrice?: Maybe<Scalars['Int']>,
  status?: Maybe<MarketProductStatus>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  productMatches?: Maybe<ProductMatchUpdateManyWithoutMarketProductInput>,
};

export type ProductMatchUpdateManyWithoutMarketProductInput = {
  create?: Maybe<Array<ProductMatchCreateWithoutMarketProductInput>>,
  connect?: Maybe<Array<ProductMatchWhereUniqueInput>>,
  set?: Maybe<Array<ProductMatchWhereUniqueInput>>,
  disconnect?: Maybe<Array<ProductMatchWhereUniqueInput>>,
  delete?: Maybe<Array<ProductMatchWhereUniqueInput>>,
  update?: Maybe<Array<ProductMatchUpdateWithWhereUniqueWithoutMarketProductInput>>,
  updateMany?: Maybe<Array<ProductMatchUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<ProductMatchScalarWhereInput>>,
  upsert?: Maybe<Array<ProductMatchUpsertWithWhereUniqueWithoutMarketProductInput>>,
};

export type ProductMatchUpdateWithWhereUniqueWithoutMarketProductInput = {
  where: ProductMatchWhereUniqueInput,
  data: ProductMatchUpdateWithoutMarketProductDataInput,
};

export type ProductMatchUpdateWithoutMarketProductDataInput = {
  id?: Maybe<Scalars['String']>,
  amount?: Maybe<Scalars['Int']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  autoMatch?: Maybe<AutoMatchUpdateOneWithoutProductMatchesInput>,
  productOption?: Maybe<ProductOptionUpdateOneRequiredWithoutProductMatchesInput>,
  finalPackages?: Maybe<FinalPackageUpdateManyWithoutProductMatchInput>,
};

export type AutoMatchUpdateOneWithoutProductMatchesInput = {
  create?: Maybe<AutoMatchCreateWithoutProductMatchesInput>,
  connect?: Maybe<AutoMatchWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<AutoMatchUpdateWithoutProductMatchesDataInput>,
  upsert?: Maybe<AutoMatchUpsertWithoutProductMatchesInput>,
};

export type AutoMatchUpdateWithoutProductMatchesDataInput = {
  id?: Maybe<Scalars['String']>,
  marketProductNum?: Maybe<Scalars['String']>,
  marketProductName?: Maybe<Scalars['String']>,
  marketProductOptionNum?: Maybe<Scalars['String']>,
  marketProductOptionName?: Maybe<Scalars['String']>,
  marketProductOptionAmount?: Maybe<Scalars['Int']>,
  productAmount?: Maybe<Scalars['Int']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  brandMarket?: Maybe<BrandMarketUpdateOneRequiredWithoutAutoMatchesInput>,
  productOption?: Maybe<ProductOptionUpdateOneRequiredWithoutAutoMatchesInput>,
};

export type BrandMarketUpdateOneRequiredWithoutAutoMatchesInput = {
  create?: Maybe<BrandMarketCreateWithoutAutoMatchesInput>,
  connect?: Maybe<BrandMarketWhereUniqueInput>,
  update?: Maybe<BrandMarketUpdateWithoutAutoMatchesDataInput>,
  upsert?: Maybe<BrandMarketUpsertWithoutAutoMatchesInput>,
};

export type BrandMarketUpdateWithoutAutoMatchesDataInput = {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  sender?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  zipcode?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  detailedAddress?: Maybe<Scalars['String']>,
  credential?: Maybe<Scalars['String']>,
  isActive?: Maybe<Scalars['Boolean']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  brand?: Maybe<BrandUpdateOneRequiredWithoutBrandMarketsInput>,
  market?: Maybe<MarketUpdateOneRequiredWithoutBrandMarketsInput>,
  orders?: Maybe<OrderUpdateManyWithoutBrandMarketInput>,
};

export type BrandUpdateOneRequiredWithoutBrandMarketsInput = {
  create?: Maybe<BrandCreateWithoutBrandMarketsInput>,
  connect?: Maybe<BrandWhereUniqueInput>,
  update?: Maybe<BrandUpdateWithoutBrandMarketsDataInput>,
  upsert?: Maybe<BrandUpsertWithoutBrandMarketsInput>,
};

export type BrandUpdateWithoutBrandMarketsDataInput = {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  zipcode?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  detailedAddress?: Maybe<Scalars['String']>,
  isActive?: Maybe<Scalars['Boolean']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  seller?: Maybe<SellerUpdateOneRequiredWithoutBrandsInput>,
  products?: Maybe<ProductUpdateManyWithoutBrandInput>,
  courierInvoices?: Maybe<CourierInvoiceUpdateManyWithoutBrandInput>,
};

export type ProductUpdateManyWithoutBrandInput = {
  create?: Maybe<Array<ProductCreateWithoutBrandInput>>,
  connect?: Maybe<Array<ProductWhereUniqueInput>>,
  set?: Maybe<Array<ProductWhereUniqueInput>>,
  disconnect?: Maybe<Array<ProductWhereUniqueInput>>,
  delete?: Maybe<Array<ProductWhereUniqueInput>>,
  update?: Maybe<Array<ProductUpdateWithWhereUniqueWithoutBrandInput>>,
  updateMany?: Maybe<Array<ProductUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<ProductScalarWhereInput>>,
  upsert?: Maybe<Array<ProductUpsertWithWhereUniqueWithoutBrandInput>>,
};

export type ProductUpdateWithWhereUniqueWithoutBrandInput = {
  where: ProductWhereUniqueInput,
  data: ProductUpdateWithoutBrandDataInput,
};

export type ProductUpdateWithoutBrandDataInput = {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  note?: Maybe<Scalars['String']>,
  isActive?: Maybe<Scalars['Boolean']>,
  hasLevel?: Maybe<Scalars['Boolean']>,
  levelOneName?: Maybe<Scalars['String']>,
  levelTwoName?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  productOptions?: Maybe<ProductOptionUpdateManyWithoutProductInput>,
};

export type ProductOptionUpdateManyWithoutProductInput = {
  create?: Maybe<Array<ProductOptionCreateWithoutProductInput>>,
  connect?: Maybe<Array<ProductOptionWhereUniqueInput>>,
  set?: Maybe<Array<ProductOptionWhereUniqueInput>>,
  disconnect?: Maybe<Array<ProductOptionWhereUniqueInput>>,
  delete?: Maybe<Array<ProductOptionWhereUniqueInput>>,
  update?: Maybe<Array<ProductOptionUpdateWithWhereUniqueWithoutProductInput>>,
  updateMany?: Maybe<Array<ProductOptionUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<ProductOptionScalarWhereInput>>,
  upsert?: Maybe<Array<ProductOptionUpsertWithWhereUniqueWithoutProductInput>>,
};

export type ProductOptionUpdateWithWhereUniqueWithoutProductInput = {
  where: ProductOptionWhereUniqueInput,
  data: ProductOptionUpdateWithoutProductDataInput,
};

export type ProductOptionUpdateWithoutProductDataInput = {
  id?: Maybe<Scalars['String']>,
  levelOne?: Maybe<Scalars['String']>,
  levelTwo?: Maybe<Scalars['String']>,
  originNum?: Maybe<Scalars['String']>,
  originBarcode?: Maybe<Scalars['String']>,
  newBarcode?: Maybe<Scalars['String']>,
  needInspection?: Maybe<Scalars['Boolean']>,
  temperature?: Maybe<TemperatureType>,
  bestPeriodDay?: Maybe<Scalars['Int']>,
  basicCourierPriceRange?: Maybe<BasicCourierPriceRange>,
  isActive?: Maybe<Scalars['Boolean']>,
  canBeMixed?: Maybe<Scalars['Boolean']>,
  maxAmount?: Maybe<Scalars['Int']>,
  note?: Maybe<Scalars['String']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  category?: Maybe<ProductCategoryUpdateOneWithoutProductOptionsInput>,
  preStocks?: Maybe<PreStockUpdateManyWithoutProductOptionInput>,
  transactions?: Maybe<TransactionUpdateManyWithoutProductOptionInput>,
  autoMatches?: Maybe<AutoMatchUpdateManyWithoutProductOptionInput>,
  productMatches?: Maybe<ProductMatchUpdateManyWithoutProductOptionInput>,
};

export type ProductCategoryUpdateOneWithoutProductOptionsInput = {
  create?: Maybe<ProductCategoryCreateWithoutProductOptionsInput>,
  connect?: Maybe<ProductCategoryWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<ProductCategoryUpdateWithoutProductOptionsDataInput>,
  upsert?: Maybe<ProductCategoryUpsertWithoutProductOptionsInput>,
};

export type ProductCategoryUpdateWithoutProductOptionsDataInput = {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type ProductCategoryUpsertWithoutProductOptionsInput = {
  update: ProductCategoryUpdateWithoutProductOptionsDataInput,
  create: ProductCategoryCreateWithoutProductOptionsInput,
};

export type PreStockUpdateManyWithoutProductOptionInput = {
  create?: Maybe<Array<PreStockCreateWithoutProductOptionInput>>,
  connect?: Maybe<Array<PreStockWhereUniqueInput>>,
  set?: Maybe<Array<PreStockWhereUniqueInput>>,
  disconnect?: Maybe<Array<PreStockWhereUniqueInput>>,
  delete?: Maybe<Array<PreStockWhereUniqueInput>>,
  update?: Maybe<Array<PreStockUpdateWithWhereUniqueWithoutProductOptionInput>>,
  updateMany?: Maybe<Array<PreStockUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<PreStockScalarWhereInput>>,
  upsert?: Maybe<Array<PreStockUpsertWithWhereUniqueWithoutProductOptionInput>>,
};

export type PreStockUpdateWithWhereUniqueWithoutProductOptionInput = {
  where: PreStockWhereUniqueInput,
  data: PreStockUpdateWithoutProductOptionDataInput,
};

export type PreStockUpdateWithoutProductOptionDataInput = {
  id?: Maybe<Scalars['String']>,
  amount?: Maybe<Scalars['Int']>,
  loadUnit?: Maybe<LoadUnit>,
  loadUnitAmount?: Maybe<Scalars['Int']>,
  scheduledDate?: Maybe<Scalars['DateTime']>,
  confirmedDate?: Maybe<Scalars['DateTime']>,
  note?: Maybe<Scalars['String']>,
  manufacturedDate?: Maybe<Scalars['DateTime']>,
  expiryDate?: Maybe<Scalars['DateTime']>,
  canceledAt?: Maybe<Scalars['DateTime']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  logistic?: Maybe<LogisticUpdateOneRequiredWithoutPreStocksInput>,
  registeredUser?: Maybe<UserUpdateOneRequiredWithoutRegisteredPreStocksInput>,
  confirmedUser?: Maybe<UserUpdateOneWithoutConfirmedPreStocksInput>,
  transactions?: Maybe<TransactionUpdateManyWithoutPreStockInput>,
};

export type LogisticUpdateOneRequiredWithoutPreStocksInput = {
  create?: Maybe<LogisticCreateWithoutPreStocksInput>,
  connect?: Maybe<LogisticWhereUniqueInput>,
  update?: Maybe<LogisticUpdateWithoutPreStocksDataInput>,
  upsert?: Maybe<LogisticUpsertWithoutPreStocksInput>,
};

export type LogisticUpdateWithoutPreStocksDataInput = {
  id?: Maybe<Scalars['String']>,
  marketing?: Maybe<Scalars['Boolean']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  company?: Maybe<CompanyUpdateOneRequiredWithoutLogisticInput>,
  defaultUser?: Maybe<UserUpdateOneRequiredWithoutLogisticdefaultOfInput>,
  defaultCourier?: Maybe<LogisticCourierUpdateOneWithoutLogisticDefaultOfInput>,
  sellers?: Maybe<SellerUpdateManyWithoutLogisticInput>,
  availableCouriers?: Maybe<LogisticCourierUpdateManyWithoutLogisticInput>,
  packets?: Maybe<PacketUpdateManyWithoutLogisticInput>,
  centers?: Maybe<CenterUpdateManyWithoutLogisticInput>,
  transactions?: Maybe<TransactionUpdateManyWithoutLogisticInput>,
  notices?: Maybe<NoticeUpdateManyWithoutLogisticInput>,
};

export type LogisticCourierUpdateManyWithoutLogisticInput = {
  create?: Maybe<Array<LogisticCourierCreateWithoutLogisticInput>>,
  connect?: Maybe<Array<LogisticCourierWhereUniqueInput>>,
  set?: Maybe<Array<LogisticCourierWhereUniqueInput>>,
  disconnect?: Maybe<Array<LogisticCourierWhereUniqueInput>>,
  delete?: Maybe<Array<LogisticCourierWhereUniqueInput>>,
  update?: Maybe<Array<LogisticCourierUpdateWithWhereUniqueWithoutLogisticInput>>,
  updateMany?: Maybe<Array<LogisticCourierUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<LogisticCourierScalarWhereInput>>,
  upsert?: Maybe<Array<LogisticCourierUpsertWithWhereUniqueWithoutLogisticInput>>,
};

export type LogisticCourierUpdateWithWhereUniqueWithoutLogisticInput = {
  where: LogisticCourierWhereUniqueInput,
  data: LogisticCourierUpdateWithoutLogisticDataInput,
};

export type LogisticCourierUpdateWithoutLogisticDataInput = {
  id?: Maybe<Scalars['String']>,
  config?: Maybe<Scalars['String']>,
  isActive?: Maybe<Scalars['Boolean']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  courier?: Maybe<CourierUpdateOneRequiredWithoutLogisticCouriersInput>,
  logisticDefaultOf?: Maybe<LogisticUpdateOneWithoutDefaultCourierInput>,
  sellerDefaultOf?: Maybe<SellerUpdateManyWithoutDefaultCourierInput>,
  courierInvoices?: Maybe<CourierInvoiceUpdateManyWithoutLogisticCourierInput>,
  courierInvoiceTrackings?: Maybe<CourierInvoiceTrackingUpdateManyWithoutLogisticCourierInput>,
};

export type LogisticUpdateOneWithoutDefaultCourierInput = {
  create?: Maybe<LogisticCreateWithoutDefaultCourierInput>,
  connect?: Maybe<LogisticWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<LogisticUpdateWithoutDefaultCourierDataInput>,
  upsert?: Maybe<LogisticUpsertWithoutDefaultCourierInput>,
};

export type LogisticUpdateWithoutDefaultCourierDataInput = {
  id?: Maybe<Scalars['String']>,
  marketing?: Maybe<Scalars['Boolean']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  company?: Maybe<CompanyUpdateOneRequiredWithoutLogisticInput>,
  defaultUser?: Maybe<UserUpdateOneRequiredWithoutLogisticdefaultOfInput>,
  sellers?: Maybe<SellerUpdateManyWithoutLogisticInput>,
  availableCouriers?: Maybe<LogisticCourierUpdateManyWithoutLogisticInput>,
  packets?: Maybe<PacketUpdateManyWithoutLogisticInput>,
  centers?: Maybe<CenterUpdateManyWithoutLogisticInput>,
  preStocks?: Maybe<PreStockUpdateManyWithoutLogisticInput>,
  transactions?: Maybe<TransactionUpdateManyWithoutLogisticInput>,
  notices?: Maybe<NoticeUpdateManyWithoutLogisticInput>,
};

export type PacketUpdateManyWithoutLogisticInput = {
  create?: Maybe<Array<PacketCreateWithoutLogisticInput>>,
  connect?: Maybe<Array<PacketWhereUniqueInput>>,
  set?: Maybe<Array<PacketWhereUniqueInput>>,
  disconnect?: Maybe<Array<PacketWhereUniqueInput>>,
  delete?: Maybe<Array<PacketWhereUniqueInput>>,
  update?: Maybe<Array<PacketUpdateWithWhereUniqueWithoutLogisticInput>>,
  updateMany?: Maybe<Array<PacketUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<PacketScalarWhereInput>>,
  upsert?: Maybe<Array<PacketUpsertWithWhereUniqueWithoutLogisticInput>>,
};

export type PacketUpdateWithWhereUniqueWithoutLogisticInput = {
  where: PacketWhereUniqueInput,
  data: PacketUpdateWithoutLogisticDataInput,
};

export type PacketUpdateWithoutLogisticDataInput = {
  id?: Maybe<Scalars['String']>,
  startDate?: Maybe<Scalars['DateTime']>,
  endDate?: Maybe<Scalars['DateTime']>,
  isPaid?: Maybe<Scalars['Boolean']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  pricing?: Maybe<PricingUpdateOneRequiredWithoutPacketsInput>,
};

export type PricingUpdateOneRequiredWithoutPacketsInput = {
  create?: Maybe<PricingCreateWithoutPacketsInput>,
  connect?: Maybe<PricingWhereUniqueInput>,
  update?: Maybe<PricingUpdateWithoutPacketsDataInput>,
  upsert?: Maybe<PricingUpsertWithoutPacketsInput>,
};

export type PricingUpdateWithoutPacketsDataInput = {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  count?: Maybe<Scalars['Int']>,
  price?: Maybe<Scalars['Int']>,
  period?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type PricingUpsertWithoutPacketsInput = {
  update: PricingUpdateWithoutPacketsDataInput,
  create: PricingCreateWithoutPacketsInput,
};

export type PacketUpdateManyWithWhereNestedInput = {
  where: PacketScalarWhereInput,
  data: PacketUpdateManyDataInput,
};

export type PacketScalarWhereInput = {
  id?: Maybe<StringFilter>,
  logisticId?: Maybe<StringFilter>,
  pricingId?: Maybe<StringFilter>,
  startDate?: Maybe<DateTimeFilter>,
  endDate?: Maybe<DateTimeFilter>,
  isPaid?: Maybe<BooleanFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  AND?: Maybe<Array<PacketScalarWhereInput>>,
  OR?: Maybe<Array<PacketScalarWhereInput>>,
  NOT?: Maybe<Array<PacketScalarWhereInput>>,
};

export type PacketUpdateManyDataInput = {
  id?: Maybe<Scalars['String']>,
  startDate?: Maybe<Scalars['DateTime']>,
  endDate?: Maybe<Scalars['DateTime']>,
  isPaid?: Maybe<Scalars['Boolean']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type PacketUpsertWithWhereUniqueWithoutLogisticInput = {
  where: PacketWhereUniqueInput,
  update: PacketUpdateWithoutLogisticDataInput,
  create: PacketCreateWithoutLogisticInput,
};

export type CenterUpdateManyWithoutLogisticInput = {
  create?: Maybe<Array<CenterCreateWithoutLogisticInput>>,
  connect?: Maybe<Array<CenterWhereUniqueInput>>,
  set?: Maybe<Array<CenterWhereUniqueInput>>,
  disconnect?: Maybe<Array<CenterWhereUniqueInput>>,
  delete?: Maybe<Array<CenterWhereUniqueInput>>,
  update?: Maybe<Array<CenterUpdateWithWhereUniqueWithoutLogisticInput>>,
  updateMany?: Maybe<Array<CenterUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<CenterScalarWhereInput>>,
  upsert?: Maybe<Array<CenterUpsertWithWhereUniqueWithoutLogisticInput>>,
};

export type CenterUpdateWithWhereUniqueWithoutLogisticInput = {
  where: CenterWhereUniqueInput,
  data: CenterUpdateWithoutLogisticDataInput,
};

export type CenterUpdateWithoutLogisticDataInput = {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  zipcode?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  detailedAddress?: Maybe<Scalars['String']>,
  shippingAvailable?: Maybe<Scalars['Boolean']>,
  isActive?: Maybe<Scalars['Boolean']>,
  useLocation?: Maybe<Scalars['Boolean']>,
  totalQuantity?: Maybe<Scalars['Int']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  settings?: Maybe<LocationSettingUpdateManyWithoutCenterInput>,
  locations?: Maybe<LocationUpdateManyWithoutCenterInput>,
};

export type LocationSettingUpdateManyWithoutCenterInput = {
  create?: Maybe<Array<LocationSettingCreateWithoutCenterInput>>,
  connect?: Maybe<Array<LocationSettingWhereUniqueInput>>,
  set?: Maybe<Array<LocationSettingWhereUniqueInput>>,
  disconnect?: Maybe<Array<LocationSettingWhereUniqueInput>>,
  delete?: Maybe<Array<LocationSettingWhereUniqueInput>>,
  update?: Maybe<Array<LocationSettingUpdateWithWhereUniqueWithoutCenterInput>>,
  updateMany?: Maybe<Array<LocationSettingUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<LocationSettingScalarWhereInput>>,
  upsert?: Maybe<Array<LocationSettingUpsertWithWhereUniqueWithoutCenterInput>>,
};

export type LocationSettingUpdateWithWhereUniqueWithoutCenterInput = {
  where: LocationSettingWhereUniqueInput,
  data: LocationSettingUpdateWithoutCenterDataInput,
};

export type LocationSettingUpdateWithoutCenterDataInput = {
  id?: Maybe<Scalars['String']>,
  zone?: Maybe<Scalars['String']>,
  rack?: Maybe<Scalars['String']>,
  level?: Maybe<Scalars['String']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  locations?: Maybe<LocationUpdateManyWithoutSettingInput>,
};

export type LocationUpdateManyWithoutSettingInput = {
  create?: Maybe<Array<LocationCreateWithoutSettingInput>>,
  connect?: Maybe<Array<LocationWhereUniqueInput>>,
  set?: Maybe<Array<LocationWhereUniqueInput>>,
  disconnect?: Maybe<Array<LocationWhereUniqueInput>>,
  delete?: Maybe<Array<LocationWhereUniqueInput>>,
  update?: Maybe<Array<LocationUpdateWithWhereUniqueWithoutSettingInput>>,
  updateMany?: Maybe<Array<LocationUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<LocationScalarWhereInput>>,
  upsert?: Maybe<Array<LocationUpsertWithWhereUniqueWithoutSettingInput>>,
};

export type LocationUpdateWithWhereUniqueWithoutSettingInput = {
  where: LocationWhereUniqueInput,
  data: LocationUpdateWithoutSettingDataInput,
};

export type LocationUpdateWithoutSettingDataInput = {
  id?: Maybe<Scalars['String']>,
  zone?: Maybe<Scalars['String']>,
  rack?: Maybe<Scalars['String']>,
  level?: Maybe<Scalars['String']>,
  isActive?: Maybe<Scalars['Boolean']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  center?: Maybe<CenterUpdateOneRequiredWithoutLocationsInput>,
  incomingRecords?: Maybe<TransactionUpdateManyWithoutLocationInput>,
};

export type CenterUpdateOneRequiredWithoutLocationsInput = {
  create?: Maybe<CenterCreateWithoutLocationsInput>,
  connect?: Maybe<CenterWhereUniqueInput>,
  update?: Maybe<CenterUpdateWithoutLocationsDataInput>,
  upsert?: Maybe<CenterUpsertWithoutLocationsInput>,
};

export type CenterUpdateWithoutLocationsDataInput = {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  zipcode?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  detailedAddress?: Maybe<Scalars['String']>,
  shippingAvailable?: Maybe<Scalars['Boolean']>,
  isActive?: Maybe<Scalars['Boolean']>,
  useLocation?: Maybe<Scalars['Boolean']>,
  totalQuantity?: Maybe<Scalars['Int']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  logistic?: Maybe<LogisticUpdateOneRequiredWithoutCentersInput>,
  settings?: Maybe<LocationSettingUpdateManyWithoutCenterInput>,
};

export type LogisticUpdateOneRequiredWithoutCentersInput = {
  create?: Maybe<LogisticCreateWithoutCentersInput>,
  connect?: Maybe<LogisticWhereUniqueInput>,
  update?: Maybe<LogisticUpdateWithoutCentersDataInput>,
  upsert?: Maybe<LogisticUpsertWithoutCentersInput>,
};

export type LogisticUpdateWithoutCentersDataInput = {
  id?: Maybe<Scalars['String']>,
  marketing?: Maybe<Scalars['Boolean']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  company?: Maybe<CompanyUpdateOneRequiredWithoutLogisticInput>,
  defaultUser?: Maybe<UserUpdateOneRequiredWithoutLogisticdefaultOfInput>,
  defaultCourier?: Maybe<LogisticCourierUpdateOneWithoutLogisticDefaultOfInput>,
  sellers?: Maybe<SellerUpdateManyWithoutLogisticInput>,
  availableCouriers?: Maybe<LogisticCourierUpdateManyWithoutLogisticInput>,
  packets?: Maybe<PacketUpdateManyWithoutLogisticInput>,
  preStocks?: Maybe<PreStockUpdateManyWithoutLogisticInput>,
  transactions?: Maybe<TransactionUpdateManyWithoutLogisticInput>,
  notices?: Maybe<NoticeUpdateManyWithoutLogisticInput>,
};

export type PreStockUpdateManyWithoutLogisticInput = {
  create?: Maybe<Array<PreStockCreateWithoutLogisticInput>>,
  connect?: Maybe<Array<PreStockWhereUniqueInput>>,
  set?: Maybe<Array<PreStockWhereUniqueInput>>,
  disconnect?: Maybe<Array<PreStockWhereUniqueInput>>,
  delete?: Maybe<Array<PreStockWhereUniqueInput>>,
  update?: Maybe<Array<PreStockUpdateWithWhereUniqueWithoutLogisticInput>>,
  updateMany?: Maybe<Array<PreStockUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<PreStockScalarWhereInput>>,
  upsert?: Maybe<Array<PreStockUpsertWithWhereUniqueWithoutLogisticInput>>,
};

export type PreStockUpdateWithWhereUniqueWithoutLogisticInput = {
  where: PreStockWhereUniqueInput,
  data: PreStockUpdateWithoutLogisticDataInput,
};

export type PreStockUpdateWithoutLogisticDataInput = {
  id?: Maybe<Scalars['String']>,
  amount?: Maybe<Scalars['Int']>,
  loadUnit?: Maybe<LoadUnit>,
  loadUnitAmount?: Maybe<Scalars['Int']>,
  scheduledDate?: Maybe<Scalars['DateTime']>,
  confirmedDate?: Maybe<Scalars['DateTime']>,
  note?: Maybe<Scalars['String']>,
  manufacturedDate?: Maybe<Scalars['DateTime']>,
  expiryDate?: Maybe<Scalars['DateTime']>,
  canceledAt?: Maybe<Scalars['DateTime']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  productOption?: Maybe<ProductOptionUpdateOneRequiredWithoutPreStocksInput>,
  registeredUser?: Maybe<UserUpdateOneRequiredWithoutRegisteredPreStocksInput>,
  confirmedUser?: Maybe<UserUpdateOneWithoutConfirmedPreStocksInput>,
  transactions?: Maybe<TransactionUpdateManyWithoutPreStockInput>,
};

export type ProductOptionUpdateOneRequiredWithoutPreStocksInput = {
  create?: Maybe<ProductOptionCreateWithoutPreStocksInput>,
  connect?: Maybe<ProductOptionWhereUniqueInput>,
  update?: Maybe<ProductOptionUpdateWithoutPreStocksDataInput>,
  upsert?: Maybe<ProductOptionUpsertWithoutPreStocksInput>,
};

export type ProductOptionUpdateWithoutPreStocksDataInput = {
  id?: Maybe<Scalars['String']>,
  levelOne?: Maybe<Scalars['String']>,
  levelTwo?: Maybe<Scalars['String']>,
  originNum?: Maybe<Scalars['String']>,
  originBarcode?: Maybe<Scalars['String']>,
  newBarcode?: Maybe<Scalars['String']>,
  needInspection?: Maybe<Scalars['Boolean']>,
  temperature?: Maybe<TemperatureType>,
  bestPeriodDay?: Maybe<Scalars['Int']>,
  basicCourierPriceRange?: Maybe<BasicCourierPriceRange>,
  isActive?: Maybe<Scalars['Boolean']>,
  canBeMixed?: Maybe<Scalars['Boolean']>,
  maxAmount?: Maybe<Scalars['Int']>,
  note?: Maybe<Scalars['String']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  product?: Maybe<ProductUpdateOneRequiredWithoutProductOptionsInput>,
  category?: Maybe<ProductCategoryUpdateOneWithoutProductOptionsInput>,
  transactions?: Maybe<TransactionUpdateManyWithoutProductOptionInput>,
  autoMatches?: Maybe<AutoMatchUpdateManyWithoutProductOptionInput>,
  productMatches?: Maybe<ProductMatchUpdateManyWithoutProductOptionInput>,
};

export type ProductUpdateOneRequiredWithoutProductOptionsInput = {
  create?: Maybe<ProductCreateWithoutProductOptionsInput>,
  connect?: Maybe<ProductWhereUniqueInput>,
  update?: Maybe<ProductUpdateWithoutProductOptionsDataInput>,
  upsert?: Maybe<ProductUpsertWithoutProductOptionsInput>,
};

export type ProductUpdateWithoutProductOptionsDataInput = {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  note?: Maybe<Scalars['String']>,
  isActive?: Maybe<Scalars['Boolean']>,
  hasLevel?: Maybe<Scalars['Boolean']>,
  levelOneName?: Maybe<Scalars['String']>,
  levelTwoName?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  brand?: Maybe<BrandUpdateOneRequiredWithoutProductsInput>,
};

export type BrandUpdateOneRequiredWithoutProductsInput = {
  create?: Maybe<BrandCreateWithoutProductsInput>,
  connect?: Maybe<BrandWhereUniqueInput>,
  update?: Maybe<BrandUpdateWithoutProductsDataInput>,
  upsert?: Maybe<BrandUpsertWithoutProductsInput>,
};

export type BrandUpdateWithoutProductsDataInput = {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  zipcode?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  detailedAddress?: Maybe<Scalars['String']>,
  isActive?: Maybe<Scalars['Boolean']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  seller?: Maybe<SellerUpdateOneRequiredWithoutBrandsInput>,
  brandMarkets?: Maybe<BrandMarketUpdateManyWithoutBrandInput>,
  courierInvoices?: Maybe<CourierInvoiceUpdateManyWithoutBrandInput>,
};

export type CourierInvoiceUpdateManyWithoutBrandInput = {
  create?: Maybe<Array<CourierInvoiceCreateWithoutBrandInput>>,
  connect?: Maybe<Array<CourierInvoiceWhereUniqueInput>>,
  set?: Maybe<Array<CourierInvoiceWhereUniqueInput>>,
  disconnect?: Maybe<Array<CourierInvoiceWhereUniqueInput>>,
  delete?: Maybe<Array<CourierInvoiceWhereUniqueInput>>,
  update?: Maybe<Array<CourierInvoiceUpdateWithWhereUniqueWithoutBrandInput>>,
  updateMany?: Maybe<Array<CourierInvoiceUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<CourierInvoiceScalarWhereInput>>,
  upsert?: Maybe<Array<CourierInvoiceUpsertWithWhereUniqueWithoutBrandInput>>,
};

export type CourierInvoiceUpdateWithWhereUniqueWithoutBrandInput = {
  where: CourierInvoiceWhereUniqueInput,
  data: CourierInvoiceUpdateWithoutBrandDataInput,
};

export type CourierInvoiceUpdateWithoutBrandDataInput = {
  id?: Maybe<Scalars['String']>,
  type?: Maybe<CourierInvoiceType>,
  courierName?: Maybe<Scalars['String']>,
  num?: Maybe<Scalars['String']>,
  groupNum?: Maybe<Scalars['Int']>,
  printedNum?: Maybe<Scalars['Int']>,
  invoiceStatus?: Maybe<CourierInvoiceStatus>,
  status?: Maybe<PackageStatus>,
  sender?: Maybe<Scalars['String']>,
  senderTel?: Maybe<Scalars['String']>,
  senderZipcode?: Maybe<Scalars['String']>,
  senderAddress?: Maybe<Scalars['String']>,
  senderDetailedAddress?: Maybe<Scalars['String']>,
  receiver?: Maybe<Scalars['String']>,
  receiverTel?: Maybe<Scalars['String']>,
  receiverZipcode?: Maybe<Scalars['String']>,
  receiverAddress?: Maybe<Scalars['String']>,
  receiverDetailedAddress?: Maybe<Scalars['String']>,
  content?: Maybe<Scalars['String']>,
  message?: Maybe<Scalars['String']>,
  note?: Maybe<Scalars['String']>,
  addressRefinedDate?: Maybe<Scalars['DateTime']>,
  addressRefinedValues?: Maybe<Scalars['String']>,
  printedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  logisticCourier?: Maybe<LogisticCourierUpdateOneWithoutCourierInvoicesInput>,
  parent?: Maybe<CourierInvoiceUpdateOneWithoutChildrenInput>,
  courierPriceRange?: Maybe<CourierPriceRangeUpdateOneWithoutCourierInvoicesInput>,
  children?: Maybe<CourierInvoiceUpdateManyWithoutParentInput>,
  finalPackages?: Maybe<FinalPackageUpdateManyWithoutCourierInvoiceInput>,
  returnOrders?: Maybe<ReturnOrderUpdateManyWithoutCourierInvoiceInput>,
};

export type LogisticCourierUpdateOneWithoutCourierInvoicesInput = {
  create?: Maybe<LogisticCourierCreateWithoutCourierInvoicesInput>,
  connect?: Maybe<LogisticCourierWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<LogisticCourierUpdateWithoutCourierInvoicesDataInput>,
  upsert?: Maybe<LogisticCourierUpsertWithoutCourierInvoicesInput>,
};

export type LogisticCourierUpdateWithoutCourierInvoicesDataInput = {
  id?: Maybe<Scalars['String']>,
  config?: Maybe<Scalars['String']>,
  isActive?: Maybe<Scalars['Boolean']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  logistic?: Maybe<LogisticUpdateOneRequiredWithoutAvailableCouriersInput>,
  courier?: Maybe<CourierUpdateOneRequiredWithoutLogisticCouriersInput>,
  logisticDefaultOf?: Maybe<LogisticUpdateOneWithoutDefaultCourierInput>,
  sellerDefaultOf?: Maybe<SellerUpdateManyWithoutDefaultCourierInput>,
  courierInvoiceTrackings?: Maybe<CourierInvoiceTrackingUpdateManyWithoutLogisticCourierInput>,
};

export type SellerUpdateManyWithoutDefaultCourierInput = {
  create?: Maybe<Array<SellerCreateWithoutDefaultCourierInput>>,
  connect?: Maybe<Array<SellerWhereUniqueInput>>,
  set?: Maybe<Array<SellerWhereUniqueInput>>,
  disconnect?: Maybe<Array<SellerWhereUniqueInput>>,
  delete?: Maybe<Array<SellerWhereUniqueInput>>,
  update?: Maybe<Array<SellerUpdateWithWhereUniqueWithoutDefaultCourierInput>>,
  updateMany?: Maybe<Array<SellerUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<SellerScalarWhereInput>>,
  upsert?: Maybe<Array<SellerUpsertWithWhereUniqueWithoutDefaultCourierInput>>,
};

export type SellerUpdateWithWhereUniqueWithoutDefaultCourierInput = {
  where: SellerWhereUniqueInput,
  data: SellerUpdateWithoutDefaultCourierDataInput,
};

export type SellerUpdateWithoutDefaultCourierDataInput = {
  id?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  company?: Maybe<CompanyUpdateOneRequiredWithoutSellerInput>,
  logistic?: Maybe<LogisticUpdateOneRequiredWithoutSellersInput>,
  brands?: Maybe<BrandUpdateManyWithoutSellerInput>,
  notices?: Maybe<NoticeUpdateManyWithoutSellerInput>,
};

export type BrandUpdateManyWithoutSellerInput = {
  create?: Maybe<Array<BrandCreateWithoutSellerInput>>,
  connect?: Maybe<Array<BrandWhereUniqueInput>>,
  set?: Maybe<Array<BrandWhereUniqueInput>>,
  disconnect?: Maybe<Array<BrandWhereUniqueInput>>,
  delete?: Maybe<Array<BrandWhereUniqueInput>>,
  update?: Maybe<Array<BrandUpdateWithWhereUniqueWithoutSellerInput>>,
  updateMany?: Maybe<Array<BrandUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<BrandScalarWhereInput>>,
  upsert?: Maybe<Array<BrandUpsertWithWhereUniqueWithoutSellerInput>>,
};

export type BrandUpdateWithWhereUniqueWithoutSellerInput = {
  where: BrandWhereUniqueInput,
  data: BrandUpdateWithoutSellerDataInput,
};

export type BrandUpdateWithoutSellerDataInput = {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  zipcode?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  detailedAddress?: Maybe<Scalars['String']>,
  isActive?: Maybe<Scalars['Boolean']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  brandMarkets?: Maybe<BrandMarketUpdateManyWithoutBrandInput>,
  products?: Maybe<ProductUpdateManyWithoutBrandInput>,
  courierInvoices?: Maybe<CourierInvoiceUpdateManyWithoutBrandInput>,
};

export type BrandUpdateManyWithWhereNestedInput = {
  where: BrandScalarWhereInput,
  data: BrandUpdateManyDataInput,
};

export type BrandScalarWhereInput = {
  id?: Maybe<StringFilter>,
  sellerId?: Maybe<StringFilter>,
  name?: Maybe<StringFilter>,
  phone?: Maybe<NullableStringFilter>,
  zipcode?: Maybe<NullableStringFilter>,
  address?: Maybe<NullableStringFilter>,
  detailedAddress?: Maybe<NullableStringFilter>,
  isActive?: Maybe<BooleanFilter>,
  deletedAt?: Maybe<NullableDateTimeFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  brandMarkets?: Maybe<BrandMarketFilter>,
  products?: Maybe<ProductFilter>,
  courierInvoices?: Maybe<CourierInvoiceFilter>,
  AND?: Maybe<Array<BrandScalarWhereInput>>,
  OR?: Maybe<Array<BrandScalarWhereInput>>,
  NOT?: Maybe<Array<BrandScalarWhereInput>>,
};

export type BrandUpdateManyDataInput = {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  zipcode?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  detailedAddress?: Maybe<Scalars['String']>,
  isActive?: Maybe<Scalars['Boolean']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type BrandUpsertWithWhereUniqueWithoutSellerInput = {
  where: BrandWhereUniqueInput,
  update: BrandUpdateWithoutSellerDataInput,
  create: BrandCreateWithoutSellerInput,
};

export type NoticeUpdateManyWithoutSellerInput = {
  create?: Maybe<Array<NoticeCreateWithoutSellerInput>>,
  connect?: Maybe<Array<NoticeWhereUniqueInput>>,
  set?: Maybe<Array<NoticeWhereUniqueInput>>,
  disconnect?: Maybe<Array<NoticeWhereUniqueInput>>,
  delete?: Maybe<Array<NoticeWhereUniqueInput>>,
  update?: Maybe<Array<NoticeUpdateWithWhereUniqueWithoutSellerInput>>,
  updateMany?: Maybe<Array<NoticeUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<NoticeScalarWhereInput>>,
  upsert?: Maybe<Array<NoticeUpsertWithWhereUniqueWithoutSellerInput>>,
};

export type NoticeUpdateWithWhereUniqueWithoutSellerInput = {
  where: NoticeWhereUniqueInput,
  data: NoticeUpdateWithoutSellerDataInput,
};

export type NoticeUpdateWithoutSellerDataInput = {
  id?: Maybe<Scalars['String']>,
  groupId?: Maybe<Scalars['String']>,
  expiryDate?: Maybe<Scalars['DateTime']>,
  title?: Maybe<Scalars['String']>,
  content?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  isPublic?: Maybe<Scalars['Boolean']>,
  isImportant?: Maybe<Scalars['Boolean']>,
  logistic?: Maybe<LogisticUpdateOneRequiredWithoutNoticesInput>,
  author?: Maybe<UserUpdateOneRequiredWithoutNoticesInput>,
};

export type LogisticUpdateOneRequiredWithoutNoticesInput = {
  create?: Maybe<LogisticCreateWithoutNoticesInput>,
  connect?: Maybe<LogisticWhereUniqueInput>,
  update?: Maybe<LogisticUpdateWithoutNoticesDataInput>,
  upsert?: Maybe<LogisticUpsertWithoutNoticesInput>,
};

export type LogisticUpdateWithoutNoticesDataInput = {
  id?: Maybe<Scalars['String']>,
  marketing?: Maybe<Scalars['Boolean']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  company?: Maybe<CompanyUpdateOneRequiredWithoutLogisticInput>,
  defaultUser?: Maybe<UserUpdateOneRequiredWithoutLogisticdefaultOfInput>,
  defaultCourier?: Maybe<LogisticCourierUpdateOneWithoutLogisticDefaultOfInput>,
  sellers?: Maybe<SellerUpdateManyWithoutLogisticInput>,
  availableCouriers?: Maybe<LogisticCourierUpdateManyWithoutLogisticInput>,
  packets?: Maybe<PacketUpdateManyWithoutLogisticInput>,
  centers?: Maybe<CenterUpdateManyWithoutLogisticInput>,
  preStocks?: Maybe<PreStockUpdateManyWithoutLogisticInput>,
  transactions?: Maybe<TransactionUpdateManyWithoutLogisticInput>,
};

export type TransactionUpdateManyWithoutLogisticInput = {
  create?: Maybe<Array<TransactionCreateWithoutLogisticInput>>,
  connect?: Maybe<Array<TransactionWhereUniqueInput>>,
  set?: Maybe<Array<TransactionWhereUniqueInput>>,
  disconnect?: Maybe<Array<TransactionWhereUniqueInput>>,
  delete?: Maybe<Array<TransactionWhereUniqueInput>>,
  update?: Maybe<Array<TransactionUpdateWithWhereUniqueWithoutLogisticInput>>,
  updateMany?: Maybe<Array<TransactionUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<TransactionScalarWhereInput>>,
  upsert?: Maybe<Array<TransactionUpsertWithWhereUniqueWithoutLogisticInput>>,
};

export type TransactionUpdateWithWhereUniqueWithoutLogisticInput = {
  where: TransactionWhereUniqueInput,
  data: TransactionUpdateWithoutLogisticDataInput,
};

export type TransactionUpdateWithoutLogisticDataInput = {
  id?: Maybe<Scalars['String']>,
  manufacturedDate?: Maybe<Scalars['DateTime']>,
  expiryDate?: Maybe<Scalars['DateTime']>,
  eventType?: Maybe<EventType>,
  transportType?: Maybe<TransportType>,
  usableAmount?: Maybe<Scalars['Int']>,
  brokenAmount?: Maybe<Scalars['Int']>,
  note?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  preStock?: Maybe<PreStockUpdateOneWithoutTransactionsInput>,
  parent?: Maybe<TransactionUpdateOneWithoutChildrenInput>,
  location?: Maybe<LocationUpdateOneWithoutIncomingRecordsInput>,
  productOption?: Maybe<ProductOptionUpdateOneRequiredWithoutTransactionsInput>,
  confirmedUser?: Maybe<UserUpdateOneWithoutConfirmedTransactionsInput>,
  finalPackage?: Maybe<FinalPackageUpdateOneWithoutTransactionsInput>,
  stocktaking?: Maybe<StocktakingUpdateOneWithoutTransactionsInput>,
  children?: Maybe<TransactionUpdateManyWithoutParentInput>,
  stocktakings?: Maybe<StocktakingUpdateManyWithoutBaseTransactionInput>,
};

export type PreStockUpdateOneWithoutTransactionsInput = {
  create?: Maybe<PreStockCreateWithoutTransactionsInput>,
  connect?: Maybe<PreStockWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<PreStockUpdateWithoutTransactionsDataInput>,
  upsert?: Maybe<PreStockUpsertWithoutTransactionsInput>,
};

export type PreStockUpdateWithoutTransactionsDataInput = {
  id?: Maybe<Scalars['String']>,
  amount?: Maybe<Scalars['Int']>,
  loadUnit?: Maybe<LoadUnit>,
  loadUnitAmount?: Maybe<Scalars['Int']>,
  scheduledDate?: Maybe<Scalars['DateTime']>,
  confirmedDate?: Maybe<Scalars['DateTime']>,
  note?: Maybe<Scalars['String']>,
  manufacturedDate?: Maybe<Scalars['DateTime']>,
  expiryDate?: Maybe<Scalars['DateTime']>,
  canceledAt?: Maybe<Scalars['DateTime']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  logistic?: Maybe<LogisticUpdateOneRequiredWithoutPreStocksInput>,
  productOption?: Maybe<ProductOptionUpdateOneRequiredWithoutPreStocksInput>,
  registeredUser?: Maybe<UserUpdateOneRequiredWithoutRegisteredPreStocksInput>,
  confirmedUser?: Maybe<UserUpdateOneWithoutConfirmedPreStocksInput>,
};

export type UserUpdateOneRequiredWithoutRegisteredPreStocksInput = {
  create?: Maybe<UserCreateWithoutRegisteredPreStocksInput>,
  connect?: Maybe<UserWhereUniqueInput>,
  update?: Maybe<UserUpdateWithoutRegisteredPreStocksDataInput>,
  upsert?: Maybe<UserUpsertWithoutRegisteredPreStocksInput>,
};

export type UserUpdateWithoutRegisteredPreStocksDataInput = {
  id?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  emailVerified?: Maybe<Scalars['Boolean']>,
  type?: Maybe<CompanyType>,
  password?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  role?: Maybe<RoleType>,
  marketing?: Maybe<Scalars['Boolean']>,
  settings?: Maybe<Scalars['Json']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  company?: Maybe<CompanyUpdateOneRequiredWithoutUsersInput>,
  team?: Maybe<TeamUpdateOneWithoutUsersInput>,
  confirmedTransactions?: Maybe<TransactionUpdateManyWithoutConfirmedUserInput>,
  confirmedPreStocks?: Maybe<PreStockUpdateManyWithoutConfirmedUserInput>,
  confirmedFinalPackages?: Maybe<FinalPackageUpdateManyWithoutConfirmedUserInput>,
  confirmedStocktaking?: Maybe<StocktakingUpdateManyWithoutConfirmedUserInput>,
  confirmedReturnOrder?: Maybe<ReturnOrderUpdateManyWithoutConfirmedUserInput>,
  logisticdefaultOf?: Maybe<LogisticUpdateManyWithoutDefaultUserInput>,
  logs?: Maybe<LogUpdateManyWithoutUserInput>,
  orderNotes?: Maybe<OrderNoteUpdateManyWithoutWriterInput>,
  confirmedOrderNotes?: Maybe<OrderNoteUpdateManyWithoutConfirmedUserInput>,
  notices?: Maybe<NoticeUpdateManyWithoutAuthorInput>,
};

export type PreStockUpdateManyWithoutConfirmedUserInput = {
  create?: Maybe<Array<PreStockCreateWithoutConfirmedUserInput>>,
  connect?: Maybe<Array<PreStockWhereUniqueInput>>,
  set?: Maybe<Array<PreStockWhereUniqueInput>>,
  disconnect?: Maybe<Array<PreStockWhereUniqueInput>>,
  delete?: Maybe<Array<PreStockWhereUniqueInput>>,
  update?: Maybe<Array<PreStockUpdateWithWhereUniqueWithoutConfirmedUserInput>>,
  updateMany?: Maybe<Array<PreStockUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<PreStockScalarWhereInput>>,
  upsert?: Maybe<Array<PreStockUpsertWithWhereUniqueWithoutConfirmedUserInput>>,
};

export type PreStockUpdateWithWhereUniqueWithoutConfirmedUserInput = {
  where: PreStockWhereUniqueInput,
  data: PreStockUpdateWithoutConfirmedUserDataInput,
};

export type PreStockUpdateWithoutConfirmedUserDataInput = {
  id?: Maybe<Scalars['String']>,
  amount?: Maybe<Scalars['Int']>,
  loadUnit?: Maybe<LoadUnit>,
  loadUnitAmount?: Maybe<Scalars['Int']>,
  scheduledDate?: Maybe<Scalars['DateTime']>,
  confirmedDate?: Maybe<Scalars['DateTime']>,
  note?: Maybe<Scalars['String']>,
  manufacturedDate?: Maybe<Scalars['DateTime']>,
  expiryDate?: Maybe<Scalars['DateTime']>,
  canceledAt?: Maybe<Scalars['DateTime']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  logistic?: Maybe<LogisticUpdateOneRequiredWithoutPreStocksInput>,
  productOption?: Maybe<ProductOptionUpdateOneRequiredWithoutPreStocksInput>,
  registeredUser?: Maybe<UserUpdateOneRequiredWithoutRegisteredPreStocksInput>,
  transactions?: Maybe<TransactionUpdateManyWithoutPreStockInput>,
};

export type TransactionUpdateManyWithoutPreStockInput = {
  create?: Maybe<Array<TransactionCreateWithoutPreStockInput>>,
  connect?: Maybe<Array<TransactionWhereUniqueInput>>,
  set?: Maybe<Array<TransactionWhereUniqueInput>>,
  disconnect?: Maybe<Array<TransactionWhereUniqueInput>>,
  delete?: Maybe<Array<TransactionWhereUniqueInput>>,
  update?: Maybe<Array<TransactionUpdateWithWhereUniqueWithoutPreStockInput>>,
  updateMany?: Maybe<Array<TransactionUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<TransactionScalarWhereInput>>,
  upsert?: Maybe<Array<TransactionUpsertWithWhereUniqueWithoutPreStockInput>>,
};

export type TransactionUpdateWithWhereUniqueWithoutPreStockInput = {
  where: TransactionWhereUniqueInput,
  data: TransactionUpdateWithoutPreStockDataInput,
};

export type TransactionUpdateWithoutPreStockDataInput = {
  id?: Maybe<Scalars['String']>,
  manufacturedDate?: Maybe<Scalars['DateTime']>,
  expiryDate?: Maybe<Scalars['DateTime']>,
  eventType?: Maybe<EventType>,
  transportType?: Maybe<TransportType>,
  usableAmount?: Maybe<Scalars['Int']>,
  brokenAmount?: Maybe<Scalars['Int']>,
  note?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  logistic?: Maybe<LogisticUpdateOneRequiredWithoutTransactionsInput>,
  parent?: Maybe<TransactionUpdateOneWithoutChildrenInput>,
  location?: Maybe<LocationUpdateOneWithoutIncomingRecordsInput>,
  productOption?: Maybe<ProductOptionUpdateOneRequiredWithoutTransactionsInput>,
  confirmedUser?: Maybe<UserUpdateOneWithoutConfirmedTransactionsInput>,
  finalPackage?: Maybe<FinalPackageUpdateOneWithoutTransactionsInput>,
  stocktaking?: Maybe<StocktakingUpdateOneWithoutTransactionsInput>,
  children?: Maybe<TransactionUpdateManyWithoutParentInput>,
  stocktakings?: Maybe<StocktakingUpdateManyWithoutBaseTransactionInput>,
};

export type TransactionUpdateOneWithoutChildrenInput = {
  create?: Maybe<TransactionCreateWithoutChildrenInput>,
  connect?: Maybe<TransactionWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<TransactionUpdateWithoutChildrenDataInput>,
  upsert?: Maybe<TransactionUpsertWithoutChildrenInput>,
};

export type TransactionUpdateWithoutChildrenDataInput = {
  id?: Maybe<Scalars['String']>,
  manufacturedDate?: Maybe<Scalars['DateTime']>,
  expiryDate?: Maybe<Scalars['DateTime']>,
  eventType?: Maybe<EventType>,
  transportType?: Maybe<TransportType>,
  usableAmount?: Maybe<Scalars['Int']>,
  brokenAmount?: Maybe<Scalars['Int']>,
  note?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  logistic?: Maybe<LogisticUpdateOneRequiredWithoutTransactionsInput>,
  preStock?: Maybe<PreStockUpdateOneWithoutTransactionsInput>,
  parent?: Maybe<TransactionUpdateOneWithoutChildrenInput>,
  location?: Maybe<LocationUpdateOneWithoutIncomingRecordsInput>,
  productOption?: Maybe<ProductOptionUpdateOneRequiredWithoutTransactionsInput>,
  confirmedUser?: Maybe<UserUpdateOneWithoutConfirmedTransactionsInput>,
  finalPackage?: Maybe<FinalPackageUpdateOneWithoutTransactionsInput>,
  stocktaking?: Maybe<StocktakingUpdateOneWithoutTransactionsInput>,
  stocktakings?: Maybe<StocktakingUpdateManyWithoutBaseTransactionInput>,
};

export type LocationUpdateOneWithoutIncomingRecordsInput = {
  create?: Maybe<LocationCreateWithoutIncomingRecordsInput>,
  connect?: Maybe<LocationWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<LocationUpdateWithoutIncomingRecordsDataInput>,
  upsert?: Maybe<LocationUpsertWithoutIncomingRecordsInput>,
};

export type LocationUpdateWithoutIncomingRecordsDataInput = {
  id?: Maybe<Scalars['String']>,
  zone?: Maybe<Scalars['String']>,
  rack?: Maybe<Scalars['String']>,
  level?: Maybe<Scalars['String']>,
  isActive?: Maybe<Scalars['Boolean']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  center?: Maybe<CenterUpdateOneRequiredWithoutLocationsInput>,
  setting?: Maybe<LocationSettingUpdateOneRequiredWithoutLocationsInput>,
};

export type LocationSettingUpdateOneRequiredWithoutLocationsInput = {
  create?: Maybe<LocationSettingCreateWithoutLocationsInput>,
  connect?: Maybe<LocationSettingWhereUniqueInput>,
  update?: Maybe<LocationSettingUpdateWithoutLocationsDataInput>,
  upsert?: Maybe<LocationSettingUpsertWithoutLocationsInput>,
};

export type LocationSettingUpdateWithoutLocationsDataInput = {
  id?: Maybe<Scalars['String']>,
  zone?: Maybe<Scalars['String']>,
  rack?: Maybe<Scalars['String']>,
  level?: Maybe<Scalars['String']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  center?: Maybe<CenterUpdateOneRequiredWithoutSettingsInput>,
};

export type CenterUpdateOneRequiredWithoutSettingsInput = {
  create?: Maybe<CenterCreateWithoutSettingsInput>,
  connect?: Maybe<CenterWhereUniqueInput>,
  update?: Maybe<CenterUpdateWithoutSettingsDataInput>,
  upsert?: Maybe<CenterUpsertWithoutSettingsInput>,
};

export type CenterUpdateWithoutSettingsDataInput = {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  zipcode?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  detailedAddress?: Maybe<Scalars['String']>,
  shippingAvailable?: Maybe<Scalars['Boolean']>,
  isActive?: Maybe<Scalars['Boolean']>,
  useLocation?: Maybe<Scalars['Boolean']>,
  totalQuantity?: Maybe<Scalars['Int']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  logistic?: Maybe<LogisticUpdateOneRequiredWithoutCentersInput>,
  locations?: Maybe<LocationUpdateManyWithoutCenterInput>,
};

export type LocationUpdateManyWithoutCenterInput = {
  create?: Maybe<Array<LocationCreateWithoutCenterInput>>,
  connect?: Maybe<Array<LocationWhereUniqueInput>>,
  set?: Maybe<Array<LocationWhereUniqueInput>>,
  disconnect?: Maybe<Array<LocationWhereUniqueInput>>,
  delete?: Maybe<Array<LocationWhereUniqueInput>>,
  update?: Maybe<Array<LocationUpdateWithWhereUniqueWithoutCenterInput>>,
  updateMany?: Maybe<Array<LocationUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<LocationScalarWhereInput>>,
  upsert?: Maybe<Array<LocationUpsertWithWhereUniqueWithoutCenterInput>>,
};

export type LocationUpdateWithWhereUniqueWithoutCenterInput = {
  where: LocationWhereUniqueInput,
  data: LocationUpdateWithoutCenterDataInput,
};

export type LocationUpdateWithoutCenterDataInput = {
  id?: Maybe<Scalars['String']>,
  zone?: Maybe<Scalars['String']>,
  rack?: Maybe<Scalars['String']>,
  level?: Maybe<Scalars['String']>,
  isActive?: Maybe<Scalars['Boolean']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  setting?: Maybe<LocationSettingUpdateOneRequiredWithoutLocationsInput>,
  incomingRecords?: Maybe<TransactionUpdateManyWithoutLocationInput>,
};

export type TransactionUpdateManyWithoutLocationInput = {
  create?: Maybe<Array<TransactionCreateWithoutLocationInput>>,
  connect?: Maybe<Array<TransactionWhereUniqueInput>>,
  set?: Maybe<Array<TransactionWhereUniqueInput>>,
  disconnect?: Maybe<Array<TransactionWhereUniqueInput>>,
  delete?: Maybe<Array<TransactionWhereUniqueInput>>,
  update?: Maybe<Array<TransactionUpdateWithWhereUniqueWithoutLocationInput>>,
  updateMany?: Maybe<Array<TransactionUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<TransactionScalarWhereInput>>,
  upsert?: Maybe<Array<TransactionUpsertWithWhereUniqueWithoutLocationInput>>,
};

export type TransactionUpdateWithWhereUniqueWithoutLocationInput = {
  where: TransactionWhereUniqueInput,
  data: TransactionUpdateWithoutLocationDataInput,
};

export type TransactionUpdateWithoutLocationDataInput = {
  id?: Maybe<Scalars['String']>,
  manufacturedDate?: Maybe<Scalars['DateTime']>,
  expiryDate?: Maybe<Scalars['DateTime']>,
  eventType?: Maybe<EventType>,
  transportType?: Maybe<TransportType>,
  usableAmount?: Maybe<Scalars['Int']>,
  brokenAmount?: Maybe<Scalars['Int']>,
  note?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  logistic?: Maybe<LogisticUpdateOneRequiredWithoutTransactionsInput>,
  preStock?: Maybe<PreStockUpdateOneWithoutTransactionsInput>,
  parent?: Maybe<TransactionUpdateOneWithoutChildrenInput>,
  productOption?: Maybe<ProductOptionUpdateOneRequiredWithoutTransactionsInput>,
  confirmedUser?: Maybe<UserUpdateOneWithoutConfirmedTransactionsInput>,
  finalPackage?: Maybe<FinalPackageUpdateOneWithoutTransactionsInput>,
  stocktaking?: Maybe<StocktakingUpdateOneWithoutTransactionsInput>,
  children?: Maybe<TransactionUpdateManyWithoutParentInput>,
  stocktakings?: Maybe<StocktakingUpdateManyWithoutBaseTransactionInput>,
};

export type ProductOptionUpdateOneRequiredWithoutTransactionsInput = {
  create?: Maybe<ProductOptionCreateWithoutTransactionsInput>,
  connect?: Maybe<ProductOptionWhereUniqueInput>,
  update?: Maybe<ProductOptionUpdateWithoutTransactionsDataInput>,
  upsert?: Maybe<ProductOptionUpsertWithoutTransactionsInput>,
};

export type ProductOptionUpdateWithoutTransactionsDataInput = {
  id?: Maybe<Scalars['String']>,
  levelOne?: Maybe<Scalars['String']>,
  levelTwo?: Maybe<Scalars['String']>,
  originNum?: Maybe<Scalars['String']>,
  originBarcode?: Maybe<Scalars['String']>,
  newBarcode?: Maybe<Scalars['String']>,
  needInspection?: Maybe<Scalars['Boolean']>,
  temperature?: Maybe<TemperatureType>,
  bestPeriodDay?: Maybe<Scalars['Int']>,
  basicCourierPriceRange?: Maybe<BasicCourierPriceRange>,
  isActive?: Maybe<Scalars['Boolean']>,
  canBeMixed?: Maybe<Scalars['Boolean']>,
  maxAmount?: Maybe<Scalars['Int']>,
  note?: Maybe<Scalars['String']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  product?: Maybe<ProductUpdateOneRequiredWithoutProductOptionsInput>,
  category?: Maybe<ProductCategoryUpdateOneWithoutProductOptionsInput>,
  preStocks?: Maybe<PreStockUpdateManyWithoutProductOptionInput>,
  autoMatches?: Maybe<AutoMatchUpdateManyWithoutProductOptionInput>,
  productMatches?: Maybe<ProductMatchUpdateManyWithoutProductOptionInput>,
};

export type AutoMatchUpdateManyWithoutProductOptionInput = {
  create?: Maybe<Array<AutoMatchCreateWithoutProductOptionInput>>,
  connect?: Maybe<Array<AutoMatchWhereUniqueInput>>,
  set?: Maybe<Array<AutoMatchWhereUniqueInput>>,
  disconnect?: Maybe<Array<AutoMatchWhereUniqueInput>>,
  delete?: Maybe<Array<AutoMatchWhereUniqueInput>>,
  update?: Maybe<Array<AutoMatchUpdateWithWhereUniqueWithoutProductOptionInput>>,
  updateMany?: Maybe<Array<AutoMatchUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<AutoMatchScalarWhereInput>>,
  upsert?: Maybe<Array<AutoMatchUpsertWithWhereUniqueWithoutProductOptionInput>>,
};

export type AutoMatchUpdateWithWhereUniqueWithoutProductOptionInput = {
  where: AutoMatchWhereUniqueInput,
  data: AutoMatchUpdateWithoutProductOptionDataInput,
};

export type AutoMatchUpdateWithoutProductOptionDataInput = {
  id?: Maybe<Scalars['String']>,
  marketProductNum?: Maybe<Scalars['String']>,
  marketProductName?: Maybe<Scalars['String']>,
  marketProductOptionNum?: Maybe<Scalars['String']>,
  marketProductOptionName?: Maybe<Scalars['String']>,
  marketProductOptionAmount?: Maybe<Scalars['Int']>,
  productAmount?: Maybe<Scalars['Int']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  brandMarket?: Maybe<BrandMarketUpdateOneRequiredWithoutAutoMatchesInput>,
  productMatches?: Maybe<ProductMatchUpdateManyWithoutAutoMatchInput>,
};

export type ProductMatchUpdateManyWithoutAutoMatchInput = {
  create?: Maybe<Array<ProductMatchCreateWithoutAutoMatchInput>>,
  connect?: Maybe<Array<ProductMatchWhereUniqueInput>>,
  set?: Maybe<Array<ProductMatchWhereUniqueInput>>,
  disconnect?: Maybe<Array<ProductMatchWhereUniqueInput>>,
  delete?: Maybe<Array<ProductMatchWhereUniqueInput>>,
  update?: Maybe<Array<ProductMatchUpdateWithWhereUniqueWithoutAutoMatchInput>>,
  updateMany?: Maybe<Array<ProductMatchUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<ProductMatchScalarWhereInput>>,
  upsert?: Maybe<Array<ProductMatchUpsertWithWhereUniqueWithoutAutoMatchInput>>,
};

export type ProductMatchUpdateWithWhereUniqueWithoutAutoMatchInput = {
  where: ProductMatchWhereUniqueInput,
  data: ProductMatchUpdateWithoutAutoMatchDataInput,
};

export type ProductMatchUpdateWithoutAutoMatchDataInput = {
  id?: Maybe<Scalars['String']>,
  amount?: Maybe<Scalars['Int']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  marketProduct?: Maybe<MarketProductUpdateOneRequiredWithoutProductMatchesInput>,
  productOption?: Maybe<ProductOptionUpdateOneRequiredWithoutProductMatchesInput>,
  finalPackages?: Maybe<FinalPackageUpdateManyWithoutProductMatchInput>,
};

export type MarketProductUpdateOneRequiredWithoutProductMatchesInput = {
  create?: Maybe<MarketProductCreateWithoutProductMatchesInput>,
  connect?: Maybe<MarketProductWhereUniqueInput>,
  update?: Maybe<MarketProductUpdateWithoutProductMatchesDataInput>,
  upsert?: Maybe<MarketProductUpsertWithoutProductMatchesInput>,
};

export type MarketProductUpdateWithoutProductMatchesDataInput = {
  id?: Maybe<Scalars['String']>,
  num?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  optionNum?: Maybe<Scalars['String']>,
  optionName?: Maybe<Scalars['String']>,
  amount?: Maybe<Scalars['Int']>,
  totalPrice?: Maybe<Scalars['Int']>,
  status?: Maybe<MarketProductStatus>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  order?: Maybe<OrderUpdateOneRequiredWithoutMarketProductsInput>,
};

export type OrderUpdateOneRequiredWithoutMarketProductsInput = {
  create?: Maybe<OrderCreateWithoutMarketProductsInput>,
  connect?: Maybe<OrderWhereUniqueInput>,
  update?: Maybe<OrderUpdateWithoutMarketProductsDataInput>,
  upsert?: Maybe<OrderUpsertWithoutMarketProductsInput>,
};

export type OrderUpdateWithoutMarketProductsDataInput = {
  id?: Maybe<Scalars['String']>,
  num?: Maybe<Scalars['String']>,
  marketOrderNum?: Maybe<Scalars['String']>,
  orderedDate?: Maybe<Scalars['DateTime']>,
  paidDate?: Maybe<Scalars['DateTime']>,
  sender?: Maybe<Scalars['String']>,
  senderTel?: Maybe<Scalars['String']>,
  senderTel2?: Maybe<Scalars['String']>,
  receiver?: Maybe<Scalars['String']>,
  receiverTel?: Maybe<Scalars['String']>,
  receiverTel2?: Maybe<Scalars['String']>,
  receiverZipcode?: Maybe<Scalars['String']>,
  receiverAddress?: Maybe<Scalars['String']>,
  receiverDetailedAddress?: Maybe<Scalars['String']>,
  message?: Maybe<Scalars['String']>,
  deliveryPaid?: Maybe<Scalars['Boolean']>,
  deliveryPrice?: Maybe<Scalars['Int']>,
  isOverseas?: Maybe<Scalars['Boolean']>,
  remoteAreaPrice?: Maybe<Scalars['Int']>,
  detailedOrder?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  brandMarket?: Maybe<BrandMarketUpdateOneRequiredWithoutOrdersInput>,
  orderNotes?: Maybe<OrderNoteUpdateManyWithoutOrderInput>,
};

export type BrandMarketUpdateOneRequiredWithoutOrdersInput = {
  create?: Maybe<BrandMarketCreateWithoutOrdersInput>,
  connect?: Maybe<BrandMarketWhereUniqueInput>,
  update?: Maybe<BrandMarketUpdateWithoutOrdersDataInput>,
  upsert?: Maybe<BrandMarketUpsertWithoutOrdersInput>,
};

export type BrandMarketUpdateWithoutOrdersDataInput = {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  sender?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  zipcode?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  detailedAddress?: Maybe<Scalars['String']>,
  credential?: Maybe<Scalars['String']>,
  isActive?: Maybe<Scalars['Boolean']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  brand?: Maybe<BrandUpdateOneRequiredWithoutBrandMarketsInput>,
  market?: Maybe<MarketUpdateOneRequiredWithoutBrandMarketsInput>,
  autoMatches?: Maybe<AutoMatchUpdateManyWithoutBrandMarketInput>,
};

export type AutoMatchUpdateManyWithoutBrandMarketInput = {
  create?: Maybe<Array<AutoMatchCreateWithoutBrandMarketInput>>,
  connect?: Maybe<Array<AutoMatchWhereUniqueInput>>,
  set?: Maybe<Array<AutoMatchWhereUniqueInput>>,
  disconnect?: Maybe<Array<AutoMatchWhereUniqueInput>>,
  delete?: Maybe<Array<AutoMatchWhereUniqueInput>>,
  update?: Maybe<Array<AutoMatchUpdateWithWhereUniqueWithoutBrandMarketInput>>,
  updateMany?: Maybe<Array<AutoMatchUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<AutoMatchScalarWhereInput>>,
  upsert?: Maybe<Array<AutoMatchUpsertWithWhereUniqueWithoutBrandMarketInput>>,
};

export type AutoMatchUpdateWithWhereUniqueWithoutBrandMarketInput = {
  where: AutoMatchWhereUniqueInput,
  data: AutoMatchUpdateWithoutBrandMarketDataInput,
};

export type AutoMatchUpdateWithoutBrandMarketDataInput = {
  id?: Maybe<Scalars['String']>,
  marketProductNum?: Maybe<Scalars['String']>,
  marketProductName?: Maybe<Scalars['String']>,
  marketProductOptionNum?: Maybe<Scalars['String']>,
  marketProductOptionName?: Maybe<Scalars['String']>,
  marketProductOptionAmount?: Maybe<Scalars['Int']>,
  productAmount?: Maybe<Scalars['Int']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  productOption?: Maybe<ProductOptionUpdateOneRequiredWithoutAutoMatchesInput>,
  productMatches?: Maybe<ProductMatchUpdateManyWithoutAutoMatchInput>,
};

export type ProductOptionUpdateOneRequiredWithoutAutoMatchesInput = {
  create?: Maybe<ProductOptionCreateWithoutAutoMatchesInput>,
  connect?: Maybe<ProductOptionWhereUniqueInput>,
  update?: Maybe<ProductOptionUpdateWithoutAutoMatchesDataInput>,
  upsert?: Maybe<ProductOptionUpsertWithoutAutoMatchesInput>,
};

export type ProductOptionUpdateWithoutAutoMatchesDataInput = {
  id?: Maybe<Scalars['String']>,
  levelOne?: Maybe<Scalars['String']>,
  levelTwo?: Maybe<Scalars['String']>,
  originNum?: Maybe<Scalars['String']>,
  originBarcode?: Maybe<Scalars['String']>,
  newBarcode?: Maybe<Scalars['String']>,
  needInspection?: Maybe<Scalars['Boolean']>,
  temperature?: Maybe<TemperatureType>,
  bestPeriodDay?: Maybe<Scalars['Int']>,
  basicCourierPriceRange?: Maybe<BasicCourierPriceRange>,
  isActive?: Maybe<Scalars['Boolean']>,
  canBeMixed?: Maybe<Scalars['Boolean']>,
  maxAmount?: Maybe<Scalars['Int']>,
  note?: Maybe<Scalars['String']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  product?: Maybe<ProductUpdateOneRequiredWithoutProductOptionsInput>,
  category?: Maybe<ProductCategoryUpdateOneWithoutProductOptionsInput>,
  preStocks?: Maybe<PreStockUpdateManyWithoutProductOptionInput>,
  transactions?: Maybe<TransactionUpdateManyWithoutProductOptionInput>,
  productMatches?: Maybe<ProductMatchUpdateManyWithoutProductOptionInput>,
};

export type TransactionUpdateManyWithoutProductOptionInput = {
  create?: Maybe<Array<TransactionCreateWithoutProductOptionInput>>,
  connect?: Maybe<Array<TransactionWhereUniqueInput>>,
  set?: Maybe<Array<TransactionWhereUniqueInput>>,
  disconnect?: Maybe<Array<TransactionWhereUniqueInput>>,
  delete?: Maybe<Array<TransactionWhereUniqueInput>>,
  update?: Maybe<Array<TransactionUpdateWithWhereUniqueWithoutProductOptionInput>>,
  updateMany?: Maybe<Array<TransactionUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<TransactionScalarWhereInput>>,
  upsert?: Maybe<Array<TransactionUpsertWithWhereUniqueWithoutProductOptionInput>>,
};

export type TransactionUpdateWithWhereUniqueWithoutProductOptionInput = {
  where: TransactionWhereUniqueInput,
  data: TransactionUpdateWithoutProductOptionDataInput,
};

export type TransactionUpdateWithoutProductOptionDataInput = {
  id?: Maybe<Scalars['String']>,
  manufacturedDate?: Maybe<Scalars['DateTime']>,
  expiryDate?: Maybe<Scalars['DateTime']>,
  eventType?: Maybe<EventType>,
  transportType?: Maybe<TransportType>,
  usableAmount?: Maybe<Scalars['Int']>,
  brokenAmount?: Maybe<Scalars['Int']>,
  note?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  logistic?: Maybe<LogisticUpdateOneRequiredWithoutTransactionsInput>,
  preStock?: Maybe<PreStockUpdateOneWithoutTransactionsInput>,
  parent?: Maybe<TransactionUpdateOneWithoutChildrenInput>,
  location?: Maybe<LocationUpdateOneWithoutIncomingRecordsInput>,
  confirmedUser?: Maybe<UserUpdateOneWithoutConfirmedTransactionsInput>,
  finalPackage?: Maybe<FinalPackageUpdateOneWithoutTransactionsInput>,
  stocktaking?: Maybe<StocktakingUpdateOneWithoutTransactionsInput>,
  children?: Maybe<TransactionUpdateManyWithoutParentInput>,
  stocktakings?: Maybe<StocktakingUpdateManyWithoutBaseTransactionInput>,
};

export type UserUpdateOneWithoutConfirmedTransactionsInput = {
  create?: Maybe<UserCreateWithoutConfirmedTransactionsInput>,
  connect?: Maybe<UserWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<UserUpdateWithoutConfirmedTransactionsDataInput>,
  upsert?: Maybe<UserUpsertWithoutConfirmedTransactionsInput>,
};

export type UserUpdateWithoutConfirmedTransactionsDataInput = {
  id?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  emailVerified?: Maybe<Scalars['Boolean']>,
  type?: Maybe<CompanyType>,
  password?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  role?: Maybe<RoleType>,
  marketing?: Maybe<Scalars['Boolean']>,
  settings?: Maybe<Scalars['Json']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  company?: Maybe<CompanyUpdateOneRequiredWithoutUsersInput>,
  team?: Maybe<TeamUpdateOneWithoutUsersInput>,
  registeredPreStocks?: Maybe<PreStockUpdateManyWithoutRegisteredUserInput>,
  confirmedPreStocks?: Maybe<PreStockUpdateManyWithoutConfirmedUserInput>,
  confirmedFinalPackages?: Maybe<FinalPackageUpdateManyWithoutConfirmedUserInput>,
  confirmedStocktaking?: Maybe<StocktakingUpdateManyWithoutConfirmedUserInput>,
  confirmedReturnOrder?: Maybe<ReturnOrderUpdateManyWithoutConfirmedUserInput>,
  logisticdefaultOf?: Maybe<LogisticUpdateManyWithoutDefaultUserInput>,
  logs?: Maybe<LogUpdateManyWithoutUserInput>,
  orderNotes?: Maybe<OrderNoteUpdateManyWithoutWriterInput>,
  confirmedOrderNotes?: Maybe<OrderNoteUpdateManyWithoutConfirmedUserInput>,
  notices?: Maybe<NoticeUpdateManyWithoutAuthorInput>,
};

export type PreStockUpdateManyWithoutRegisteredUserInput = {
  create?: Maybe<Array<PreStockCreateWithoutRegisteredUserInput>>,
  connect?: Maybe<Array<PreStockWhereUniqueInput>>,
  set?: Maybe<Array<PreStockWhereUniqueInput>>,
  disconnect?: Maybe<Array<PreStockWhereUniqueInput>>,
  delete?: Maybe<Array<PreStockWhereUniqueInput>>,
  update?: Maybe<Array<PreStockUpdateWithWhereUniqueWithoutRegisteredUserInput>>,
  updateMany?: Maybe<Array<PreStockUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<PreStockScalarWhereInput>>,
  upsert?: Maybe<Array<PreStockUpsertWithWhereUniqueWithoutRegisteredUserInput>>,
};

export type PreStockUpdateWithWhereUniqueWithoutRegisteredUserInput = {
  where: PreStockWhereUniqueInput,
  data: PreStockUpdateWithoutRegisteredUserDataInput,
};

export type PreStockUpdateWithoutRegisteredUserDataInput = {
  id?: Maybe<Scalars['String']>,
  amount?: Maybe<Scalars['Int']>,
  loadUnit?: Maybe<LoadUnit>,
  loadUnitAmount?: Maybe<Scalars['Int']>,
  scheduledDate?: Maybe<Scalars['DateTime']>,
  confirmedDate?: Maybe<Scalars['DateTime']>,
  note?: Maybe<Scalars['String']>,
  manufacturedDate?: Maybe<Scalars['DateTime']>,
  expiryDate?: Maybe<Scalars['DateTime']>,
  canceledAt?: Maybe<Scalars['DateTime']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  logistic?: Maybe<LogisticUpdateOneRequiredWithoutPreStocksInput>,
  productOption?: Maybe<ProductOptionUpdateOneRequiredWithoutPreStocksInput>,
  confirmedUser?: Maybe<UserUpdateOneWithoutConfirmedPreStocksInput>,
  transactions?: Maybe<TransactionUpdateManyWithoutPreStockInput>,
};

export type UserUpdateOneWithoutConfirmedPreStocksInput = {
  create?: Maybe<UserCreateWithoutConfirmedPreStocksInput>,
  connect?: Maybe<UserWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<UserUpdateWithoutConfirmedPreStocksDataInput>,
  upsert?: Maybe<UserUpsertWithoutConfirmedPreStocksInput>,
};

export type UserUpdateWithoutConfirmedPreStocksDataInput = {
  id?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  emailVerified?: Maybe<Scalars['Boolean']>,
  type?: Maybe<CompanyType>,
  password?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  role?: Maybe<RoleType>,
  marketing?: Maybe<Scalars['Boolean']>,
  settings?: Maybe<Scalars['Json']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  company?: Maybe<CompanyUpdateOneRequiredWithoutUsersInput>,
  team?: Maybe<TeamUpdateOneWithoutUsersInput>,
  confirmedTransactions?: Maybe<TransactionUpdateManyWithoutConfirmedUserInput>,
  registeredPreStocks?: Maybe<PreStockUpdateManyWithoutRegisteredUserInput>,
  confirmedFinalPackages?: Maybe<FinalPackageUpdateManyWithoutConfirmedUserInput>,
  confirmedStocktaking?: Maybe<StocktakingUpdateManyWithoutConfirmedUserInput>,
  confirmedReturnOrder?: Maybe<ReturnOrderUpdateManyWithoutConfirmedUserInput>,
  logisticdefaultOf?: Maybe<LogisticUpdateManyWithoutDefaultUserInput>,
  logs?: Maybe<LogUpdateManyWithoutUserInput>,
  orderNotes?: Maybe<OrderNoteUpdateManyWithoutWriterInput>,
  confirmedOrderNotes?: Maybe<OrderNoteUpdateManyWithoutConfirmedUserInput>,
  notices?: Maybe<NoticeUpdateManyWithoutAuthorInput>,
};

export type FinalPackageUpdateManyWithoutConfirmedUserInput = {
  create?: Maybe<Array<FinalPackageCreateWithoutConfirmedUserInput>>,
  connect?: Maybe<Array<FinalPackageWhereUniqueInput>>,
  set?: Maybe<Array<FinalPackageWhereUniqueInput>>,
  disconnect?: Maybe<Array<FinalPackageWhereUniqueInput>>,
  delete?: Maybe<Array<FinalPackageWhereUniqueInput>>,
  update?: Maybe<Array<FinalPackageUpdateWithWhereUniqueWithoutConfirmedUserInput>>,
  updateMany?: Maybe<Array<FinalPackageUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<FinalPackageScalarWhereInput>>,
  upsert?: Maybe<Array<FinalPackageUpsertWithWhereUniqueWithoutConfirmedUserInput>>,
};

export type FinalPackageUpdateWithWhereUniqueWithoutConfirmedUserInput = {
  where: FinalPackageWhereUniqueInput,
  data: FinalPackageUpdateWithoutConfirmedUserDataInput,
};

export type FinalPackageUpdateWithoutConfirmedUserDataInput = {
  id?: Maybe<Scalars['String']>,
  amount?: Maybe<Scalars['Int']>,
  status?: Maybe<FinalPackageStatus>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  courierInvoice?: Maybe<CourierInvoiceUpdateOneRequiredWithoutFinalPackagesInput>,
  productMatch?: Maybe<ProductMatchUpdateOneRequiredWithoutFinalPackagesInput>,
  returnOrderProducts?: Maybe<ReturnOrderProductUpdateManyWithoutFinalPackageInput>,
  transactions?: Maybe<TransactionUpdateManyWithoutFinalPackageInput>,
};

export type CourierInvoiceUpdateOneRequiredWithoutFinalPackagesInput = {
  create?: Maybe<CourierInvoiceCreateWithoutFinalPackagesInput>,
  connect?: Maybe<CourierInvoiceWhereUniqueInput>,
  update?: Maybe<CourierInvoiceUpdateWithoutFinalPackagesDataInput>,
  upsert?: Maybe<CourierInvoiceUpsertWithoutFinalPackagesInput>,
};

export type CourierInvoiceUpdateWithoutFinalPackagesDataInput = {
  id?: Maybe<Scalars['String']>,
  type?: Maybe<CourierInvoiceType>,
  courierName?: Maybe<Scalars['String']>,
  num?: Maybe<Scalars['String']>,
  groupNum?: Maybe<Scalars['Int']>,
  printedNum?: Maybe<Scalars['Int']>,
  invoiceStatus?: Maybe<CourierInvoiceStatus>,
  status?: Maybe<PackageStatus>,
  sender?: Maybe<Scalars['String']>,
  senderTel?: Maybe<Scalars['String']>,
  senderZipcode?: Maybe<Scalars['String']>,
  senderAddress?: Maybe<Scalars['String']>,
  senderDetailedAddress?: Maybe<Scalars['String']>,
  receiver?: Maybe<Scalars['String']>,
  receiverTel?: Maybe<Scalars['String']>,
  receiverZipcode?: Maybe<Scalars['String']>,
  receiverAddress?: Maybe<Scalars['String']>,
  receiverDetailedAddress?: Maybe<Scalars['String']>,
  content?: Maybe<Scalars['String']>,
  message?: Maybe<Scalars['String']>,
  note?: Maybe<Scalars['String']>,
  addressRefinedDate?: Maybe<Scalars['DateTime']>,
  addressRefinedValues?: Maybe<Scalars['String']>,
  printedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  brand?: Maybe<BrandUpdateOneRequiredWithoutCourierInvoicesInput>,
  logisticCourier?: Maybe<LogisticCourierUpdateOneWithoutCourierInvoicesInput>,
  parent?: Maybe<CourierInvoiceUpdateOneWithoutChildrenInput>,
  courierPriceRange?: Maybe<CourierPriceRangeUpdateOneWithoutCourierInvoicesInput>,
  children?: Maybe<CourierInvoiceUpdateManyWithoutParentInput>,
  returnOrders?: Maybe<ReturnOrderUpdateManyWithoutCourierInvoiceInput>,
};

export type CourierInvoiceUpdateOneWithoutChildrenInput = {
  create?: Maybe<CourierInvoiceCreateWithoutChildrenInput>,
  connect?: Maybe<CourierInvoiceWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<CourierInvoiceUpdateWithoutChildrenDataInput>,
  upsert?: Maybe<CourierInvoiceUpsertWithoutChildrenInput>,
};

export type CourierInvoiceUpdateWithoutChildrenDataInput = {
  id?: Maybe<Scalars['String']>,
  type?: Maybe<CourierInvoiceType>,
  courierName?: Maybe<Scalars['String']>,
  num?: Maybe<Scalars['String']>,
  groupNum?: Maybe<Scalars['Int']>,
  printedNum?: Maybe<Scalars['Int']>,
  invoiceStatus?: Maybe<CourierInvoiceStatus>,
  status?: Maybe<PackageStatus>,
  sender?: Maybe<Scalars['String']>,
  senderTel?: Maybe<Scalars['String']>,
  senderZipcode?: Maybe<Scalars['String']>,
  senderAddress?: Maybe<Scalars['String']>,
  senderDetailedAddress?: Maybe<Scalars['String']>,
  receiver?: Maybe<Scalars['String']>,
  receiverTel?: Maybe<Scalars['String']>,
  receiverZipcode?: Maybe<Scalars['String']>,
  receiverAddress?: Maybe<Scalars['String']>,
  receiverDetailedAddress?: Maybe<Scalars['String']>,
  content?: Maybe<Scalars['String']>,
  message?: Maybe<Scalars['String']>,
  note?: Maybe<Scalars['String']>,
  addressRefinedDate?: Maybe<Scalars['DateTime']>,
  addressRefinedValues?: Maybe<Scalars['String']>,
  printedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  brand?: Maybe<BrandUpdateOneRequiredWithoutCourierInvoicesInput>,
  logisticCourier?: Maybe<LogisticCourierUpdateOneWithoutCourierInvoicesInput>,
  parent?: Maybe<CourierInvoiceUpdateOneWithoutChildrenInput>,
  courierPriceRange?: Maybe<CourierPriceRangeUpdateOneWithoutCourierInvoicesInput>,
  finalPackages?: Maybe<FinalPackageUpdateManyWithoutCourierInvoiceInput>,
  returnOrders?: Maybe<ReturnOrderUpdateManyWithoutCourierInvoiceInput>,
};

export type CourierPriceRangeUpdateOneWithoutCourierInvoicesInput = {
  create?: Maybe<CourierPriceRangeCreateWithoutCourierInvoicesInput>,
  connect?: Maybe<CourierPriceRangeWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<CourierPriceRangeUpdateWithoutCourierInvoicesDataInput>,
  upsert?: Maybe<CourierPriceRangeUpsertWithoutCourierInvoicesInput>,
};

export type CourierPriceRangeUpdateWithoutCourierInvoicesDataInput = {
  id?: Maybe<Scalars['String']>,
  basic?: Maybe<BasicCourierPriceRange>,
  name?: Maybe<Scalars['String']>,
  value?: Maybe<Scalars['String']>,
  price?: Maybe<Scalars['Int']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  courier?: Maybe<CourierUpdateOneRequiredWithoutPriceRangesInput>,
};

export type CourierUpdateOneRequiredWithoutPriceRangesInput = {
  create?: Maybe<CourierCreateWithoutPriceRangesInput>,
  connect?: Maybe<CourierWhereUniqueInput>,
  update?: Maybe<CourierUpdateWithoutPriceRangesDataInput>,
  upsert?: Maybe<CourierUpsertWithoutPriceRangesInput>,
};

export type CourierUpdateWithoutPriceRangesDataInput = {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  configFields?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  logisticCouriers?: Maybe<LogisticCourierUpdateManyWithoutCourierInput>,
};

export type LogisticCourierUpdateManyWithoutCourierInput = {
  create?: Maybe<Array<LogisticCourierCreateWithoutCourierInput>>,
  connect?: Maybe<Array<LogisticCourierWhereUniqueInput>>,
  set?: Maybe<Array<LogisticCourierWhereUniqueInput>>,
  disconnect?: Maybe<Array<LogisticCourierWhereUniqueInput>>,
  delete?: Maybe<Array<LogisticCourierWhereUniqueInput>>,
  update?: Maybe<Array<LogisticCourierUpdateWithWhereUniqueWithoutCourierInput>>,
  updateMany?: Maybe<Array<LogisticCourierUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<LogisticCourierScalarWhereInput>>,
  upsert?: Maybe<Array<LogisticCourierUpsertWithWhereUniqueWithoutCourierInput>>,
};

export type LogisticCourierUpdateWithWhereUniqueWithoutCourierInput = {
  where: LogisticCourierWhereUniqueInput,
  data: LogisticCourierUpdateWithoutCourierDataInput,
};

export type LogisticCourierUpdateWithoutCourierDataInput = {
  id?: Maybe<Scalars['String']>,
  config?: Maybe<Scalars['String']>,
  isActive?: Maybe<Scalars['Boolean']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  logistic?: Maybe<LogisticUpdateOneRequiredWithoutAvailableCouriersInput>,
  logisticDefaultOf?: Maybe<LogisticUpdateOneWithoutDefaultCourierInput>,
  sellerDefaultOf?: Maybe<SellerUpdateManyWithoutDefaultCourierInput>,
  courierInvoices?: Maybe<CourierInvoiceUpdateManyWithoutLogisticCourierInput>,
  courierInvoiceTrackings?: Maybe<CourierInvoiceTrackingUpdateManyWithoutLogisticCourierInput>,
};

export type CourierInvoiceUpdateManyWithoutLogisticCourierInput = {
  create?: Maybe<Array<CourierInvoiceCreateWithoutLogisticCourierInput>>,
  connect?: Maybe<Array<CourierInvoiceWhereUniqueInput>>,
  set?: Maybe<Array<CourierInvoiceWhereUniqueInput>>,
  disconnect?: Maybe<Array<CourierInvoiceWhereUniqueInput>>,
  delete?: Maybe<Array<CourierInvoiceWhereUniqueInput>>,
  update?: Maybe<Array<CourierInvoiceUpdateWithWhereUniqueWithoutLogisticCourierInput>>,
  updateMany?: Maybe<Array<CourierInvoiceUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<CourierInvoiceScalarWhereInput>>,
  upsert?: Maybe<Array<CourierInvoiceUpsertWithWhereUniqueWithoutLogisticCourierInput>>,
};

export type CourierInvoiceUpdateWithWhereUniqueWithoutLogisticCourierInput = {
  where: CourierInvoiceWhereUniqueInput,
  data: CourierInvoiceUpdateWithoutLogisticCourierDataInput,
};

export type CourierInvoiceUpdateWithoutLogisticCourierDataInput = {
  id?: Maybe<Scalars['String']>,
  type?: Maybe<CourierInvoiceType>,
  courierName?: Maybe<Scalars['String']>,
  num?: Maybe<Scalars['String']>,
  groupNum?: Maybe<Scalars['Int']>,
  printedNum?: Maybe<Scalars['Int']>,
  invoiceStatus?: Maybe<CourierInvoiceStatus>,
  status?: Maybe<PackageStatus>,
  sender?: Maybe<Scalars['String']>,
  senderTel?: Maybe<Scalars['String']>,
  senderZipcode?: Maybe<Scalars['String']>,
  senderAddress?: Maybe<Scalars['String']>,
  senderDetailedAddress?: Maybe<Scalars['String']>,
  receiver?: Maybe<Scalars['String']>,
  receiverTel?: Maybe<Scalars['String']>,
  receiverZipcode?: Maybe<Scalars['String']>,
  receiverAddress?: Maybe<Scalars['String']>,
  receiverDetailedAddress?: Maybe<Scalars['String']>,
  content?: Maybe<Scalars['String']>,
  message?: Maybe<Scalars['String']>,
  note?: Maybe<Scalars['String']>,
  addressRefinedDate?: Maybe<Scalars['DateTime']>,
  addressRefinedValues?: Maybe<Scalars['String']>,
  printedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  brand?: Maybe<BrandUpdateOneRequiredWithoutCourierInvoicesInput>,
  parent?: Maybe<CourierInvoiceUpdateOneWithoutChildrenInput>,
  courierPriceRange?: Maybe<CourierPriceRangeUpdateOneWithoutCourierInvoicesInput>,
  children?: Maybe<CourierInvoiceUpdateManyWithoutParentInput>,
  finalPackages?: Maybe<FinalPackageUpdateManyWithoutCourierInvoiceInput>,
  returnOrders?: Maybe<ReturnOrderUpdateManyWithoutCourierInvoiceInput>,
};

export type CourierInvoiceUpdateManyWithoutParentInput = {
  create?: Maybe<Array<CourierInvoiceCreateWithoutParentInput>>,
  connect?: Maybe<Array<CourierInvoiceWhereUniqueInput>>,
  set?: Maybe<Array<CourierInvoiceWhereUniqueInput>>,
  disconnect?: Maybe<Array<CourierInvoiceWhereUniqueInput>>,
  delete?: Maybe<Array<CourierInvoiceWhereUniqueInput>>,
  update?: Maybe<Array<CourierInvoiceUpdateWithWhereUniqueWithoutParentInput>>,
  updateMany?: Maybe<Array<CourierInvoiceUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<CourierInvoiceScalarWhereInput>>,
  upsert?: Maybe<Array<CourierInvoiceUpsertWithWhereUniqueWithoutParentInput>>,
};

export type CourierInvoiceUpdateWithWhereUniqueWithoutParentInput = {
  where: CourierInvoiceWhereUniqueInput,
  data: CourierInvoiceUpdateWithoutParentDataInput,
};

export type CourierInvoiceUpdateWithoutParentDataInput = {
  id?: Maybe<Scalars['String']>,
  type?: Maybe<CourierInvoiceType>,
  courierName?: Maybe<Scalars['String']>,
  num?: Maybe<Scalars['String']>,
  groupNum?: Maybe<Scalars['Int']>,
  printedNum?: Maybe<Scalars['Int']>,
  invoiceStatus?: Maybe<CourierInvoiceStatus>,
  status?: Maybe<PackageStatus>,
  sender?: Maybe<Scalars['String']>,
  senderTel?: Maybe<Scalars['String']>,
  senderZipcode?: Maybe<Scalars['String']>,
  senderAddress?: Maybe<Scalars['String']>,
  senderDetailedAddress?: Maybe<Scalars['String']>,
  receiver?: Maybe<Scalars['String']>,
  receiverTel?: Maybe<Scalars['String']>,
  receiverZipcode?: Maybe<Scalars['String']>,
  receiverAddress?: Maybe<Scalars['String']>,
  receiverDetailedAddress?: Maybe<Scalars['String']>,
  content?: Maybe<Scalars['String']>,
  message?: Maybe<Scalars['String']>,
  note?: Maybe<Scalars['String']>,
  addressRefinedDate?: Maybe<Scalars['DateTime']>,
  addressRefinedValues?: Maybe<Scalars['String']>,
  printedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  brand?: Maybe<BrandUpdateOneRequiredWithoutCourierInvoicesInput>,
  logisticCourier?: Maybe<LogisticCourierUpdateOneWithoutCourierInvoicesInput>,
  courierPriceRange?: Maybe<CourierPriceRangeUpdateOneWithoutCourierInvoicesInput>,
  children?: Maybe<CourierInvoiceUpdateManyWithoutParentInput>,
  finalPackages?: Maybe<FinalPackageUpdateManyWithoutCourierInvoiceInput>,
  returnOrders?: Maybe<ReturnOrderUpdateManyWithoutCourierInvoiceInput>,
};

export type FinalPackageUpdateManyWithoutCourierInvoiceInput = {
  create?: Maybe<Array<FinalPackageCreateWithoutCourierInvoiceInput>>,
  connect?: Maybe<Array<FinalPackageWhereUniqueInput>>,
  set?: Maybe<Array<FinalPackageWhereUniqueInput>>,
  disconnect?: Maybe<Array<FinalPackageWhereUniqueInput>>,
  delete?: Maybe<Array<FinalPackageWhereUniqueInput>>,
  update?: Maybe<Array<FinalPackageUpdateWithWhereUniqueWithoutCourierInvoiceInput>>,
  updateMany?: Maybe<Array<FinalPackageUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<FinalPackageScalarWhereInput>>,
  upsert?: Maybe<Array<FinalPackageUpsertWithWhereUniqueWithoutCourierInvoiceInput>>,
};

export type FinalPackageUpdateWithWhereUniqueWithoutCourierInvoiceInput = {
  where: FinalPackageWhereUniqueInput,
  data: FinalPackageUpdateWithoutCourierInvoiceDataInput,
};

export type FinalPackageUpdateWithoutCourierInvoiceDataInput = {
  id?: Maybe<Scalars['String']>,
  amount?: Maybe<Scalars['Int']>,
  status?: Maybe<FinalPackageStatus>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  productMatch?: Maybe<ProductMatchUpdateOneRequiredWithoutFinalPackagesInput>,
  confirmedUser?: Maybe<UserUpdateOneWithoutConfirmedFinalPackagesInput>,
  returnOrderProducts?: Maybe<ReturnOrderProductUpdateManyWithoutFinalPackageInput>,
  transactions?: Maybe<TransactionUpdateManyWithoutFinalPackageInput>,
};

export type ProductMatchUpdateOneRequiredWithoutFinalPackagesInput = {
  create?: Maybe<ProductMatchCreateWithoutFinalPackagesInput>,
  connect?: Maybe<ProductMatchWhereUniqueInput>,
  update?: Maybe<ProductMatchUpdateWithoutFinalPackagesDataInput>,
  upsert?: Maybe<ProductMatchUpsertWithoutFinalPackagesInput>,
};

export type ProductMatchUpdateWithoutFinalPackagesDataInput = {
  id?: Maybe<Scalars['String']>,
  amount?: Maybe<Scalars['Int']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  marketProduct?: Maybe<MarketProductUpdateOneRequiredWithoutProductMatchesInput>,
  autoMatch?: Maybe<AutoMatchUpdateOneWithoutProductMatchesInput>,
  productOption?: Maybe<ProductOptionUpdateOneRequiredWithoutProductMatchesInput>,
};

export type ProductOptionUpdateOneRequiredWithoutProductMatchesInput = {
  create?: Maybe<ProductOptionCreateWithoutProductMatchesInput>,
  connect?: Maybe<ProductOptionWhereUniqueInput>,
  update?: Maybe<ProductOptionUpdateWithoutProductMatchesDataInput>,
  upsert?: Maybe<ProductOptionUpsertWithoutProductMatchesInput>,
};

export type ProductOptionUpdateWithoutProductMatchesDataInput = {
  id?: Maybe<Scalars['String']>,
  levelOne?: Maybe<Scalars['String']>,
  levelTwo?: Maybe<Scalars['String']>,
  originNum?: Maybe<Scalars['String']>,
  originBarcode?: Maybe<Scalars['String']>,
  newBarcode?: Maybe<Scalars['String']>,
  needInspection?: Maybe<Scalars['Boolean']>,
  temperature?: Maybe<TemperatureType>,
  bestPeriodDay?: Maybe<Scalars['Int']>,
  basicCourierPriceRange?: Maybe<BasicCourierPriceRange>,
  isActive?: Maybe<Scalars['Boolean']>,
  canBeMixed?: Maybe<Scalars['Boolean']>,
  maxAmount?: Maybe<Scalars['Int']>,
  note?: Maybe<Scalars['String']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  product?: Maybe<ProductUpdateOneRequiredWithoutProductOptionsInput>,
  category?: Maybe<ProductCategoryUpdateOneWithoutProductOptionsInput>,
  preStocks?: Maybe<PreStockUpdateManyWithoutProductOptionInput>,
  transactions?: Maybe<TransactionUpdateManyWithoutProductOptionInput>,
  autoMatches?: Maybe<AutoMatchUpdateManyWithoutProductOptionInput>,
};

export type ProductOptionUpsertWithoutProductMatchesInput = {
  update: ProductOptionUpdateWithoutProductMatchesDataInput,
  create: ProductOptionCreateWithoutProductMatchesInput,
};

export type ProductMatchUpsertWithoutFinalPackagesInput = {
  update: ProductMatchUpdateWithoutFinalPackagesDataInput,
  create: ProductMatchCreateWithoutFinalPackagesInput,
};

export type UserUpdateOneWithoutConfirmedFinalPackagesInput = {
  create?: Maybe<UserCreateWithoutConfirmedFinalPackagesInput>,
  connect?: Maybe<UserWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<UserUpdateWithoutConfirmedFinalPackagesDataInput>,
  upsert?: Maybe<UserUpsertWithoutConfirmedFinalPackagesInput>,
};

export type UserUpdateWithoutConfirmedFinalPackagesDataInput = {
  id?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  emailVerified?: Maybe<Scalars['Boolean']>,
  type?: Maybe<CompanyType>,
  password?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  role?: Maybe<RoleType>,
  marketing?: Maybe<Scalars['Boolean']>,
  settings?: Maybe<Scalars['Json']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  company?: Maybe<CompanyUpdateOneRequiredWithoutUsersInput>,
  team?: Maybe<TeamUpdateOneWithoutUsersInput>,
  confirmedTransactions?: Maybe<TransactionUpdateManyWithoutConfirmedUserInput>,
  registeredPreStocks?: Maybe<PreStockUpdateManyWithoutRegisteredUserInput>,
  confirmedPreStocks?: Maybe<PreStockUpdateManyWithoutConfirmedUserInput>,
  confirmedStocktaking?: Maybe<StocktakingUpdateManyWithoutConfirmedUserInput>,
  confirmedReturnOrder?: Maybe<ReturnOrderUpdateManyWithoutConfirmedUserInput>,
  logisticdefaultOf?: Maybe<LogisticUpdateManyWithoutDefaultUserInput>,
  logs?: Maybe<LogUpdateManyWithoutUserInput>,
  orderNotes?: Maybe<OrderNoteUpdateManyWithoutWriterInput>,
  confirmedOrderNotes?: Maybe<OrderNoteUpdateManyWithoutConfirmedUserInput>,
  notices?: Maybe<NoticeUpdateManyWithoutAuthorInput>,
};

export type StocktakingUpdateManyWithoutConfirmedUserInput = {
  create?: Maybe<Array<StocktakingCreateWithoutConfirmedUserInput>>,
  connect?: Maybe<Array<StocktakingWhereUniqueInput>>,
  set?: Maybe<Array<StocktakingWhereUniqueInput>>,
  disconnect?: Maybe<Array<StocktakingWhereUniqueInput>>,
  delete?: Maybe<Array<StocktakingWhereUniqueInput>>,
  update?: Maybe<Array<StocktakingUpdateWithWhereUniqueWithoutConfirmedUserInput>>,
  updateMany?: Maybe<Array<StocktakingUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<StocktakingScalarWhereInput>>,
  upsert?: Maybe<Array<StocktakingUpsertWithWhereUniqueWithoutConfirmedUserInput>>,
};

export type StocktakingUpdateWithWhereUniqueWithoutConfirmedUserInput = {
  where: StocktakingWhereUniqueInput,
  data: StocktakingUpdateWithoutConfirmedUserDataInput,
};

export type StocktakingUpdateWithoutConfirmedUserDataInput = {
  id?: Maybe<Scalars['String']>,
  inspectedDate?: Maybe<Scalars['DateTime']>,
  baseAmount?: Maybe<Scalars['Int']>,
  confirmedDate?: Maybe<Scalars['DateTime']>,
  confirmedNote?: Maybe<Scalars['String']>,
  amount?: Maybe<Scalars['Int']>,
  note?: Maybe<Scalars['String']>,
  status?: Maybe<StocktakingStatus>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  baseTransaction?: Maybe<TransactionUpdateOneRequiredWithoutStocktakingsInput>,
  transactions?: Maybe<TransactionUpdateManyWithoutStocktakingInput>,
};

export type TransactionUpdateOneRequiredWithoutStocktakingsInput = {
  create?: Maybe<TransactionCreateWithoutStocktakingsInput>,
  connect?: Maybe<TransactionWhereUniqueInput>,
  update?: Maybe<TransactionUpdateWithoutStocktakingsDataInput>,
  upsert?: Maybe<TransactionUpsertWithoutStocktakingsInput>,
};

export type TransactionUpdateWithoutStocktakingsDataInput = {
  id?: Maybe<Scalars['String']>,
  manufacturedDate?: Maybe<Scalars['DateTime']>,
  expiryDate?: Maybe<Scalars['DateTime']>,
  eventType?: Maybe<EventType>,
  transportType?: Maybe<TransportType>,
  usableAmount?: Maybe<Scalars['Int']>,
  brokenAmount?: Maybe<Scalars['Int']>,
  note?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  logistic?: Maybe<LogisticUpdateOneRequiredWithoutTransactionsInput>,
  preStock?: Maybe<PreStockUpdateOneWithoutTransactionsInput>,
  parent?: Maybe<TransactionUpdateOneWithoutChildrenInput>,
  location?: Maybe<LocationUpdateOneWithoutIncomingRecordsInput>,
  productOption?: Maybe<ProductOptionUpdateOneRequiredWithoutTransactionsInput>,
  confirmedUser?: Maybe<UserUpdateOneWithoutConfirmedTransactionsInput>,
  finalPackage?: Maybe<FinalPackageUpdateOneWithoutTransactionsInput>,
  stocktaking?: Maybe<StocktakingUpdateOneWithoutTransactionsInput>,
  children?: Maybe<TransactionUpdateManyWithoutParentInput>,
};

export type FinalPackageUpdateOneWithoutTransactionsInput = {
  create?: Maybe<FinalPackageCreateWithoutTransactionsInput>,
  connect?: Maybe<FinalPackageWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<FinalPackageUpdateWithoutTransactionsDataInput>,
  upsert?: Maybe<FinalPackageUpsertWithoutTransactionsInput>,
};

export type FinalPackageUpdateWithoutTransactionsDataInput = {
  id?: Maybe<Scalars['String']>,
  amount?: Maybe<Scalars['Int']>,
  status?: Maybe<FinalPackageStatus>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  courierInvoice?: Maybe<CourierInvoiceUpdateOneRequiredWithoutFinalPackagesInput>,
  productMatch?: Maybe<ProductMatchUpdateOneRequiredWithoutFinalPackagesInput>,
  confirmedUser?: Maybe<UserUpdateOneWithoutConfirmedFinalPackagesInput>,
  returnOrderProducts?: Maybe<ReturnOrderProductUpdateManyWithoutFinalPackageInput>,
};

export type ReturnOrderProductUpdateManyWithoutFinalPackageInput = {
  create?: Maybe<Array<ReturnOrderProductCreateWithoutFinalPackageInput>>,
  connect?: Maybe<Array<ReturnOrderProductWhereUniqueInput>>,
  set?: Maybe<Array<ReturnOrderProductWhereUniqueInput>>,
  disconnect?: Maybe<Array<ReturnOrderProductWhereUniqueInput>>,
  delete?: Maybe<Array<ReturnOrderProductWhereUniqueInput>>,
  update?: Maybe<Array<ReturnOrderProductUpdateWithWhereUniqueWithoutFinalPackageInput>>,
  updateMany?: Maybe<Array<ReturnOrderProductUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<ReturnOrderProductScalarWhereInput>>,
  upsert?: Maybe<Array<ReturnOrderProductUpsertWithWhereUniqueWithoutFinalPackageInput>>,
};

export type ReturnOrderProductUpdateWithWhereUniqueWithoutFinalPackageInput = {
  where: ReturnOrderProductWhereUniqueInput,
  data: ReturnOrderProductUpdateWithoutFinalPackageDataInput,
};

export type ReturnOrderProductUpdateWithoutFinalPackageDataInput = {
  id?: Maybe<Scalars['String']>,
  requestAmount?: Maybe<Scalars['Int']>,
  category?: Maybe<ReturnOrderType>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  returnOrder?: Maybe<ReturnOrderUpdateOneRequiredWithoutReturnOrderProductsInput>,
};

export type ReturnOrderUpdateOneRequiredWithoutReturnOrderProductsInput = {
  create?: Maybe<ReturnOrderCreateWithoutReturnOrderProductsInput>,
  connect?: Maybe<ReturnOrderWhereUniqueInput>,
  update?: Maybe<ReturnOrderUpdateWithoutReturnOrderProductsDataInput>,
  upsert?: Maybe<ReturnOrderUpsertWithoutReturnOrderProductsInput>,
};

export type ReturnOrderUpdateWithoutReturnOrderProductsDataInput = {
  id?: Maybe<Scalars['String']>,
  returnCourierInvoiceNum?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  tel?: Maybe<Scalars['String']>,
  zipcode?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  detailedAddress?: Maybe<Scalars['String']>,
  message?: Maybe<Scalars['String']>,
  status?: Maybe<ReturnStatus>,
  note?: Maybe<Scalars['String']>,
  deliveryPaidType?: Maybe<DeliveryPaidType>,
  returnedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  courierInvoice?: Maybe<CourierInvoiceUpdateOneRequiredWithoutReturnOrdersInput>,
  confirmedUser?: Maybe<UserUpdateOneRequiredWithoutConfirmedReturnOrderInput>,
};

export type CourierInvoiceUpdateOneRequiredWithoutReturnOrdersInput = {
  create?: Maybe<CourierInvoiceCreateWithoutReturnOrdersInput>,
  connect?: Maybe<CourierInvoiceWhereUniqueInput>,
  update?: Maybe<CourierInvoiceUpdateWithoutReturnOrdersDataInput>,
  upsert?: Maybe<CourierInvoiceUpsertWithoutReturnOrdersInput>,
};

export type CourierInvoiceUpdateWithoutReturnOrdersDataInput = {
  id?: Maybe<Scalars['String']>,
  type?: Maybe<CourierInvoiceType>,
  courierName?: Maybe<Scalars['String']>,
  num?: Maybe<Scalars['String']>,
  groupNum?: Maybe<Scalars['Int']>,
  printedNum?: Maybe<Scalars['Int']>,
  invoiceStatus?: Maybe<CourierInvoiceStatus>,
  status?: Maybe<PackageStatus>,
  sender?: Maybe<Scalars['String']>,
  senderTel?: Maybe<Scalars['String']>,
  senderZipcode?: Maybe<Scalars['String']>,
  senderAddress?: Maybe<Scalars['String']>,
  senderDetailedAddress?: Maybe<Scalars['String']>,
  receiver?: Maybe<Scalars['String']>,
  receiverTel?: Maybe<Scalars['String']>,
  receiverZipcode?: Maybe<Scalars['String']>,
  receiverAddress?: Maybe<Scalars['String']>,
  receiverDetailedAddress?: Maybe<Scalars['String']>,
  content?: Maybe<Scalars['String']>,
  message?: Maybe<Scalars['String']>,
  note?: Maybe<Scalars['String']>,
  addressRefinedDate?: Maybe<Scalars['DateTime']>,
  addressRefinedValues?: Maybe<Scalars['String']>,
  printedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  brand?: Maybe<BrandUpdateOneRequiredWithoutCourierInvoicesInput>,
  logisticCourier?: Maybe<LogisticCourierUpdateOneWithoutCourierInvoicesInput>,
  parent?: Maybe<CourierInvoiceUpdateOneWithoutChildrenInput>,
  courierPriceRange?: Maybe<CourierPriceRangeUpdateOneWithoutCourierInvoicesInput>,
  children?: Maybe<CourierInvoiceUpdateManyWithoutParentInput>,
  finalPackages?: Maybe<FinalPackageUpdateManyWithoutCourierInvoiceInput>,
};

export type CourierInvoiceUpsertWithoutReturnOrdersInput = {
  update: CourierInvoiceUpdateWithoutReturnOrdersDataInput,
  create: CourierInvoiceCreateWithoutReturnOrdersInput,
};

export type UserUpdateOneRequiredWithoutConfirmedReturnOrderInput = {
  create?: Maybe<UserCreateWithoutConfirmedReturnOrderInput>,
  connect?: Maybe<UserWhereUniqueInput>,
  update?: Maybe<UserUpdateWithoutConfirmedReturnOrderDataInput>,
  upsert?: Maybe<UserUpsertWithoutConfirmedReturnOrderInput>,
};

export type UserUpdateWithoutConfirmedReturnOrderDataInput = {
  id?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  emailVerified?: Maybe<Scalars['Boolean']>,
  type?: Maybe<CompanyType>,
  password?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  role?: Maybe<RoleType>,
  marketing?: Maybe<Scalars['Boolean']>,
  settings?: Maybe<Scalars['Json']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  company?: Maybe<CompanyUpdateOneRequiredWithoutUsersInput>,
  team?: Maybe<TeamUpdateOneWithoutUsersInput>,
  confirmedTransactions?: Maybe<TransactionUpdateManyWithoutConfirmedUserInput>,
  registeredPreStocks?: Maybe<PreStockUpdateManyWithoutRegisteredUserInput>,
  confirmedPreStocks?: Maybe<PreStockUpdateManyWithoutConfirmedUserInput>,
  confirmedFinalPackages?: Maybe<FinalPackageUpdateManyWithoutConfirmedUserInput>,
  confirmedStocktaking?: Maybe<StocktakingUpdateManyWithoutConfirmedUserInput>,
  logisticdefaultOf?: Maybe<LogisticUpdateManyWithoutDefaultUserInput>,
  logs?: Maybe<LogUpdateManyWithoutUserInput>,
  orderNotes?: Maybe<OrderNoteUpdateManyWithoutWriterInput>,
  confirmedOrderNotes?: Maybe<OrderNoteUpdateManyWithoutConfirmedUserInput>,
  notices?: Maybe<NoticeUpdateManyWithoutAuthorInput>,
};

export type LogisticUpdateManyWithoutDefaultUserInput = {
  create?: Maybe<Array<LogisticCreateWithoutDefaultUserInput>>,
  connect?: Maybe<Array<LogisticWhereUniqueInput>>,
  set?: Maybe<Array<LogisticWhereUniqueInput>>,
  disconnect?: Maybe<Array<LogisticWhereUniqueInput>>,
  delete?: Maybe<Array<LogisticWhereUniqueInput>>,
  update?: Maybe<Array<LogisticUpdateWithWhereUniqueWithoutDefaultUserInput>>,
  updateMany?: Maybe<Array<LogisticUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<LogisticScalarWhereInput>>,
  upsert?: Maybe<Array<LogisticUpsertWithWhereUniqueWithoutDefaultUserInput>>,
};

export type LogisticUpdateWithWhereUniqueWithoutDefaultUserInput = {
  where: LogisticWhereUniqueInput,
  data: LogisticUpdateWithoutDefaultUserDataInput,
};

export type LogisticUpdateWithoutDefaultUserDataInput = {
  id?: Maybe<Scalars['String']>,
  marketing?: Maybe<Scalars['Boolean']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  company?: Maybe<CompanyUpdateOneRequiredWithoutLogisticInput>,
  defaultCourier?: Maybe<LogisticCourierUpdateOneWithoutLogisticDefaultOfInput>,
  sellers?: Maybe<SellerUpdateManyWithoutLogisticInput>,
  availableCouriers?: Maybe<LogisticCourierUpdateManyWithoutLogisticInput>,
  packets?: Maybe<PacketUpdateManyWithoutLogisticInput>,
  centers?: Maybe<CenterUpdateManyWithoutLogisticInput>,
  preStocks?: Maybe<PreStockUpdateManyWithoutLogisticInput>,
  transactions?: Maybe<TransactionUpdateManyWithoutLogisticInput>,
  notices?: Maybe<NoticeUpdateManyWithoutLogisticInput>,
};

export type NoticeUpdateManyWithoutLogisticInput = {
  create?: Maybe<Array<NoticeCreateWithoutLogisticInput>>,
  connect?: Maybe<Array<NoticeWhereUniqueInput>>,
  set?: Maybe<Array<NoticeWhereUniqueInput>>,
  disconnect?: Maybe<Array<NoticeWhereUniqueInput>>,
  delete?: Maybe<Array<NoticeWhereUniqueInput>>,
  update?: Maybe<Array<NoticeUpdateWithWhereUniqueWithoutLogisticInput>>,
  updateMany?: Maybe<Array<NoticeUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<NoticeScalarWhereInput>>,
  upsert?: Maybe<Array<NoticeUpsertWithWhereUniqueWithoutLogisticInput>>,
};

export type NoticeUpdateWithWhereUniqueWithoutLogisticInput = {
  where: NoticeWhereUniqueInput,
  data: NoticeUpdateWithoutLogisticDataInput,
};

export type NoticeUpdateWithoutLogisticDataInput = {
  id?: Maybe<Scalars['String']>,
  groupId?: Maybe<Scalars['String']>,
  expiryDate?: Maybe<Scalars['DateTime']>,
  title?: Maybe<Scalars['String']>,
  content?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  isPublic?: Maybe<Scalars['Boolean']>,
  isImportant?: Maybe<Scalars['Boolean']>,
  author?: Maybe<UserUpdateOneRequiredWithoutNoticesInput>,
  seller?: Maybe<SellerUpdateOneRequiredWithoutNoticesInput>,
};

export type UserUpdateOneRequiredWithoutNoticesInput = {
  create?: Maybe<UserCreateWithoutNoticesInput>,
  connect?: Maybe<UserWhereUniqueInput>,
  update?: Maybe<UserUpdateWithoutNoticesDataInput>,
  upsert?: Maybe<UserUpsertWithoutNoticesInput>,
};

export type UserUpdateWithoutNoticesDataInput = {
  id?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  emailVerified?: Maybe<Scalars['Boolean']>,
  type?: Maybe<CompanyType>,
  password?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  role?: Maybe<RoleType>,
  marketing?: Maybe<Scalars['Boolean']>,
  settings?: Maybe<Scalars['Json']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  company?: Maybe<CompanyUpdateOneRequiredWithoutUsersInput>,
  team?: Maybe<TeamUpdateOneWithoutUsersInput>,
  confirmedTransactions?: Maybe<TransactionUpdateManyWithoutConfirmedUserInput>,
  registeredPreStocks?: Maybe<PreStockUpdateManyWithoutRegisteredUserInput>,
  confirmedPreStocks?: Maybe<PreStockUpdateManyWithoutConfirmedUserInput>,
  confirmedFinalPackages?: Maybe<FinalPackageUpdateManyWithoutConfirmedUserInput>,
  confirmedStocktaking?: Maybe<StocktakingUpdateManyWithoutConfirmedUserInput>,
  confirmedReturnOrder?: Maybe<ReturnOrderUpdateManyWithoutConfirmedUserInput>,
  logisticdefaultOf?: Maybe<LogisticUpdateManyWithoutDefaultUserInput>,
  logs?: Maybe<LogUpdateManyWithoutUserInput>,
  orderNotes?: Maybe<OrderNoteUpdateManyWithoutWriterInput>,
  confirmedOrderNotes?: Maybe<OrderNoteUpdateManyWithoutConfirmedUserInput>,
};

export type ReturnOrderUpdateManyWithoutConfirmedUserInput = {
  create?: Maybe<Array<ReturnOrderCreateWithoutConfirmedUserInput>>,
  connect?: Maybe<Array<ReturnOrderWhereUniqueInput>>,
  set?: Maybe<Array<ReturnOrderWhereUniqueInput>>,
  disconnect?: Maybe<Array<ReturnOrderWhereUniqueInput>>,
  delete?: Maybe<Array<ReturnOrderWhereUniqueInput>>,
  update?: Maybe<Array<ReturnOrderUpdateWithWhereUniqueWithoutConfirmedUserInput>>,
  updateMany?: Maybe<Array<ReturnOrderUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<ReturnOrderScalarWhereInput>>,
  upsert?: Maybe<Array<ReturnOrderUpsertWithWhereUniqueWithoutConfirmedUserInput>>,
};

export type ReturnOrderUpdateWithWhereUniqueWithoutConfirmedUserInput = {
  where: ReturnOrderWhereUniqueInput,
  data: ReturnOrderUpdateWithoutConfirmedUserDataInput,
};

export type ReturnOrderUpdateWithoutConfirmedUserDataInput = {
  id?: Maybe<Scalars['String']>,
  returnCourierInvoiceNum?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  tel?: Maybe<Scalars['String']>,
  zipcode?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  detailedAddress?: Maybe<Scalars['String']>,
  message?: Maybe<Scalars['String']>,
  status?: Maybe<ReturnStatus>,
  note?: Maybe<Scalars['String']>,
  deliveryPaidType?: Maybe<DeliveryPaidType>,
  returnedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  courierInvoice?: Maybe<CourierInvoiceUpdateOneRequiredWithoutReturnOrdersInput>,
  returnOrderProducts?: Maybe<ReturnOrderProductUpdateManyWithoutReturnOrderInput>,
};

export type ReturnOrderProductUpdateManyWithoutReturnOrderInput = {
  create?: Maybe<Array<ReturnOrderProductCreateWithoutReturnOrderInput>>,
  connect?: Maybe<Array<ReturnOrderProductWhereUniqueInput>>,
  set?: Maybe<Array<ReturnOrderProductWhereUniqueInput>>,
  disconnect?: Maybe<Array<ReturnOrderProductWhereUniqueInput>>,
  delete?: Maybe<Array<ReturnOrderProductWhereUniqueInput>>,
  update?: Maybe<Array<ReturnOrderProductUpdateWithWhereUniqueWithoutReturnOrderInput>>,
  updateMany?: Maybe<Array<ReturnOrderProductUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<ReturnOrderProductScalarWhereInput>>,
  upsert?: Maybe<Array<ReturnOrderProductUpsertWithWhereUniqueWithoutReturnOrderInput>>,
};

export type ReturnOrderProductUpdateWithWhereUniqueWithoutReturnOrderInput = {
  where: ReturnOrderProductWhereUniqueInput,
  data: ReturnOrderProductUpdateWithoutReturnOrderDataInput,
};

export type ReturnOrderProductUpdateWithoutReturnOrderDataInput = {
  id?: Maybe<Scalars['String']>,
  requestAmount?: Maybe<Scalars['Int']>,
  category?: Maybe<ReturnOrderType>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  finalPackage?: Maybe<FinalPackageUpdateOneRequiredWithoutReturnOrderProductsInput>,
};

export type FinalPackageUpdateOneRequiredWithoutReturnOrderProductsInput = {
  create?: Maybe<FinalPackageCreateWithoutReturnOrderProductsInput>,
  connect?: Maybe<FinalPackageWhereUniqueInput>,
  update?: Maybe<FinalPackageUpdateWithoutReturnOrderProductsDataInput>,
  upsert?: Maybe<FinalPackageUpsertWithoutReturnOrderProductsInput>,
};

export type FinalPackageUpdateWithoutReturnOrderProductsDataInput = {
  id?: Maybe<Scalars['String']>,
  amount?: Maybe<Scalars['Int']>,
  status?: Maybe<FinalPackageStatus>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  courierInvoice?: Maybe<CourierInvoiceUpdateOneRequiredWithoutFinalPackagesInput>,
  productMatch?: Maybe<ProductMatchUpdateOneRequiredWithoutFinalPackagesInput>,
  confirmedUser?: Maybe<UserUpdateOneWithoutConfirmedFinalPackagesInput>,
  transactions?: Maybe<TransactionUpdateManyWithoutFinalPackageInput>,
};

export type TransactionUpdateManyWithoutFinalPackageInput = {
  create?: Maybe<Array<TransactionCreateWithoutFinalPackageInput>>,
  connect?: Maybe<Array<TransactionWhereUniqueInput>>,
  set?: Maybe<Array<TransactionWhereUniqueInput>>,
  disconnect?: Maybe<Array<TransactionWhereUniqueInput>>,
  delete?: Maybe<Array<TransactionWhereUniqueInput>>,
  update?: Maybe<Array<TransactionUpdateWithWhereUniqueWithoutFinalPackageInput>>,
  updateMany?: Maybe<Array<TransactionUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<TransactionScalarWhereInput>>,
  upsert?: Maybe<Array<TransactionUpsertWithWhereUniqueWithoutFinalPackageInput>>,
};

export type TransactionUpdateWithWhereUniqueWithoutFinalPackageInput = {
  where: TransactionWhereUniqueInput,
  data: TransactionUpdateWithoutFinalPackageDataInput,
};

export type TransactionUpdateWithoutFinalPackageDataInput = {
  id?: Maybe<Scalars['String']>,
  manufacturedDate?: Maybe<Scalars['DateTime']>,
  expiryDate?: Maybe<Scalars['DateTime']>,
  eventType?: Maybe<EventType>,
  transportType?: Maybe<TransportType>,
  usableAmount?: Maybe<Scalars['Int']>,
  brokenAmount?: Maybe<Scalars['Int']>,
  note?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  logistic?: Maybe<LogisticUpdateOneRequiredWithoutTransactionsInput>,
  preStock?: Maybe<PreStockUpdateOneWithoutTransactionsInput>,
  parent?: Maybe<TransactionUpdateOneWithoutChildrenInput>,
  location?: Maybe<LocationUpdateOneWithoutIncomingRecordsInput>,
  productOption?: Maybe<ProductOptionUpdateOneRequiredWithoutTransactionsInput>,
  confirmedUser?: Maybe<UserUpdateOneWithoutConfirmedTransactionsInput>,
  stocktaking?: Maybe<StocktakingUpdateOneWithoutTransactionsInput>,
  children?: Maybe<TransactionUpdateManyWithoutParentInput>,
  stocktakings?: Maybe<StocktakingUpdateManyWithoutBaseTransactionInput>,
};

export type StocktakingUpdateOneWithoutTransactionsInput = {
  create?: Maybe<StocktakingCreateWithoutTransactionsInput>,
  connect?: Maybe<StocktakingWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<StocktakingUpdateWithoutTransactionsDataInput>,
  upsert?: Maybe<StocktakingUpsertWithoutTransactionsInput>,
};

export type StocktakingUpdateWithoutTransactionsDataInput = {
  id?: Maybe<Scalars['String']>,
  inspectedDate?: Maybe<Scalars['DateTime']>,
  baseAmount?: Maybe<Scalars['Int']>,
  confirmedDate?: Maybe<Scalars['DateTime']>,
  confirmedNote?: Maybe<Scalars['String']>,
  amount?: Maybe<Scalars['Int']>,
  note?: Maybe<Scalars['String']>,
  status?: Maybe<StocktakingStatus>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  baseTransaction?: Maybe<TransactionUpdateOneRequiredWithoutStocktakingsInput>,
  confirmedUser?: Maybe<UserUpdateOneWithoutConfirmedStocktakingInput>,
};

export type UserUpdateOneWithoutConfirmedStocktakingInput = {
  create?: Maybe<UserCreateWithoutConfirmedStocktakingInput>,
  connect?: Maybe<UserWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<UserUpdateWithoutConfirmedStocktakingDataInput>,
  upsert?: Maybe<UserUpsertWithoutConfirmedStocktakingInput>,
};

export type UserUpdateWithoutConfirmedStocktakingDataInput = {
  id?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  emailVerified?: Maybe<Scalars['Boolean']>,
  type?: Maybe<CompanyType>,
  password?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  role?: Maybe<RoleType>,
  marketing?: Maybe<Scalars['Boolean']>,
  settings?: Maybe<Scalars['Json']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  company?: Maybe<CompanyUpdateOneRequiredWithoutUsersInput>,
  team?: Maybe<TeamUpdateOneWithoutUsersInput>,
  confirmedTransactions?: Maybe<TransactionUpdateManyWithoutConfirmedUserInput>,
  registeredPreStocks?: Maybe<PreStockUpdateManyWithoutRegisteredUserInput>,
  confirmedPreStocks?: Maybe<PreStockUpdateManyWithoutConfirmedUserInput>,
  confirmedFinalPackages?: Maybe<FinalPackageUpdateManyWithoutConfirmedUserInput>,
  confirmedReturnOrder?: Maybe<ReturnOrderUpdateManyWithoutConfirmedUserInput>,
  logisticdefaultOf?: Maybe<LogisticUpdateManyWithoutDefaultUserInput>,
  logs?: Maybe<LogUpdateManyWithoutUserInput>,
  orderNotes?: Maybe<OrderNoteUpdateManyWithoutWriterInput>,
  confirmedOrderNotes?: Maybe<OrderNoteUpdateManyWithoutConfirmedUserInput>,
  notices?: Maybe<NoticeUpdateManyWithoutAuthorInput>,
};

export type LogUpdateManyWithoutUserInput = {
  create?: Maybe<Array<LogCreateWithoutUserInput>>,
  connect?: Maybe<Array<LogWhereUniqueInput>>,
  set?: Maybe<Array<LogWhereUniqueInput>>,
  disconnect?: Maybe<Array<LogWhereUniqueInput>>,
  delete?: Maybe<Array<LogWhereUniqueInput>>,
  update?: Maybe<Array<LogUpdateWithWhereUniqueWithoutUserInput>>,
  updateMany?: Maybe<Array<LogUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<LogScalarWhereInput>>,
  upsert?: Maybe<Array<LogUpsertWithWhereUniqueWithoutUserInput>>,
};

export type LogUpdateWithWhereUniqueWithoutUserInput = {
  where: LogWhereUniqueInput,
  data: LogUpdateWithoutUserDataInput,
};

export type LogUpdateWithoutUserDataInput = {
  id?: Maybe<Scalars['String']>,
  content?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
};

export type LogUpdateManyWithWhereNestedInput = {
  where: LogScalarWhereInput,
  data: LogUpdateManyDataInput,
};

export type LogScalarWhereInput = {
  id?: Maybe<StringFilter>,
  content?: Maybe<StringFilter>,
  userId?: Maybe<NullableStringFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  AND?: Maybe<Array<LogScalarWhereInput>>,
  OR?: Maybe<Array<LogScalarWhereInput>>,
  NOT?: Maybe<Array<LogScalarWhereInput>>,
};

export type LogUpdateManyDataInput = {
  id?: Maybe<Scalars['String']>,
  content?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
};

export type LogUpsertWithWhereUniqueWithoutUserInput = {
  where: LogWhereUniqueInput,
  update: LogUpdateWithoutUserDataInput,
  create: LogCreateWithoutUserInput,
};

export type OrderNoteUpdateManyWithoutWriterInput = {
  create?: Maybe<Array<OrderNoteCreateWithoutWriterInput>>,
  connect?: Maybe<Array<OrderNoteWhereUniqueInput>>,
  set?: Maybe<Array<OrderNoteWhereUniqueInput>>,
  disconnect?: Maybe<Array<OrderNoteWhereUniqueInput>>,
  delete?: Maybe<Array<OrderNoteWhereUniqueInput>>,
  update?: Maybe<Array<OrderNoteUpdateWithWhereUniqueWithoutWriterInput>>,
  updateMany?: Maybe<Array<OrderNoteUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<OrderNoteScalarWhereInput>>,
  upsert?: Maybe<Array<OrderNoteUpsertWithWhereUniqueWithoutWriterInput>>,
};

export type OrderNoteUpdateWithWhereUniqueWithoutWriterInput = {
  where: OrderNoteWhereUniqueInput,
  data: OrderNoteUpdateWithoutWriterDataInput,
};

export type OrderNoteUpdateWithoutWriterDataInput = {
  id?: Maybe<Scalars['String']>,
  type?: Maybe<OrderNoteType>,
  note?: Maybe<Scalars['String']>,
  status?: Maybe<OrderNoteStatus>,
  confirmedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  order?: Maybe<OrderUpdateOneRequiredWithoutOrderNotesInput>,
  confirmedUser?: Maybe<UserUpdateOneWithoutConfirmedOrderNotesInput>,
};

export type OrderUpdateOneRequiredWithoutOrderNotesInput = {
  create?: Maybe<OrderCreateWithoutOrderNotesInput>,
  connect?: Maybe<OrderWhereUniqueInput>,
  update?: Maybe<OrderUpdateWithoutOrderNotesDataInput>,
  upsert?: Maybe<OrderUpsertWithoutOrderNotesInput>,
};

export type OrderUpdateWithoutOrderNotesDataInput = {
  id?: Maybe<Scalars['String']>,
  num?: Maybe<Scalars['String']>,
  marketOrderNum?: Maybe<Scalars['String']>,
  orderedDate?: Maybe<Scalars['DateTime']>,
  paidDate?: Maybe<Scalars['DateTime']>,
  sender?: Maybe<Scalars['String']>,
  senderTel?: Maybe<Scalars['String']>,
  senderTel2?: Maybe<Scalars['String']>,
  receiver?: Maybe<Scalars['String']>,
  receiverTel?: Maybe<Scalars['String']>,
  receiverTel2?: Maybe<Scalars['String']>,
  receiverZipcode?: Maybe<Scalars['String']>,
  receiverAddress?: Maybe<Scalars['String']>,
  receiverDetailedAddress?: Maybe<Scalars['String']>,
  message?: Maybe<Scalars['String']>,
  deliveryPaid?: Maybe<Scalars['Boolean']>,
  deliveryPrice?: Maybe<Scalars['Int']>,
  isOverseas?: Maybe<Scalars['Boolean']>,
  remoteAreaPrice?: Maybe<Scalars['Int']>,
  detailedOrder?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  brandMarket?: Maybe<BrandMarketUpdateOneRequiredWithoutOrdersInput>,
  marketProducts?: Maybe<MarketProductUpdateManyWithoutOrderInput>,
};

export type OrderUpsertWithoutOrderNotesInput = {
  update: OrderUpdateWithoutOrderNotesDataInput,
  create: OrderCreateWithoutOrderNotesInput,
};

export type UserUpdateOneWithoutConfirmedOrderNotesInput = {
  create?: Maybe<UserCreateWithoutConfirmedOrderNotesInput>,
  connect?: Maybe<UserWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<UserUpdateWithoutConfirmedOrderNotesDataInput>,
  upsert?: Maybe<UserUpsertWithoutConfirmedOrderNotesInput>,
};

export type UserUpdateWithoutConfirmedOrderNotesDataInput = {
  id?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  emailVerified?: Maybe<Scalars['Boolean']>,
  type?: Maybe<CompanyType>,
  password?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  role?: Maybe<RoleType>,
  marketing?: Maybe<Scalars['Boolean']>,
  settings?: Maybe<Scalars['Json']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  company?: Maybe<CompanyUpdateOneRequiredWithoutUsersInput>,
  team?: Maybe<TeamUpdateOneWithoutUsersInput>,
  confirmedTransactions?: Maybe<TransactionUpdateManyWithoutConfirmedUserInput>,
  registeredPreStocks?: Maybe<PreStockUpdateManyWithoutRegisteredUserInput>,
  confirmedPreStocks?: Maybe<PreStockUpdateManyWithoutConfirmedUserInput>,
  confirmedFinalPackages?: Maybe<FinalPackageUpdateManyWithoutConfirmedUserInput>,
  confirmedStocktaking?: Maybe<StocktakingUpdateManyWithoutConfirmedUserInput>,
  confirmedReturnOrder?: Maybe<ReturnOrderUpdateManyWithoutConfirmedUserInput>,
  logisticdefaultOf?: Maybe<LogisticUpdateManyWithoutDefaultUserInput>,
  logs?: Maybe<LogUpdateManyWithoutUserInput>,
  orderNotes?: Maybe<OrderNoteUpdateManyWithoutWriterInput>,
  notices?: Maybe<NoticeUpdateManyWithoutAuthorInput>,
};

export type NoticeUpdateManyWithoutAuthorInput = {
  create?: Maybe<Array<NoticeCreateWithoutAuthorInput>>,
  connect?: Maybe<Array<NoticeWhereUniqueInput>>,
  set?: Maybe<Array<NoticeWhereUniqueInput>>,
  disconnect?: Maybe<Array<NoticeWhereUniqueInput>>,
  delete?: Maybe<Array<NoticeWhereUniqueInput>>,
  update?: Maybe<Array<NoticeUpdateWithWhereUniqueWithoutAuthorInput>>,
  updateMany?: Maybe<Array<NoticeUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<NoticeScalarWhereInput>>,
  upsert?: Maybe<Array<NoticeUpsertWithWhereUniqueWithoutAuthorInput>>,
};

export type NoticeUpdateWithWhereUniqueWithoutAuthorInput = {
  where: NoticeWhereUniqueInput,
  data: NoticeUpdateWithoutAuthorDataInput,
};

export type NoticeUpdateWithoutAuthorDataInput = {
  id?: Maybe<Scalars['String']>,
  groupId?: Maybe<Scalars['String']>,
  expiryDate?: Maybe<Scalars['DateTime']>,
  title?: Maybe<Scalars['String']>,
  content?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  isPublic?: Maybe<Scalars['Boolean']>,
  isImportant?: Maybe<Scalars['Boolean']>,
  logistic?: Maybe<LogisticUpdateOneRequiredWithoutNoticesInput>,
  seller?: Maybe<SellerUpdateOneRequiredWithoutNoticesInput>,
};

export type SellerUpdateOneRequiredWithoutNoticesInput = {
  create?: Maybe<SellerCreateWithoutNoticesInput>,
  connect?: Maybe<SellerWhereUniqueInput>,
  update?: Maybe<SellerUpdateWithoutNoticesDataInput>,
  upsert?: Maybe<SellerUpsertWithoutNoticesInput>,
};

export type SellerUpdateWithoutNoticesDataInput = {
  id?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  company?: Maybe<CompanyUpdateOneRequiredWithoutSellerInput>,
  logistic?: Maybe<LogisticUpdateOneRequiredWithoutSellersInput>,
  defaultCourier?: Maybe<LogisticCourierUpdateOneWithoutSellerDefaultOfInput>,
  brands?: Maybe<BrandUpdateManyWithoutSellerInput>,
};

export type SellerUpsertWithoutNoticesInput = {
  update: SellerUpdateWithoutNoticesDataInput,
  create: SellerCreateWithoutNoticesInput,
};

export type NoticeUpdateManyWithWhereNestedInput = {
  where: NoticeScalarWhereInput,
  data: NoticeUpdateManyDataInput,
};

export type NoticeScalarWhereInput = {
  id?: Maybe<StringFilter>,
  logisticId?: Maybe<StringFilter>,
  groupId?: Maybe<StringFilter>,
  expiryDate?: Maybe<NullableDateTimeFilter>,
  title?: Maybe<StringFilter>,
  content?: Maybe<StringFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  isPublic?: Maybe<BooleanFilter>,
  isImportant?: Maybe<BooleanFilter>,
  authorId?: Maybe<StringFilter>,
  sellerId?: Maybe<StringFilter>,
  AND?: Maybe<Array<NoticeScalarWhereInput>>,
  OR?: Maybe<Array<NoticeScalarWhereInput>>,
  NOT?: Maybe<Array<NoticeScalarWhereInput>>,
};

export type NoticeUpdateManyDataInput = {
  id?: Maybe<Scalars['String']>,
  groupId?: Maybe<Scalars['String']>,
  expiryDate?: Maybe<Scalars['DateTime']>,
  title?: Maybe<Scalars['String']>,
  content?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  isPublic?: Maybe<Scalars['Boolean']>,
  isImportant?: Maybe<Scalars['Boolean']>,
};

export type NoticeUpsertWithWhereUniqueWithoutAuthorInput = {
  where: NoticeWhereUniqueInput,
  update: NoticeUpdateWithoutAuthorDataInput,
  create: NoticeCreateWithoutAuthorInput,
};

export type UserUpsertWithoutConfirmedOrderNotesInput = {
  update: UserUpdateWithoutConfirmedOrderNotesDataInput,
  create: UserCreateWithoutConfirmedOrderNotesInput,
};

export type OrderNoteUpdateManyWithWhereNestedInput = {
  where: OrderNoteScalarWhereInput,
  data: OrderNoteUpdateManyDataInput,
};

export type OrderNoteScalarWhereInput = {
  id?: Maybe<StringFilter>,
  orderId?: Maybe<StringFilter>,
  type?: Maybe<OrderNoteType>,
  note?: Maybe<NullableStringFilter>,
  status?: Maybe<OrderNoteStatus>,
  writerId?: Maybe<StringFilter>,
  confirmedUserId?: Maybe<NullableStringFilter>,
  confirmedAt?: Maybe<NullableDateTimeFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  AND?: Maybe<Array<OrderNoteScalarWhereInput>>,
  OR?: Maybe<Array<OrderNoteScalarWhereInput>>,
  NOT?: Maybe<Array<OrderNoteScalarWhereInput>>,
};

export type OrderNoteUpdateManyDataInput = {
  id?: Maybe<Scalars['String']>,
  type?: Maybe<OrderNoteType>,
  note?: Maybe<Scalars['String']>,
  status?: Maybe<OrderNoteStatus>,
  confirmedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type OrderNoteUpsertWithWhereUniqueWithoutWriterInput = {
  where: OrderNoteWhereUniqueInput,
  update: OrderNoteUpdateWithoutWriterDataInput,
  create: OrderNoteCreateWithoutWriterInput,
};

export type OrderNoteUpdateManyWithoutConfirmedUserInput = {
  create?: Maybe<Array<OrderNoteCreateWithoutConfirmedUserInput>>,
  connect?: Maybe<Array<OrderNoteWhereUniqueInput>>,
  set?: Maybe<Array<OrderNoteWhereUniqueInput>>,
  disconnect?: Maybe<Array<OrderNoteWhereUniqueInput>>,
  delete?: Maybe<Array<OrderNoteWhereUniqueInput>>,
  update?: Maybe<Array<OrderNoteUpdateWithWhereUniqueWithoutConfirmedUserInput>>,
  updateMany?: Maybe<Array<OrderNoteUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<OrderNoteScalarWhereInput>>,
  upsert?: Maybe<Array<OrderNoteUpsertWithWhereUniqueWithoutConfirmedUserInput>>,
};

export type OrderNoteUpdateWithWhereUniqueWithoutConfirmedUserInput = {
  where: OrderNoteWhereUniqueInput,
  data: OrderNoteUpdateWithoutConfirmedUserDataInput,
};

export type OrderNoteUpdateWithoutConfirmedUserDataInput = {
  id?: Maybe<Scalars['String']>,
  type?: Maybe<OrderNoteType>,
  note?: Maybe<Scalars['String']>,
  status?: Maybe<OrderNoteStatus>,
  confirmedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  order?: Maybe<OrderUpdateOneRequiredWithoutOrderNotesInput>,
  writer?: Maybe<UserUpdateOneRequiredWithoutOrderNotesInput>,
};

export type UserUpdateOneRequiredWithoutOrderNotesInput = {
  create?: Maybe<UserCreateWithoutOrderNotesInput>,
  connect?: Maybe<UserWhereUniqueInput>,
  update?: Maybe<UserUpdateWithoutOrderNotesDataInput>,
  upsert?: Maybe<UserUpsertWithoutOrderNotesInput>,
};

export type UserUpdateWithoutOrderNotesDataInput = {
  id?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  emailVerified?: Maybe<Scalars['Boolean']>,
  type?: Maybe<CompanyType>,
  password?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  role?: Maybe<RoleType>,
  marketing?: Maybe<Scalars['Boolean']>,
  settings?: Maybe<Scalars['Json']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  company?: Maybe<CompanyUpdateOneRequiredWithoutUsersInput>,
  team?: Maybe<TeamUpdateOneWithoutUsersInput>,
  confirmedTransactions?: Maybe<TransactionUpdateManyWithoutConfirmedUserInput>,
  registeredPreStocks?: Maybe<PreStockUpdateManyWithoutRegisteredUserInput>,
  confirmedPreStocks?: Maybe<PreStockUpdateManyWithoutConfirmedUserInput>,
  confirmedFinalPackages?: Maybe<FinalPackageUpdateManyWithoutConfirmedUserInput>,
  confirmedStocktaking?: Maybe<StocktakingUpdateManyWithoutConfirmedUserInput>,
  confirmedReturnOrder?: Maybe<ReturnOrderUpdateManyWithoutConfirmedUserInput>,
  logisticdefaultOf?: Maybe<LogisticUpdateManyWithoutDefaultUserInput>,
  logs?: Maybe<LogUpdateManyWithoutUserInput>,
  confirmedOrderNotes?: Maybe<OrderNoteUpdateManyWithoutConfirmedUserInput>,
  notices?: Maybe<NoticeUpdateManyWithoutAuthorInput>,
};

export type UserUpsertWithoutOrderNotesInput = {
  update: UserUpdateWithoutOrderNotesDataInput,
  create: UserCreateWithoutOrderNotesInput,
};

export type OrderNoteUpsertWithWhereUniqueWithoutConfirmedUserInput = {
  where: OrderNoteWhereUniqueInput,
  update: OrderNoteUpdateWithoutConfirmedUserDataInput,
  create: OrderNoteCreateWithoutConfirmedUserInput,
};

export type UserUpsertWithoutConfirmedStocktakingInput = {
  update: UserUpdateWithoutConfirmedStocktakingDataInput,
  create: UserCreateWithoutConfirmedStocktakingInput,
};

export type StocktakingUpsertWithoutTransactionsInput = {
  update: StocktakingUpdateWithoutTransactionsDataInput,
  create: StocktakingCreateWithoutTransactionsInput,
};

export type TransactionUpdateManyWithoutParentInput = {
  create?: Maybe<Array<TransactionCreateWithoutParentInput>>,
  connect?: Maybe<Array<TransactionWhereUniqueInput>>,
  set?: Maybe<Array<TransactionWhereUniqueInput>>,
  disconnect?: Maybe<Array<TransactionWhereUniqueInput>>,
  delete?: Maybe<Array<TransactionWhereUniqueInput>>,
  update?: Maybe<Array<TransactionUpdateWithWhereUniqueWithoutParentInput>>,
  updateMany?: Maybe<Array<TransactionUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<TransactionScalarWhereInput>>,
  upsert?: Maybe<Array<TransactionUpsertWithWhereUniqueWithoutParentInput>>,
};

export type TransactionUpdateWithWhereUniqueWithoutParentInput = {
  where: TransactionWhereUniqueInput,
  data: TransactionUpdateWithoutParentDataInput,
};

export type TransactionUpdateWithoutParentDataInput = {
  id?: Maybe<Scalars['String']>,
  manufacturedDate?: Maybe<Scalars['DateTime']>,
  expiryDate?: Maybe<Scalars['DateTime']>,
  eventType?: Maybe<EventType>,
  transportType?: Maybe<TransportType>,
  usableAmount?: Maybe<Scalars['Int']>,
  brokenAmount?: Maybe<Scalars['Int']>,
  note?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  logistic?: Maybe<LogisticUpdateOneRequiredWithoutTransactionsInput>,
  preStock?: Maybe<PreStockUpdateOneWithoutTransactionsInput>,
  location?: Maybe<LocationUpdateOneWithoutIncomingRecordsInput>,
  productOption?: Maybe<ProductOptionUpdateOneRequiredWithoutTransactionsInput>,
  confirmedUser?: Maybe<UserUpdateOneWithoutConfirmedTransactionsInput>,
  finalPackage?: Maybe<FinalPackageUpdateOneWithoutTransactionsInput>,
  stocktaking?: Maybe<StocktakingUpdateOneWithoutTransactionsInput>,
  children?: Maybe<TransactionUpdateManyWithoutParentInput>,
  stocktakings?: Maybe<StocktakingUpdateManyWithoutBaseTransactionInput>,
};

export type StocktakingUpdateManyWithoutBaseTransactionInput = {
  create?: Maybe<Array<StocktakingCreateWithoutBaseTransactionInput>>,
  connect?: Maybe<Array<StocktakingWhereUniqueInput>>,
  set?: Maybe<Array<StocktakingWhereUniqueInput>>,
  disconnect?: Maybe<Array<StocktakingWhereUniqueInput>>,
  delete?: Maybe<Array<StocktakingWhereUniqueInput>>,
  update?: Maybe<Array<StocktakingUpdateWithWhereUniqueWithoutBaseTransactionInput>>,
  updateMany?: Maybe<Array<StocktakingUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<StocktakingScalarWhereInput>>,
  upsert?: Maybe<Array<StocktakingUpsertWithWhereUniqueWithoutBaseTransactionInput>>,
};

export type StocktakingUpdateWithWhereUniqueWithoutBaseTransactionInput = {
  where: StocktakingWhereUniqueInput,
  data: StocktakingUpdateWithoutBaseTransactionDataInput,
};

export type StocktakingUpdateWithoutBaseTransactionDataInput = {
  id?: Maybe<Scalars['String']>,
  inspectedDate?: Maybe<Scalars['DateTime']>,
  baseAmount?: Maybe<Scalars['Int']>,
  confirmedDate?: Maybe<Scalars['DateTime']>,
  confirmedNote?: Maybe<Scalars['String']>,
  amount?: Maybe<Scalars['Int']>,
  note?: Maybe<Scalars['String']>,
  status?: Maybe<StocktakingStatus>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  confirmedUser?: Maybe<UserUpdateOneWithoutConfirmedStocktakingInput>,
  transactions?: Maybe<TransactionUpdateManyWithoutStocktakingInput>,
};

export type TransactionUpdateManyWithoutStocktakingInput = {
  create?: Maybe<Array<TransactionCreateWithoutStocktakingInput>>,
  connect?: Maybe<Array<TransactionWhereUniqueInput>>,
  set?: Maybe<Array<TransactionWhereUniqueInput>>,
  disconnect?: Maybe<Array<TransactionWhereUniqueInput>>,
  delete?: Maybe<Array<TransactionWhereUniqueInput>>,
  update?: Maybe<Array<TransactionUpdateWithWhereUniqueWithoutStocktakingInput>>,
  updateMany?: Maybe<Array<TransactionUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<TransactionScalarWhereInput>>,
  upsert?: Maybe<Array<TransactionUpsertWithWhereUniqueWithoutStocktakingInput>>,
};

export type TransactionUpdateWithWhereUniqueWithoutStocktakingInput = {
  where: TransactionWhereUniqueInput,
  data: TransactionUpdateWithoutStocktakingDataInput,
};

export type TransactionUpdateWithoutStocktakingDataInput = {
  id?: Maybe<Scalars['String']>,
  manufacturedDate?: Maybe<Scalars['DateTime']>,
  expiryDate?: Maybe<Scalars['DateTime']>,
  eventType?: Maybe<EventType>,
  transportType?: Maybe<TransportType>,
  usableAmount?: Maybe<Scalars['Int']>,
  brokenAmount?: Maybe<Scalars['Int']>,
  note?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  logistic?: Maybe<LogisticUpdateOneRequiredWithoutTransactionsInput>,
  preStock?: Maybe<PreStockUpdateOneWithoutTransactionsInput>,
  parent?: Maybe<TransactionUpdateOneWithoutChildrenInput>,
  location?: Maybe<LocationUpdateOneWithoutIncomingRecordsInput>,
  productOption?: Maybe<ProductOptionUpdateOneRequiredWithoutTransactionsInput>,
  confirmedUser?: Maybe<UserUpdateOneWithoutConfirmedTransactionsInput>,
  finalPackage?: Maybe<FinalPackageUpdateOneWithoutTransactionsInput>,
  children?: Maybe<TransactionUpdateManyWithoutParentInput>,
  stocktakings?: Maybe<StocktakingUpdateManyWithoutBaseTransactionInput>,
};

export type TransactionUpdateManyWithWhereNestedInput = {
  where: TransactionScalarWhereInput,
  data: TransactionUpdateManyDataInput,
};

export type TransactionScalarWhereInput = {
  id?: Maybe<StringFilter>,
  logisticId?: Maybe<StringFilter>,
  preStockId?: Maybe<NullableStringFilter>,
  parentId?: Maybe<NullableStringFilter>,
  locationId?: Maybe<NullableStringFilter>,
  productOptionId?: Maybe<StringFilter>,
  manufacturedDate?: Maybe<NullableDateTimeFilter>,
  expiryDate?: Maybe<NullableDateTimeFilter>,
  eventType?: Maybe<EventType>,
  transportType?: Maybe<TransportType>,
  confirmedUserId?: Maybe<NullableStringFilter>,
  finalPackageId?: Maybe<NullableStringFilter>,
  stocktakingId?: Maybe<NullableStringFilter>,
  usableAmount?: Maybe<IntFilter>,
  brokenAmount?: Maybe<IntFilter>,
  note?: Maybe<NullableStringFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  children?: Maybe<TransactionFilter>,
  stocktakings?: Maybe<StocktakingFilter>,
  AND?: Maybe<Array<TransactionScalarWhereInput>>,
  OR?: Maybe<Array<TransactionScalarWhereInput>>,
  NOT?: Maybe<Array<TransactionScalarWhereInput>>,
};

export type TransactionUpdateManyDataInput = {
  id?: Maybe<Scalars['String']>,
  manufacturedDate?: Maybe<Scalars['DateTime']>,
  expiryDate?: Maybe<Scalars['DateTime']>,
  eventType?: Maybe<EventType>,
  transportType?: Maybe<TransportType>,
  usableAmount?: Maybe<Scalars['Int']>,
  brokenAmount?: Maybe<Scalars['Int']>,
  note?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type TransactionUpsertWithWhereUniqueWithoutStocktakingInput = {
  where: TransactionWhereUniqueInput,
  update: TransactionUpdateWithoutStocktakingDataInput,
  create: TransactionCreateWithoutStocktakingInput,
};

export type StocktakingUpdateManyWithWhereNestedInput = {
  where: StocktakingScalarWhereInput,
  data: StocktakingUpdateManyDataInput,
};

export type StocktakingScalarWhereInput = {
  id?: Maybe<StringFilter>,
  inspectedDate?: Maybe<DateTimeFilter>,
  baseTransactionId?: Maybe<StringFilter>,
  baseAmount?: Maybe<IntFilter>,
  confirmedUserId?: Maybe<NullableStringFilter>,
  confirmedDate?: Maybe<NullableDateTimeFilter>,
  confirmedNote?: Maybe<NullableStringFilter>,
  amount?: Maybe<IntFilter>,
  note?: Maybe<NullableStringFilter>,
  status?: Maybe<StocktakingStatus>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  deletedAt?: Maybe<NullableDateTimeFilter>,
  transactions?: Maybe<TransactionFilter>,
  AND?: Maybe<Array<StocktakingScalarWhereInput>>,
  OR?: Maybe<Array<StocktakingScalarWhereInput>>,
  NOT?: Maybe<Array<StocktakingScalarWhereInput>>,
};

export type StocktakingUpdateManyDataInput = {
  id?: Maybe<Scalars['String']>,
  inspectedDate?: Maybe<Scalars['DateTime']>,
  baseAmount?: Maybe<Scalars['Int']>,
  confirmedDate?: Maybe<Scalars['DateTime']>,
  confirmedNote?: Maybe<Scalars['String']>,
  amount?: Maybe<Scalars['Int']>,
  note?: Maybe<Scalars['String']>,
  status?: Maybe<StocktakingStatus>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
};

export type StocktakingUpsertWithWhereUniqueWithoutBaseTransactionInput = {
  where: StocktakingWhereUniqueInput,
  update: StocktakingUpdateWithoutBaseTransactionDataInput,
  create: StocktakingCreateWithoutBaseTransactionInput,
};

export type TransactionUpsertWithWhereUniqueWithoutParentInput = {
  where: TransactionWhereUniqueInput,
  update: TransactionUpdateWithoutParentDataInput,
  create: TransactionCreateWithoutParentInput,
};

export type TransactionUpsertWithWhereUniqueWithoutFinalPackageInput = {
  where: TransactionWhereUniqueInput,
  update: TransactionUpdateWithoutFinalPackageDataInput,
  create: TransactionCreateWithoutFinalPackageInput,
};

export type FinalPackageUpsertWithoutReturnOrderProductsInput = {
  update: FinalPackageUpdateWithoutReturnOrderProductsDataInput,
  create: FinalPackageCreateWithoutReturnOrderProductsInput,
};

export type ReturnOrderProductUpdateManyWithWhereNestedInput = {
  where: ReturnOrderProductScalarWhereInput,
  data: ReturnOrderProductUpdateManyDataInput,
};

export type ReturnOrderProductScalarWhereInput = {
  id?: Maybe<StringFilter>,
  returnOrderId?: Maybe<StringFilter>,
  finalPackageId?: Maybe<StringFilter>,
  requestAmount?: Maybe<IntFilter>,
  category?: Maybe<ReturnOrderType>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  AND?: Maybe<Array<ReturnOrderProductScalarWhereInput>>,
  OR?: Maybe<Array<ReturnOrderProductScalarWhereInput>>,
  NOT?: Maybe<Array<ReturnOrderProductScalarWhereInput>>,
};

export type ReturnOrderProductUpdateManyDataInput = {
  id?: Maybe<Scalars['String']>,
  requestAmount?: Maybe<Scalars['Int']>,
  category?: Maybe<ReturnOrderType>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type ReturnOrderProductUpsertWithWhereUniqueWithoutReturnOrderInput = {
  where: ReturnOrderProductWhereUniqueInput,
  update: ReturnOrderProductUpdateWithoutReturnOrderDataInput,
  create: ReturnOrderProductCreateWithoutReturnOrderInput,
};

export type ReturnOrderUpdateManyWithWhereNestedInput = {
  where: ReturnOrderScalarWhereInput,
  data: ReturnOrderUpdateManyDataInput,
};

export type ReturnOrderScalarWhereInput = {
  id?: Maybe<StringFilter>,
  courierInvoiceId?: Maybe<StringFilter>,
  returnCourierInvoiceNum?: Maybe<NullableStringFilter>,
  name?: Maybe<StringFilter>,
  tel?: Maybe<StringFilter>,
  zipcode?: Maybe<StringFilter>,
  address?: Maybe<StringFilter>,
  detailedAddress?: Maybe<NullableStringFilter>,
  message?: Maybe<StringFilter>,
  status?: Maybe<ReturnStatus>,
  note?: Maybe<NullableStringFilter>,
  confirmedUserId?: Maybe<StringFilter>,
  deliveryPaidType?: Maybe<DeliveryPaidType>,
  returnedAt?: Maybe<NullableDateTimeFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  returnOrderProducts?: Maybe<ReturnOrderProductFilter>,
  AND?: Maybe<Array<ReturnOrderScalarWhereInput>>,
  OR?: Maybe<Array<ReturnOrderScalarWhereInput>>,
  NOT?: Maybe<Array<ReturnOrderScalarWhereInput>>,
};

export type ReturnOrderUpdateManyDataInput = {
  id?: Maybe<Scalars['String']>,
  returnCourierInvoiceNum?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  tel?: Maybe<Scalars['String']>,
  zipcode?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  detailedAddress?: Maybe<Scalars['String']>,
  message?: Maybe<Scalars['String']>,
  status?: Maybe<ReturnStatus>,
  note?: Maybe<Scalars['String']>,
  deliveryPaidType?: Maybe<DeliveryPaidType>,
  returnedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type ReturnOrderUpsertWithWhereUniqueWithoutConfirmedUserInput = {
  where: ReturnOrderWhereUniqueInput,
  update: ReturnOrderUpdateWithoutConfirmedUserDataInput,
  create: ReturnOrderCreateWithoutConfirmedUserInput,
};

export type UserUpsertWithoutNoticesInput = {
  update: UserUpdateWithoutNoticesDataInput,
  create: UserCreateWithoutNoticesInput,
};

export type NoticeUpsertWithWhereUniqueWithoutLogisticInput = {
  where: NoticeWhereUniqueInput,
  update: NoticeUpdateWithoutLogisticDataInput,
  create: NoticeCreateWithoutLogisticInput,
};

export type LogisticUpdateManyWithWhereNestedInput = {
  where: LogisticScalarWhereInput,
  data: LogisticUpdateManyDataInput,
};

export type LogisticScalarWhereInput = {
  id?: Maybe<StringFilter>,
  companyId?: Maybe<StringFilter>,
  defaultUserId?: Maybe<StringFilter>,
  defaultCourierId?: Maybe<NullableStringFilter>,
  marketing?: Maybe<BooleanFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  sellers?: Maybe<SellerFilter>,
  availableCouriers?: Maybe<LogisticCourierFilter>,
  packets?: Maybe<PacketFilter>,
  centers?: Maybe<CenterFilter>,
  preStocks?: Maybe<PreStockFilter>,
  transactions?: Maybe<TransactionFilter>,
  notices?: Maybe<NoticeFilter>,
  AND?: Maybe<Array<LogisticScalarWhereInput>>,
  OR?: Maybe<Array<LogisticScalarWhereInput>>,
  NOT?: Maybe<Array<LogisticScalarWhereInput>>,
};

export type LogisticUpdateManyDataInput = {
  id?: Maybe<Scalars['String']>,
  marketing?: Maybe<Scalars['Boolean']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type LogisticUpsertWithWhereUniqueWithoutDefaultUserInput = {
  where: LogisticWhereUniqueInput,
  update: LogisticUpdateWithoutDefaultUserDataInput,
  create: LogisticCreateWithoutDefaultUserInput,
};

export type UserUpsertWithoutConfirmedReturnOrderInput = {
  update: UserUpdateWithoutConfirmedReturnOrderDataInput,
  create: UserCreateWithoutConfirmedReturnOrderInput,
};

export type ReturnOrderUpsertWithoutReturnOrderProductsInput = {
  update: ReturnOrderUpdateWithoutReturnOrderProductsDataInput,
  create: ReturnOrderCreateWithoutReturnOrderProductsInput,
};

export type ReturnOrderProductUpsertWithWhereUniqueWithoutFinalPackageInput = {
  where: ReturnOrderProductWhereUniqueInput,
  update: ReturnOrderProductUpdateWithoutFinalPackageDataInput,
  create: ReturnOrderProductCreateWithoutFinalPackageInput,
};

export type FinalPackageUpsertWithoutTransactionsInput = {
  update: FinalPackageUpdateWithoutTransactionsDataInput,
  create: FinalPackageCreateWithoutTransactionsInput,
};

export type TransactionUpsertWithoutStocktakingsInput = {
  update: TransactionUpdateWithoutStocktakingsDataInput,
  create: TransactionCreateWithoutStocktakingsInput,
};

export type StocktakingUpsertWithWhereUniqueWithoutConfirmedUserInput = {
  where: StocktakingWhereUniqueInput,
  update: StocktakingUpdateWithoutConfirmedUserDataInput,
  create: StocktakingCreateWithoutConfirmedUserInput,
};

export type UserUpsertWithoutConfirmedFinalPackagesInput = {
  update: UserUpdateWithoutConfirmedFinalPackagesDataInput,
  create: UserCreateWithoutConfirmedFinalPackagesInput,
};

export type FinalPackageUpdateManyWithWhereNestedInput = {
  where: FinalPackageScalarWhereInput,
  data: FinalPackageUpdateManyDataInput,
};

export type FinalPackageScalarWhereInput = {
  id?: Maybe<StringFilter>,
  courierInvoiceId?: Maybe<StringFilter>,
  productMatchId?: Maybe<StringFilter>,
  amount?: Maybe<IntFilter>,
  confirmedUserId?: Maybe<NullableStringFilter>,
  status?: Maybe<FinalPackageStatus>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  returnOrderProducts?: Maybe<ReturnOrderProductFilter>,
  transactions?: Maybe<TransactionFilter>,
  AND?: Maybe<Array<FinalPackageScalarWhereInput>>,
  OR?: Maybe<Array<FinalPackageScalarWhereInput>>,
  NOT?: Maybe<Array<FinalPackageScalarWhereInput>>,
};

export type FinalPackageUpdateManyDataInput = {
  id?: Maybe<Scalars['String']>,
  amount?: Maybe<Scalars['Int']>,
  status?: Maybe<FinalPackageStatus>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type FinalPackageUpsertWithWhereUniqueWithoutCourierInvoiceInput = {
  where: FinalPackageWhereUniqueInput,
  update: FinalPackageUpdateWithoutCourierInvoiceDataInput,
  create: FinalPackageCreateWithoutCourierInvoiceInput,
};

export type ReturnOrderUpdateManyWithoutCourierInvoiceInput = {
  create?: Maybe<Array<ReturnOrderCreateWithoutCourierInvoiceInput>>,
  connect?: Maybe<Array<ReturnOrderWhereUniqueInput>>,
  set?: Maybe<Array<ReturnOrderWhereUniqueInput>>,
  disconnect?: Maybe<Array<ReturnOrderWhereUniqueInput>>,
  delete?: Maybe<Array<ReturnOrderWhereUniqueInput>>,
  update?: Maybe<Array<ReturnOrderUpdateWithWhereUniqueWithoutCourierInvoiceInput>>,
  updateMany?: Maybe<Array<ReturnOrderUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<ReturnOrderScalarWhereInput>>,
  upsert?: Maybe<Array<ReturnOrderUpsertWithWhereUniqueWithoutCourierInvoiceInput>>,
};

export type ReturnOrderUpdateWithWhereUniqueWithoutCourierInvoiceInput = {
  where: ReturnOrderWhereUniqueInput,
  data: ReturnOrderUpdateWithoutCourierInvoiceDataInput,
};

export type ReturnOrderUpdateWithoutCourierInvoiceDataInput = {
  id?: Maybe<Scalars['String']>,
  returnCourierInvoiceNum?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  tel?: Maybe<Scalars['String']>,
  zipcode?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  detailedAddress?: Maybe<Scalars['String']>,
  message?: Maybe<Scalars['String']>,
  status?: Maybe<ReturnStatus>,
  note?: Maybe<Scalars['String']>,
  deliveryPaidType?: Maybe<DeliveryPaidType>,
  returnedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  confirmedUser?: Maybe<UserUpdateOneRequiredWithoutConfirmedReturnOrderInput>,
  returnOrderProducts?: Maybe<ReturnOrderProductUpdateManyWithoutReturnOrderInput>,
};

export type ReturnOrderUpsertWithWhereUniqueWithoutCourierInvoiceInput = {
  where: ReturnOrderWhereUniqueInput,
  update: ReturnOrderUpdateWithoutCourierInvoiceDataInput,
  create: ReturnOrderCreateWithoutCourierInvoiceInput,
};

export type CourierInvoiceUpdateManyWithWhereNestedInput = {
  where: CourierInvoiceScalarWhereInput,
  data: CourierInvoiceUpdateManyDataInput,
};

export type CourierInvoiceScalarWhereInput = {
  id?: Maybe<StringFilter>,
  type?: Maybe<CourierInvoiceType>,
  brandId?: Maybe<StringFilter>,
  logisticCourierId?: Maybe<NullableStringFilter>,
  courierName?: Maybe<NullableStringFilter>,
  num?: Maybe<NullableStringFilter>,
  groupNum?: Maybe<NullableIntFilter>,
  printedNum?: Maybe<IntFilter>,
  invoiceStatus?: Maybe<CourierInvoiceStatus>,
  status?: Maybe<PackageStatus>,
  parentId?: Maybe<NullableStringFilter>,
  sender?: Maybe<StringFilter>,
  senderTel?: Maybe<StringFilter>,
  senderZipcode?: Maybe<StringFilter>,
  senderAddress?: Maybe<StringFilter>,
  senderDetailedAddress?: Maybe<NullableStringFilter>,
  receiver?: Maybe<StringFilter>,
  receiverTel?: Maybe<StringFilter>,
  receiverZipcode?: Maybe<StringFilter>,
  receiverAddress?: Maybe<StringFilter>,
  receiverDetailedAddress?: Maybe<NullableStringFilter>,
  content?: Maybe<NullableStringFilter>,
  message?: Maybe<NullableStringFilter>,
  note?: Maybe<NullableStringFilter>,
  addressRefinedDate?: Maybe<NullableDateTimeFilter>,
  addressRefinedValues?: Maybe<NullableStringFilter>,
  courierPriceRangeId?: Maybe<NullableStringFilter>,
  printedAt?: Maybe<NullableDateTimeFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  children?: Maybe<CourierInvoiceFilter>,
  finalPackages?: Maybe<FinalPackageFilter>,
  returnOrders?: Maybe<ReturnOrderFilter>,
  AND?: Maybe<Array<CourierInvoiceScalarWhereInput>>,
  OR?: Maybe<Array<CourierInvoiceScalarWhereInput>>,
  NOT?: Maybe<Array<CourierInvoiceScalarWhereInput>>,
};

export type CourierInvoiceUpdateManyDataInput = {
  id?: Maybe<Scalars['String']>,
  type?: Maybe<CourierInvoiceType>,
  courierName?: Maybe<Scalars['String']>,
  num?: Maybe<Scalars['String']>,
  groupNum?: Maybe<Scalars['Int']>,
  printedNum?: Maybe<Scalars['Int']>,
  invoiceStatus?: Maybe<CourierInvoiceStatus>,
  status?: Maybe<PackageStatus>,
  sender?: Maybe<Scalars['String']>,
  senderTel?: Maybe<Scalars['String']>,
  senderZipcode?: Maybe<Scalars['String']>,
  senderAddress?: Maybe<Scalars['String']>,
  senderDetailedAddress?: Maybe<Scalars['String']>,
  receiver?: Maybe<Scalars['String']>,
  receiverTel?: Maybe<Scalars['String']>,
  receiverZipcode?: Maybe<Scalars['String']>,
  receiverAddress?: Maybe<Scalars['String']>,
  receiverDetailedAddress?: Maybe<Scalars['String']>,
  content?: Maybe<Scalars['String']>,
  message?: Maybe<Scalars['String']>,
  note?: Maybe<Scalars['String']>,
  addressRefinedDate?: Maybe<Scalars['DateTime']>,
  addressRefinedValues?: Maybe<Scalars['String']>,
  printedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type CourierInvoiceUpsertWithWhereUniqueWithoutParentInput = {
  where: CourierInvoiceWhereUniqueInput,
  update: CourierInvoiceUpdateWithoutParentDataInput,
  create: CourierInvoiceCreateWithoutParentInput,
};

export type CourierInvoiceUpsertWithWhereUniqueWithoutLogisticCourierInput = {
  where: CourierInvoiceWhereUniqueInput,
  update: CourierInvoiceUpdateWithoutLogisticCourierDataInput,
  create: CourierInvoiceCreateWithoutLogisticCourierInput,
};

export type CourierInvoiceTrackingUpdateManyWithoutLogisticCourierInput = {
  create?: Maybe<Array<CourierInvoiceTrackingCreateWithoutLogisticCourierInput>>,
  connect?: Maybe<Array<CourierInvoiceTrackingWhereUniqueInput>>,
  set?: Maybe<Array<CourierInvoiceTrackingWhereUniqueInput>>,
  disconnect?: Maybe<Array<CourierInvoiceTrackingWhereUniqueInput>>,
  delete?: Maybe<Array<CourierInvoiceTrackingWhereUniqueInput>>,
  update?: Maybe<Array<CourierInvoiceTrackingUpdateWithWhereUniqueWithoutLogisticCourierInput>>,
  updateMany?: Maybe<Array<CourierInvoiceTrackingUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<CourierInvoiceTrackingScalarWhereInput>>,
  upsert?: Maybe<Array<CourierInvoiceTrackingUpsertWithWhereUniqueWithoutLogisticCourierInput>>,
};

export type CourierInvoiceTrackingUpdateWithWhereUniqueWithoutLogisticCourierInput = {
  where: CourierInvoiceTrackingWhereUniqueInput,
  data: CourierInvoiceTrackingUpdateWithoutLogisticCourierDataInput,
};

export type CourierInvoiceTrackingUpdateWithoutLogisticCourierDataInput = {
  id?: Maybe<Scalars['String']>,
  courierInvoiceNum?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  trackedDate?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type CourierInvoiceTrackingUpdateManyWithWhereNestedInput = {
  where: CourierInvoiceTrackingScalarWhereInput,
  data: CourierInvoiceTrackingUpdateManyDataInput,
};

export type CourierInvoiceTrackingScalarWhereInput = {
  id?: Maybe<StringFilter>,
  logisticCourierId?: Maybe<StringFilter>,
  courierInvoiceNum?: Maybe<StringFilter>,
  status?: Maybe<StringFilter>,
  trackedDate?: Maybe<DateTimeFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  AND?: Maybe<Array<CourierInvoiceTrackingScalarWhereInput>>,
  OR?: Maybe<Array<CourierInvoiceTrackingScalarWhereInput>>,
  NOT?: Maybe<Array<CourierInvoiceTrackingScalarWhereInput>>,
};

export type CourierInvoiceTrackingUpdateManyDataInput = {
  id?: Maybe<Scalars['String']>,
  courierInvoiceNum?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  trackedDate?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type CourierInvoiceTrackingUpsertWithWhereUniqueWithoutLogisticCourierInput = {
  where: CourierInvoiceTrackingWhereUniqueInput,
  update: CourierInvoiceTrackingUpdateWithoutLogisticCourierDataInput,
  create: CourierInvoiceTrackingCreateWithoutLogisticCourierInput,
};

export type LogisticCourierUpdateManyWithWhereNestedInput = {
  where: LogisticCourierScalarWhereInput,
  data: LogisticCourierUpdateManyDataInput,
};

export type LogisticCourierScalarWhereInput = {
  id?: Maybe<StringFilter>,
  logisticId?: Maybe<StringFilter>,
  courierId?: Maybe<StringFilter>,
  config?: Maybe<StringFilter>,
  isActive?: Maybe<BooleanFilter>,
  deletedAt?: Maybe<NullableDateTimeFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  sellerDefaultOf?: Maybe<SellerFilter>,
  courierInvoices?: Maybe<CourierInvoiceFilter>,
  courierInvoiceTrackings?: Maybe<CourierInvoiceTrackingFilter>,
  AND?: Maybe<Array<LogisticCourierScalarWhereInput>>,
  OR?: Maybe<Array<LogisticCourierScalarWhereInput>>,
  NOT?: Maybe<Array<LogisticCourierScalarWhereInput>>,
};

export type LogisticCourierUpdateManyDataInput = {
  id?: Maybe<Scalars['String']>,
  config?: Maybe<Scalars['String']>,
  isActive?: Maybe<Scalars['Boolean']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type LogisticCourierUpsertWithWhereUniqueWithoutCourierInput = {
  where: LogisticCourierWhereUniqueInput,
  update: LogisticCourierUpdateWithoutCourierDataInput,
  create: LogisticCourierCreateWithoutCourierInput,
};

export type CourierUpsertWithoutPriceRangesInput = {
  update: CourierUpdateWithoutPriceRangesDataInput,
  create: CourierCreateWithoutPriceRangesInput,
};

export type CourierPriceRangeUpsertWithoutCourierInvoicesInput = {
  update: CourierPriceRangeUpdateWithoutCourierInvoicesDataInput,
  create: CourierPriceRangeCreateWithoutCourierInvoicesInput,
};

export type CourierInvoiceUpsertWithoutChildrenInput = {
  update: CourierInvoiceUpdateWithoutChildrenDataInput,
  create: CourierInvoiceCreateWithoutChildrenInput,
};

export type CourierInvoiceUpsertWithoutFinalPackagesInput = {
  update: CourierInvoiceUpdateWithoutFinalPackagesDataInput,
  create: CourierInvoiceCreateWithoutFinalPackagesInput,
};

export type FinalPackageUpsertWithWhereUniqueWithoutConfirmedUserInput = {
  where: FinalPackageWhereUniqueInput,
  update: FinalPackageUpdateWithoutConfirmedUserDataInput,
  create: FinalPackageCreateWithoutConfirmedUserInput,
};

export type UserUpsertWithoutConfirmedPreStocksInput = {
  update: UserUpdateWithoutConfirmedPreStocksDataInput,
  create: UserCreateWithoutConfirmedPreStocksInput,
};

export type PreStockUpdateManyWithWhereNestedInput = {
  where: PreStockScalarWhereInput,
  data: PreStockUpdateManyDataInput,
};

export type PreStockScalarWhereInput = {
  id?: Maybe<StringFilter>,
  logisticId?: Maybe<StringFilter>,
  productOptionId?: Maybe<StringFilter>,
  amount?: Maybe<IntFilter>,
  loadUnit?: Maybe<LoadUnit>,
  loadUnitAmount?: Maybe<NullableIntFilter>,
  scheduledDate?: Maybe<DateTimeFilter>,
  registeredUserId?: Maybe<StringFilter>,
  confirmedUserId?: Maybe<NullableStringFilter>,
  confirmedDate?: Maybe<NullableDateTimeFilter>,
  note?: Maybe<NullableStringFilter>,
  manufacturedDate?: Maybe<NullableDateTimeFilter>,
  expiryDate?: Maybe<NullableDateTimeFilter>,
  canceledAt?: Maybe<NullableDateTimeFilter>,
  deletedAt?: Maybe<NullableDateTimeFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  transactions?: Maybe<TransactionFilter>,
  AND?: Maybe<Array<PreStockScalarWhereInput>>,
  OR?: Maybe<Array<PreStockScalarWhereInput>>,
  NOT?: Maybe<Array<PreStockScalarWhereInput>>,
};

export type PreStockUpdateManyDataInput = {
  id?: Maybe<Scalars['String']>,
  amount?: Maybe<Scalars['Int']>,
  loadUnit?: Maybe<LoadUnit>,
  loadUnitAmount?: Maybe<Scalars['Int']>,
  scheduledDate?: Maybe<Scalars['DateTime']>,
  confirmedDate?: Maybe<Scalars['DateTime']>,
  note?: Maybe<Scalars['String']>,
  manufacturedDate?: Maybe<Scalars['DateTime']>,
  expiryDate?: Maybe<Scalars['DateTime']>,
  canceledAt?: Maybe<Scalars['DateTime']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type PreStockUpsertWithWhereUniqueWithoutRegisteredUserInput = {
  where: PreStockWhereUniqueInput,
  update: PreStockUpdateWithoutRegisteredUserDataInput,
  create: PreStockCreateWithoutRegisteredUserInput,
};

export type UserUpsertWithoutConfirmedTransactionsInput = {
  update: UserUpdateWithoutConfirmedTransactionsDataInput,
  create: UserCreateWithoutConfirmedTransactionsInput,
};

export type TransactionUpsertWithWhereUniqueWithoutProductOptionInput = {
  where: TransactionWhereUniqueInput,
  update: TransactionUpdateWithoutProductOptionDataInput,
  create: TransactionCreateWithoutProductOptionInput,
};

export type ProductMatchUpdateManyWithoutProductOptionInput = {
  create?: Maybe<Array<ProductMatchCreateWithoutProductOptionInput>>,
  connect?: Maybe<Array<ProductMatchWhereUniqueInput>>,
  set?: Maybe<Array<ProductMatchWhereUniqueInput>>,
  disconnect?: Maybe<Array<ProductMatchWhereUniqueInput>>,
  delete?: Maybe<Array<ProductMatchWhereUniqueInput>>,
  update?: Maybe<Array<ProductMatchUpdateWithWhereUniqueWithoutProductOptionInput>>,
  updateMany?: Maybe<Array<ProductMatchUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<ProductMatchScalarWhereInput>>,
  upsert?: Maybe<Array<ProductMatchUpsertWithWhereUniqueWithoutProductOptionInput>>,
};

export type ProductMatchUpdateWithWhereUniqueWithoutProductOptionInput = {
  where: ProductMatchWhereUniqueInput,
  data: ProductMatchUpdateWithoutProductOptionDataInput,
};

export type ProductMatchUpdateWithoutProductOptionDataInput = {
  id?: Maybe<Scalars['String']>,
  amount?: Maybe<Scalars['Int']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  marketProduct?: Maybe<MarketProductUpdateOneRequiredWithoutProductMatchesInput>,
  autoMatch?: Maybe<AutoMatchUpdateOneWithoutProductMatchesInput>,
  finalPackages?: Maybe<FinalPackageUpdateManyWithoutProductMatchInput>,
};

export type FinalPackageUpdateManyWithoutProductMatchInput = {
  create?: Maybe<Array<FinalPackageCreateWithoutProductMatchInput>>,
  connect?: Maybe<Array<FinalPackageWhereUniqueInput>>,
  set?: Maybe<Array<FinalPackageWhereUniqueInput>>,
  disconnect?: Maybe<Array<FinalPackageWhereUniqueInput>>,
  delete?: Maybe<Array<FinalPackageWhereUniqueInput>>,
  update?: Maybe<Array<FinalPackageUpdateWithWhereUniqueWithoutProductMatchInput>>,
  updateMany?: Maybe<Array<FinalPackageUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<FinalPackageScalarWhereInput>>,
  upsert?: Maybe<Array<FinalPackageUpsertWithWhereUniqueWithoutProductMatchInput>>,
};

export type FinalPackageUpdateWithWhereUniqueWithoutProductMatchInput = {
  where: FinalPackageWhereUniqueInput,
  data: FinalPackageUpdateWithoutProductMatchDataInput,
};

export type FinalPackageUpdateWithoutProductMatchDataInput = {
  id?: Maybe<Scalars['String']>,
  amount?: Maybe<Scalars['Int']>,
  status?: Maybe<FinalPackageStatus>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  courierInvoice?: Maybe<CourierInvoiceUpdateOneRequiredWithoutFinalPackagesInput>,
  confirmedUser?: Maybe<UserUpdateOneWithoutConfirmedFinalPackagesInput>,
  returnOrderProducts?: Maybe<ReturnOrderProductUpdateManyWithoutFinalPackageInput>,
  transactions?: Maybe<TransactionUpdateManyWithoutFinalPackageInput>,
};

export type FinalPackageUpsertWithWhereUniqueWithoutProductMatchInput = {
  where: FinalPackageWhereUniqueInput,
  update: FinalPackageUpdateWithoutProductMatchDataInput,
  create: FinalPackageCreateWithoutProductMatchInput,
};

export type ProductMatchUpdateManyWithWhereNestedInput = {
  where: ProductMatchScalarWhereInput,
  data: ProductMatchUpdateManyDataInput,
};

export type ProductMatchScalarWhereInput = {
  id?: Maybe<StringFilter>,
  marketProductId?: Maybe<StringFilter>,
  autoMatchId?: Maybe<NullableStringFilter>,
  productOptionId?: Maybe<StringFilter>,
  amount?: Maybe<IntFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  finalPackages?: Maybe<FinalPackageFilter>,
  AND?: Maybe<Array<ProductMatchScalarWhereInput>>,
  OR?: Maybe<Array<ProductMatchScalarWhereInput>>,
  NOT?: Maybe<Array<ProductMatchScalarWhereInput>>,
};

export type ProductMatchUpdateManyDataInput = {
  id?: Maybe<Scalars['String']>,
  amount?: Maybe<Scalars['Int']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type ProductMatchUpsertWithWhereUniqueWithoutProductOptionInput = {
  where: ProductMatchWhereUniqueInput,
  update: ProductMatchUpdateWithoutProductOptionDataInput,
  create: ProductMatchCreateWithoutProductOptionInput,
};

export type ProductOptionUpsertWithoutAutoMatchesInput = {
  update: ProductOptionUpdateWithoutAutoMatchesDataInput,
  create: ProductOptionCreateWithoutAutoMatchesInput,
};

export type AutoMatchUpdateManyWithWhereNestedInput = {
  where: AutoMatchScalarWhereInput,
  data: AutoMatchUpdateManyDataInput,
};

export type AutoMatchScalarWhereInput = {
  id?: Maybe<StringFilter>,
  brandMarketId?: Maybe<StringFilter>,
  marketProductNum?: Maybe<NullableStringFilter>,
  marketProductName?: Maybe<StringFilter>,
  marketProductOptionNum?: Maybe<NullableStringFilter>,
  marketProductOptionName?: Maybe<NullableStringFilter>,
  marketProductOptionAmount?: Maybe<IntFilter>,
  productOptionId?: Maybe<StringFilter>,
  productAmount?: Maybe<IntFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  productMatches?: Maybe<ProductMatchFilter>,
  AND?: Maybe<Array<AutoMatchScalarWhereInput>>,
  OR?: Maybe<Array<AutoMatchScalarWhereInput>>,
  NOT?: Maybe<Array<AutoMatchScalarWhereInput>>,
};

export type AutoMatchUpdateManyDataInput = {
  id?: Maybe<Scalars['String']>,
  marketProductNum?: Maybe<Scalars['String']>,
  marketProductName?: Maybe<Scalars['String']>,
  marketProductOptionNum?: Maybe<Scalars['String']>,
  marketProductOptionName?: Maybe<Scalars['String']>,
  marketProductOptionAmount?: Maybe<Scalars['Int']>,
  productAmount?: Maybe<Scalars['Int']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type AutoMatchUpsertWithWhereUniqueWithoutBrandMarketInput = {
  where: AutoMatchWhereUniqueInput,
  update: AutoMatchUpdateWithoutBrandMarketDataInput,
  create: AutoMatchCreateWithoutBrandMarketInput,
};

export type BrandMarketUpsertWithoutOrdersInput = {
  update: BrandMarketUpdateWithoutOrdersDataInput,
  create: BrandMarketCreateWithoutOrdersInput,
};

export type OrderNoteUpdateManyWithoutOrderInput = {
  create?: Maybe<Array<OrderNoteCreateWithoutOrderInput>>,
  connect?: Maybe<Array<OrderNoteWhereUniqueInput>>,
  set?: Maybe<Array<OrderNoteWhereUniqueInput>>,
  disconnect?: Maybe<Array<OrderNoteWhereUniqueInput>>,
  delete?: Maybe<Array<OrderNoteWhereUniqueInput>>,
  update?: Maybe<Array<OrderNoteUpdateWithWhereUniqueWithoutOrderInput>>,
  updateMany?: Maybe<Array<OrderNoteUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<OrderNoteScalarWhereInput>>,
  upsert?: Maybe<Array<OrderNoteUpsertWithWhereUniqueWithoutOrderInput>>,
};

export type OrderNoteUpdateWithWhereUniqueWithoutOrderInput = {
  where: OrderNoteWhereUniqueInput,
  data: OrderNoteUpdateWithoutOrderDataInput,
};

export type OrderNoteUpdateWithoutOrderDataInput = {
  id?: Maybe<Scalars['String']>,
  type?: Maybe<OrderNoteType>,
  note?: Maybe<Scalars['String']>,
  status?: Maybe<OrderNoteStatus>,
  confirmedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  writer?: Maybe<UserUpdateOneRequiredWithoutOrderNotesInput>,
  confirmedUser?: Maybe<UserUpdateOneWithoutConfirmedOrderNotesInput>,
};

export type OrderNoteUpsertWithWhereUniqueWithoutOrderInput = {
  where: OrderNoteWhereUniqueInput,
  update: OrderNoteUpdateWithoutOrderDataInput,
  create: OrderNoteCreateWithoutOrderInput,
};

export type OrderUpsertWithoutMarketProductsInput = {
  update: OrderUpdateWithoutMarketProductsDataInput,
  create: OrderCreateWithoutMarketProductsInput,
};

export type MarketProductUpsertWithoutProductMatchesInput = {
  update: MarketProductUpdateWithoutProductMatchesDataInput,
  create: MarketProductCreateWithoutProductMatchesInput,
};

export type ProductMatchUpsertWithWhereUniqueWithoutAutoMatchInput = {
  where: ProductMatchWhereUniqueInput,
  update: ProductMatchUpdateWithoutAutoMatchDataInput,
  create: ProductMatchCreateWithoutAutoMatchInput,
};

export type AutoMatchUpsertWithWhereUniqueWithoutProductOptionInput = {
  where: AutoMatchWhereUniqueInput,
  update: AutoMatchUpdateWithoutProductOptionDataInput,
  create: AutoMatchCreateWithoutProductOptionInput,
};

export type ProductOptionUpsertWithoutTransactionsInput = {
  update: ProductOptionUpdateWithoutTransactionsDataInput,
  create: ProductOptionCreateWithoutTransactionsInput,
};

export type TransactionUpsertWithWhereUniqueWithoutLocationInput = {
  where: TransactionWhereUniqueInput,
  update: TransactionUpdateWithoutLocationDataInput,
  create: TransactionCreateWithoutLocationInput,
};

export type LocationUpdateManyWithWhereNestedInput = {
  where: LocationScalarWhereInput,
  data: LocationUpdateManyDataInput,
};

export type LocationScalarWhereInput = {
  id?: Maybe<StringFilter>,
  centerId?: Maybe<StringFilter>,
  settingId?: Maybe<StringFilter>,
  zone?: Maybe<StringFilter>,
  rack?: Maybe<StringFilter>,
  level?: Maybe<StringFilter>,
  isActive?: Maybe<BooleanFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  incomingRecords?: Maybe<TransactionFilter>,
  AND?: Maybe<Array<LocationScalarWhereInput>>,
  OR?: Maybe<Array<LocationScalarWhereInput>>,
  NOT?: Maybe<Array<LocationScalarWhereInput>>,
};

export type LocationUpdateManyDataInput = {
  id?: Maybe<Scalars['String']>,
  zone?: Maybe<Scalars['String']>,
  rack?: Maybe<Scalars['String']>,
  level?: Maybe<Scalars['String']>,
  isActive?: Maybe<Scalars['Boolean']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type LocationUpsertWithWhereUniqueWithoutCenterInput = {
  where: LocationWhereUniqueInput,
  update: LocationUpdateWithoutCenterDataInput,
  create: LocationCreateWithoutCenterInput,
};

export type CenterUpsertWithoutSettingsInput = {
  update: CenterUpdateWithoutSettingsDataInput,
  create: CenterCreateWithoutSettingsInput,
};

export type LocationSettingUpsertWithoutLocationsInput = {
  update: LocationSettingUpdateWithoutLocationsDataInput,
  create: LocationSettingCreateWithoutLocationsInput,
};

export type LocationUpsertWithoutIncomingRecordsInput = {
  update: LocationUpdateWithoutIncomingRecordsDataInput,
  create: LocationCreateWithoutIncomingRecordsInput,
};

export type TransactionUpsertWithoutChildrenInput = {
  update: TransactionUpdateWithoutChildrenDataInput,
  create: TransactionCreateWithoutChildrenInput,
};

export type TransactionUpsertWithWhereUniqueWithoutPreStockInput = {
  where: TransactionWhereUniqueInput,
  update: TransactionUpdateWithoutPreStockDataInput,
  create: TransactionCreateWithoutPreStockInput,
};

export type PreStockUpsertWithWhereUniqueWithoutConfirmedUserInput = {
  where: PreStockWhereUniqueInput,
  update: PreStockUpdateWithoutConfirmedUserDataInput,
  create: PreStockCreateWithoutConfirmedUserInput,
};

export type UserUpsertWithoutRegisteredPreStocksInput = {
  update: UserUpdateWithoutRegisteredPreStocksDataInput,
  create: UserCreateWithoutRegisteredPreStocksInput,
};

export type PreStockUpsertWithoutTransactionsInput = {
  update: PreStockUpdateWithoutTransactionsDataInput,
  create: PreStockCreateWithoutTransactionsInput,
};

export type TransactionUpsertWithWhereUniqueWithoutLogisticInput = {
  where: TransactionWhereUniqueInput,
  update: TransactionUpdateWithoutLogisticDataInput,
  create: TransactionCreateWithoutLogisticInput,
};

export type LogisticUpsertWithoutNoticesInput = {
  update: LogisticUpdateWithoutNoticesDataInput,
  create: LogisticCreateWithoutNoticesInput,
};

export type NoticeUpsertWithWhereUniqueWithoutSellerInput = {
  where: NoticeWhereUniqueInput,
  update: NoticeUpdateWithoutSellerDataInput,
  create: NoticeCreateWithoutSellerInput,
};

export type SellerUpdateManyWithWhereNestedInput = {
  where: SellerScalarWhereInput,
  data: SellerUpdateManyDataInput,
};

export type SellerScalarWhereInput = {
  id?: Maybe<StringFilter>,
  companyId?: Maybe<StringFilter>,
  logisticId?: Maybe<StringFilter>,
  defaultCourierId?: Maybe<NullableStringFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  brands?: Maybe<BrandFilter>,
  notices?: Maybe<NoticeFilter>,
  AND?: Maybe<Array<SellerScalarWhereInput>>,
  OR?: Maybe<Array<SellerScalarWhereInput>>,
  NOT?: Maybe<Array<SellerScalarWhereInput>>,
};

export type SellerUpdateManyDataInput = {
  id?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type SellerUpsertWithWhereUniqueWithoutDefaultCourierInput = {
  where: SellerWhereUniqueInput,
  update: SellerUpdateWithoutDefaultCourierDataInput,
  create: SellerCreateWithoutDefaultCourierInput,
};

export type LogisticCourierUpsertWithoutCourierInvoicesInput = {
  update: LogisticCourierUpdateWithoutCourierInvoicesDataInput,
  create: LogisticCourierCreateWithoutCourierInvoicesInput,
};

export type CourierInvoiceUpsertWithWhereUniqueWithoutBrandInput = {
  where: CourierInvoiceWhereUniqueInput,
  update: CourierInvoiceUpdateWithoutBrandDataInput,
  create: CourierInvoiceCreateWithoutBrandInput,
};

export type BrandUpsertWithoutProductsInput = {
  update: BrandUpdateWithoutProductsDataInput,
  create: BrandCreateWithoutProductsInput,
};

export type ProductUpsertWithoutProductOptionsInput = {
  update: ProductUpdateWithoutProductOptionsDataInput,
  create: ProductCreateWithoutProductOptionsInput,
};

export type ProductOptionUpsertWithoutPreStocksInput = {
  update: ProductOptionUpdateWithoutPreStocksDataInput,
  create: ProductOptionCreateWithoutPreStocksInput,
};

export type PreStockUpsertWithWhereUniqueWithoutLogisticInput = {
  where: PreStockWhereUniqueInput,
  update: PreStockUpdateWithoutLogisticDataInput,
  create: PreStockCreateWithoutLogisticInput,
};

export type LogisticUpsertWithoutCentersInput = {
  update: LogisticUpdateWithoutCentersDataInput,
  create: LogisticCreateWithoutCentersInput,
};

export type CenterUpsertWithoutLocationsInput = {
  update: CenterUpdateWithoutLocationsDataInput,
  create: CenterCreateWithoutLocationsInput,
};

export type LocationUpsertWithWhereUniqueWithoutSettingInput = {
  where: LocationWhereUniqueInput,
  update: LocationUpdateWithoutSettingDataInput,
  create: LocationCreateWithoutSettingInput,
};

export type LocationSettingUpdateManyWithWhereNestedInput = {
  where: LocationSettingScalarWhereInput,
  data: LocationSettingUpdateManyDataInput,
};

export type LocationSettingScalarWhereInput = {
  id?: Maybe<StringFilter>,
  centerId?: Maybe<StringFilter>,
  zone?: Maybe<StringFilter>,
  rack?: Maybe<StringFilter>,
  level?: Maybe<StringFilter>,
  deletedAt?: Maybe<NullableDateTimeFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  locations?: Maybe<LocationFilter>,
  AND?: Maybe<Array<LocationSettingScalarWhereInput>>,
  OR?: Maybe<Array<LocationSettingScalarWhereInput>>,
  NOT?: Maybe<Array<LocationSettingScalarWhereInput>>,
};

export type LocationSettingUpdateManyDataInput = {
  id?: Maybe<Scalars['String']>,
  zone?: Maybe<Scalars['String']>,
  rack?: Maybe<Scalars['String']>,
  level?: Maybe<Scalars['String']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type LocationSettingUpsertWithWhereUniqueWithoutCenterInput = {
  where: LocationSettingWhereUniqueInput,
  update: LocationSettingUpdateWithoutCenterDataInput,
  create: LocationSettingCreateWithoutCenterInput,
};

export type CenterUpdateManyWithWhereNestedInput = {
  where: CenterScalarWhereInput,
  data: CenterUpdateManyDataInput,
};

export type CenterScalarWhereInput = {
  id?: Maybe<StringFilter>,
  logisticId?: Maybe<StringFilter>,
  name?: Maybe<StringFilter>,
  zipcode?: Maybe<StringFilter>,
  address?: Maybe<StringFilter>,
  detailedAddress?: Maybe<NullableStringFilter>,
  shippingAvailable?: Maybe<BooleanFilter>,
  isActive?: Maybe<BooleanFilter>,
  useLocation?: Maybe<BooleanFilter>,
  totalQuantity?: Maybe<NullableIntFilter>,
  deletedAt?: Maybe<NullableDateTimeFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  settings?: Maybe<LocationSettingFilter>,
  locations?: Maybe<LocationFilter>,
  AND?: Maybe<Array<CenterScalarWhereInput>>,
  OR?: Maybe<Array<CenterScalarWhereInput>>,
  NOT?: Maybe<Array<CenterScalarWhereInput>>,
};

export type CenterUpdateManyDataInput = {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  zipcode?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  detailedAddress?: Maybe<Scalars['String']>,
  shippingAvailable?: Maybe<Scalars['Boolean']>,
  isActive?: Maybe<Scalars['Boolean']>,
  useLocation?: Maybe<Scalars['Boolean']>,
  totalQuantity?: Maybe<Scalars['Int']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type CenterUpsertWithWhereUniqueWithoutLogisticInput = {
  where: CenterWhereUniqueInput,
  update: CenterUpdateWithoutLogisticDataInput,
  create: CenterCreateWithoutLogisticInput,
};

export type LogisticUpsertWithoutDefaultCourierInput = {
  update: LogisticUpdateWithoutDefaultCourierDataInput,
  create: LogisticCreateWithoutDefaultCourierInput,
};

export type LogisticCourierUpsertWithWhereUniqueWithoutLogisticInput = {
  where: LogisticCourierWhereUniqueInput,
  update: LogisticCourierUpdateWithoutLogisticDataInput,
  create: LogisticCourierCreateWithoutLogisticInput,
};

export type LogisticUpsertWithoutPreStocksInput = {
  update: LogisticUpdateWithoutPreStocksDataInput,
  create: LogisticCreateWithoutPreStocksInput,
};

export type PreStockUpsertWithWhereUniqueWithoutProductOptionInput = {
  where: PreStockWhereUniqueInput,
  update: PreStockUpdateWithoutProductOptionDataInput,
  create: PreStockCreateWithoutProductOptionInput,
};

export type ProductOptionUpdateManyWithWhereNestedInput = {
  where: ProductOptionScalarWhereInput,
  data: ProductOptionUpdateManyDataInput,
};

export type ProductOptionScalarWhereInput = {
  id?: Maybe<StringFilter>,
  productId?: Maybe<StringFilter>,
  levelOne?: Maybe<NullableStringFilter>,
  levelTwo?: Maybe<NullableStringFilter>,
  originNum?: Maybe<NullableStringFilter>,
  categoryId?: Maybe<NullableStringFilter>,
  originBarcode?: Maybe<NullableStringFilter>,
  newBarcode?: Maybe<NullableStringFilter>,
  needInspection?: Maybe<BooleanFilter>,
  temperature?: Maybe<TemperatureType>,
  bestPeriodDay?: Maybe<NullableIntFilter>,
  basicCourierPriceRange?: Maybe<BasicCourierPriceRange>,
  isActive?: Maybe<BooleanFilter>,
  canBeMixed?: Maybe<BooleanFilter>,
  maxAmount?: Maybe<NullableIntFilter>,
  note?: Maybe<NullableStringFilter>,
  deletedAt?: Maybe<NullableDateTimeFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  preStocks?: Maybe<PreStockFilter>,
  transactions?: Maybe<TransactionFilter>,
  autoMatches?: Maybe<AutoMatchFilter>,
  productMatches?: Maybe<ProductMatchFilter>,
  AND?: Maybe<Array<ProductOptionScalarWhereInput>>,
  OR?: Maybe<Array<ProductOptionScalarWhereInput>>,
  NOT?: Maybe<Array<ProductOptionScalarWhereInput>>,
};

export type ProductOptionUpdateManyDataInput = {
  id?: Maybe<Scalars['String']>,
  levelOne?: Maybe<Scalars['String']>,
  levelTwo?: Maybe<Scalars['String']>,
  originNum?: Maybe<Scalars['String']>,
  originBarcode?: Maybe<Scalars['String']>,
  newBarcode?: Maybe<Scalars['String']>,
  needInspection?: Maybe<Scalars['Boolean']>,
  temperature?: Maybe<TemperatureType>,
  bestPeriodDay?: Maybe<Scalars['Int']>,
  basicCourierPriceRange?: Maybe<BasicCourierPriceRange>,
  isActive?: Maybe<Scalars['Boolean']>,
  canBeMixed?: Maybe<Scalars['Boolean']>,
  maxAmount?: Maybe<Scalars['Int']>,
  note?: Maybe<Scalars['String']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type ProductOptionUpsertWithWhereUniqueWithoutProductInput = {
  where: ProductOptionWhereUniqueInput,
  update: ProductOptionUpdateWithoutProductDataInput,
  create: ProductOptionCreateWithoutProductInput,
};

export type ProductUpdateManyWithWhereNestedInput = {
  where: ProductScalarWhereInput,
  data: ProductUpdateManyDataInput,
};

export type ProductScalarWhereInput = {
  id?: Maybe<StringFilter>,
  brandId?: Maybe<StringFilter>,
  name?: Maybe<StringFilter>,
  note?: Maybe<NullableStringFilter>,
  isActive?: Maybe<BooleanFilter>,
  hasLevel?: Maybe<BooleanFilter>,
  levelOneName?: Maybe<NullableStringFilter>,
  levelTwoName?: Maybe<NullableStringFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  productOptions?: Maybe<ProductOptionFilter>,
  AND?: Maybe<Array<ProductScalarWhereInput>>,
  OR?: Maybe<Array<ProductScalarWhereInput>>,
  NOT?: Maybe<Array<ProductScalarWhereInput>>,
};

export type ProductUpdateManyDataInput = {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  note?: Maybe<Scalars['String']>,
  isActive?: Maybe<Scalars['Boolean']>,
  hasLevel?: Maybe<Scalars['Boolean']>,
  levelOneName?: Maybe<Scalars['String']>,
  levelTwoName?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type ProductUpsertWithWhereUniqueWithoutBrandInput = {
  where: ProductWhereUniqueInput,
  update: ProductUpdateWithoutBrandDataInput,
  create: ProductCreateWithoutBrandInput,
};

export type BrandUpsertWithoutBrandMarketsInput = {
  update: BrandUpdateWithoutBrandMarketsDataInput,
  create: BrandCreateWithoutBrandMarketsInput,
};

export type BrandMarketUpsertWithoutAutoMatchesInput = {
  update: BrandMarketUpdateWithoutAutoMatchesDataInput,
  create: BrandMarketCreateWithoutAutoMatchesInput,
};

export type AutoMatchUpsertWithoutProductMatchesInput = {
  update: AutoMatchUpdateWithoutProductMatchesDataInput,
  create: AutoMatchCreateWithoutProductMatchesInput,
};

export type ProductMatchUpsertWithWhereUniqueWithoutMarketProductInput = {
  where: ProductMatchWhereUniqueInput,
  update: ProductMatchUpdateWithoutMarketProductDataInput,
  create: ProductMatchCreateWithoutMarketProductInput,
};

export type MarketProductUpdateManyWithWhereNestedInput = {
  where: MarketProductScalarWhereInput,
  data: MarketProductUpdateManyDataInput,
};

export type MarketProductScalarWhereInput = {
  id?: Maybe<StringFilter>,
  orderId?: Maybe<StringFilter>,
  num?: Maybe<NullableStringFilter>,
  name?: Maybe<StringFilter>,
  optionNum?: Maybe<NullableStringFilter>,
  optionName?: Maybe<NullableStringFilter>,
  amount?: Maybe<IntFilter>,
  totalPrice?: Maybe<IntFilter>,
  status?: Maybe<MarketProductStatus>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  productMatches?: Maybe<ProductMatchFilter>,
  AND?: Maybe<Array<MarketProductScalarWhereInput>>,
  OR?: Maybe<Array<MarketProductScalarWhereInput>>,
  NOT?: Maybe<Array<MarketProductScalarWhereInput>>,
};

export type MarketProductUpdateManyDataInput = {
  id?: Maybe<Scalars['String']>,
  num?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  optionNum?: Maybe<Scalars['String']>,
  optionName?: Maybe<Scalars['String']>,
  amount?: Maybe<Scalars['Int']>,
  totalPrice?: Maybe<Scalars['Int']>,
  status?: Maybe<MarketProductStatus>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type MarketProductUpsertWithWhereUniqueWithoutOrderInput = {
  where: MarketProductWhereUniqueInput,
  update: MarketProductUpdateWithoutOrderDataInput,
  create: MarketProductCreateWithoutOrderInput,
};

export type OrderUpdateManyWithWhereNestedInput = {
  where: OrderScalarWhereInput,
  data: OrderUpdateManyDataInput,
};

export type OrderScalarWhereInput = {
  id?: Maybe<StringFilter>,
  num?: Maybe<StringFilter>,
  brandMarketId?: Maybe<StringFilter>,
  marketOrderNum?: Maybe<StringFilter>,
  orderedDate?: Maybe<NullableDateTimeFilter>,
  paidDate?: Maybe<NullableDateTimeFilter>,
  sender?: Maybe<NullableStringFilter>,
  senderTel?: Maybe<NullableStringFilter>,
  senderTel2?: Maybe<NullableStringFilter>,
  receiver?: Maybe<StringFilter>,
  receiverTel?: Maybe<StringFilter>,
  receiverTel2?: Maybe<NullableStringFilter>,
  receiverZipcode?: Maybe<NullableStringFilter>,
  receiverAddress?: Maybe<StringFilter>,
  receiverDetailedAddress?: Maybe<NullableStringFilter>,
  message?: Maybe<NullableStringFilter>,
  deliveryPaid?: Maybe<BooleanFilter>,
  deliveryPrice?: Maybe<IntFilter>,
  isOverseas?: Maybe<BooleanFilter>,
  remoteAreaPrice?: Maybe<IntFilter>,
  detailedOrder?: Maybe<NullableStringFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  marketProducts?: Maybe<MarketProductFilter>,
  orderNotes?: Maybe<OrderNoteFilter>,
  AND?: Maybe<Array<OrderScalarWhereInput>>,
  OR?: Maybe<Array<OrderScalarWhereInput>>,
  NOT?: Maybe<Array<OrderScalarWhereInput>>,
};

export type OrderUpdateManyDataInput = {
  id?: Maybe<Scalars['String']>,
  num?: Maybe<Scalars['String']>,
  marketOrderNum?: Maybe<Scalars['String']>,
  orderedDate?: Maybe<Scalars['DateTime']>,
  paidDate?: Maybe<Scalars['DateTime']>,
  sender?: Maybe<Scalars['String']>,
  senderTel?: Maybe<Scalars['String']>,
  senderTel2?: Maybe<Scalars['String']>,
  receiver?: Maybe<Scalars['String']>,
  receiverTel?: Maybe<Scalars['String']>,
  receiverTel2?: Maybe<Scalars['String']>,
  receiverZipcode?: Maybe<Scalars['String']>,
  receiverAddress?: Maybe<Scalars['String']>,
  receiverDetailedAddress?: Maybe<Scalars['String']>,
  message?: Maybe<Scalars['String']>,
  deliveryPaid?: Maybe<Scalars['Boolean']>,
  deliveryPrice?: Maybe<Scalars['Int']>,
  isOverseas?: Maybe<Scalars['Boolean']>,
  remoteAreaPrice?: Maybe<Scalars['Int']>,
  detailedOrder?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type OrderUpsertWithWhereUniqueWithoutBrandMarketInput = {
  where: OrderWhereUniqueInput,
  update: OrderUpdateWithoutBrandMarketDataInput,
  create: OrderCreateWithoutBrandMarketInput,
};

export type BrandMarketUpdateManyWithWhereNestedInput = {
  where: BrandMarketScalarWhereInput,
  data: BrandMarketUpdateManyDataInput,
};

export type BrandMarketScalarWhereInput = {
  id?: Maybe<StringFilter>,
  brandId?: Maybe<StringFilter>,
  marketId?: Maybe<StringFilter>,
  name?: Maybe<StringFilter>,
  sender?: Maybe<NullableStringFilter>,
  phone?: Maybe<NullableStringFilter>,
  zipcode?: Maybe<NullableStringFilter>,
  address?: Maybe<NullableStringFilter>,
  detailedAddress?: Maybe<NullableStringFilter>,
  credential?: Maybe<NullableStringFilter>,
  isActive?: Maybe<BooleanFilter>,
  deletedAt?: Maybe<NullableDateTimeFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  orders?: Maybe<OrderFilter>,
  autoMatches?: Maybe<AutoMatchFilter>,
  AND?: Maybe<Array<BrandMarketScalarWhereInput>>,
  OR?: Maybe<Array<BrandMarketScalarWhereInput>>,
  NOT?: Maybe<Array<BrandMarketScalarWhereInput>>,
};

export type BrandMarketUpdateManyDataInput = {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  sender?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  zipcode?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  detailedAddress?: Maybe<Scalars['String']>,
  credential?: Maybe<Scalars['String']>,
  isActive?: Maybe<Scalars['Boolean']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type BrandMarketUpsertWithWhereUniqueWithoutBrandInput = {
  where: BrandMarketWhereUniqueInput,
  update: BrandMarketUpdateWithoutBrandDataInput,
  create: BrandMarketCreateWithoutBrandInput,
};

export type BrandUpsertWithoutCourierInvoicesInput = {
  update: BrandUpdateWithoutCourierInvoicesDataInput,
  create: BrandCreateWithoutCourierInvoicesInput,
};

export type CourierInvoiceUpsertWithWhereUniqueWithoutCourierPriceRangeInput = {
  where: CourierInvoiceWhereUniqueInput,
  update: CourierInvoiceUpdateWithoutCourierPriceRangeDataInput,
  create: CourierInvoiceCreateWithoutCourierPriceRangeInput,
};

export type CourierPriceRangeUpdateManyWithWhereNestedInput = {
  where: CourierPriceRangeScalarWhereInput,
  data: CourierPriceRangeUpdateManyDataInput,
};

export type CourierPriceRangeScalarWhereInput = {
  id?: Maybe<StringFilter>,
  courierId?: Maybe<StringFilter>,
  basic?: Maybe<BasicCourierPriceRange>,
  name?: Maybe<StringFilter>,
  value?: Maybe<NullableStringFilter>,
  price?: Maybe<IntFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  courierInvoices?: Maybe<CourierInvoiceFilter>,
  AND?: Maybe<Array<CourierPriceRangeScalarWhereInput>>,
  OR?: Maybe<Array<CourierPriceRangeScalarWhereInput>>,
  NOT?: Maybe<Array<CourierPriceRangeScalarWhereInput>>,
};

export type CourierPriceRangeUpdateManyDataInput = {
  id?: Maybe<Scalars['String']>,
  basic?: Maybe<BasicCourierPriceRange>,
  name?: Maybe<Scalars['String']>,
  value?: Maybe<Scalars['String']>,
  price?: Maybe<Scalars['Int']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type CourierPriceRangeUpsertWithWhereUniqueWithoutCourierInput = {
  where: CourierPriceRangeWhereUniqueInput,
  update: CourierPriceRangeUpdateWithoutCourierDataInput,
  create: CourierPriceRangeCreateWithoutCourierInput,
};

export type CourierUpsertWithoutLogisticCouriersInput = {
  update: CourierUpdateWithoutLogisticCouriersDataInput,
  create: CourierCreateWithoutLogisticCouriersInput,
};

export type LogisticCourierUpsertWithoutSellerDefaultOfInput = {
  update: LogisticCourierUpdateWithoutSellerDefaultOfDataInput,
  create: LogisticCourierCreateWithoutSellerDefaultOfInput,
};

export type SellerUpsertWithWhereUniqueWithoutLogisticInput = {
  where: SellerWhereUniqueInput,
  update: SellerUpdateWithoutLogisticDataInput,
  create: SellerCreateWithoutLogisticInput,
};

export type LogisticUpsertWithoutAvailableCouriersInput = {
  update: LogisticUpdateWithoutAvailableCouriersDataInput,
  create: LogisticCreateWithoutAvailableCouriersInput,
};

export type LogisticCourierUpsertWithoutLogisticDefaultOfInput = {
  update: LogisticCourierUpdateWithoutLogisticDefaultOfDataInput,
  create: LogisticCourierCreateWithoutLogisticDefaultOfInput,
};

export type LogisticUpsertWithoutSellersInput = {
  update: LogisticUpdateWithoutSellersDataInput,
  create: LogisticCreateWithoutSellersInput,
};

export type SellerUpsertWithoutCompanyInput = {
  update: SellerUpdateWithoutCompanyDataInput,
  create: SellerCreateWithoutCompanyInput,
};

export type CompanyUpsertWithoutLogisticInput = {
  update: CompanyUpdateWithoutLogisticDataInput,
  create: CompanyCreateWithoutLogisticInput,
};

export type LogisticUpsertWithoutTransactionsInput = {
  update: LogisticUpdateWithoutTransactionsDataInput,
  create: LogisticCreateWithoutTransactionsInput,
};

export type TransactionUpsertWithWhereUniqueWithoutConfirmedUserInput = {
  where: TransactionWhereUniqueInput,
  update: TransactionUpdateWithoutConfirmedUserDataInput,
  create: TransactionCreateWithoutConfirmedUserInput,
};

export type UserUpdateManyWithWhereNestedInput = {
  where: UserScalarWhereInput,
  data: UserUpdateManyDataInput,
};

export type UserScalarWhereInput = {
  id?: Maybe<StringFilter>,
  email?: Maybe<StringFilter>,
  emailVerified?: Maybe<BooleanFilter>,
  type?: Maybe<CompanyType>,
  password?: Maybe<StringFilter>,
  name?: Maybe<NullableStringFilter>,
  phone?: Maybe<NullableStringFilter>,
  companyId?: Maybe<StringFilter>,
  teamId?: Maybe<NullableStringFilter>,
  role?: Maybe<RoleType>,
  marketing?: Maybe<BooleanFilter>,
  deletedAt?: Maybe<NullableDateTimeFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  confirmedTransactions?: Maybe<TransactionFilter>,
  registeredPreStocks?: Maybe<PreStockFilter>,
  confirmedPreStocks?: Maybe<PreStockFilter>,
  confirmedFinalPackages?: Maybe<FinalPackageFilter>,
  confirmedStocktaking?: Maybe<StocktakingFilter>,
  confirmedReturnOrder?: Maybe<ReturnOrderFilter>,
  logisticdefaultOf?: Maybe<LogisticFilter>,
  logs?: Maybe<LogFilter>,
  orderNotes?: Maybe<OrderNoteFilter>,
  confirmedOrderNotes?: Maybe<OrderNoteFilter>,
  notices?: Maybe<NoticeFilter>,
  AND?: Maybe<Array<UserScalarWhereInput>>,
  OR?: Maybe<Array<UserScalarWhereInput>>,
  NOT?: Maybe<Array<UserScalarWhereInput>>,
};

export type UserUpdateManyDataInput = {
  id?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  emailVerified?: Maybe<Scalars['Boolean']>,
  type?: Maybe<CompanyType>,
  password?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  role?: Maybe<RoleType>,
  marketing?: Maybe<Scalars['Boolean']>,
  settings?: Maybe<Scalars['Json']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type UserUpsertWithWhereUniqueWithoutTeamInput = {
  where: UserWhereUniqueInput,
  update: UserUpdateWithoutTeamDataInput,
  create: UserCreateWithoutTeamInput,
};

export type TeamUpdateManyWithWhereNestedInput = {
  where: TeamScalarWhereInput,
  data: TeamUpdateManyDataInput,
};

export type TeamScalarWhereInput = {
  id?: Maybe<StringFilter>,
  companyId?: Maybe<StringFilter>,
  name?: Maybe<StringFilter>,
  deletedAt?: Maybe<NullableDateTimeFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  users?: Maybe<UserFilter>,
  AND?: Maybe<Array<TeamScalarWhereInput>>,
  OR?: Maybe<Array<TeamScalarWhereInput>>,
  NOT?: Maybe<Array<TeamScalarWhereInput>>,
};

export type TeamUpdateManyDataInput = {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type TeamUpsertWithWhereUniqueWithoutCompanyInput = {
  where: TeamWhereUniqueInput,
  update: TeamUpdateWithoutCompanyDataInput,
  create: TeamCreateWithoutCompanyInput,
};

export type CompanyUpsertWithoutUsersInput = {
  update: CompanyUpdateWithoutUsersDataInput,
  create: CompanyCreateWithoutUsersInput,
};

export type UserUpsertWithoutLogisticdefaultOfInput = {
  update: UserUpdateWithoutLogisticdefaultOfDataInput,
  create: UserCreateWithoutLogisticdefaultOfInput,
};

export type LogisticUpsertWithoutCompanyInput = {
  update: LogisticUpdateWithoutCompanyDataInput,
  create: LogisticCreateWithoutCompanyInput,
};

export type CompanyUpsertWithoutTeamsInput = {
  update: CompanyUpdateWithoutTeamsDataInput,
  create: CompanyCreateWithoutTeamsInput,
};

export type TeamUpsertWithoutUsersInput = {
  update: TeamUpdateWithoutUsersDataInput,
  create: TeamCreateWithoutUsersInput,
};

export type UserUpsertWithWhereUniqueWithoutCompanyInput = {
  where: UserWhereUniqueInput,
  update: UserUpdateWithoutCompanyDataInput,
  create: UserCreateWithoutCompanyInput,
};

export type CompanyUpsertWithoutSellerInput = {
  update: CompanyUpdateWithoutSellerDataInput,
  create: CompanyCreateWithoutSellerInput,
};

export type SellerUpsertWithoutBrandsInput = {
  update: SellerUpdateWithoutBrandsDataInput,
  create: SellerCreateWithoutBrandsInput,
};

export type BrandMarketCreateInput = {
  id?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  sender?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  zipcode?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  detailedAddress?: Maybe<Scalars['String']>,
  credential?: Maybe<Scalars['String']>,
  isActive?: Maybe<Scalars['Boolean']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  brand: BrandCreateOneWithoutBrandMarketsInput,
  market: MarketCreateOneWithoutBrandMarketsInput,
  orders?: Maybe<OrderCreateManyWithoutBrandMarketInput>,
  autoMatches?: Maybe<AutoMatchCreateManyWithoutBrandMarketInput>,
};

export type BrandMarketUpdateInput = {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  sender?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  zipcode?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  detailedAddress?: Maybe<Scalars['String']>,
  credential?: Maybe<Scalars['String']>,
  isActive?: Maybe<Scalars['Boolean']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  brand?: Maybe<BrandUpdateOneRequiredWithoutBrandMarketsInput>,
  market?: Maybe<MarketUpdateOneRequiredWithoutBrandMarketsInput>,
  orders?: Maybe<OrderUpdateManyWithoutBrandMarketInput>,
  autoMatches?: Maybe<AutoMatchUpdateManyWithoutBrandMarketInput>,
};

export type CenterCreateInput = {
  id?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  zipcode: Scalars['String'],
  address: Scalars['String'],
  detailedAddress?: Maybe<Scalars['String']>,
  shippingAvailable?: Maybe<Scalars['Boolean']>,
  isActive?: Maybe<Scalars['Boolean']>,
  useLocation?: Maybe<Scalars['Boolean']>,
  totalQuantity?: Maybe<Scalars['Int']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  logistic: LogisticCreateOneWithoutCentersInput,
  settings?: Maybe<LocationSettingCreateManyWithoutCenterInput>,
  locations?: Maybe<LocationCreateManyWithoutCenterInput>,
};

export type CenterUpdateInput = {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  zipcode?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  detailedAddress?: Maybe<Scalars['String']>,
  shippingAvailable?: Maybe<Scalars['Boolean']>,
  isActive?: Maybe<Scalars['Boolean']>,
  useLocation?: Maybe<Scalars['Boolean']>,
  totalQuantity?: Maybe<Scalars['Int']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  logistic?: Maybe<LogisticUpdateOneRequiredWithoutCentersInput>,
  settings?: Maybe<LocationSettingUpdateManyWithoutCenterInput>,
  locations?: Maybe<LocationUpdateManyWithoutCenterInput>,
};

export type CompanyUpdateInput = {
  id?: Maybe<Scalars['String']>,
  type?: Maybe<CompanyType>,
  name?: Maybe<Scalars['String']>,
  ceo?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  zipcode?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  detailedAddress?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  status?: Maybe<CompanyStatus>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  taxInfo?: Maybe<TaxInfoUpdateOneRequiredWithoutCompanyInput>,
  users?: Maybe<UserUpdateManyWithoutCompanyInput>,
  teams?: Maybe<TeamUpdateManyWithoutCompanyInput>,
  bankAccounts?: Maybe<BankAccountUpdateManyWithoutCompanyInput>,
  superAdmin?: Maybe<SuperAdminUpdateOneWithoutCompanyInput>,
  logistic?: Maybe<LogisticUpdateOneWithoutCompanyInput>,
  seller?: Maybe<SellerUpdateOneWithoutCompanyInput>,
};

export type CourierInvoicesInputType = {
  sellerId?: Maybe<Scalars['ID']>,
  courierInvoiceId?: Maybe<Scalars['ID']>,
  brandId?: Maybe<Scalars['ID']>,
  content: Scalars['String'],
  sender: Scalars['String'],
  senderTel: Scalars['String'],
  senderZipcode: Scalars['String'],
  senderAddress: Scalars['String'],
  senderDetailedAddress?: Maybe<Scalars['String']>,
  receiver: Scalars['String'],
  receiverTel: Scalars['String'],
  receiverZipcode: Scalars['String'],
  receiverAddress: Scalars['String'],
  receiverDetailedAddress?: Maybe<Scalars['String']>,
  message: Scalars['String'],
  logisticCourierId: Scalars['ID'],
  courierPriceRangeId: Scalars['ID'],
  copyNum?: Maybe<Scalars['Int']>,
};

export type ReplaceAddressInputType = {
  originAddress: Scalars['String'],
  zipcode: Scalars['String'],
  address: Scalars['String'],
  detailedAddress?: Maybe<Scalars['String']>,
};

export type CombineResult = {
   __typename?: 'CombineResult',
  successCount: Scalars['Int'],
  addressErrors: Array<AddressErrorType>,
  stockErrors: Array<Invoice>,
  requestErrors: Array<RequestErrorType>,
};

export type AddressErrorType = {
   __typename?: 'AddressErrorType',
  invoices: Array<Invoice>,
  address: Scalars['String'],
  logisticId: Scalars['ID'],
};

export type RequestErrorType = {
   __typename?: 'RequestErrorType',
  invoice: Invoice,
  errorMsg: Scalars['String'],
};

export type LocationCreateInput = {
  id?: Maybe<Scalars['String']>,
  zone: Scalars['String'],
  rack: Scalars['String'],
  level: Scalars['String'],
  isActive?: Maybe<Scalars['Boolean']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  center: CenterCreateOneWithoutLocationsInput,
  setting: LocationSettingCreateOneWithoutLocationsInput,
  incomingRecords?: Maybe<TransactionCreateManyWithoutLocationInput>,
};

export type LocationSettingCreateInput = {
  id?: Maybe<Scalars['String']>,
  zone: Scalars['String'],
  rack: Scalars['String'],
  level: Scalars['String'],
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  center: CenterCreateOneWithoutSettingsInput,
  locations?: Maybe<LocationCreateManyWithoutSettingInput>,
};

export type LocationSettingUpdateInput = {
  id?: Maybe<Scalars['String']>,
  zone?: Maybe<Scalars['String']>,
  rack?: Maybe<Scalars['String']>,
  level?: Maybe<Scalars['String']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  center?: Maybe<CenterUpdateOneRequiredWithoutSettingsInput>,
  locations?: Maybe<LocationUpdateManyWithoutSettingInput>,
};

export type LogisticUpdateInput = {
  id?: Maybe<Scalars['String']>,
  marketing?: Maybe<Scalars['Boolean']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  company?: Maybe<CompanyUpdateOneRequiredWithoutLogisticInput>,
  defaultUser?: Maybe<UserUpdateOneRequiredWithoutLogisticdefaultOfInput>,
  defaultCourier?: Maybe<LogisticCourierUpdateOneWithoutLogisticDefaultOfInput>,
  sellers?: Maybe<SellerUpdateManyWithoutLogisticInput>,
  availableCouriers?: Maybe<LogisticCourierUpdateManyWithoutLogisticInput>,
  packets?: Maybe<PacketUpdateManyWithoutLogisticInput>,
  centers?: Maybe<CenterUpdateManyWithoutLogisticInput>,
  preStocks?: Maybe<PreStockUpdateManyWithoutLogisticInput>,
  transactions?: Maybe<TransactionUpdateManyWithoutLogisticInput>,
  notices?: Maybe<NoticeUpdateManyWithoutLogisticInput>,
};

export type LogisticCourierCreateInput = {
  id?: Maybe<Scalars['String']>,
  config: Scalars['String'],
  isActive?: Maybe<Scalars['Boolean']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  logistic: LogisticCreateOneWithoutAvailableCouriersInput,
  courier: CourierCreateOneWithoutLogisticCouriersInput,
  logisticDefaultOf?: Maybe<LogisticCreateOneWithoutDefaultCourierInput>,
  sellerDefaultOf?: Maybe<SellerCreateManyWithoutDefaultCourierInput>,
  courierInvoices?: Maybe<CourierInvoiceCreateManyWithoutLogisticCourierInput>,
  courierInvoiceTrackings?: Maybe<CourierInvoiceTrackingCreateManyWithoutLogisticCourierInput>,
};

export type LogisticCourierUpdateInput = {
  id?: Maybe<Scalars['String']>,
  config?: Maybe<Scalars['String']>,
  isActive?: Maybe<Scalars['Boolean']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  logistic?: Maybe<LogisticUpdateOneRequiredWithoutAvailableCouriersInput>,
  courier?: Maybe<CourierUpdateOneRequiredWithoutLogisticCouriersInput>,
  logisticDefaultOf?: Maybe<LogisticUpdateOneWithoutDefaultCourierInput>,
  sellerDefaultOf?: Maybe<SellerUpdateManyWithoutDefaultCourierInput>,
  courierInvoices?: Maybe<CourierInvoiceUpdateManyWithoutLogisticCourierInput>,
  courierInvoiceTrackings?: Maybe<CourierInvoiceTrackingUpdateManyWithoutLogisticCourierInput>,
};

export type MarketProductUpdateInput = {
  id?: Maybe<Scalars['String']>,
  num?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  optionNum?: Maybe<Scalars['String']>,
  optionName?: Maybe<Scalars['String']>,
  amount?: Maybe<Scalars['Int']>,
  totalPrice?: Maybe<Scalars['Int']>,
  status?: Maybe<MarketProductStatus>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  order?: Maybe<OrderUpdateOneRequiredWithoutMarketProductsInput>,
  productMatches?: Maybe<ProductMatchUpdateManyWithoutMarketProductInput>,
};

export type ProductMatchesInputType = {
  productOptionId: Scalars['ID'],
  amount: Scalars['Int'],
};

export type NoticeCreateInput = {
  id?: Maybe<Scalars['String']>,
  groupId: Scalars['String'],
  expiryDate?: Maybe<Scalars['DateTime']>,
  title: Scalars['String'],
  content: Scalars['String'],
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  isPublic?: Maybe<Scalars['Boolean']>,
  isImportant?: Maybe<Scalars['Boolean']>,
  logistic: LogisticCreateOneWithoutNoticesInput,
  author: UserCreateOneWithoutNoticesInput,
  seller: SellerCreateOneWithoutNoticesInput,
};

export type NoticeUpdateInput = {
  id?: Maybe<Scalars['String']>,
  groupId?: Maybe<Scalars['String']>,
  expiryDate?: Maybe<Scalars['DateTime']>,
  title?: Maybe<Scalars['String']>,
  content?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  isPublic?: Maybe<Scalars['Boolean']>,
  isImportant?: Maybe<Scalars['Boolean']>,
  logistic?: Maybe<LogisticUpdateOneRequiredWithoutNoticesInput>,
  author?: Maybe<UserUpdateOneRequiredWithoutNoticesInput>,
  seller?: Maybe<SellerUpdateOneRequiredWithoutNoticesInput>,
};

export type GetMarketOrdersResultType = {
   __typename?: 'GetMarketOrdersResultType',
  brandMarket?: Maybe<BrandMarket>,
  originCnt: Scalars['Int'],
  duplicateCnt: Scalars['Int'],
  successCnt: Scalars['Int'],
  errMsgs: Array<Scalars['String']>,
};

export type UploadExcelOrdersInputType = {
  orderNum?: Maybe<Scalars['String']>,
  orderDate?: Maybe<Scalars['String']>,
  productNum?: Maybe<Scalars['String']>,
  productName: Scalars['String'],
  optionNum?: Maybe<Scalars['String']>,
  optionName?: Maybe<Scalars['String']>,
  amount: Scalars['Int'],
  sender?: Maybe<Scalars['String']>,
  senderTel?: Maybe<Scalars['String']>,
  receiver: Scalars['String'],
  receiverTel: Scalars['String'],
  zipcode?: Maybe<Scalars['String']>,
  address: Scalars['String'],
  message?: Maybe<Scalars['String']>,
};

export type AdditionalShippingInputType = {
  productOptionId: Scalars['ID'],
  amount: Scalars['Int'],
};

export type OrderNoteCreateInput = {
  id?: Maybe<Scalars['String']>,
  type?: Maybe<OrderNoteType>,
  note?: Maybe<Scalars['String']>,
  status?: Maybe<OrderNoteStatus>,
  confirmedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  order: OrderCreateOneWithoutOrderNotesInput,
  writer: UserCreateOneWithoutOrderNotesInput,
  confirmedUser?: Maybe<UserCreateOneWithoutConfirmedOrderNotesInput>,
};

export type OrderNoteUpdateInput = {
  id?: Maybe<Scalars['String']>,
  type?: Maybe<OrderNoteType>,
  note?: Maybe<Scalars['String']>,
  status?: Maybe<OrderNoteStatus>,
  confirmedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  order?: Maybe<OrderUpdateOneRequiredWithoutOrderNotesInput>,
  writer?: Maybe<UserUpdateOneRequiredWithoutOrderNotesInput>,
  confirmedUser?: Maybe<UserUpdateOneWithoutConfirmedOrderNotesInput>,
};

export type PreStockCreateInput = {
  id?: Maybe<Scalars['String']>,
  amount?: Maybe<Scalars['Int']>,
  loadUnit?: Maybe<LoadUnit>,
  loadUnitAmount?: Maybe<Scalars['Int']>,
  scheduledDate: Scalars['DateTime'],
  confirmedDate?: Maybe<Scalars['DateTime']>,
  note?: Maybe<Scalars['String']>,
  manufacturedDate?: Maybe<Scalars['DateTime']>,
  expiryDate?: Maybe<Scalars['DateTime']>,
  canceledAt?: Maybe<Scalars['DateTime']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  logistic: LogisticCreateOneWithoutPreStocksInput,
  productOption: ProductOptionCreateOneWithoutPreStocksInput,
  registeredUser: UserCreateOneWithoutRegisteredPreStocksInput,
  confirmedUser?: Maybe<UserCreateOneWithoutConfirmedPreStocksInput>,
  transactions?: Maybe<TransactionCreateManyWithoutPreStockInput>,
};

export type PreStockUpdateInput = {
  id?: Maybe<Scalars['String']>,
  amount?: Maybe<Scalars['Int']>,
  loadUnit?: Maybe<LoadUnit>,
  loadUnitAmount?: Maybe<Scalars['Int']>,
  scheduledDate?: Maybe<Scalars['DateTime']>,
  confirmedDate?: Maybe<Scalars['DateTime']>,
  note?: Maybe<Scalars['String']>,
  manufacturedDate?: Maybe<Scalars['DateTime']>,
  expiryDate?: Maybe<Scalars['DateTime']>,
  canceledAt?: Maybe<Scalars['DateTime']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  logistic?: Maybe<LogisticUpdateOneRequiredWithoutPreStocksInput>,
  productOption?: Maybe<ProductOptionUpdateOneRequiredWithoutPreStocksInput>,
  registeredUser?: Maybe<UserUpdateOneRequiredWithoutRegisteredPreStocksInput>,
  confirmedUser?: Maybe<UserUpdateOneWithoutConfirmedPreStocksInput>,
  transactions?: Maybe<TransactionUpdateManyWithoutPreStockInput>,
};

export type CreateManyPreStocksInput = {
  preStocks: Array<PreStockCreateInput>,
};

export type UploadExcelPreStocksInput = {
  sellerId: Scalars['ID'],
  brandName: Scalars['String'],
  productName: Scalars['String'],
  levelOneName?: Maybe<Scalars['String']>,
  levelTwoName?: Maybe<Scalars['String']>,
  levelOne?: Maybe<Scalars['String']>,
  levelTwo?: Maybe<Scalars['String']>,
  amount: Scalars['Int'],
  loadUnit?: Maybe<LoadUnit>,
  loadUnitAmount?: Maybe<Scalars['Int']>,
  scheduledDate: Scalars['String'],
  manufacturedDate?: Maybe<Scalars['String']>,
  expiryDate?: Maybe<Scalars['String']>,
  note?: Maybe<Scalars['String']>,
};

export type ConfirmPreStockInputType = {
  productOptionId?: Maybe<Scalars['ID']>,
  usableAmount: Scalars['Int'],
  brokenAmount: Scalars['Int'],
  manufacturedDate?: Maybe<Scalars['String']>,
  expiryDate?: Maybe<Scalars['String']>,
  transportType?: Maybe<Scalars['String']>,
  type?: Maybe<TransportType>,
};

export type ProductCreateInput = {
  id?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  note?: Maybe<Scalars['String']>,
  isActive?: Maybe<Scalars['Boolean']>,
  hasLevel?: Maybe<Scalars['Boolean']>,
  levelOneName?: Maybe<Scalars['String']>,
  levelTwoName?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  brand: BrandCreateOneWithoutProductsInput,
  productOptions?: Maybe<ProductOptionCreateManyWithoutProductInput>,
};

export type ProductUpdateInput = {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  note?: Maybe<Scalars['String']>,
  isActive?: Maybe<Scalars['Boolean']>,
  hasLevel?: Maybe<Scalars['Boolean']>,
  levelOneName?: Maybe<Scalars['String']>,
  levelTwoName?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  brand?: Maybe<BrandUpdateOneRequiredWithoutProductsInput>,
  productOptions?: Maybe<ProductOptionUpdateManyWithoutProductInput>,
};

export type CreateManyProductsInput = {
  products: Array<ProductCreateInput>,
};

export type ProductMatchUpdateInput = {
  id?: Maybe<Scalars['String']>,
  amount?: Maybe<Scalars['Int']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  marketProduct?: Maybe<MarketProductUpdateOneRequiredWithoutProductMatchesInput>,
  autoMatch?: Maybe<AutoMatchUpdateOneWithoutProductMatchesInput>,
  productOption?: Maybe<ProductOptionUpdateOneRequiredWithoutProductMatchesInput>,
  finalPackages?: Maybe<FinalPackageUpdateManyWithoutProductMatchInput>,
};

export type ProductOptionCreateInput = {
  id?: Maybe<Scalars['String']>,
  levelOne?: Maybe<Scalars['String']>,
  levelTwo?: Maybe<Scalars['String']>,
  originNum?: Maybe<Scalars['String']>,
  originBarcode?: Maybe<Scalars['String']>,
  newBarcode?: Maybe<Scalars['String']>,
  needInspection?: Maybe<Scalars['Boolean']>,
  temperature?: Maybe<TemperatureType>,
  bestPeriodDay?: Maybe<Scalars['Int']>,
  basicCourierPriceRange: BasicCourierPriceRange,
  isActive?: Maybe<Scalars['Boolean']>,
  canBeMixed?: Maybe<Scalars['Boolean']>,
  maxAmount?: Maybe<Scalars['Int']>,
  note?: Maybe<Scalars['String']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  product: ProductCreateOneWithoutProductOptionsInput,
  category?: Maybe<ProductCategoryCreateOneWithoutProductOptionsInput>,
  preStocks?: Maybe<PreStockCreateManyWithoutProductOptionInput>,
  transactions?: Maybe<TransactionCreateManyWithoutProductOptionInput>,
  autoMatches?: Maybe<AutoMatchCreateManyWithoutProductOptionInput>,
  productMatches?: Maybe<ProductMatchCreateManyWithoutProductOptionInput>,
};

export type ProductOptionUpdateInput = {
  id?: Maybe<Scalars['String']>,
  levelOne?: Maybe<Scalars['String']>,
  levelTwo?: Maybe<Scalars['String']>,
  originNum?: Maybe<Scalars['String']>,
  originBarcode?: Maybe<Scalars['String']>,
  newBarcode?: Maybe<Scalars['String']>,
  needInspection?: Maybe<Scalars['Boolean']>,
  temperature?: Maybe<TemperatureType>,
  bestPeriodDay?: Maybe<Scalars['Int']>,
  basicCourierPriceRange?: Maybe<BasicCourierPriceRange>,
  isActive?: Maybe<Scalars['Boolean']>,
  canBeMixed?: Maybe<Scalars['Boolean']>,
  maxAmount?: Maybe<Scalars['Int']>,
  note?: Maybe<Scalars['String']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  product?: Maybe<ProductUpdateOneRequiredWithoutProductOptionsInput>,
  category?: Maybe<ProductCategoryUpdateOneWithoutProductOptionsInput>,
  preStocks?: Maybe<PreStockUpdateManyWithoutProductOptionInput>,
  transactions?: Maybe<TransactionUpdateManyWithoutProductOptionInput>,
  autoMatches?: Maybe<AutoMatchUpdateManyWithoutProductOptionInput>,
  productMatches?: Maybe<ProductMatchUpdateManyWithoutProductOptionInput>,
};

export type ProductOptionUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>,
  levelOne?: Maybe<Scalars['String']>,
  levelTwo?: Maybe<Scalars['String']>,
  originNum?: Maybe<Scalars['String']>,
  originBarcode?: Maybe<Scalars['String']>,
  newBarcode?: Maybe<Scalars['String']>,
  needInspection?: Maybe<Scalars['Boolean']>,
  temperature?: Maybe<TemperatureType>,
  bestPeriodDay?: Maybe<Scalars['Int']>,
  basicCourierPriceRange?: Maybe<BasicCourierPriceRange>,
  isActive?: Maybe<Scalars['Boolean']>,
  canBeMixed?: Maybe<Scalars['Boolean']>,
  maxAmount?: Maybe<Scalars['Int']>,
  note?: Maybe<Scalars['String']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type RequsetReturnOrderFinalPackageType = {
  id: Scalars['ID'],
  requestAmount: Scalars['Int'],
  category: ReturnOrderType,
};

export type ConfirmReturnOrderProductInputType = {
  id: Scalars['ID'],
  confirmType: EventType,
};

export type SelectInvoiceInputType = {
  zipcode?: Maybe<Scalars['String']>,
  address: Scalars['String'],
  detailedAddress?: Maybe<Scalars['String']>,
  productMatches?: Maybe<Array<SelectInvoiceProductMatcheInputType>>,
};

export type SelectInvoiceProductMatcheInputType = {
  id: Scalars['ID'],
  amount: Scalars['Int'],
};

export type SelectCombineResult = {
   __typename?: 'SelectCombineResult',
  successCount: Scalars['Int'],
  requestErrors: Array<SelectRequestErrorType>,
};

export type SelectRequestErrorType = {
   __typename?: 'SelectRequestErrorType',
  invoice: Invoice,
  errorMsg: Scalars['String'],
};

export type SellerCreateInput = {
  id?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  company: CompanyCreateOneWithoutSellerInput,
  logistic: LogisticCreateOneWithoutSellersInput,
  defaultCourier?: Maybe<LogisticCourierCreateOneWithoutSellerDefaultOfInput>,
  brands?: Maybe<BrandCreateManyWithoutSellerInput>,
  notices?: Maybe<NoticeCreateManyWithoutSellerInput>,
};

export type SellerUpdateInput = {
  id?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  company?: Maybe<CompanyUpdateOneRequiredWithoutSellerInput>,
  logistic?: Maybe<LogisticUpdateOneRequiredWithoutSellersInput>,
  defaultCourier?: Maybe<LogisticCourierUpdateOneWithoutSellerDefaultOfInput>,
  brands?: Maybe<BrandUpdateManyWithoutSellerInput>,
  notices?: Maybe<NoticeUpdateManyWithoutSellerInput>,
};

export type ConfirmStocktakingsInput = {
  stocktakingId: Scalars['ID'],
  confirmedDate?: Maybe<Scalars['String']>,
  confirmedNote?: Maybe<Scalars['String']>,
};

export type CancelStocktakingsInput = {
  stocktakingId: Scalars['ID'],
  confirmedDate?: Maybe<Scalars['String']>,
  confirmedNote?: Maybe<Scalars['String']>,
};

export type TeamCreateInput = {
  id?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  company: CompanyCreateOneWithoutTeamsInput,
  users?: Maybe<UserCreateManyWithoutTeamInput>,
};

export type TeamUpdateInput = {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  company?: Maybe<CompanyUpdateOneRequiredWithoutTeamsInput>,
  users?: Maybe<UserUpdateManyWithoutTeamInput>,
};

export type SelectTransactionLocationInputType = {
  centerId: Scalars['ID'],
  settingId?: Maybe<Scalars['ID']>,
  zone?: Maybe<Scalars['String']>,
  rack?: Maybe<Scalars['String']>,
  level?: Maybe<Scalars['String']>,
  amount?: Maybe<Scalars['Int']>,
  usableAmount?: Maybe<Scalars['Int']>,
  brokenAmount?: Maybe<Scalars['Int']>,
  transportType?: Maybe<TransportType>,
};

export type MoveStockInputType = {
  centerId: Scalars['ID'],
  settingId?: Maybe<Scalars['ID']>,
  zone?: Maybe<Scalars['String']>,
  rack?: Maybe<Scalars['String']>,
  level?: Maybe<Scalars['String']>,
  amount: Scalars['Int'],
  transportType?: Maybe<TransportType>,
};

export type UploadExcelStockInputType = {
  sellerName: Scalars['String'],
  brandName: Scalars['String'],
  productName: Scalars['String'],
  levelOneName?: Maybe<Scalars['String']>,
  levelTwoName?: Maybe<Scalars['String']>,
  loadUnit?: Maybe<LoadUnit>,
  loadUnitAmount?: Maybe<Scalars['Int']>,
  amount: Scalars['Int'],
  manufacturedDate?: Maybe<Scalars['DateTime']>,
  expiryDate?: Maybe<Scalars['DateTime']>,
  note?: Maybe<Scalars['String']>,
  centerName: Scalars['String'],
  zoneName?: Maybe<Scalars['String']>,
  rackName?: Maybe<Scalars['String']>,
  levelName?: Maybe<Scalars['String']>,
};

export type UserCreateInput = {
  id?: Maybe<Scalars['String']>,
  email: Scalars['String'],
  emailVerified?: Maybe<Scalars['Boolean']>,
  type?: Maybe<CompanyType>,
  password: Scalars['String'],
  name?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  role?: Maybe<RoleType>,
  marketing?: Maybe<Scalars['Boolean']>,
  settings?: Maybe<Scalars['Json']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  company: CompanyCreateOneWithoutUsersInput,
  team?: Maybe<TeamCreateOneWithoutUsersInput>,
  confirmedTransactions?: Maybe<TransactionCreateManyWithoutConfirmedUserInput>,
  registeredPreStocks?: Maybe<PreStockCreateManyWithoutRegisteredUserInput>,
  confirmedPreStocks?: Maybe<PreStockCreateManyWithoutConfirmedUserInput>,
  confirmedFinalPackages?: Maybe<FinalPackageCreateManyWithoutConfirmedUserInput>,
  confirmedStocktaking?: Maybe<StocktakingCreateManyWithoutConfirmedUserInput>,
  confirmedReturnOrder?: Maybe<ReturnOrderCreateManyWithoutConfirmedUserInput>,
  logisticdefaultOf?: Maybe<LogisticCreateManyWithoutDefaultUserInput>,
  logs?: Maybe<LogCreateManyWithoutUserInput>,
  orderNotes?: Maybe<OrderNoteCreateManyWithoutWriterInput>,
  confirmedOrderNotes?: Maybe<OrderNoteCreateManyWithoutConfirmedUserInput>,
  notices?: Maybe<NoticeCreateManyWithoutAuthorInput>,
};

export type UserUpdateInput = {
  id?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  emailVerified?: Maybe<Scalars['Boolean']>,
  type?: Maybe<CompanyType>,
  password?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  role?: Maybe<RoleType>,
  marketing?: Maybe<Scalars['Boolean']>,
  settings?: Maybe<Scalars['Json']>,
  deletedAt?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  company?: Maybe<CompanyUpdateOneRequiredWithoutUsersInput>,
  team?: Maybe<TeamUpdateOneWithoutUsersInput>,
  confirmedTransactions?: Maybe<TransactionUpdateManyWithoutConfirmedUserInput>,
  registeredPreStocks?: Maybe<PreStockUpdateManyWithoutRegisteredUserInput>,
  confirmedPreStocks?: Maybe<PreStockUpdateManyWithoutConfirmedUserInput>,
  confirmedFinalPackages?: Maybe<FinalPackageUpdateManyWithoutConfirmedUserInput>,
  confirmedStocktaking?: Maybe<StocktakingUpdateManyWithoutConfirmedUserInput>,
  confirmedReturnOrder?: Maybe<ReturnOrderUpdateManyWithoutConfirmedUserInput>,
  logisticdefaultOf?: Maybe<LogisticUpdateManyWithoutDefaultUserInput>,
  logs?: Maybe<LogUpdateManyWithoutUserInput>,
  orderNotes?: Maybe<OrderNoteUpdateManyWithoutWriterInput>,
  confirmedOrderNotes?: Maybe<OrderNoteUpdateManyWithoutConfirmedUserInput>,
  notices?: Maybe<NoticeUpdateManyWithoutAuthorInput>,
};

export type AuthPayload = {
   __typename?: 'AuthPayload',
  token: Scalars['String'],
  user: User,
  logistic?: Maybe<Logistic>,
  seller?: Maybe<Seller>,
  accessList: Array<Access>,
  settings?: Maybe<UserSettings>,
};

export type Access = {
   __typename?: 'Access',
  id: Scalars['String'],
  companyType: CompanyType,
  role: RoleType,
  pageId: PageId,
  readable: Scalars['Boolean'],
  writable: Scalars['Boolean'],
};

export enum PageId {
  Logisticinfo = 'LOGISTICINFO',
  Sellersettings = 'SELLERSETTINGS',
  Centersettings = 'CENTERSETTINGS',
  Productsettings = 'PRODUCTSETTINGS',
  Sellerinfo = 'SELLERINFO',
  Productedit = 'PRODUCTEDIT',
  Versionpage = 'VERSIONPAGE',
  Marketchannels = 'MARKETCHANNELS',
  Addprestock = 'ADDPRESTOCK',
  Confirmprestock = 'CONFIRMPRESTOCK',
  Assignlocation = 'ASSIGNLOCATION',
  Confirmedprestocks = 'CONFIRMEDPRESTOCKS',
  Fetchneworders = 'FETCHNEWORDERS',
  Matchorders = 'MATCHORDERS',
  Combineorders = 'COMBINEORDERS',
  Invoices = 'INVOICES',
  Extrainvoice = 'EXTRAINVOICE',
  Stocklist = 'STOCKLIST',
  Stockdetail = 'STOCKDETAIL',
  Transferstatus = 'TRANSFERSTATUS',
  Stocktransfer = 'STOCKTRANSFER',
  Extraoutbound = 'EXTRAOUTBOUND',
  Extraoutbounddetail = 'EXTRAOUTBOUNDDETAIL',
  Manageorders = 'MANAGEORDERS',
  Orderdetail = 'ORDERDETAIL',
  Confirmstock = 'CONFIRMSTOCK',
  Outboundlist = 'OUTBOUNDLIST',
  Docsdownload = 'DOCSDOWNLOAD',
  Outboundinspection = 'OUTBOUNDINSPECTION'
}

export type UserSettings = {
   __typename?: 'UserSettings',
  LOGISTIC?: Maybe<UserSettingsLogistic>,
  SELLER?: Maybe<UserSettingsSeller>,
  SUPER?: Maybe<UserSettingsSuper>,
};

export type UserSettingsLogistic = {
   __typename?: 'UserSettingsLogistic',
  dashboard?: Maybe<UserSettingsDashboard>,
};

export type UserSettingsDashboard = {
   __typename?: 'UserSettingsDashboard',
  widgets?: Maybe<Array<UserSettingsDashboardWidget>>,
};

export type UserSettingsDashboardWidget = {
   __typename?: 'UserSettingsDashboardWidget',
  widgetId: Scalars['String'],
  x?: Maybe<Scalars['Int']>,
  y?: Maybe<Scalars['Int']>,
  w?: Maybe<Scalars['Int']>,
  h?: Maybe<Scalars['Int']>,
  collapsed?: Maybe<Scalars['Boolean']>,
  hidden?: Maybe<Scalars['Boolean']>,
};

export type UserSettingsSeller = {
   __typename?: 'UserSettingsSeller',
  dashboard?: Maybe<UserSettingsDashboard>,
};

export type UserSettingsSuper = {
   __typename?: 'UserSettingsSuper',
  dashboard?: Maybe<UserSettingsDashboard>,
};

export type UserSettingsInput = {
  LOGISTIC?: Maybe<UserSettingsLogisticInput>,
  SELLER?: Maybe<UserSettingsSellerInput>,
};

export type UserSettingsLogisticInput = {
  dashboard?: Maybe<UserSettingsDashboardInput>,
};

export type UserSettingsDashboardInput = {
  widgets?: Maybe<Array<UserSettingsDashboardWidgetInput>>,
};

export type UserSettingsDashboardWidgetInput = {
  widgetId: Scalars['String'],
  x?: Maybe<Scalars['Int']>,
  y?: Maybe<Scalars['Int']>,
  w?: Maybe<Scalars['Int']>,
  h?: Maybe<Scalars['Int']>,
  collapsed?: Maybe<Scalars['Boolean']>,
  hidden?: Maybe<Scalars['Boolean']>,
};

export type UserSettingsSellerInput = {
  dashboard?: Maybe<UserSettingsDashboardInput>,
};

export type UpdateUserDashboardSettingsResultType = {
   __typename?: 'UpdateUserDashboardSettingsResultType',
  companyType: CompanyType,
  dashboard: UserSettingsDashboard,
};

export type Log = {
   __typename?: 'Log',
  id: Scalars['String'],
  content: Scalars['String'],
  createdAt: Scalars['DateTime'],
};

export type WidgetProps = {
   __typename?: 'WidgetProps',
  x?: Maybe<Scalars['Int']>,
  y?: Maybe<Scalars['Int']>,
  w?: Maybe<Scalars['Int']>,
  h?: Maybe<Scalars['Int']>,
  refresh?: Maybe<Scalars['Boolean']>,
  hidden?: Maybe<Scalars['Boolean']>,
  link?: Maybe<Scalars['String']>,
};

export type WidgetPropsInput = {
  x?: Maybe<Scalars['Int']>,
  y?: Maybe<Scalars['Int']>,
  w?: Maybe<Scalars['Int']>,
  h?: Maybe<Scalars['Int']>,
  refresh: Scalars['Boolean'],
  hidden: Scalars['Boolean'],
  link: Scalars['String'],
};

export type LoginMutationVariables = {
  email: Scalars['String'],
  password: Scalars['String']
};


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'AuthPayload' }
    & Pick<AuthPayload, 'token'>
    & { accessList: Array<(
      { __typename?: 'Access' }
      & Pick<Access, 'companyType' | 'role'>
    )>, user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phone' | 'role' | 'type'>
      & { team: Maybe<(
        { __typename?: 'Team' }
        & Pick<Team, 'id' | 'name'>
      )>, company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id'>
      ) }
    ) }
  ) }
);

export type UploadExcelStockMutationVariables = {
  logisticId: Scalars['ID'],
  records: Array<UploadExcelStockInputType>
};


export type UploadExcelStockMutation = (
  { __typename?: 'Mutation' }
  & { uploadExcelStock: Array<(
    { __typename?: 'Transaction' }
    & Pick<Transaction, 'id'>
  )> }
);

export type SellersQueryVariables = {
  where?: Maybe<SellerWhereInput>
};


export type SellersQuery = (
  { __typename?: 'Query' }
  & { sellers: Array<(
    { __typename?: 'Seller' }
    & Pick<Seller, 'id'>
    & { brands: Array<(
      { __typename?: 'Brand' }
      & Pick<Brand, 'id' | 'name' | 'isActive' | 'deletedAt'>
    )>, company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'ceo' | 'name' | 'email' | 'address' | 'detailedAddress' | 'zipcode' | 'phone'>
    ) }
  )> }
);

export type BrandsQueryVariables = {
  where?: Maybe<BrandWhereInput>,
  orderBy?: Maybe<Array<BrandOrderByInput>>,
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<BrandWhereUniqueInput>
};


export type BrandsQuery = (
  { __typename?: 'Query' }
  & { brands: Array<(
    { __typename?: 'Brand' }
    & Pick<Brand, 'id' | 'name' | 'isActive' | 'sellerId'>
    & { seller: (
      { __typename?: 'Seller' }
      & Pick<Seller, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ) }
    ) }
  )> }
);

export type CentersQueryVariables = {
  where?: Maybe<CenterWhereInput>
};


export type CentersQuery = (
  { __typename?: 'Query' }
  & { centers: Array<(
    { __typename?: 'Center' }
    & Pick<Center, 'id' | 'name' | 'zipcode' | 'address' | 'detailedAddress' | 'shippingAvailable' | 'isActive' | 'useLocation' | 'deletedAt'>
    & { settings: Array<(
      { __typename?: 'LocationSetting' }
      & Pick<LocationSetting, 'id' | 'zone' | 'rack' | 'level' | 'deletedAt'>
    )>, locations: Array<(
      { __typename?: 'Location' }
      & Pick<Location, 'zone' | 'rack' | 'level' | 'isActive'>
    )> }
  )> }
);

export type LogisticsQueryVariables = {
  where?: Maybe<LogisticWhereInput>,
  orderBy?: Maybe<Array<LogisticOrderByInput>>,
  take?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>,
  cursor?: Maybe<LogisticWhereUniqueInput>
};


export type LogisticsQuery = (
  { __typename?: 'Query' }
  & { logistics: Array<(
    { __typename?: 'Logistic' }
    & Pick<Logistic, 'id'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name' | 'deletedAt'>
    ) }
  )> }
);


export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    token
    accessList {
      companyType
      role
    }
    user {
      id
      email
      name
      phone
      role
      type
      team {
        id
        name
      }
      company {
        id
      }
    }
  }
}
    `;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const UploadExcelStockDocument = gql`
    mutation uploadExcelStock($logisticId: ID!, $records: [UploadExcelStockInputType!]!) {
  uploadExcelStock(logisticId: $logisticId, records: $records) {
    id
  }
}
    `;
export type UploadExcelStockMutationFn = ApolloReactCommon.MutationFunction<UploadExcelStockMutation, UploadExcelStockMutationVariables>;

/**
 * __useUploadExcelStockMutation__
 *
 * To run a mutation, you first call `useUploadExcelStockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadExcelStockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadExcelStockMutation, { data, loading, error }] = useUploadExcelStockMutation({
 *   variables: {
 *      logisticId: // value for 'logisticId'
 *      records: // value for 'records'
 *   },
 * });
 */
export function useUploadExcelStockMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadExcelStockMutation, UploadExcelStockMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadExcelStockMutation, UploadExcelStockMutationVariables>(UploadExcelStockDocument, baseOptions);
      }
export type UploadExcelStockMutationHookResult = ReturnType<typeof useUploadExcelStockMutation>;
export type UploadExcelStockMutationResult = ApolloReactCommon.MutationResult<UploadExcelStockMutation>;
export type UploadExcelStockMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadExcelStockMutation, UploadExcelStockMutationVariables>;
export const SellersDocument = gql`
    query Sellers($where: SellerWhereInput) {
  sellers(where: $where) {
    id
    brands {
      id
      name
      isActive
      deletedAt
    }
    company {
      id
      ceo
      name
      email
      address
      detailedAddress
      zipcode
      phone
    }
  }
}
    `;

/**
 * __useSellersQuery__
 *
 * To run a query within a React component, call `useSellersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSellersQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSellersQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSellersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SellersQuery, SellersQueryVariables>) {
        return ApolloReactHooks.useQuery<SellersQuery, SellersQueryVariables>(SellersDocument, baseOptions);
      }
export function useSellersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SellersQuery, SellersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SellersQuery, SellersQueryVariables>(SellersDocument, baseOptions);
        }
export type SellersQueryHookResult = ReturnType<typeof useSellersQuery>;
export type SellersLazyQueryHookResult = ReturnType<typeof useSellersLazyQuery>;
export type SellersQueryResult = ApolloReactCommon.QueryResult<SellersQuery, SellersQueryVariables>;
export const BrandsDocument = gql`
    query Brands($where: BrandWhereInput, $orderBy: [BrandOrderByInput!], $take: Int, $skip: Int, $cursor: BrandWhereUniqueInput) {
  brands(where: $where, orderBy: $orderBy, take: $take, skip: $skip, cursor: $cursor) {
    id
    name
    isActive
    sellerId
    seller {
      id
      company {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useBrandsQuery__
 *
 * To run a query within a React component, call `useBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useBrandsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BrandsQuery, BrandsQueryVariables>) {
        return ApolloReactHooks.useQuery<BrandsQuery, BrandsQueryVariables>(BrandsDocument, baseOptions);
      }
export function useBrandsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BrandsQuery, BrandsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BrandsQuery, BrandsQueryVariables>(BrandsDocument, baseOptions);
        }
export type BrandsQueryHookResult = ReturnType<typeof useBrandsQuery>;
export type BrandsLazyQueryHookResult = ReturnType<typeof useBrandsLazyQuery>;
export type BrandsQueryResult = ApolloReactCommon.QueryResult<BrandsQuery, BrandsQueryVariables>;
export const CentersDocument = gql`
    query Centers($where: CenterWhereInput) {
  centers(where: $where) {
    id
    name
    zipcode
    address
    detailedAddress
    shippingAvailable
    isActive
    settings {
      id
      zone
      rack
      level
      deletedAt
    }
    locations {
      zone
      rack
      level
      isActive
    }
    useLocation
    deletedAt
  }
}
    `;

/**
 * __useCentersQuery__
 *
 * To run a query within a React component, call `useCentersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCentersQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCentersQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCentersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CentersQuery, CentersQueryVariables>) {
        return ApolloReactHooks.useQuery<CentersQuery, CentersQueryVariables>(CentersDocument, baseOptions);
      }
export function useCentersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CentersQuery, CentersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CentersQuery, CentersQueryVariables>(CentersDocument, baseOptions);
        }
export type CentersQueryHookResult = ReturnType<typeof useCentersQuery>;
export type CentersLazyQueryHookResult = ReturnType<typeof useCentersLazyQuery>;
export type CentersQueryResult = ApolloReactCommon.QueryResult<CentersQuery, CentersQueryVariables>;
export const LogisticsDocument = gql`
    query logistics($where: LogisticWhereInput, $orderBy: [LogisticOrderByInput!], $take: Int, $skip: Int, $cursor: LogisticWhereUniqueInput) {
  logistics(where: $where, orderBy: $orderBy, take: $take, skip: $skip, cursor: $cursor) {
    id
    company {
      id
      name
      deletedAt
    }
  }
}
    `;

/**
 * __useLogisticsQuery__
 *
 * To run a query within a React component, call `useLogisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLogisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLogisticsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useLogisticsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LogisticsQuery, LogisticsQueryVariables>) {
        return ApolloReactHooks.useQuery<LogisticsQuery, LogisticsQueryVariables>(LogisticsDocument, baseOptions);
      }
export function useLogisticsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LogisticsQuery, LogisticsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LogisticsQuery, LogisticsQueryVariables>(LogisticsDocument, baseOptions);
        }
export type LogisticsQueryHookResult = ReturnType<typeof useLogisticsQuery>;
export type LogisticsLazyQueryHookResult = ReturnType<typeof useLogisticsLazyQuery>;
export type LogisticsQueryResult = ApolloReactCommon.QueryResult<LogisticsQuery, LogisticsQueryVariables>;
export const namedOperations = {
  Query: {
    Sellers: 'Sellers',
    Brands: 'Brands',
    Centers: 'Centers',
    logistics: 'logistics'
  },
  Mutation: {
    Login: 'Login',
    uploadExcelStock: 'uploadExcelStock'
  }
}