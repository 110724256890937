import { useState } from 'react';
import XLSX from 'xlsx';

// TODO: Provide loading state?
const useExcelReader = () => {
  const [data, setData] = useState<any[]>([]);
  const reader = new FileReader();

  reader.onabort = () => console.log('file reading was aborted');
  reader.onerror = () => console.log('file reading has failed');
  reader.onload = () => {
    const binaryStr = reader.result;
    const wb = XLSX.read(binaryStr, {
      type: !!reader.readAsBinaryString ? 'binary' : 'array',
      cellDates: false,
    });
    const wsname = wb.SheetNames[0];
    const ws = wb.Sheets[wsname];

    /* Convert array of arrays */
    const data: Object[] = XLSX.utils.sheet_to_json(ws, {
      header: 1,
      raw: false,
    });

    setData(data);
  };

  return { data, read: reader.readAsBinaryString.bind(reader) };
};

export default useExcelReader;
