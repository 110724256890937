import React from "react";
import CancelIcon from "@material-ui/icons/Cancel";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { main } from "./color";
import { ThemeOptions } from "@material-ui/core";

const OPACITY_HOVER = 0.04;
const OPACITY_SELECTED = 0.08;
const OPACITY_FOCUS = 0.12;
const OPACITY_ACTIVE = 0.12;

const defaultTheme: ThemeOptions = {
  // https://material-ui.com/customization/palette/#custom-palette
  palette: {
    primary: {
      main: main[0],
    },
    secondary: {
      main: main[2],
    },
    error: {
      main: "#E60012",
    },
    action: {
      hover: fade("#1ec5e2", OPACITY_HOVER),
      selected: fade("#1ec5e2", OPACITY_SELECTED),
      active: fade("#1ec5e2", OPACITY_ACTIVE),
      focus: fade("#1ec5e2", OPACITY_FOCUS),
    },
  },
  typography: {
    fontFamily: "Noto Sans KR",
    htmlFontSize: 10,
  },
  overrides: {
    MuiButton: {
      root: {
        minWidth: 80,
        "& > span > p": {
          fontWeight: 500,
        },
      },
      containedPrimary: {
        backgroundColor: main[0],
        "&:hover": {
          backgroundColor: main[2],
        },
        "&$$$": {
          color: "white",
        },
      },
      outlinedSecondary: {
        color: main[0],
        border: `1px solid ${main[0]}`,
        "&:hover": {
          border: `1px solid ${main[2]}`,
          color: main[2],
          backgroundColor: fade(main[2], 0.04),
        },
      },
      text: {
        "&:hover": {
          color: main[2],
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: 6,
        color: main[0],
        fill: main[0],
        "&:hover": {
          backgroundColor: "transparent",
          fill: main[2],
          color: main[2],
        },
        "&$disabled": {
          fill: "#a8aab1",
        },
      },
    },
    MuiListItem: {
      root: {
        "&$selected": {
          color: main[2],
          backgroundColor: fade(main[2], OPACITY_FOCUS),
          "&$focusVisible": {
            backgroundColor: fade(main[2], OPACITY_SELECTED + OPACITY_FOCUS),
          },
        },
        "&:hover": {
          textDecoration: "none",
          backgroundColor: `${fade(main[2], OPACITY_HOVER)} !important;`,
          color: main[2],
        },
      },
    },
    MuiListItemText: {
      primary: {
        fontSize: "1.8rem",
      },
    },
    MuiListItemIcon: {
      root: {
        color: "inherit",
      },
    },
    MuiPaper: {
      root: {
        color: main[0],
      },
      rounded: {
        borderRadius: "10px",
      },
    },
    MuiDialogActions: {
      spacing: {
        "& > :not(:first-child)": {
          marginLeft: "2rem",
        },
      },
    },
    MuiFormHelperText: {
      root: {
        marginTop: 0,
      },
      marginDense: {
        marginTop: 0,
      },
    },
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: "transparent",
        },
      },
      iconOutlined: {
        right: 12,
      },
    },
    MuiMenu: {
      paper: {
        borderRadius: 3,
      },
    },
    MuiSwitch: {
      root: {
        width: 54,
        height: 34,
        padding: 8,
      },
      thumb: {
        width: 16,
        height: 16,
        color: "#ffffff",
      },
      track: {
        borderRadius: 20,
        backgroundColor: "#a8aab1",
        opacity: 1,
      },
      colorSecondary: {
        "&$checked": {
          color: "#ffffff",
        },
        color: "#ffffff",
        "&$checked+$track": {
          backgroundColor: `${main[2]} !important`,
          opacity: 1,
        },
        "&$disabled+$track": {
          backgroundColor: "#a8aab1",
          opacity: 1,
        },
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        "&$checked": {
          color: main[2],
        },
      },
    },
    MuiInput: {
      underline: {
        "&:before": {
          borderBottom: "1px solid #a8aab1",
        },
      },
    },
    MuiInputBase: {
      input: {
        height: "1.2em",
      },
    },
    MuiOutlinedInput: {
      root: {
        "&$focused $notchedOutline": {
          borderWidth: 1,
        },
      },
      input: {
        paddingLeft: 16,
      },
      adornedEnd: {
        paddingRight: 0,
      },
    },
    MuiInputAdornment: {
      positionEnd: {
        marginRight: 6,
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: main[2],
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 2,
        paddingBottom: 2,
        borderRadius: 1,
      },
      tooltipPlacementBottom: {
        margin: 0,
        "@media all": {
          margin: 0,
        },
      },
    },
    MuiChip: {
      root: {
        height: 24,
        fontSize: 14,
      },
      deleteIcon: {
        width: 16,
        height: 16,
      },
    },
    MuiTable: {
      root: {
        border: "1px solid #e4e4e4",
      },
    },
    MuiTableCell: {
      head: {
        color: "inherit",
        fontSize: 16,
      },
      body: {
        color: "inherit",
        fontSize: 14,
      },
    },
    MuiLink: {
      root: {
        color: main[1],
      },
    },
  },
  props: {
    MuiDialog: {
      disableBackdropClick: true,
    },
  },
};

export default defaultTheme;
