import React, { useState } from "react";
import styled from "styled-components";
import { useApolloClient } from "@apollo/react-hooks";
import { Formik } from "formik";
import * as Yup from "yup";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import LinearProgress from "@material-ui/core/LinearProgress";
import { FormHelperText, Typography } from "@material-ui/core";
import SigninBackground from "../../components/icons/SigninBackground";
import { ReactComponent as LogoImage } from "../../images/logos/logo_login.svg";
import { CompanyType, useLoginMutation } from "../../generated/graphql";
import OutlinedTextInputField from "../../components/inputs/OutlinedTextInputField";

const Root = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  backgroundColor: theme.palette.background.paper,
}));

const LeftSection = styled.div(({ theme }) => ({
  marginRight: theme.spacing(18),
  [theme.breakpoints.up("lg")]: {
    display: "block",
  },
  display: "none",
}));

const RightSection = styled.div(({ theme }) => ({
  marginTop: -1 * theme.spacing(10),
}));

const CenteredContainer = styled.div({
  display: "flex",
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "baseline",
  maxWidth: 460,
  borderRadius: "14px",
  boxShadow: "none",
  [theme.breakpoints.up("sm")]: {
    boxShadow: "0 5px 10px 0 rgba(0, 0, 0, 0.16)",
    padding: theme.spacing(14.5, 8.5, 13),
  },
  padding: theme.spacing(2),
}));

const LoginButtonRow = styled.div({
  display: "flex",
  justifyContent: "flex-end",
});

const StyledLogo = styled(LogoImage)(({ theme }) => ({
  margin: "0 auto",
  marginBottom: theme.spacing(9),
  width: 300,
  height: 75,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  height: theme.spacing(6),
}));

const StyledLinearProgress = styled(LinearProgress)({
  marginLeft: "auto",
  marginRight: "auto",
});

const FullWidthForm = styled.form({
  width: "100%",
});

const MainImage = styled(SigninBackground)(({ theme }) => ({
  width: 761,
  height: 390,
  marginTop: theme.spacing(9.5),
}));

const TypoGrey = styled(Typography)({
  color: "#7d808a",
});

export default ({ onSignIn }: any) => {
  const client = useApolloClient();
  const [error, setError] = useState(false);
  const [login, { loading }] = useLoginMutation({
    onError: (e) => {
      console.log(e);
    },
    onCompleted: ({ login }) => {
      const user = login.user;
      if (user.type !== CompanyType.Super) {
        setError(true);
        return;
      }
      const token = login.token;

      localStorage.setItem("token", token);

      const auth = {
        isAuthenticated: true,
        companyId: user?.company.id || "",
        user,
      };

      const serializedAuth = JSON.stringify(auth);
      localStorage.setItem("auth", serializedAuth);

      client.writeData({ data: auth });
      onSignIn();
    },
  });

  return (
    <Root>
      <CenteredContainer>
        <LeftSection>
          <Typography variant="h6" gutterBottom>
            e-commerce fulfillment 물류파트너는 이지스토리지
          </Typography>
          <TypoGrey variant="subtitle1">
            누구든지 신뢰할 수 있는 편리하고 효율적인 물류서비스를 제공합니다
          </TypoGrey>
          <MainImage />
        </LeftSection>
        <RightSection>
          {loading && <StyledLinearProgress color="secondary" />}
          <StyledPaper>
            <StyledLogo />
            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              validationSchema={Yup.object({
                email: Yup.string()
                  .trim()
                  .email()
                  .required(),
                password: Yup.string().required(),
              })}
              onSubmit={({ email, password }, { setSubmitting }) => {
                login({
                  variables: { email: email.trim(), password },
                }).then(() => {
                  setSubmitting(false);
                });
              }}
            >
              {({ values, handleChange, handleSubmit, isSubmitting }) => {
                return (
                  <FullWidthForm onSubmit={handleSubmit}>
                    <OutlinedTextInputField
                      name="email"
                      variant="outlined"
                      InputProps={{
                        inputProps: {
                          "data-testid": "input-id",
                          autoComplete: "email",
                        },
                      }}
                      label="이메일"
                      size="medium"
                      margin="normal"
                      disabled={isSubmitting}
                      fullWidth
                    />
                    <OutlinedTextInputField
                      name="password"
                      variant="outlined"
                      InputProps={{
                        type: "password",
                        inputProps: {
                          "data-testid": "input-password",
                          autoComplete: "current-password",
                        },
                      }}
                      label="비밀번호"
                      size="medium"
                      margin="normal"
                      disabled={isSubmitting}
                      fullWidth
                    />
                    {error && (
                      <FormHelperText error>
                        올바른 계정이 아닙니다
                      </FormHelperText>
                    )}
                    <LoginButtonRow>
                      <StyledButton
                        data-testid="login-button"
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={isSubmitting}
                        disableElevation
                      >
                        로그인
                      </StyledButton>
                    </LoginButtonRow>
                  </FullWidthForm>
                );
              }}
            </Formik>
          </StyledPaper>
        </RightSection>
      </CenteredContainer>
    </Root>
  );
};
