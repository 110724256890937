/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Header from "./Header";
import Drawer from "./Drawer";
import { Container } from "@material-ui/core";

const drawerWidth = 240;

const Root = styled.div(({ theme }) => ({
  display: "flex",
  paddingBottom: theme.spacing(14),
  background: "#fff",
}));

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    paddingTop: 64,
    position: "relative",
  },
  container: {
    paddingTop: theme.spacing(6),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export interface Props {
  readonly children: JSX.Element[] | JSX.Element;
}

export default ({ children }: Props) => {
  const [open, setOpen] = useState(true);
  const classes = useStyles();

  return (
    <Root>
      <Header
        onToggleMenu={() => {
          setOpen((prev: any) => !prev);
        }}
      />
      <Drawer open={open} />
      <div className={classes.content}>
        <Container className={classes.container} maxWidth="lg">
          {children}
        </Container>
      </div>
    </Root>
  );
};
