import React, { useState, useEffect } from "react";
import styled from "styled-components";
import clsx from "clsx";
import { Link, useLocation } from "react-router-dom";
import { useQuery, useApolloClient } from "@apollo/react-hooks";
import gql from "graphql-tag";
import Drawer from "@material-ui/core/Drawer";
import Collapse from "@material-ui/core/Collapse";
import { makeStyles, Theme, fade } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import { ReactComponent as Home } from "../../images/menus/home.svg";
import { ReactComponent as Inbound } from "../../images/menus/inbound.svg";
import { ReactComponent as AddPrestock } from "../../images/menus/inbound_registation.svg";

import { main } from "../../constants/color";

const drawerWidth = 240;

const GET_USER = gql`
  {
    sellerId @client
    user @client {
      id
      email
      name
      role
      type
      company {
        id
      }
    }
  }
`;

const AbsoluteCircle = styled.div(({ theme }) => ({
  position: "absolute",
  width: 56,
  height: 56,
  background: `${fade(main[2], theme.palette.action.focusOpacity)}`,
  borderRadius: "50%",
  left: 9,
}));

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(9) + 1,
  },
  listItem: {
    position: "relative",
    borderTopRightRadius: "30px",
    borderBottomRightRadius: "30px",
    fontSize: theme.typography.pxToRem(18),
    height: 48,
    transition: theme.transitions.create("padding", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  listItemClose: {
    paddingLeft: theme.spacing(3),
    backgroundColor: "transparent",
  },
  transparentBackground: {
    backgroundColor: "transparent !important",
  },
  toolbar: theme.mixins.toolbar,
  nested: {
    paddingLeft: theme.spacing(4),
  },
  iconSelected: {
    fill: main[2],
  },
}));

export default ({ open }: any) => {
  const classes = useStyles();
  const client = useApolloClient();
  const [openIndex, setOpenIndex] = useState(-1);
  const [subIndex, setSubIndex] = useState<string>("");
  const { loading, error, data } = useQuery(GET_USER);
  const { pathname } = useLocation();

  useEffect(() => {
    let matchingOpenIndex = -1;
    let matchingSubIndex = "";

    rootMenus.some(({ menus }, i: number) =>
      menus.some((menu: any, j: number) => {
        if (
          menu?.to === pathname ||
          menu?.linkedList?.includes(`/${pathname.split("/")[1]}`)
        ) {
          matchingOpenIndex = i;
          matchingSubIndex = `${i}${j}`;
          return true;
        }
        return false;
      })
    );

    setOpenIndex(matchingOpenIndex);
    setSubIndex(matchingSubIndex);
  }, [pathname]);

  const handleClick = (index: number) => () => {
    if (index === openIndex) {
      setOpenIndex(-1);
    } else {
      setOpenIndex(index);
    }
  };

  const handleSubClick = (index: string) => () => {
    setSubIndex(index);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      anchor="left"
      open={open}
    >
      <div className={classes.toolbar} />
      <ListItem
        className={clsx(classes.listItem, {
          [classes.listItemClose]: !open,
        })}
        component={Link}
        to="/"
        button
        selected={pathname === "/" && open}
      >
        {pathname === "/" && !open && <AbsoluteCircle />}
        <ListItemIcon>
          <Home style={{ width: 26, height: 26 }} />
        </ListItemIcon>
        <ListItemText primary={"Home"} />
      </ListItem>
      {rootMenus.map(
        ({ name, icon: IconComponent, menus = [] }: any, index) => {
          return (
            <React.Fragment key={index}>
              <ListItem
                className={clsx(classes.listItem, {
                  [classes.listItemClose]: !open,
                })}
                button
                onClick={handleClick(index)}
              >
                <ListItemIcon>
                  <IconComponent style={{ width: 26, height: 26 }} />
                </ListItemIcon>
                <ListItemText primary={name} />
                {openIndex === index ? <ExpandLess /> : <ExpandMore />}
              </ListItem>

              <Collapse in={openIndex === index} timeout="auto" unmountOnExit>
                <>
                  <List component="div" disablePadding>
                    {menus.map(
                      (
                        { name, to = "/", icon: IconComponent, pageId }: any,
                        i: number
                      ) => {
                        const isSelected = subIndex === `${index}${i}`;

                        return (
                          <ListItem
                            onClick={handleSubClick(`${index}${i}`)}
                            className={clsx(classes.listItem, {
                              [classes.nested]: open,
                              [classes.listItemClose]: !open,
                            })}
                            classes={{
                              selected: clsx({
                                [classes.transparentBackground]: !open,
                              }),
                            }}
                            component={Link}
                            key={i}
                            to={to}
                            button
                            selected={isSelected}
                          >
                            {!open && isSelected && <AbsoluteCircle />}
                            <ListItemIcon
                              className={clsx({
                                [classes.iconSelected]: isSelected,
                              })}
                            >
                              <IconComponent
                                style={{ width: 26, height: 26 }}
                              />
                            </ListItemIcon>
                            <ListItemText primary={name} />
                          </ListItem>
                        );
                      }
                    )}
                  </List>
                </>
              </Collapse>
            </React.Fragment>
          );
        }
      )}
    </Drawer>
  );
};

const rootMenus = [
  {
    name: "입고",
    icon: Inbound,
    menus: [
      {
        name: "대량 입고",
        icon: AddPrestock,
        to: "/add-prestock",
      },
    ],
  },
];
